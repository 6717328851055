<template>
  <TransitionRoot as="template" :show="seedetail">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      {{ contact.firstname }} {{ contact.lastname }}
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="closePanel"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Voici toutes les informations du contact.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <!-- Visualize  -->
                  <div v-if="editionmode == false">
                    <div class="absolute inset-0 py-6 px-4 sm:px-6">
                      <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                        <dl class="space-y-8 px-4 sm:px-6 sm:space-y-6">
                          <div>
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Personnes liées
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                              v-if="contact.inmates"
                            >
                              <ul class="list-disc">
                                <li
                                  v-for="inmate in contact.inmates"
                                  :key="inmate.id"
                                >
                                  {{ inmate.firstname }} {{ inmate.lastname }}
                                  <span v-if="inmate.bound"
                                    >({{ inmate.bound.label }})</span
                                  >
                                </li>
                              </ul>
                            </dd>
                          </div>
                          <div v-if="contact.gender">
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Genre
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                            >
                              {{ contact.gender.label }}
                            </dd>
                          </div>
                          <div>
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Email
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                            >
                              <a :href="'mailto:' + contact.email">{{
                                contact.email
                              }}</a>
                            </dd>
                          </div>
                          <div>
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Téléphone
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                            >
                              {{ contact.phone }}
                            </dd>
                          </div>
                          <div>
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Adresse complète
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                            >
                              {{ contact.address }}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <!-- Visualize ends -->
                  <!-- Edit  -->
                  <div v-if="editionmode == true">
                    <h4
                      class="font-bold text-xl text-gray-900 sm:text-2xl mb-2"
                    >
                      Modifier ce contact
                    </h4>
                    <label class="block text-sm font-medium text-gray-700 mb-2"
                      >Vous pouvez éditer ce contact en modifiant les valeurs
                      des champs ci-dessous.</label
                    >
                    <div class="mt-1">
                      <label
                        for="project-name"
                        class="block text-sm font-medium text-gray-900"
                      >
                        Genre
                      </label>
                      <div class="mt-1">
                        <select
                          id="genre"
                          name="genre"
                          v-model="edition.gender_id"
                          class="
                            mt-1
                            block
                            w-full
                            pl-3
                            pr-10
                            py-2
                            text-base
                            border-gray-300
                            focus:outline-none
                            focus:ring-indigo-500
                            focus:border-indigo-500
                            sm:text-sm
                            rounded-md
                          "
                        >
                          <option
                            v-for="gender in genders"
                            :key="gender.id"
                            :value="gender.id"
                          >
                            {{ gender.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-1">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Prénom</label
                      >
                      <div class="mt-1">
                        <input
                          type="firstname"
                          name="firstname"
                          id="firstname"
                          v-model="edition.firstname"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-1">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Nom</label
                      >
                      <div class="mt-1">
                        <input
                          type="lastname"
                          name="lastname"
                          id="lastname"
                          v-model="edition.lastname"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-1">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Email</label
                      >
                      <div class="mt-1">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          v-model="edition.email"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                          placeholder="you@example.com"
                        />
                      </div>
                    </div>
                    <div class="mt-1">
                      <label
                        for="phone"
                        class="block text-sm font-medium text-gray-700"
                        >Téléphone</label
                      >
                      <div class="mt-1">
                        <input
                          type="phone"
                          name="phone"
                          id="phone"
                          v-model="edition.phone"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-2">
                      <label
                        for="address"
                        class="block text-sm font-medium text-gray-700"
                        >Modifier l'adresse complète</label
                      >
                      <div class="mt-1">
                        <textarea
                          rows="4"
                          name="address"
                          v-model="edition.address"
                          id="address"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Edit ends -->
                  <!-- /End replace -->
                </div>
                <div class="rounded-md bg-red-50 p-4" v-if="imsure == true">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <ExclamationCircleIcon
                        class="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ml-3 md:justify-between">
                      <p class="text-sm text-red-700">
                        Vous êtes sur le point de supprimer ce contact, ainsi
                        que toutes les relations avec toutes les personnes
                        détenues.
                      </p>
                      <p class="mt-3 text-sm">
                        <a
                          @click.prevent="deleteContact"
                          class="
                            whitespace-nowrap
                            font-medium
                            text-red-700
                            hover:text-red-600
                            cursor-pointer
                          "
                          >Je confirme <span aria-hidden="true">&rarr;</span></a
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Action buttons -->
                <div
                  v-if="editionmode == false"
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      v-if="relationupdater == true"
                      @click.prevent="removeContactRelation"
                      type="button"
                      class="
                        mr-auto
                        p-1
                        rounded-full
                        hover:text-red-600
                        focus:outline-none
                        focus:ring-offset-gray-800
                        focus:ring-white
                      "
                    >
                      <UserRemoveIcon class="h-6 w-6 mr-2" aria-hidden="true" />
                    </button>
                    <button
                      v-if="relationupdater == false"
                      @click.prevent="deleteContact"
                      type="button"
                      class="
                        mr-auto
                        p-1
                        rounded-full
                        hover:text-red-600
                        focus:outline-none
                        focus:ring-offset-gray-800
                        focus:ring-white
                      "
                    >
                      <TrashIcon class="h-6 w-6 mr-2" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="closePanel"
                    >
                      Fermer
                    </button>
                    <button
                      @click.prevent="enterEditionMode"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Éditer le contact
                    </button>
                  </div>
                </div>
                <div
                  v-if="editionmode == true"
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="closePanel"
                    >
                      Ne rien changer
                    </button>
                    <button
                      @click.prevent="updateContact"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Enregister les changements
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import {
  TrashIcon, UserRemoveIcon, ExclamationCircleIcon,
} from '@heroicons/vue/solid';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    TrashIcon,
    UserRemoveIcon,
    ExclamationCircleIcon,
  },
  setup() {
    const open = ref(true);
    const editionmode = ref(false);
    const imsure = ref(false);

    return {
      open,
      editionmode,
      imsure,
      edition: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        address: null,
        gender_id: null,
      },
    };
  },
  props: {
    contact: Object,
    seedetail: Boolean,
    inmate_id: Number,
    relationupdater: Boolean,
  },
  created() {
    this.$http.get('/api/genders')
      .then((response) => {
        this.genders = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
  methods: {
    enterEditionMode() {
      this.setData();
      this.editionmode = true;
      this.imsure = false;
    },
    setData() {
      this.edition.firstname = this.contact.firstname;
      this.edition.lastname = this.contact.lastname;
      this.edition.email = this.contact.email;
      this.edition.phone = this.contact.phone;
      this.edition.address = this.contact.address;
      this.edition.gender_id = this.contact.gender_id;
    },
    closePanel() {
      this.editionmode = false;
      this.imsure = false;
      this.$emit('shut');
    },
    updateContact() {
      this.$http.patch('api/contact-update', {
        id: this.contact.id,
        gender_id: this.edition.gender_id,
        firstname: this.edition.firstname,
        lastname: this.edition.lastname,
        email: this.edition.email,
        phone: this.edition.phone,
        address: this.edition.address,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('contactupdated');
        this.editionmode = false;
      }).catch((error) => {
        window.console.log(error);
      });
    },
    removeContactRelation() {
      this.$http.patch('api/inmate-contact-remove', {
        id: this.inmate_id,
        contact_id: this.contact.id,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('contactupdated');
      }).catch((error) => {
        window.console.log(error);
      });
    },
    deleteContact() {
      if (this.imsure === true) {
        this.$http.patch('api/contact-delete', {
          id: this.contact.id,
        }).then((response) => {
          window.console.log(response.data);
          this.$emit('contactupdated');
          this.imsure = false;
        }).catch((error) => {
          window.console.log(error);
        });
      } else {
        this.imsure = true;
      }
    },
  },
};
</script>
