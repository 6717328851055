<template>
  <div>
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Données générales
    </h3>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
      <div
        v-for="item in items"
        :key="item.name"
        class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
      >
        <dt class="text-sm font-medium text-gray-500 truncate">
          {{ item.name }}
        </dt>
        <dd class="mt-1 text-3xl font-semibold text-gray-900" v-if="stats">
          <span v-if="item.name == 'Dossiers'">{{ stats.inmates }}</span>
          <span v-else-if="item.name == 'Sollicitations'">{{
            stats.sollicitations
          }}</span>
          <span v-else-if="item.name == 'Actions'">{{ stats.actions }}</span>
        </dd>
      </div>
    </dl>
  </div>
  <div class="mt-12">
    <div class="flex items-center">
      <h3 class="text-lg leading-6 font-medium text-gray-900">Exporter</h3>
      <button
        @click.prevent="resetSearch"
        type="button"
        class="
          ml-auto
          inline-flex
          items-center
          p-2
          border border-transparent
          rounded-full
          shadow-sm
          text-white
          bg-green-600
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-green-500
        "
      >
        <RefreshIcon class="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
    <div class="mt-2 max-w-xl text-sm text-gray-500">
      <p>
        Vous pouvez paramétrer vos exports à partir de cette vue. Les paramètres
        valent à la fois pour l'export des sollicitations et des dossiers. Les
        thèmes et sous-thèmes ne sont valables que pour les sollicitations et
        non pour les dossiers.
      </p>
    </div>

    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
      <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt class="text-sm font-medium text-gray-500 truncate">
          Date de début
        </dt>
        <div class="mt-3">
          <input
            type="datetime-local"
            name="startingdate"
            v-model="exportcsv.startingdate"
            class="
              shadow-sm
              focus:ring-indigo-500 focus:border-indigo-500
              block
              w-full
              sm:text-sm
              border-gray-300
              rounded-md
            "
          />
        </div>
      </div>
      <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt class="text-sm font-medium text-gray-500 truncate">Date de fin</dt>
        <div class="mt-3">
          <input
            type="datetime-local"
            name="endingdate"
            v-model="exportcsv.endingdate"
            class="
              shadow-sm
              focus:ring-indigo-500 focus:border-indigo-500
              block
              w-full
              sm:text-sm
              border-gray-300
              rounded-md
            "
          />
        </div>
      </div>
      <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt class="text-sm font-medium text-gray-500 truncate">DISP</dt>
        <div class="mt-4">
          <select
            id="disp"
            name="disp"
            autocomplete="disp"
            v-model="chosendisp"
            class="
              shadow-sm
              focus:ring-indigo-500 focus:border-indigo-500
              block
              w-full
              sm:text-sm
              border-gray-300
              rounded-md
            "
          >
            <option v-for="disp in disps" :key="disp.id" :value="disp">
              {{ disp.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt class="text-sm font-medium text-gray-500 truncate">
          Établissement
        </dt>
        <div class="mt-4">
          <select
            v-if="chosendisp"
            id="prison"
            name="prison"
            autocomplete="prison"
            v-model="chosenprison"
            class="
              shadow-sm
              focus:ring-indigo-500 focus:border-indigo-500
              block
              w-full
              sm:text-sm
              border-gray-300
              rounded-md
            "
          >
            <option
              v-for="prison in chosendisp.prisons"
              :key="prison.id"
              :value="prison.id"
            >
              {{ prison.title }}
            </option>
          </select>
          <p class="text-sm font-small text-gray-500 truncate" v-else>
            Choisissez d'abord la DISP
          </p>
        </div>
      </div>
    </dl>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
      <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt class="text-sm font-medium text-gray-500 truncate">Thème</dt>
        <div class="mt-4">
          <select
            id="theme"
            name="theme"
            autocomplete="theme"
            v-model="chosentheme"
            class="
              shadow-sm
              focus:ring-indigo-500 focus:border-indigo-500
              block
              w-full
              sm:text-sm
              border-gray-300
              rounded-md
            "
          >
            <option v-for="theme in themes" :key="theme.id" :value="theme">
              {{ theme.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <dt class="text-sm font-medium text-gray-500 truncate">Sous-thème</dt>
        <div class="mt-4">
          <select
            v-if="chosentheme"
            id="subtheme"
            name="subtheme"
            autocomplete="theme"
            v-model="chosensubtheme"
            class="
              shadow-sm
              focus:ring-indigo-500 focus:border-indigo-500
              block
              w-full
              sm:text-sm
              border-gray-300
              rounded-md
            "
          >
            <option
              v-for="subtheme in chosentheme.subthemes"
              :key="subtheme.id"
              :value="subtheme.id"
            >
              {{ subtheme.title }}
            </option>
          </select>
        </div>
      </div>
    </dl>
    <div class="text-center mt-6">
      <button
        @click="downloadSollicitations"
        type="button"
        class="
          inline-flex
          items-center
          px-4
          py-2
          border border-transparent
          shadow-sm
          text-sm
          font-medium
          rounded-md
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          ml-6
          mr-6
          mb-6
          md:mb-0
        "
      >
        <DownloadIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Exporter les sollicitations
      </button>
      <button
        @click="downloadInmates"
        type="button"
        class="
          inline-flex
          items-center
          px-4
          py-2
          border border-transparent
          shadow-sm
          text-sm
          font-medium
          rounded-md
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          ml-6
          mr-6
          mb-6
          md:mb-0
        "
      >
        <UsersIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Exporter les dossiers
      </button>
      <button
        @click="downloadPhonecalls"
        type="button"
        class="
          inline-flex
          items-center
          px-4
          py-2
          border border-transparent
          shadow-sm
          text-sm
          font-medium
          rounded-md
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          ml-6
          mr-6
          mb-6
          md:mb-0
        "
      >
        <PhoneIncomingIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Exporter les appels
      </button>
      <button
        @click="downloadGuides"
        type="button"
        class="
          inline-flex
          items-center
          px-4
          py-2
          border border-transparent
          shadow-sm
          text-sm
          font-medium
          rounded-md
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          ml-6
          mr-6
          mb-6
          md:mb-0
        "
      >
        <BookOpenIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Exporter les guides
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import { RefreshIcon } from '@heroicons/vue/outline';
import {
  DownloadIcon, UsersIcon, BookOpenIcon, PhoneIncomingIcon,
} from '@heroicons/vue/solid';

const stats = ref();
const prisons = ref();
const disps = ref();
const themes = ref();
const subthemes = ref();
const chosentheme = ref();
const chosensubtheme = ref();
const chosendisp = ref();
const chosenprison = ref();
const items = [
  {
    name: 'Dossiers',
  },
  {
    name: 'Sollicitations',
  },
];

export default {
  components: {
    RefreshIcon,
    DownloadIcon,
    UsersIcon,
    BookOpenIcon,
    PhoneIncomingIcon,
  },
  setup() {
    return {
      stats,
      items,
      prisons,
      disps,
      themes,
      subthemes,
      chosentheme,
      chosensubtheme,
      chosendisp,
      chosenprison,
      exportcsv: {
        startingdate: null,
        endingdate: null,
        prison: null,
      },
    };
  },
  created() {
    this.moment = moment;
    this.setDates();
  },
  mounted() {
    this.getGeneralStatistics();
    this.getPrisons();
    this.getDisps();
    this.getThemes();
  },
  methods: {
    setDates() {
      const proposedstartingdate = moment().startOf('year').format('YYYY-MM-DDTHH:mm');
      this.exportcsv.startingdate = proposedstartingdate;
      const proposeddate = moment(new Date()).format('YYYY-MM-DDTHH:mm');
      this.exportcsv.endingdate = proposeddate;
    },
    getPrisons() {
      this.$http.get('/api/prisons')
        .then((response) => {
          this.prisons = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getDisps() {
      this.$http.get('api/disps')
        .then((response) => {
          this.disps = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getThemes() {
      this.$http.get('/api/sollicitations-themes')
        .then((response) => {
          this.themes = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getGeneralStatistics() {
      this.$http.get('/api/general-stats/')
        .then((response) => {
          this.stats = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    resetSearch() {
      this.setDates();
      this.chosendisp = null;
      this.chosensubtheme = null;
      this.chosenprison = null;
      this.chosentheme = null;
    },
    downloadSollicitations() {
      let startingDate = '';
      let endingDate = '';
      let themeId = '';
      let subthemeId = '';
      let prisonId = '';
      let dispId = '';
      if (this.exportcsv.startingdate) {
        startingDate = `startingdate=${this.exportcsv.startingdate}`;
      }
      if (this.exportcsv.endingdate) {
        endingDate = `&endingdate=${this.exportcsv.endingdate}`;
      }
      if (this.chosentheme) {
        themeId = `&theme_id=${this.chosentheme.id}`;
      }
      if (this.chosensubtheme) {
        subthemeId = `&subtheme_id=${this.chosensubtheme}`;
      }
      if (this.chosendisp) {
        dispId = `&disp=${this.chosendisp.id}`;
      }
      if (this.chosenprison) {
        prisonId = `&prison_id=${this.chosenprison}`;
      }
      this.$http.get(`api/sollicitations_excel/?${startingDate}${themeId}${subthemeId}${dispId}${prisonId}${endingDate}`, {
        responseType: 'blob',
      }).then((response) => {
        window.console.log(response.data.type);
        const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
        const filename = `${currentDate}_oip_sollicitations`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
    downloadInmates() {
      let startingDate = '';
      let endingDate = '';
      let themeId = '';
      let subthemeId = '';
      let prisonId = '';
      let dispId = '';
      if (this.exportcsv.startingdate) {
        startingDate = `startingdate=${this.exportcsv.startingdate}`;
      }
      if (this.exportcsv.endingdate) {
        endingDate = `&endingdate=${this.exportcsv.endingdate}`;
      }
      if (this.chosentheme) {
        themeId = `&theme_id=${this.chosentheme.id}`;
      }
      if (this.chosensubtheme) {
        subthemeId = `&subtheme_id=${this.chosensubtheme}`;
      }
      if (this.chosendisp) {
        dispId = `&disp=${this.chosendisp.id}`;
      }
      if (this.chosenprison) {
        prisonId = `&prison_id=${this.chosenprison}`;
      }
      this.$http.get(`api/inmatesexport/?${startingDate}${themeId}${subthemeId}${dispId}${prisonId}${endingDate}`, {
        responseType: 'blob',
      }).then((response) => {
        window.console.log(response.data.type);
        const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
        const filename = `${currentDate}_oip_inmates`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.csv`);
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
    downloadPhonecalls() {
      this.$http.get('api/phonecalls_export', {
        responseType: 'blob',
      }).then((response) => {
        window.console.log(response.data.type);
        const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
        const filename = `${currentDate}_oip_phonecalls`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
    downloadGuides() {
      let startingDate = '';
      let endingDate = '';
      let prisonId = '';
      let dispId = '';
      if (this.exportcsv.startingdate) {
        startingDate = `startingdate=${this.exportcsv.startingdate}`;
      }
      if (this.exportcsv.endingdate) {
        endingDate = `&endingdate=${this.exportcsv.endingdate}`;
      }
      if (this.chosendisp) {
        dispId = `&disp=${this.chosendisp.id}`;
      }
      if (this.chosenprison) {
        prisonId = `&prison_id=${this.chosenprison}`;
      }
      this.$http.get(`api/guidesexport/?${startingDate}${dispId}${prisonId}${endingDate}`, {
        responseType: 'blob',
      }).then((response) => {
        window.console.log(response.data.type);
        const filename = 'oip_guides';
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.csv`);
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
  },
};
</script>
