<template>
  <TransitionRoot as="template" :show="handleguide">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Gérer cet envoi de guide
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Vous pouvez gérer, ici, le statut de cet envoi de guide,
                      et compléter les différentes informations au fur et à
                      mesure de sa progression.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div v-if="editionmode == false">
                    <h4
                      class="font-bold text-xl text-gray-900 sm:text-2xl mb-2"
                    >
                      Progression
                    </h4>
                    <div class="mt-6">
                      <h3 class="font-medium text-gray-900">Statut</h3>
                      <dl
                        class="
                          mt-2
                          border-t border-b border-gray-200
                          divide-y divide-gray-200
                        "
                      >
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">État</dt>
                          <dd class="text-gray-900">
                            {{ guide.status }}
                          </dd>
                        </div>
                        <div
                          v-if="guide.authorization_sending_date"
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Autorisation envoyée le</dt>
                          <dd class="text-gray-900">
                            {{
                              moment(guide.authorization_sending_date).format(
                                "dddd D MMMM YYYY"
                              )
                            }}
                          </dd>
                        </div>
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Confirmation reçue ?</dt>
                          <dd class="text-gray-900">
                            <span
                              v-if="guide.inmate_confirmation == false"
                              class="
                                inline-flex
                                items-center
                                px-2.5
                                py-0.5
                                rounded-full
                                text-xs
                                font-medium
                                bg-red-100
                                text-red-800
                              "
                            >
                              Non
                            </span>
                            <span
                              v-if="guide.inmate_confirmation == true"
                              class="
                                inline-flex
                                items-center
                                px-2.5
                                py-0.5
                                rounded-full
                                text-xs
                                font-medium
                                bg-green-100
                                text-green-800
                              "
                            >
                              Oui
                            </span>
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div class="mt-6">
                      <h3 class="font-medium text-gray-900">Information</h3>
                      <dl
                        class="
                          mt-2
                          border-t border-b border-gray-200
                          divide-y divide-gray-200
                        "
                      >
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Date</dt>
                          <dd class="text-gray-900">
                            {{
                              moment(guide.created_at).format(
                                "dddd DD MMMM YYYY"
                              )
                            }}
                          </dd>
                        </div>
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Destinataire</dt>
                          <dd class="text-gray-900" v-if="guide.inmate">
                            {{ guide.inmate.firstname }}
                            {{ guide.inmate.lastname }}
                          </dd>
                        </div>
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Établissement</dt>
                          <dd class="text-gray-900" v-if="guide.prison">
                            {{ guide.prison.title }}
                          </dd>
                        </div>
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">
                            Adresse de <br />
                            l'établissement
                          </dt>
                          <dd class="text-gray-900" v-if="guide.prison">
                            {{ guide.prison.address }} <br />
                            {{ guide.prison.postcode }} <br />
                            {{ guide.prison.city }}
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div class="mt-6">
                      <h3 class="font-medium text-gray-900">Contact</h3>
                      <dl
                        class="
                          mt-2
                          border-t border-b border-gray-200
                          divide-y divide-gray-200
                        "
                      >
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Nom complet</dt>
                          <dd class="text-gray-900">
                            {{ guide.contact_name }}
                          </dd>
                        </div>
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Lien</dt>
                          <dd class="text-gray-900">
                            {{ guide.contact_bound }}
                          </dd>
                        </div>
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Téléphone</dt>
                          <dd class="text-gray-900">
                            {{ guide.contact_phone }}
                          </dd>
                        </div>
                        <div
                          class="py-3 flex justify-between text-sm font-medium"
                        >
                          <dt class="text-gray-500">Adresse</dt>
                          <dd class="text-gray-900">
                            {{ guide.contact_address }}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <div v-if="editionmode == true">
                    <h4
                      class="font-bold text-xl text-gray-900 sm:text-2xl mb-2"
                    >
                      Progression
                    </h4>
                    <SwitchGroup as="div" class="flex items-center mt-4">
                      <Switch
                        v-model="authsent"
                        :class="[
                          authsent ? 'bg-indigo-600' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        ]"
                      >
                        <span
                          aria-hidden="true"
                          :class="[
                            authsent ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                          ]"
                        />
                      </Switch>
                      <SwitchLabel as="span" class="ml-3">
                        <span
                          class="text-sm font-medium text-gray-900"
                          v-if="authsent == true"
                          >Autorisation envoyée
                        </span>
                        <span class="text-sm font-medium text-gray-900" v-else
                          >Autorisation non envoyée
                        </span>
                      </SwitchLabel>
                    </SwitchGroup>
                    <div class="mt-2" v-if="authsent == true">
                      <label
                        for="authdate"
                        class="block text-sm font-medium text-gray-700"
                        >Date de l'envoi
                      </label>
                      <div class="mt-1">
                        <input
                          type="datetime-local"
                          name="authdate"
                          id="authdate"
                          v-model="handlededguide.authorization_sending_date"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <SwitchGroup as="div" class="flex items-center mt-4">
                      <Switch
                        v-model="inmateconfirmed"
                        :class="[
                          inmateconfirmed ? 'bg-indigo-600' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        ]"
                      >
                        <span
                          aria-hidden="true"
                          :class="[
                            inmateconfirmed ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                          ]"
                        />
                      </Switch>
                      <SwitchLabel as="span" class="ml-3">
                        <span
                          class="text-sm font-medium text-gray-900"
                          v-if="inmateconfirmed == true"
                          >Confirmation du détenu reçue
                        </span>
                        <span class="text-sm font-medium text-gray-900" v-else
                          >Confirmation du détenu non reçue
                        </span>
                      </SwitchLabel>
                    </SwitchGroup>
                    <SwitchGroup as="div" class="flex items-center mt-4">
                      <Switch
                        v-model="guidesent"
                        :class="[
                          guidesent ? 'bg-indigo-600' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        ]"
                      >
                        <span
                          aria-hidden="true"
                          :class="[
                            guidesent ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                          ]"
                        />
                      </Switch>
                      <SwitchLabel as="span" class="ml-3">
                        <span
                          class="text-sm font-medium text-gray-900"
                          v-if="guidesent == true"
                          >Guide envoyé
                        </span>
                        <span class="text-sm font-medium text-gray-900" v-else
                          >Guide non envoyé
                        </span>
                      </SwitchLabel>
                    </SwitchGroup>
                    <div class="mt-2" v-if="guidesent == true">
                      <label
                        for="authdate"
                        class="block text-sm font-medium text-gray-700"
                        >Date de l'envoi du guide
                      </label>
                      <div class="mt-1">
                        <input
                          type="datetime-local"
                          name="authdate"
                          id="authdate"
                          v-model="handlededguide.sending_date"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <h4
                      class="
                        font-bold
                        text-xl text-gray-900
                        sm:text-2xl
                        mb-2
                        mt-6
                      "
                    >
                      Contact
                    </h4>
                    <div>
                      <label
                        for="date"
                        class="block text-sm font-medium text-gray-700"
                        >Contact</label
                      >
                      <div class="mt-1">
                        <input
                          type="contact_name"
                          name="contact_name"
                          id="contact_name"
                          v-model="handlededguide.contact_name"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                          placeholder="Jacques Dupont"
                        />
                      </div>
                    </div>
                    <div class="mt-2">
                      <label
                        for="date"
                        class="block text-sm font-medium text-gray-700"
                        >Contact (lien)</label
                      >
                      <div class="mt-1">
                        <input
                          type="contact_name"
                          name="contact_name"
                          id="contact_name"
                          v-model="handlededguide.contact_bound"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                          placeholder="Proche, avocat, conjoint.e, etc."
                        />
                      </div>
                    </div>
                    <div class="mt-2">
                      <label
                        for="date"
                        class="block text-sm font-medium text-gray-700"
                        >Contact (téléphone)</label
                      >
                      <div class="mt-1">
                        <input
                          type="contact_name"
                          name="contact_name"
                          id="contact_name"
                          v-model="handlededguide.contact_phone"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                          placeholder="01 XX XX XX XX"
                        />
                      </div>
                    </div>
                    <div class="mt-2">
                      <label
                        for="comment"
                        class="block text-sm font-medium text-gray-700"
                        >Adresse du contact</label
                      >
                      <div class="mt-1">
                        <textarea
                          rows="4"
                          name="comment"
                          id="comment"
                          v-model="handlededguide.contact_address"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <!-- /End replace -->
                </div>
                <!-- Action buttons -->
                <div
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      v-if="editionmode == true"
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="closeEditionMode"
                    >
                      Annuler
                    </button>
                    <button
                      v-if="editionmode == false"
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('shut')"
                    >
                      Fermer
                    </button>
                    <button
                      v-if="editionmode == false"
                      @click.prevent="enterEditionMode"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Éditer cet envoi
                    </button>
                    <button
                      v-if="editionmode == true"
                      @click.prevent="updateGuide"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Mettre à jour
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
  Switch, SwitchGroup, SwitchLabel,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  setup() {
    const open = ref(true);
    const editionmode = ref(false);
    const authsent = ref();
    const inmateconfirmed = ref();
    const guidesent = ref();

    return {
      open,
      editionmode,
      authsent,
      inmateconfirmed,
      guidesent,
      handlededguide: {
        prison_id: null,
        inmate_id: null,
        inmate_confirmation: null,
        authorization_sent: null,
        contact_name: null,
        contact_bound: null,
        authorization_sending_date: null,
        contact_phone: null,
        contact_address: null,
        auth_code: null,
        sent: null,
      },
    };
  },
  props: {
    guide: Object,
    handleguide: Boolean,
  },
  created() {
    this.moment = moment;
  },
  methods: {
    enterEditionMode() {
      this.editionmode = true;
      this.setData();
    },
    closeEditionMode() {
      this.editionmode = false;
    },
    setData() {
      this.handlededguide.prison_id = this.guide.prison_id;
      this.inmateconfirmed = this.guide.inmate_confirmation;
      this.handlededguide.inmate_id = this.guide.inmate_id;
      this.authsent = this.guide.authorization_sent;
      this.handlededguide.contact_name = this.guide.contact_name;
      this.handlededguide.contact_bound = this.guide.contact_bound;
      this.handlededguide.auth_code = this.guide.auth_code;
      if (this.guide.authorization_sending_date !== null) {
        window.console.log(this.guide.authorization_sending_date);
        const proposeddate = moment(this.guide.authorization_sending_date).format('YYYY-MM-DDTHH:mm');
        this.handlededguide.authorization_sending_date = proposeddate;
      } else {
        const proposeddate = moment(new Date()).format('YYYY-MM-DDTHH:mm');
        this.handlededguide.authorization_sending_date = proposeddate;
      }
      if (this.guide.sending_date !== null) {
        window.console.log(this.guide.sending_date);
        const proposeddate = moment(this.guide.sending_date).format('YYYY-MM-DDTHH:mm');
        this.handlededguide.sending_date = proposeddate;
      } else {
        const todaydate = moment(new Date()).format('YYYY-MM-DDTHH:mm');
        this.handlededguide.sending_date = todaydate;
      }
      this.handlededguide.contact_phone = this.guide.contact_phone;
      this.handlededguide.contact_address = this.guide.contact_address;
      this.guidesent = this.guide.sent;
    },
    updateGuide() {
      this.$http.patch('api/guide-update', {
        id: this.guide.id,
        authorization_sent: this.authsent,
        inmate_confirmation: this.inmateconfirmed,
        sent: this.guidesent,
        contact_name: this.handlededguide.contact_name,
        contact_bound: this.handlededguide.contact_bound,
        contact_phone: this.handlededguide.contact_phone,
        contact_address: this.handlededguide.contact_address,
        auth_code: this.handlededguide.auth_code,
        sending_date: this.handlededguide.sending_date,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('guideupdated');
        this.editionmode = false;
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
