import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const client = axios.create({
  baseURL: 'https://bckbabase.oip.org',
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  rejectUnauthorized: false,
  timeout: 60000,
});

// return a promise that wrap the token cookie setter
function setAccessTokenCookie(value) {
  return new Promise((resolve) => {
    Cookies.set('token', value, {
      expires: new Date(jwtDecode(value).exp * 1000),
      // domain: `.${process.env.PLATFORM_NAME}`
    });
    resolve();
  });
}

client.interceptors.request.use((config) => {
  const newConfig = config;
  // We search for a token in the cookies, and if an token cookie is
  // present, we put it on an authorization header before sending the request.
  const accessToken = Cookies.get('token');
  if (accessToken) {
    newConfig.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  return newConfig;
}, (error) => Promise.reject(error));

client.interceptors.response.use((response) => {
  if (response.data && Object.prototype.hasOwnProperty.call(response.data, 'token')) {
    // The /login endpoint was called, the jwt token should be in the response
    // body. We get the token and put it in a cookie.
    setAccessTokenCookie(response.data.token).then(() => response);
  }
  return response;
}, (error) => Promise.reject(error));

export default client;
