<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-2xl">
              <div
                class="
                  h-full
                  flex flex-col
                  py-6
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      Détails de l'action
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          bg-white
                          rounded-md
                          text-gray-400
                          hover:text-gray-500
                          focus:outline-none
                          focus:ring-2
                          focus:ring-offset-2
                          focus:ring-indigo-500
                        "
                        @click="closePanel"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mt-6 relative flex-1 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <!-- Visualization mode -->
                  <div
                    class="bg-white shadow overflow-hidden sm:rounded-lg"
                    v-if="editionmode == false"
                  >
                    <div class="px-4 py-5 sm:px-6">
                      <div class="flex items-center">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                          {{ action.type }}
                        </h3>
                        <span
                          v-if="!enabled"
                          class="
                            inline-flex
                            items-center
                            px-2.5
                            py-0.5
                            rounded-full
                            text-xs
                            font-medium
                            bg-yellow-100
                            text-yellow-800
                            ml-2
                          "
                        >
                          En cours
                        </span>
                        <span
                          v-else
                          class="
                            inline-flex
                            items-center
                            px-2.5
                            py-0.5
                            rounded-full
                            text-xs
                            font-medium
                            bg-green-100
                            text-green-800
                            ml-2
                          "
                        >
                          Terminée
                        </span>
                      </div>
                      <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        {{ moment(action.date).format("dddd DD MMMM YYYY") }}
                      </p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <dl class="sm:divide-y sm:divide-gray-200">
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="action.url"
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            URL du communiqué
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.url }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="action.type == 'Email'"
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            Destinataire
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.recipient }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="
                            action.type == 'Email' && action.comment !== null
                          "
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            Commentaire
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.comment }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="action.type == 'Rappel'"
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            Contact à appeler
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.contact_fullname }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="action.type == 'Rappel'"
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            Numéro de téléphone
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.phone_number }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="action.type == 'Réponse par courrier'"
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            Établissement
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.prison.title }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="
                            action.type == 'Réponse par courrier' &&
                            action.comment !== null
                          "
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            Commentaire
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.comment }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="action.type == 'Saisine'"
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            Institution
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.institution.name }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="action.type == 'Saisine'"
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            La personne détenue a-t-elle répondu ?
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.institution_answer }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="action.type == 'Contact tiers'"
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            Nom du contact
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.contact }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="action.type == 'Contact tiers'"
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            Commentaire
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.comment }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="action.type == 'Saisine'"
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            L'institution a-t-elle répondu ?
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.inmate_answer }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="action.question"
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            Question
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.question }}
                          </dd>
                        </div>
                        <div
                          class="
                            py-4
                            sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                          "
                          v-if="action.answer"
                        >
                          <dt class="text-sm font-medium text-gray-500">
                            Réponse
                          </dt>
                          <dd
                            class="
                              mt-1
                              text-sm text-gray-900
                              sm:mt-0 sm:col-span-2
                            "
                          >
                            {{ action.answer }}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <!-- Visualization mode ends -->
                  <!-- Edition mode  -->
                  <div v-if="editionmode == true">
                    <h4
                      class="font-bold text-xl text-gray-900 sm:text-2xl mb-2"
                    >
                      Changer la date et le statut
                    </h4>
                    <!-- switch -->
                    <SwitchGroup as="div" class="flex items-center mt-8">
                      <Switch
                        v-model="enabled"
                        :class="[
                          enabled ? 'bg-indigo-600' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        ]"
                      >
                        <span
                          aria-hidden="true"
                          :class="[
                            enabled ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                          ]"
                        />
                      </Switch>
                      <SwitchLabel as="span" class="ml-3">
                        <span
                          class="text-sm font-medium text-gray-900"
                          v-if="enabled == true"
                          >Cette action a été effectuée
                        </span>
                        <span
                          class="text-sm font-medium text-gray-900"
                          v-if="enabled == false"
                          >Cette action est en cours
                        </span>
                      </SwitchLabel>
                    </SwitchGroup>
                    <!-- switch ends -->
                    <div class="mt-6">
                      <label
                        for="date"
                        class="block text-sm font-medium text-gray-700"
                        >Changer la date de l'action</label
                      >
                      <div class="mt-1">
                        <input
                          type="datetime-local"
                          name="date"
                          id="date"
                          v-model="updateddate"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <h4
                      class="
                        font-bold
                        text-xl text-gray-900
                        sm:text-2xl
                        mb-2
                        mt-6
                      "
                    >
                      Ou éditez l'action
                    </h4>
                    <!-- actions edition  -->
                    <div v-if="action.type === 'Question'">
                      <div>
                        <label
                          for="question"
                          class="block text-sm font-medium text-gray-900"
                        >
                          Question posée
                        </label>
                        <div class="mt-1">
                          <textarea
                            v-model="answer.question"
                            id="description"
                            name="description"
                            rows="4"
                            class="
                              block
                              w-full
                              shadow-sm
                              sm:text-sm
                              focus:ring-indigo-500 focus:border-indigo-500
                              border border-gray-300
                              rounded-md
                            "
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="description"
                          class="block text-sm font-medium text-gray-900 mt-6"
                        >
                          Réponse donnée
                        </label>
                        <div class="mt-1">
                          <textarea
                            v-model="answer.answer"
                            id="answer"
                            name="answer"
                            rows="4"
                            class="
                              block
                              w-full
                              shadow-sm
                              sm:text-sm
                              focus:ring-indigo-500 focus:border-indigo-500
                              border border-gray-300
                              rounded-md
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="action.type === 'Réponse par courrier'">
                      <legend class="text-gray-900 mb-6">
                        Réponse par courrier
                      </legend>
                      <div>
                        <label
                          for="description"
                          class="block text-sm font-medium text-gray-900 mt-6"
                        >
                          Commentaire
                        </label>
                        <div class="mt-1">
                          <textarea
                            v-model="mailanswer.comment"
                            id="answer"
                            name="answer"
                            rows="4"
                            class="
                              block
                              w-full
                              shadow-sm
                              sm:text-sm
                              focus:ring-indigo-500 focus:border-indigo-500
                              border border-gray-300
                              rounded-md
                            "
                          />
                        </div>
                      </div>
                      <!-- prisons -->
                      <!-- <label
                        for="description"
                        class="block text-sm font-medium text-gray-900 mt-6"
                      >
                        Établissement concerné
                      </label> -->
                      <!-- <div
                        v-if="!chosenprison"
                        class="
                          border border-gray-300
                          rounded-md
                          px-3
                          py-2
                          shadow-sm
                          focus-within:ring-1
                          focus-within:ring-indigo-600
                          focus-within:border-indigo-600
                          mt-2
                        "
                      >
                        <label
                          for="name"
                          class="block text-xs font-medium text-gray-900"
                          >Recherchez par nom d'établissement</label
                        >
                        <input
                          v-model="search"
                          type="text"
                          name="search"
                          id="search"
                          class="
                            block
                            w-full
                            border-0
                            p-0
                            text-gray-900
                            placeholder-gray-500
                            focus:ring-0
                            sm:text-sm
                          "
                          placeholder="ex : Marseille"
                        />
                      </div> -->
                      <!-- <ul
                        role="list"
                        class="-my-5 divide-y divide-gray-200 mt-6"
                        v-if="search && !chosenprison"
                      >
                        <li
                          v-for="prison in matchingPrisons"
                          :key="prison.id"
                          class="py-4"
                        >
                          <div class="flex items-center space-x-4">
                            <div class="flex-1 min-w-0">
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-900
                                  truncate
                                "
                              >
                                {{ prison.title }}
                              </p>
                            </div>
                            <div>
                              <a
                                @click.prevent="choosePrison(prison)"
                                class="
                                  inline-flex
                                  items-center
                                  shadow-sm
                                  px-2.5
                                  py-0.5
                                  text-sm
                                  leading-5
                                  font-medium
                                  rounded-full
                                  text-white
                                  bg-green-500
                                  hover:bg-green-700
                                  cursor-pointer
                                "
                              >
                                Choisir
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul> -->
                      <!-- <p
                        class="text-sm text-gray-500 mt-4"
                        v-if="!search && !chosenprison"
                      >
                        Tapez quelques lettres du nom de l'établissement pour
                        choisir l'établissement destinataire du courrier.
                      </p> -->
                      <!-- <div v-if="chosenprison">
                        <span
                          class="
                            inline-flex
                            rounded-full
                            items-center
                            py-0.5
                            pl-2.5
                            pr-1
                            text-sm
                            font-medium
                            bg-indigo-100
                            text-indigo-700
                            mt-2
                          "
                        >
                          {{ chosenprison.title }}
                          <button
                            @click.prevent="chosenprison = null"
                            type="button"
                            class="
                              flex-shrink-0
                              ml-0.5
                              h-4
                              w-4
                              rounded-full
                              inline-flex
                              items-center
                              justify-center
                              text-indigo-400
                              hover:bg-indigo-200 hover:text-indigo-500
                              focus:outline-none
                              focus:bg-indigo-500
                              focus:text-white
                            "
                          >
                            <svg
                              class="h-2 w-2"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 8 8"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-width="1.5"
                                d="M1 1l6 6m0-6L1 7"
                              />
                            </svg>
                          </button>
                        </span>
                      </div> -->
                    </div>
                    <!-- prisons end -->
                    <div v-if="action.type === 'Rappel'">
                      <legend class="text-gray-900 mb-6">
                        Planifier un rappel
                      </legend>
                      <div>
                        <label
                          for="callback"
                          class="block text-sm font-medium text-gray-900"
                        >
                          Nom complet du contact à rappeler
                        </label>
                        <div class="mt-1">
                          <input
                            type="text"
                            v-model="callback.contact_fullname"
                            id="description"
                            name="contact"
                            rows="4"
                            class="
                              block
                              w-full
                              shadow-sm
                              sm:text-sm
                              focus:ring-indigo-500 focus:border-indigo-500
                              border border-gray-300
                              rounded-md
                            "
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="phone"
                          class="block text-sm font-medium text-gray-900 mt-6"
                        >
                          Numéro de téléphone
                        </label>
                        <div class="mt-1">
                          <input
                            type="text"
                            v-model="callback.phone_number"
                            id="phone"
                            name="phone"
                            rows="4"
                            class="
                              block
                              w-full
                              shadow-sm
                              sm:text-sm
                              focus:ring-indigo-500 focus:border-indigo-500
                              border border-gray-300
                              rounded-md
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="action.type === 'Contact tiers'">
                      <legend class="text-gray-900 mb-6">
                        Contacter un tiers
                      </legend>
                      <div>
                        <label
                          for="callback"
                          class="block text-sm font-medium text-gray-900"
                        >
                          Nom complet du contact à rappeler
                        </label>
                        <div class="mt-1">
                          <input
                            type="text"
                            v-model="thirdpart.contact"
                            id="contact"
                            name="contact"
                            rows="4"
                            class="
                              block
                              w-full
                              shadow-sm
                              sm:text-sm
                              focus:ring-indigo-500 focus:border-indigo-500
                              border border-gray-300
                              rounded-md
                            "
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="phone"
                          class="block text-sm font-medium text-gray-900 mt-6"
                        >
                          Commentaire
                        </label>
                        <div class="mt-1">
                          <textarea
                            type="text"
                            v-model="thirdpart.comment"
                            id="phone"
                            name="phone"
                            rows="4"
                            class="
                              block
                              w-full
                              shadow-sm
                              sm:text-sm
                              focus:ring-indigo-500 focus:border-indigo-500
                              border border-gray-300
                              rounded-md
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="action.type === 'Communiqué'">
                      <legend class="text-gray-900 mb-6">
                        Communiqué de presse
                      </legend>
                      <div>
                        <label
                          for="company-website"
                          class="block text-sm font-medium text-gray-700"
                        >
                          URL du communiqué
                        </label>
                        <div class="mt-1 flex rounded-md shadow-sm">
                          <span
                            class="
                              inline-flex
                              items-center
                              px-3
                              rounded-l-md
                              border border-r-0 border-gray-300
                              bg-gray-50
                              text-gray-500
                              sm:text-sm
                            "
                          >
                            https://
                          </span>
                          <input
                            type="text"
                            name="press-url"
                            id="press-url"
                            v-model="pressrelease.url"
                            class="
                              flex-1
                              min-w-0
                              block
                              w-full
                              px-3
                              py-2
                              rounded-none rounded-r-md
                              focus:ring-indigo-500 focus:border-indigo-500
                              sm:text-sm
                              border-gray-300
                            "
                            placeholder="www.oip.org"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="action.type == 'Email'">
                      <legend class="text-gray-900 mb-6">
                        Envoyer un email
                      </legend>
                      <div>
                        <label
                          for="company-website"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Email du contact
                        </label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <div
                            class="
                              absolute
                              inset-y-0
                              left-0
                              pl-3
                              flex
                              items-center
                              pointer-events-none
                            "
                          >
                            <MailIcon
                              class="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="email"
                            v-model="mail.recipient"
                            name="email"
                            id="email"
                            class="
                              focus:ring-indigo-500 focus:border-indigo-500
                              block
                              w-full
                              pl-10
                              sm:text-sm
                              border-gray-300
                              rounded-md
                            "
                            placeholder="john@acme.com"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="comment"
                          class="block text-sm font-medium text-gray-900 mt-6"
                        >
                          Commentaire
                        </label>
                        <div class="mt-1">
                          <textarea
                            v-model="mail.comment"
                            id="comment"
                            name="comment"
                            rows="4"
                            class="
                              block
                              w-full
                              shadow-sm
                              sm:text-sm
                              focus:ring-indigo-500 focus:border-indigo-500
                              border border-gray-300
                              rounded-md
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div v-if="action.type === 'Saisine'">
                      <!-- <div>
                        <label
                          for="callback"
                          class="block text-sm font-medium text-gray-900"
                        >
                          Institution saisie
                        </label>
                        <div class="mt-1">
                          <select
                            id="institution"
                            name="institution"
                            v-model="referral.institution_id"
                            class="
                              mt-1
                              block
                              w-full
                              pl-3
                              pr-10
                              py-2
                              text-base
                              border-gray-300
                              focus:outline-none
                              focus:ring-indigo-500
                              focus:border-indigo-500
                              sm:text-sm
                              rounded-md
                            "
                          >
                            <option
                              v-for="institution in institutions"
                              :key="institution.id"
                              :value="institution.id"
                            >
                              {{ institution.name }}
                            </option>
                          </select>
                        </div>
                      </div> -->
                      <div class="mt-4">
                        <label
                          for="callback"
                          class="block text-sm font-medium text-gray-900"
                        >
                          Réponses attendues
                        </label>
                        <SwitchGroup as="div" class="flex items-center mt-4">
                          <Switch
                            v-model="institutionanswer"
                            :class="[
                              institutionanswer
                                ? 'bg-indigo-600'
                                : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                            ]"
                          >
                            <span
                              aria-hidden="true"
                              :class="[
                                institutionanswer
                                  ? 'translate-x-5'
                                  : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                              ]"
                            />
                          </Switch>
                          <SwitchLabel as="span" class="ml-3">
                            <span
                              class="text-sm font-medium text-gray-900"
                              v-if="institutionanswer == false"
                              >L'institution n'a pas répondu
                            </span>
                            <span
                              class="text-sm font-medium text-gray-900"
                              v-else
                              >L'institution a répondu
                            </span>
                          </SwitchLabel>
                        </SwitchGroup>
                        <SwitchGroup as="div" class="flex items-center mt-4">
                          <Switch
                            v-model="inmateanswer"
                            :class="[
                              inmateanswer ? 'bg-indigo-600' : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                            ]"
                          >
                            <span
                              aria-hidden="true"
                              :class="[
                                inmateanswer
                                  ? 'translate-x-5'
                                  : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                              ]"
                            />
                          </Switch>
                          <SwitchLabel as="span" class="ml-3">
                            <span
                              class="text-sm font-medium text-gray-900"
                              v-if="inmateanswer == false"
                              >La personne détenue n'a pas répondu
                            </span>
                            <span
                              class="text-sm font-medium text-gray-900"
                              v-else
                              >La personne détenue a répondu
                            </span>
                          </SwitchLabel>
                        </SwitchGroup>
                      </div>
                    </div>
                    <!-- actions edition ends -->
                  </div>
                  <!-- Edition mode ends -->
                  <!-- /End replace -->
                </div>
                <!-- Action buttons -->
                <div class="rounded-md bg-red-50 p-4" v-if="imsure == true">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <ExclamationCircleIcon
                        class="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ml-3 md:justify-between">
                      <p class="text-sm text-red-700">
                        Vous êtes sur le point de supprimer cette action. Il
                        sera impossible de la restaurer.
                      </p>
                      <p class="mt-3 text-sm">
                        <a
                          @click.prevent="deleteAction"
                          class="
                            whitespace-nowrap
                            font-medium
                            text-red-700
                            hover:text-red-600
                            cursor-pointer
                          "
                          >Je confirme <span aria-hidden="true">&rarr;</span></a
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      @click.prevent="imsure = true"
                      type="button"
                      class="
                        mr-auto
                        p-1
                        rounded-full
                        hover:text-red-600
                        focus:outline-none
                        focus:ring-offset-gray-800
                        focus:ring-white
                      "
                    >
                      <TrashIcon class="h-6 w-6 mr-2" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="closePanel"
                    >
                      Annuler
                    </button>
                    <button
                      v-if="editionmode == false"
                      @click.prevent="enterEditionMode"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Éditer cette action
                    </button>
                    <button
                      v-if="editionmode == true"
                      @click.prevent="updateAction"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Mettre l'action à jour
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, Switch, SwitchGroup, SwitchLabel,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { MailIcon, TrashIcon } from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    MailIcon,
    TrashIcon,
  },
  setup() {
    const open = ref(true);
    const enabled = ref();
    const updateddate = ref();
    const editionmode = ref(false);
    const institutionanswer = ref();
    const inmateanswer = ref();
    const imsure = ref(false);

    return {
      open,
      enabled,
      updateddate,
      editionmode,
      answer: {
        question: null,
        answer: null,
      },
      mailanswer: {
        comment: null,
      },
      callback: {
        contact_fullname: null,
        phone_number: null,
      },
      thirdpart: {
        contact: null,
        comment: null,
      },
      pressrelease: {
        url: null,
      },
      mail: {
        recipient: null,
        comment: null,
      },
      institutionanswer,
      inmateanswer,
      referral: {
        inmate_answer: null,
        institution_answer: null,
      },
      imsure,
    };
  },
  props: {
    action: Object,
    displayactionpanel: Boolean,
    actionstatus: Boolean,
  },
  watch: {
    action() {
      this.enabled = this.actionstatus;
    },
  },
  created() {
    this.moment = moment;
  },
  methods: {
    closePanel() {
      this.$emit('shut');
      this.editionmode = false;
    },
    enterEditionMode() {
      this.editionmode = true;
      this.setActionData();
    },
    setActionData() {
      if (this.action.type === 'Question') {
        this.answer.question = this.action.question;
        this.answer.answer = this.action.answer;
      } else if (this.action.type === 'Réponse par courrier') {
        this.mailanswer.comment = this.action.comment;
        this.mailanswer.prison_id = this.action.prison_id;
      } else if (this.action.type === 'Rappel') {
        this.callback.contact_fullname = this.action.contact_fullname;
        this.callback.phone_number = this.action.phone_number;
      } else if (this.action.type === 'Contact tiers') {
        this.thirdpart.contact = this.action.contact;
        this.thirdpart.comment = this.action.comment;
      } else if (this.action.type === 'Communiqué') {
        if (this.action.url !== 'Il n\'a pas encore été publié') {
          this.pressrelease.url = this.action.url;
        }
      } else if (this.action.type === 'Email') {
        this.mail.recipient = this.action.recipient;
        this.mail.comment = this.action.comment;
      } else if (this.action.type === 'Saisine') {
        if (this.action.inmate_answer === 'Elle n\'a pas répondu') {
          this.referral.inmate_answer = false;
          this.inmateanswer = false;
        } if (this.action.institution_answer === 'Elle n\'a pas répondu') {
          this.referral.institution_answer = false;
          this.institutionanswer = false;
        } else {
          this.institutionanswer = true;
          this.inmateanswer = true;
        }
      }
      this.updateddate = this.action.date;
    },
    updateAction() {
      if (this.action.type === 'Question') {
        this.$http.patch('api/action-answer', {
          id: this.action.id,
          is_closed: this.enabled,
          date: this.updateddate,
          answer: this.answer.answer,
          question: this.answer.question,
        }).then((response) => {
          window.console.log(response.data);
          this.$emit('statuschanged');
          this.editionmode = false;
        }).catch((error) => {
          window.console.log(error);
        });
      } else if (this.action.type === 'Réponse par courrier') {
        this.$http.patch('api/action-mailanswer', {
          id: this.action.id,
          is_closed: this.enabled,
          date: this.updateddate,
          comment: this.mailanswer.comment,
          prison_id: this.action.prison_id,
        }).then((response) => {
          window.console.log(response.data);
          this.$emit('statuschanged');
          this.editionmode = false;
        }).catch((error) => {
          window.console.log(error);
        });
      } else if (this.action.type === 'Communiqué') {
        this.$http.patch('api/action-pressrelease', {
          id: this.action.id,
          is_closed: this.enabled,
          date: this.updateddate,
          url: this.pressrelease.url,
        }).then((response) => {
          window.console.log(response.data);
          this.$emit('statuschanged');
          this.editionmode = false;
        }).catch((error) => {
          window.console.log(error);
        });
      } else if (this.action.type === 'Contact tiers') {
        this.$http.patch('api/action-thirdpart', {
          id: this.action.id,
          is_closed: this.enabled,
          date: this.updateddate,
          comment: this.thirdpart.comment,
          contact: this.thirdpart.contact,
        }).then((response) => {
          window.console.log(response.data);
          this.$emit('statuschanged');
          this.editionmode = false;
        }).catch((error) => {
          window.console.log(error);
        });
      } else if (this.action.type === 'Rappel') {
        this.$http.patch('api/action-callback', {
          id: this.action.id,
          is_closed: this.enabled,
          date: this.updateddate,
          contact_fullname: this.callback.contact_fullname,
          phone_number: this.callback.phone_number,
        }).then((response) => {
          window.console.log(response.data);
          this.$emit('statuschanged');
          this.editionmode = false;
        }).catch((error) => {
          window.console.log(error);
        });
      } else if (this.action.type === 'Saisine') {
        this.$http.patch('api/action-referral', {
          id: this.action.id,
          is_closed: this.enabled,
          date: this.updateddate,
          inmate_answer: this.inmateanswer,
          institution_answer: this.institutionanswer,
          institution_id: this.action.institution_id,
        }).then((response) => {
          window.console.log(response.data);
          this.$emit('statuschanged');
          this.editionmode = false;
        }).catch((error) => {
          window.console.log(error);
        });
      } else if (this.action.type === 'Email') {
        this.$http.patch('api/action-mail', {
          id: this.action.id,
          is_closed: this.enabled,
          date: this.updateddate,
          comment: this.mail.comment,
          recipient: this.mail.recipient,
        }).then((response) => {
          window.console.log(response.data);
          this.$emit('statuschanged');
          this.editionmode = false;
        }).catch((error) => {
          window.console.log(error);
        });
      }
    },
    deleteAction() {
      this.$http.patch('api/action-delete', {
        id: this.action.id,
        type: this.action.type,
      }).then((response) => {
        window.console.log(response.data);
        this.imsure = false;
        this.$emit('statuschanged');
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
