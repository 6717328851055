<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="min-h-full">
    <div class="bg-gray-800 pb-32">
      <AppNavbar />
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">Publications</h1>
          <h3 class="text-2xl text-white">
            Cette vue est dédiée aux abonnements et à la gestion des
            publications.
          </h3>
        </div>
      </header>
    </div>
    <main class="-mt-32">
      <DeadletterResolution
        v-model:deadletterstatus="deadletterstatus"
        v-model:deadletter="selecteddeadletter"
        @shut="closeDeadletterStatus"
        @problemsolved="deadletterSolved"
      />
      <SendingConfirmation
        v-show="sendingconfirmation"
        v-model:sendingconfirmation="sendingconfirmation"
        v-model:sending="chosensending"
        @shut="closeSendingConfirmation"
        @sent="SendingConfirmed"
      />
      <HandleSubscription
        v-show="displayhandlesubscription"
        v-model:displayhandlesubscription="displayhandlesubscription"
        v-model:subscription="selectedsubscription"
        @shut="endHandleSubscription"
      />
      <HandleGuide
        v-show="handleguide"
        v-model:guide="selectedguide"
        v-model:handleguide="handleguide"
        @guideupdated="confirmGuideUpdate"
        @shut="endHandleGuide"
      />
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
          <!-- tabs -->
          <div class="mb-12">
            <div class="sm:hidden">
              <label for="tabs" class="sr-only">Choississez une rubrique</label>
              <select
                @change="gotoTab(currenttab)"
                v-model="currenttab"
                id="tabs"
                name="tabs"
                class="
                  block
                  w-full
                  focus:ring-indigo-500 focus:border-indigo-500
                  border-gray-300
                  rounded-md
                "
              >
                <option
                  v-for="tab in tabs"
                  :key="tab.name"
                  :selected="tab.href"
                  :value="tab.href"
                >
                  {{ tab.name }}
                </option>
              </select>
            </div>
            <div class="hidden sm:block">
              <div class="border-b border-gray-200">
                <nav class="-mb-px flex" aria-label="Tabs">
                  <a
                    v-for="tab in tabs"
                    :key="tab.name"
                    @click="gotoTab(tab.href)"
                    :class="[
                      currenttab === tab.href
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm cursor-pointer',
                    ]"
                    :aria-current="tab.current ? 'page' : undefined"
                  >
                    {{ tab.name }}
                  </a>
                </nav>
              </div>
            </div>
          </div>
          <!-- tabs end -->
          <div v-if="currenttab == 'envois'">
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="
                    py-2
                    align-middle
                    inline-block
                    min-w-full
                    sm:px-6
                    lg:px-8
                  "
                >
                  <div
                    class="
                      shadow
                      overflow-hidden
                      border-b border-gray-200
                      sm:rounded-lg
                    "
                    v-if="sendings"
                  >
                    <table
                      class="min-w-full divide-y divide-gray-200"
                      v-if="sendings.data.length > 0"
                    >
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Identifiant
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Date de demande
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Destinataire
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Prison
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Contenu
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Statut
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Fait ?</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="sendings">
                        <tr
                          v-for="(sending, sendingIdx) in sendings.data"
                          :key="sending.id"
                          :class="
                            sendingIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          "
                        >
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm
                              font-medium
                              text-gray-900
                            "
                          >
                            {{ sending.id }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{
                              moment(sending.created_at).format("D MMMM YYYY")
                            }}
                          </td>
                          <td
                            v-if="sending.inmate"
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{ sending.inmate.firstname }} <br />
                            {{ sending.inmate.lastname }}
                          </td>
                          <td
                            v-if="sending.prison"
                            class="px-6 py-4 text-sm text-gray-500"
                          >
                            {{ sending.prison.title }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{ sending.type }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            <span
                              v-if="sending.status == 'À envoyer'"
                              class="
                                inline-flex
                                items-center
                                px-2.5
                                py-0.5
                                rounded-full
                                text-xs
                                font-medium
                                bg-red-100
                                text-red-800
                              "
                            >
                              {{ sending.status }}
                            </span>
                            <span
                              v-else
                              class="
                                inline-flex
                                items-center
                                px-2.5
                                py-0.5
                                rounded-full
                                text-xs
                                font-medium
                                bg-green-100
                                text-green-800
                              "
                            >
                              {{ sending.status }}
                            </span>
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-right text-sm
                              font-medium
                            "
                          >
                            <a
                              @click.prevent="confirmSending(sending)"
                              v-if="sending.status == 'À envoyer'"
                              class="
                                text-indigo-600
                                hover:text-indigo-900
                                cursor-pointer
                              "
                              >Envoyé ?</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- pagination  -->
                  <div v-if="sendings">
                    <nav
                      v-if="sendings.data.length > 0"
                      class="
                        bg-white
                        px-4
                        py-3
                        flex
                        items-center
                        justify-between
                        border-t border-gray-200
                        sm:px-6
                      "
                      aria-label="Pagination"
                    >
                      <div class="hidden sm:block">
                        <p class="text-sm text-gray-700">
                          Page
                          {{ " " }}
                          <span class="font-medium">{{ currentpage }}</span>
                          {{ " " }}
                          sur
                          {{ " " }}
                          <span class="font-medium">{{
                            sendings.last_page
                          }}</span>
                          {{ " " }}
                          de
                          {{ " " }}
                          <span class="font-medium">{{ sendings.total }}</span>
                          {{ " " }}
                          résultats
                        </p>
                      </div>
                      <div class="flex-1 flex justify-between sm:justify-end">
                        <button
                          @click.prevent="goPreviouspage"
                          :disabled="currentpage < 2"
                          :class="currentpage < 2 ? 'cursor-not-allowed' : ''"
                          class="
                            relative
                            inline-flex
                            items-center
                            px-4
                            py-2
                            border border-gray-300
                            text-sm
                            font-medium
                            rounded-md
                            text-gray-700
                            bg-white
                            hover:bg-gray-50
                            cursor-pointer
                          "
                        >
                          Précédents
                        </button>
                        <button
                          @click.prevent="goNextpage"
                          :disabled="currentpage == sendings.last_page"
                          :class="
                            currentpage == sendings.last_page
                              ? 'cursor-not-allowed'
                              : ''
                          "
                          class="
                            ml-3
                            relative
                            inline-flex
                            items-center
                            px-4
                            py-2
                            border border-gray-300
                            text-sm
                            font-medium
                            rounded-md
                            text-gray-700
                            bg-white
                            hover:bg-gray-50
                            cursor-pointer
                          "
                        >
                          Suivants
                        </button>
                      </div>
                    </nav>
                  </div>
                  <!-- pagination ends -->
                </div>
              </div>
            </div>
          </div>
          <div v-if="currenttab == 'abonnements'">
            <!-- Subscriptions download -->
            <div class="bg-gray-50 sm:rounded-lg mb-12">
              <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Télécharger les abonnements
                </h3>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <p>
                    Vous pouvez télécharger les abonnements,
                    <span class="font-bold">au format csv</span>, à partir de
                    cette vue. Attention, il s'agit uniquement des abonnements
                    qui <span class="font-bold">sont actifs et sans NPAI</span>.
                  </p>
                </div>
                <div class="mt-5">
                  <button
                    @click.prevent="exportSubscriptions"
                    type="button"
                    class="
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-gray-300
                      shadow-sm
                      font-medium
                      rounded-md
                      text-gray-700
                      bg-white
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                      sm:text-sm
                    "
                  >
                    Télécharger
                  </button>
                </div>
              </div>
            </div>
            <!-- Subscriptions download ends -->
            <!-- Search sollicitation -->
            <div class="mb-4" v-if="!usersearch">
              <label
                for="search"
                class="block text-sm font-medium text-gray-700"
                >Recherchez avec
                <span class="font-bold"
                  >le nom de famille d'un.e détenu.e</span
                ></label
              >
              <div class="mt-1 relative flex items-center">
                <input
                  @keyup.enter="searchSubscriptions()"
                  @keydown.space.prevent
                  type="text"
                  name="search"
                  v-model="search"
                  id="search"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    pr-12
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  placeholder="Vous pouvez rechercher un abonnement avec un nom de famille"
                />
                <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                  <kbd
                    class="
                      inline-flex
                      items-center
                      border border-gray-200
                      rounded
                      px-2
                      text-sm
                      font-sans font-medium
                      text-gray-400
                    "
                  >
                    Entrer
                  </kbd>
                </div>
              </div>
            </div>
            <!-- Search ends -->
            <!-- Search query -->
            <div class="mb-4" v-if="usersearch">
              Vous avez recherché :
              <span
                class="
                  inline-flex
                  rounded-full
                  items-center
                  py-0.5
                  pl-2.5
                  pr-1
                  text-sm
                  font-medium
                  bg-indigo-100
                  text-indigo-700
                "
              >
                {{ usersearch }}
                <button
                  @click.prevent="resetSearch"
                  type="button"
                  class="
                    flex-shrink-0
                    ml-0.5
                    h-4
                    w-4
                    rounded-full
                    inline-flex
                    items-center
                    justify-center
                    text-indigo-400
                    hover:bg-indigo-200 hover:text-indigo-500
                    focus:outline-none focus:bg-indigo-500 focus:text-white
                  "
                >
                  <span class="sr-only">Réinitialisez</span>
                  <svg
                    class="h-2 w-2"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 8 8"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-width="1.5"
                      d="M1 1l6 6m0-6L1 7"
                    />
                  </svg>
                </button>
              </span>
            </div>
            <!-- Search query ends -->
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="
                    py-2
                    align-middle
                    inline-block
                    min-w-full
                    sm:px-6
                    lg:px-8
                  "
                >
                  <div
                    class="
                      shadow
                      overflow-hidden
                      border-b border-gray-200
                      sm:rounded-lg
                    "
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Identifiant
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Personne détenue / Abonnement lié
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Date de début
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            État
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Gérer</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="subscriptions">
                        <tr
                          v-for="(
                            subscription, subscriptionIdx
                          ) in subscriptions.data"
                          :key="subscription.id"
                          :class="
                            subscriptionIdx % 2 === 0
                              ? 'bg-white'
                              : 'bg-gray-50'
                          "
                        >
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm
                              font-medium
                              text-gray-900
                            "
                          >
                            {{ subscription.id }}
                          </td>
                          <td
                            v-if="subscription.inmate"
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{ subscription.inmate.firstname }}
                            {{ subscription.inmate.lastname }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            <span v-if="subscription.date === null"
                              >Non commencé</span
                            >
                            <span v-else>
                              {{
                                moment(subscription.starting_date).format(
                                  "D MMMM YYYY"
                                )
                              }}</span
                            >
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{ subscription.status }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-right text-sm
                              font-medium
                            "
                          >
                            <a
                              @click.prevent="handleSubscription(subscription)"
                              class="
                                text-indigo-600
                                hover:text-indigo-900
                                cursor-pointer
                              "
                              >Gérer</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- pagination  -->
                  <div v-if="subscriptions">
                    <nav
                      class="
                        bg-white
                        px-4
                        py-3
                        flex
                        items-center
                        justify-between
                        border-t border-gray-200
                        sm:px-6
                      "
                      aria-label="Pagination"
                    >
                      <div class="hidden sm:block">
                        <p class="text-sm text-gray-700">
                          Page
                          {{ " " }}
                          <span class="font-medium">{{ currentpage }}</span>
                          {{ " " }}
                          sur
                          {{ " " }}
                          <span class="font-medium">{{
                            subscriptions.last_page
                          }}</span>
                          {{ " " }}
                          de
                          {{ " " }}
                          <span class="font-medium">{{
                            subscriptions.total
                          }}</span>
                          {{ " " }}
                          résultats
                        </p>
                      </div>
                      <div class="flex-1 flex justify-between sm:justify-end">
                        <button
                          @click.prevent="goPreviouspage"
                          :disabled="currentpage < 2"
                          :class="currentpage < 2 ? 'cursor-not-allowed' : ''"
                          class="
                            relative
                            inline-flex
                            items-center
                            px-4
                            py-2
                            border border-gray-300
                            text-sm
                            font-medium
                            rounded-md
                            text-gray-700
                            bg-white
                            hover:bg-gray-50
                            cursor-pointer
                          "
                        >
                          Précédents
                        </button>
                        <button
                          @click.prevent="goNextpage"
                          :disabled="currentpage == subscriptions.last_page"
                          :class="
                            currentpage == subscriptions.last_page
                              ? 'cursor-not-allowed'
                              : ''
                          "
                          class="
                            ml-3
                            relative
                            inline-flex
                            items-center
                            px-4
                            py-2
                            border border-gray-300
                            text-sm
                            font-medium
                            rounded-md
                            text-gray-700
                            bg-white
                            hover:bg-gray-50
                            cursor-pointer
                          "
                        >
                          Suivants
                        </button>
                      </div>
                    </nav>
                  </div>
                  <!-- pagination ends -->
                </div>
              </div>
            </div>
          </div>
          <div v-if="currenttab == 'guides'">
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="
                    py-2
                    align-middle
                    inline-block
                    min-w-full
                    sm:px-6
                    lg:px-8
                  "
                >
                  <div
                    class="
                      shadow
                      overflow-hidden
                      border-b border-gray-200
                      sm:rounded-lg
                    "
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Identifiant
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Date de la demande
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Statut
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Personne concernée
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Établissement
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Détail</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="guides">
                        <tr
                          v-for="(guide, guideIdx) in guides.data"
                          :key="guide.id"
                          :class="
                            guideIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          "
                        >
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm
                              font-medium
                              text-gray-900
                            "
                          >
                            {{ guide.id }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{ moment(guide.created_at).format("D MMMM YYYY") }}
                          </td>
                          <td class="px-6 py-4 text-sm text-gray-500">
                            <span
                              class="
                                inline-flex
                                items-center
                                px-2.5
                                py-0.5
                                rounded-full
                                text-xs
                                font-medium
                                bg-indigo-100
                                text-indigo-800
                              "
                            >
                              {{ guide.status }}
                            </span>
                          </td>
                          <td
                            v-if="guide.inmate"
                            class="px-6 py-4 text-sm text-gray-500"
                          >
                            {{ guide.inmate.firstname }}
                            {{ guide.inmate.lastname }}
                          </td>
                          <td
                            v-if="guide.prison"
                            class="px-6 py-4 text-sm text-gray-500"
                          >
                            {{ guide.prison.title }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-right text-sm
                              font-medium
                            "
                          >
                            <a
                              @click.prevent="handleGuide(guide)"
                              class="
                                text-indigo-600
                                hover:text-indigo-900
                                cursor-pointer
                              "
                              >Gérer</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- pagination  -->
                  <div v-if="guides">
                    <nav
                      class="
                        bg-white
                        px-4
                        py-3
                        flex
                        items-center
                        justify-between
                        border-t border-gray-200
                        sm:px-6
                      "
                      aria-label="Pagination"
                    >
                      <div class="hidden sm:block">
                        <p class="text-sm text-gray-700">
                          Page
                          {{ " " }}
                          <span class="font-medium">{{ currentpage }}</span>
                          {{ " " }}
                          sur
                          {{ " " }}
                          <span class="font-medium">{{
                            guides.last_page
                          }}</span>
                          {{ " " }}
                          de
                          {{ " " }}
                          <span class="font-medium">{{ guides.total }}</span>
                          {{ " " }}
                          résultats
                        </p>
                      </div>
                      <div class="flex-1 flex justify-between sm:justify-end">
                        <button
                          @click.prevent="goPreviouspage"
                          :disabled="currentpage < 2"
                          :class="currentpage < 2 ? 'cursor-not-allowed' : ''"
                          class="
                            relative
                            inline-flex
                            items-center
                            px-4
                            py-2
                            border border-gray-300
                            text-sm
                            font-medium
                            rounded-md
                            text-gray-700
                            bg-white
                            hover:bg-gray-50
                            cursor-pointer
                          "
                        >
                          Précédents
                        </button>
                        <button
                          @click.prevent="goNextpage"
                          :disabled="currentpage == guides.last_page"
                          :class="
                            currentpage == guides.last_page
                              ? 'cursor-not-allowed'
                              : ''
                          "
                          class="
                            ml-3
                            relative
                            inline-flex
                            items-center
                            px-4
                            py-2
                            border border-gray-300
                            text-sm
                            font-medium
                            rounded-md
                            text-gray-700
                            bg-white
                            hover:bg-gray-50
                            cursor-pointer
                          "
                        >
                          Suivants
                        </button>
                      </div>
                    </nav>
                  </div>
                  <!-- pagination ends -->
                </div>
              </div>
            </div>
          </div>
          <div v-if="currenttab == 'npai'">
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="
                    py-2
                    align-middle
                    inline-block
                    min-w-full
                    sm:px-6
                    lg:px-8
                  "
                >
                  <div
                    class="
                      shadow
                      overflow-hidden
                      border-b border-gray-200
                      sm:rounded-lg
                    "
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Identifiant
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Personne détenue / Abonnement lié
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Identifiant de l'abonnement
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Statut
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Réglé ?</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="deadletters">
                        <tr
                          v-for="(
                            deadletter, deadletterIdx
                          ) in deadletters.data"
                          :key="deadletter.id"
                          :class="
                            deadletterIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          "
                        >
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm
                              font-medium
                              text-gray-900
                            "
                          >
                            {{ deadletter.id }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{ deadletter.inmate.firstname }}
                            {{ deadletter.inmate.lastname }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{ deadletter.subscription.id }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            <span
                              v-if="deadletter.resolved == true"
                              class="
                                inline-flex
                                items-center
                                px-2.5
                                py-0.5
                                rounded-full
                                text-xs
                                font-medium
                                bg-green-100
                                text-green-800
                              "
                            >
                              Réglé
                            </span>
                            <span
                              v-else
                              class="
                                inline-flex
                                items-center
                                px-2.5
                                py-0.5
                                rounded-full
                                text-xs
                                font-medium
                                bg-red-100
                                text-red-800
                              "
                            >
                              À régler
                            </span>
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-right text-sm
                              font-medium
                            "
                          >
                            <a
                              v-if="deadletter.resolved == false"
                              @click.prevent="showDeadletterStatus(deadletter)"
                              class="
                                text-indigo-600
                                hover:text-indigo-900
                                cursor-pointer
                              "
                              >Réglé ?</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import AppNavbar from '@/components/AppNavbar.vue';
import DeadletterResolution from '@/components/Modals/DeadletterResolution.vue';
import SendingConfirmation from '@/components/Modals/SendingConfirmation.vue';
import HandleSubscription from '@/components/Modals/HandleSubscription.vue';
import HandleGuide from '@/components/Modals/HandleGuide.vue';

const tabs = [
  { name: 'Envois', href: 'envois', current: false },
  { name: 'Abonnements', href: 'abonnements', current: false },
  { name: 'Guides du prisonnier', href: 'guides', current: false },
  { name: 'NPAI', href: 'npai', current: false },
];
const currenttab = ref('');
const deadletters = ref();
const subscriptions = ref();
const sendings = ref();
const guides = ref();
const deadletterstatus = ref(false);
const sendingconfirmation = ref(false);
const displayhandlesubscription = ref(false);
const selectedsubscription = ref();
const selecteddeadletter = ref();
const chosensending = ref();
const currentpage = ref();
const handleguide = ref(false);
const search = ref();
const usersearch = ref();

export default {
  components: {
    AppNavbar,
    DeadletterResolution,
    SendingConfirmation,
    HandleSubscription,
    HandleGuide,
  },
  setup() {
    return {
      tabs,
      currenttab,
      deadletters,
      sendings,
      guides,
      chosensending,
      subscriptions,
      deadletterstatus,
      sendingconfirmation,
      displayhandlesubscription,
      selectedsubscription,
      selecteddeadletter,
      currentpage,
      handleguide,
      search,
      usersearch,
    };
  },
  created() {
    this.currentpage = 1;
    this.moment = moment;
    this.currenttab = this.$route.params.tab;
    this.getDeadletters();
    this.getSubcriptions();
    this.getSendings();
    this.getGuides();
  },
  methods: {
    gotoTab(tabname) {
      this.$router.push({ name: 'Publications', params: { tab: tabname } });
      this.currenttab = tabname;
      this.currentpage = 1;
    },
    getSendings() {
      this.$http.get(`api/sendings?page=${this.currentpage}`)
        .then((response) => {
          this.sendings = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getGuides() {
      this.$http.get(`api/guides?page=${this.currentpage}`)
        .then((response) => {
          this.guides = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getDeadletters() {
      this.$http.get(`api/deadletters?page=${this.currentpage}`)
        .then((response) => {
          this.deadletters = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getSubcriptions() {
      this.$http.get(`api/subscriptions?page=${this.currentpage}`)
        .then((response) => {
          this.subscriptions = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    searchSubscriptions() {
      this.usersearch = this.search;
      this.$http.get(`api/subscriptions-search/${this.search}`)
        .then((response) => {
          this.subscriptions = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    showDeadletterStatus(chosenDeadletter) {
      this.deadletterstatus = true;
      this.selecteddeadletter = chosenDeadletter;
    },
    closeDeadletterStatus() {
      this.deadletterstatus = false;
    },
    deadletterSolved() {
      this.deadletterstatus = false;
      this.getDeadletters();
      this.getSubcriptions();
    },
    confirmSending(chosenSending) {
      this.sendingconfirmation = true;
      this.chosensending = chosenSending;
    },
    closeSendingConfirmation() {
      this.sendingconfirmation = false;
    },
    SendingConfirmed() {
      this.sendingconfirmation = false;
      this.getSendings();
    },
    handleSubscription(chosenSubscription) {
      this.selectedsubscription = chosenSubscription;
      this.displayhandlesubscription = true;
    },
    endHandleSubscription() {
      this.displayhandlesubscription = false;
      this.getSubcriptions();
    },
    handleGuide(chosenGuide) {
      this.handleguide = true;
      this.selectedguide = chosenGuide;
    },
    endHandleGuide() {
      this.handleguide = false;
      this.chosenGuide = null;
    },
    confirmGuideUpdate() {
      this.handleguide = false;
      this.getGuides();
    },
    goNextpage() {
      this.currentpage += 1;
      this.getSendings();
      this.getSubcriptions();
      this.getGuides();
    },
    goPreviouspage() {
      this.currentpage -= 1;
      this.getSendings();
      this.getSubcriptions();
      this.getGuides();
    },
    resetSearch() {
      this.usersearch = null;
      this.search = null;
      this.getSubcriptions();
    },
    exportSubscriptions() {
      this.$http.get('api/subscriptionsexport', {
        responseType: 'blob',
      }).then((response) => {
        window.console.log(response.data.type);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'oip_abonnements.csv');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
  },
};
</script>
