<template>
  <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
    <div class="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="-ml-2 mr-2 flex items-center mid:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="
                inline-flex
                items-center
                justify-center
                p-2
                rounded-md
                text-gray-400
                hover:text-white hover:bg-gray-700
                focus:outline-none
                focus:ring-2
                focus:ring-inset
                focus:ring-white
              "
            >
              <span class="sr-only">Ouvrir</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div class="flex-shrink-0 flex items-center">
            <a href="/">
              <img
                class="block lg:hidden h-8 w-auto"
                src="@/assets/img/favicon.svg" />
              <img
                class="hidden lg:block h-8 w-auto"
                src="@/assets/img/favicon.svg"
            /></a>
          </div>
          <div class="hidden mid:ml-6 mid:flex mid:items-center mid:space-x-4">
            <router-link
              v-for="item in navigation"
              :key="item.name"
              :to="item.href"
              :class="[
                item.current
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'px-3 py-2 rounded-md text-sm font-medium',
              ]"
              :aria-current="item.current ? 'page' : undefined"
              >{{ item.name }}</router-link
            >
          </div>
        </div>
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <button
              @click.prevent="gotoSollicitationCreation"
              type="button"
              class="
                relative
                inline-flex
                items-center
                px-4
                py-2
                border border-transparent
                shadow-sm
                text-sm
                font-medium
                rounded-md
                text-white
                bg-red-500
                hover:bg-red-600
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-offset-gray-800
                focus:ring-red-500
              "
            >
              <PlusSmIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              <span>Sollicitation</span>
            </button>
          </div>
          <div class="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
            <button
              @click.prevent="displaynotifications = true"
              type="button"
              class="
                bg-gray-800
                p-1
                rounded-full
                text-gray-400
                hover:text-white
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-offset-gray-800
                focus:ring-white
              "
            >
              <span class="sr-only">Voir les notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>
            <div v-if="unseenfollowups">
              <span
                v-if="unseenfollowups.length !== 0"
                class="
                  -ml-3
                  inline-flex
                  items-center
                  px-2.5
                  py-0.5
                  rounded-full
                  text-xs
                  font-medium
                  bg-red-500
                  text-white
                "
              >
                {{ unseenfollowups.length }}
              </span>
            </div>
            <button
              @click.prevent="displaynotepads = true"
              type="button"
              class="
                bg-gray-800
                p-1
                rounded-full
                text-gray-400
                hover:text-white
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-offset-gray-800
                focus:ring-white
              "
            >
              <span class="sr-only">Voir les notifications</span>
              <PencilAltIcon class="h-6 w-6" aria-hidden="true" />
            </button>
            <div v-if="notepads">
              <span
                v-if="notepads.length !== 0"
                class="
                  -ml-3
                  inline-flex
                  items-center
                  px-2.5
                  py-0.5
                  rounded-full
                  text-xs
                  font-medium
                  bg-red-500
                  text-white
                "
              >
                {{ notepads.length }}
              </span>
            </div>
            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton
                  class="
                    bg-gray-800
                    flex
                    text-sm
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-offset-gray-800
                    focus:ring-white
                  "
                >
                  <span class="sr-only">Mon compte</span>
                  <img
                    class="h-8 w-8 rounded-full"
                    src="@/assets/img/logout.svg"
                    alt=""
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-48
                    rounded-md
                    shadow-lg
                    py-1
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <MenuItem>
                    <a
                      @click.prevent="sayGoodbye"
                      class="
                        [
                        active
                        ?
                        'bg-gray-100'
                        :
                        '',
                        'block
                        px-4
                        py-2
                        text-sm text-gray-700',
                        ]
                        cursor-pointer
                      "
                      >Déconnexion</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>

    <DisclosurePanel class="mid:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <a
          v-for="item in navigation"
          :key="item.name"
          :href="item.href"
          :class="[
            item.current
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'block px-3 py-2 rounded-md text-base font-medium',
          ]"
          :aria-current="item.current ? 'page' : undefined"
          >{{ item.name }}</a
        >
      </div>
      <div class="pt-4 pb-3 border-t border-gray-700">
        <div class="flex items-center px-5 sm:px-6">
          <!-- <div class="flex-shrink-0">
            <img class="h-10 w-10 rounded-full" :src="user.imageUrl" alt="" />
          </div> -->
          <div class="ml-3">
            <div class="text-base font-medium text-white">
              {{ currentuser.name }}
            </div>
            <div class="text-sm font-medium text-gray-400">
              {{ currentuser.email }}
            </div>
          </div>
          <button
            @click.prevent="displaynotifications = true"
            type="button"
            class="
              ml-auto
              flex-shrink-0
              bg-gray-800
              p-1
              rounded-full
              text-gray-400
              hover:text-white
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-offset-gray-800
              focus:ring-white
            "
          >
            <span class="sr-only">Voir les notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div v-if="unseenfollowups">
            <span
              v-if="unseenfollowups.length !== 0"
              class="
                -ml-3
                inline-flex
                items-center
                px-2.5
                py-0.5
                rounded-full
                text-xs
                font-medium
                bg-red-500
                text-white
              "
            >
              {{ unseenfollowups.length }}
            </span>
          </div>
          <button
            @click.prevent="displaynotepads = true"
            type="button"
            class="
              bg-gray-800
              p-1
              rounded-full
              text-gray-400
              hover:text-white
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-offset-gray-800
              focus:ring-white
            "
          >
            <span class="sr-only">Voir les notifications</span>
            <PencilAltIcon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div v-if="notepads">
            <span
              v-if="notepads.length !== 0"
              class="
                -ml-3
                inline-flex
                items-center
                px-2.5
                py-0.5
                rounded-full
                text-xs
                font-medium
                bg-red-500
                text-white
              "
            >
              {{ notepads.length }}
            </span>
          </div>
        </div>
        <div class="mt-3 px-2 space-y-1 sm:px-3">
          <a
            v-for="item in userNavigation"
            :key="item.name"
            :href="item.href"
            class="
              block
              px-3
              py-2
              rounded-md
              text-base
              font-medium
              text-gray-400
              hover:text-white hover:bg-gray-700
            "
            >{{ item.name }}</a
          >
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
  <AppNotifications
    v-show="displaynotifications"
    v-model:currentuserid="currentuser.id"
    v-model:displaynotifications="displaynotifications"
    v-model:unseenfollowups="unseenfollowups"
    @shut="hideNotifs"
  />
  <NotepadNotifications
    v-show="displaynotepads"
    v-model:notepads="notepads"
    v-model:displaynotepads="displaynotepads"
    @shut="hideNotepadNotif"
  />
</template>

<script>
import { ref } from 'vue';
import {
  Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import {
  BellIcon, MenuIcon, XIcon, PencilAltIcon,
} from '@heroicons/vue/outline';
import { PlusSmIcon } from '@heroicons/vue/solid';
import Cookies from 'js-cookie';
import AppNotifications from '@/components/AppNotifications.vue';
import NotepadNotifications from '@/components/NotepadNotifications.vue';

const navigation = [
  { name: 'Accueil', href: '/', current: false },
  { name: 'Dossiers', href: '/personnes-detenues', current: false },
  { name: 'Sollicitations', href: '/sollicitations', current: false },
  { name: 'Contacts', href: '/contacts/', current: false },
  { name: 'Insiders', href: '/insiders/', current: false },
  { name: 'Fichiers', href: '/mediatheque/', current: false },
  { name: 'Prisons', href: '/etablissements/', current: false },
  { name: 'Publications', href: '/publications/envois/', current: false },
  { name: 'Stats', href: '/statistiques/', current: false },
];

const currentuser = ref();
const displaynotifications = ref(false);
const displaynotepads = ref(false);
const followupscount = ref();
const notepads = ref();

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BellIcon,
    MenuIcon,
    XIcon,
    PlusSmIcon,
    AppNotifications,
    NotepadNotifications,
    PencilAltIcon,
  },
  setup() {
    return {
      currentuser,
      navigation,
      displaynotifications,
      displaynotepads,
      followupscount,
      notepads,
    };
  },
  created() {
    if (localStorage.user) {
      this.currentuser = JSON.parse(localStorage.user);
    }
  },
  mounted() {
    this.getNotifs();
    this.getNotepads();
  },
  watch: {
    user(newUser) {
      localStorage.user = newUser;
    },
  },
  methods: {
    sayGoodbye() {
      localStorage.removeItem('user');
      Cookies.remove('token');
      this.$router.push({ name: 'Login' });
    },
    getNotifs() {
      this.$http.get(`/api/myfollowups/${this.currentuser.id}`)
        .then((response) => {
          this.unseenfollowups = response.data.myfollowups;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getNotepads() {
      this.$http.get(`/api/mynotepads/${this.currentuser.id}`)
        .then((response) => {
          this.notepads = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    gotoSollicitationCreation() {
      this.$router.push({ name: 'SollicitationCreate' });
    },
    hideNotifs() {
      this.getNotifs();
      this.displaynotifications = false;
    },
    hideNotepadNotif() {
      this.displaynotepads = false;
    },
  },
};
</script>
