<template>
  <div
    class="
      min-w-screen min-h-screen
      bg-black-100
      flex
      items-center
      p-5
      lg:p-20
      overflow-hidden
      relative
    "
  >
    <div
      class="
        flex-1
        min-h-full min-w-full
        rounded-3xl
        bg-white
        shadow-xl
        p-10
        lg:p-20
        text-gray-800
        relative
        md:flex
        items-center
        text-center
        md:text-left
      "
    >
      <div class="w-full md:w-1/2">
        <div class="mb-10 md:mb-20 text-gray-600">
          <h1 class="uppercase text-3xl lg:text-5xl text-black-700 mb-10">
            Mince...
          </h1>
          <p>Cette page n'existe pas</p>
          <p>dans l'application.</p>
        </div>
        <div class="mb-20 md:mb-0">
          <a
            href="/"
            class="
              text-lg
              font-light
              outline-none
              focus:outline-none
              transform
              transition-all
              hover:scale-110
              text-red-500
              hover:text-red-600
            "
          >
            Revenir au tableau de bord
          </a>
        </div>
      </div>
      <div class="w-full md:w-1/2 text-center">
        <img src="@/assets/img/favicon.svg" class="w-64 h-64" />
      </div>
    </div>
    <div
      class="
        w-64
        md:w-96
        h-96
        md:h-full
        bg-red-200 bg-opacity-30
        absolute
        -top-64
        md:-top-96
        right-20
        md:right-32
        rounded-full
        pointer-events-none
        -rotate-45
        transform
      "
    ></div>
    <div
      class="
        w-96
        h-full
        bg-red-200 bg-opacity-20
        absolute
        -bottom-96
        right-64
        rounded-full
        pointer-events-none
        -rotate-45
        transform
      "
    ></div>
  </div>
</template>
