<template>
  <TransitionRoot as="template" :show="displayfollowupcreation">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <form
                class="
                  h-full
                  divide-y divide-gray-200
                  flex flex-col
                  bg-white
                  shadow-xl
                "
              >
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-gray-800 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        Créer un suivi
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          class="
                            bg-gray-800
                            rounded-md
                            text-white
                            hover:text-white
                            focus:outline-none focus:ring-2 focus:ring-white
                          "
                          @click="$emit('shut')"
                        >
                          <span class="sr-only">Fermer</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-white">
                        Créer un suivi vous permet d'attribuer cette
                        sollicitation à n'importe quel autre membre de l'équipe,
                        qui verra remonter cette sollicitaiton dans son tableau
                        de bord.
                      </p>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <div>
                          <label
                            for="project-name"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Pour quel membre de l'équipe est ce suivi ?
                          </label>
                          <div class="mt-1">
                            <select
                              id="type"
                              name="type"
                              autocomplete="filetype"
                              v-model="form.user_id"
                              class="
                                shadow-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                sm:text-sm
                                border-gray-300
                                rounded-md
                              "
                            >
                              <option
                                v-for="user in users"
                                :key="user.id"
                                :value="user.id"
                              >
                                {{ user.name }} {{ user.surname }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <label
                            for="date"
                            class="block text-sm font-medium text-gray-900 mt-6"
                          >
                            Date de rappel
                          </label>
                          <div class="mt-1">
                            <input
                              type="datetime-local"
                              v-model="form.date"
                              id="date"
                              name="date"
                              rows="4"
                              class="
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                border border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            for="comment"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Commentaire
                          </label>
                          <div class="mt-1">
                            <textarea
                              v-model="form.comment"
                              id="comment"
                              name="comment"
                              rows="4"
                              class="
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                border border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button
                    type="button"
                    class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                    @click="$emit('shut')"
                  >
                    Annuler
                  </button>
                  <button
                    @click.prevent="createFollowup"
                    type="submit"
                    class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-red-500
                    "
                  >
                    Attribuer le suivi
                  </button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const open = ref(true);
    const users = ref();
    const currentuser = ref();

    return {
      currentuser,
      open,
      users,
      form: {
        date: null,
        user_id: null,
        follower_id: null,
        comment: '',
      },
    };
  },
  props: {
    displayfollowupcreation: Boolean,
    sollicitation: Object,
  },
  created() {
    if (localStorage.user) {
      this.currentuser = JSON.parse(localStorage.user);
    }
    this.getUsers();
  },
  watch: {
    user(newUser) {
      localStorage.user = newUser;
    },
  },
  methods: {
    getUsers() {
      this.$http.get('/api/users')
        .then((response) => {
          this.users = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    createFollowup() {
      this.$http.post('/api/followup-creation/', {
        user_id: this.currentuser.id,
        date: this.form.date,
        follower_id: this.form.user_id,
        comment: this.form.comment,
        sollicitation_id: this.sollicitation.id,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('followupcreated');
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
