<template>
  <TransitionRoot as="template" :show="displayfilecreation">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-2xl">
              <form
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="flex-1">
                  <!-- Header -->
                  <div class="px-4 py-6 bg-gray-50 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle
                          class="text-lg font-medium text-gray-900"
                          v-if="currentstep == 1"
                        >
                          Ajouter un fichier
                        </DialogTitle>
                        <DialogTitle
                          class="text-lg font-medium text-gray-900"
                          v-if="currentstep == 2"
                        >
                          Maintenant, liez le fichier crée à une ou plusieurs
                          personnes
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          Vous pouvez créer un fichier ici, qui sera lié à cette
                          sollicitation.
                        </p>
                      </div>
                      <div class="h-7 flex items-center">
                        <button
                          type="button"
                          class="text-gray-400 hover:text-gray-500"
                          @click="closePanel"
                        >
                          <span class="sr-only">Fermer</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Divider container -->
                  <div
                    class="
                      py-6
                      space-y-6
                      sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200
                    "
                  >
                    <div
                      v-if="currentstep == 1"
                      class="
                        space-y-1
                        px-4
                        sm:space-y-0
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:px-6
                        sm:py-5
                      "
                    >
                      <div>
                        <label
                          for="title"
                          class="
                            block
                            text-sm
                            font-medium
                            text-gray-900
                            sm:mt-px sm:pt-2
                          "
                        >
                          Titre du fichier
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          name="title"
                          id="title"
                          v-model="form.title"
                          placeholder="Ex : témoignage de la prison de Fresnes"
                          class="
                            block
                            w-full
                            shadow-sm
                            sm:text-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>

                    <div
                      v-if="currentstep == 1"
                      class="
                        space-y-1
                        px-4
                        sm:space-y-0
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:px-6
                        sm:py-5
                      "
                    >
                      <div>
                        <label
                          for="title"
                          class="
                            block
                            text-sm
                            font-medium
                            text-gray-900
                            sm:mt-px sm:pt-2
                          "
                        >
                          Type du fichier
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <select
                          id="type"
                          name="type"
                          autocomplete="filetype"
                          v-model="form.type_id"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        >
                          <option
                            v-for="filetype in filetypes"
                            :key="filetype.id"
                            :value="filetype.id"
                          >
                            {{ filetype.title }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      v-if="currentstep == 1"
                      class="
                        space-y-1
                        px-4
                        sm:space-y-0
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:px-6
                        sm:py-5
                      "
                    >
                      <div>
                        <label
                          for="date"
                          class="
                            block
                            text-sm
                            font-medium
                            text-gray-900
                            sm:mt-px sm:pt-2
                          "
                        >
                          Date
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          id="date"
                          name="date"
                          type="datetime-local"
                          v-model="form.date"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>

                    <div
                      v-if="currentstep == 1"
                      class="
                        space-y-1
                        px-4
                        sm:space-y-0
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:px-6
                        sm:py-5
                      "
                    >
                      <div>
                        <label
                          for="comment"
                          class="
                            block
                            text-sm
                            font-medium
                            text-gray-900
                            sm:mt-px sm:pt-2
                          "
                        >
                          Commentaire
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <textarea
                          id="comment"
                          name="comment"
                          v-model="form.comment"
                          placeholder="Vous pouvez décrire, en quelques mots, le fichier afin de permettre aux autres membres de l'équipe d'avoir une idée du contenu sans pour autant le télécharger."
                          rows="5"
                          class="
                            block
                            w-full
                            shadow-sm
                            sm:text-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            border border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>

                    <div
                      v-if="currentstep == 1"
                      class="
                        space-y-1
                        px-4
                        sm:space-y-0
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:px-6
                        sm:py-5
                      "
                    >
                      <div>
                        <label
                          for="document"
                          class="
                            block
                            text-sm
                            font-medium
                            text-gray-900
                            sm:mt-px sm:pt-2
                          "
                        >
                          Document
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          @change="selectFile"
                          type="file"
                          name="file"
                          id="file"
                          class="
                            block
                            w-full
                            shadow-sm
                            sm:text-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            rounded-md
                          "
                        />
                      </div>
                    </div>

                    <div
                      v-if="currentstep == 2"
                      class="
                        space-y-1
                        px-4
                        sm:space-y-0
                        sm:grid
                        sm:grid-cols-1
                        sm:gap-4
                        sm:px-6
                        sm:py-5
                      "
                    >
                      <div
                        class="
                          border border-gray-300
                          rounded-md
                          px-3
                          py-2
                          shadow-sm
                          focus-within:ring-1
                          focus-within:ring-indigo-600
                          focus-within:border-indigo-600
                        "
                      >
                        <label
                          for="name"
                          class="block text-xs font-medium text-gray-900"
                          >Rechercher par nom</label
                        >
                        <input
                          v-model="search"
                          type="text"
                          name="search"
                          id="search"
                          class="
                            block
                            w-full
                            border-0
                            p-0
                            text-gray-900
                            placeholder-gray-500
                            focus:ring-0
                            sm:text-sm
                          "
                          placeholder="Jane Doe"
                        />
                      </div>
                      <ul
                        role="list"
                        class="-my-5 divide-y divide-gray-200 mt-6"
                        v-if="search"
                      >
                        <li
                          v-for="inmate in matchingInmates"
                          :key="inmate.id"
                          class="py-4"
                        >
                          <div class="flex items-center space-x-4">
                            <div class="flex-1 min-w-0">
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-900
                                  truncate
                                "
                              >
                                {{ inmate.firstname }} {{ inmate.lastname }}
                              </p>
                            </div>
                            <div>
                              <a
                                @click.prevent="linkInmate(inmate.id)"
                                class="
                                  inline-flex
                                  items-center
                                  shadow-sm
                                  px-2.5
                                  py-0.5
                                  text-sm
                                  leading-5
                                  font-medium
                                  rounded-full
                                  text-white
                                  bg-green-500
                                  hover:bg-green-700
                                  cursor-pointer
                                "
                              >
                                Ajouter
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <p class="text-sm text-gray-500" v-else>
                        Tapez quelques lettres du nom ou prénom pour pouvoir
                        afficher puis lier ce fichier à une personne détenue.
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Action buttons -->
                <div
                  v-if="currentstep == 1"
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('shut')"
                    >
                      Annuler
                    </button>
                    <button
                      @click.prevent="uploadFile"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Créer le fichier
                    </button>
                  </div>
                </div>
                <div
                  v-if="currentstep == 2"
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      @click.prevent="closePanel"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      OK, j'ai terminé !
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, computed } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const open = ref(true);
    const processingfile = ref();
    const filetypes = ref();
    const inmates = ref();
    const search = ref('');
    const searchquery = computed(() => search.value.toLowerCase());
    const matchingInmates = computed(() => inmates.value.filter((inmate) => inmate.firstname.toLowerCase().includes(searchquery.value) || inmate.lastname.toLowerCase().includes(searchquery.value)));
    const postedfile = ref();
    const currentstep = ref(1);

    return {
      open,
      processingfile,
      filetypes,
      inmates,
      search,
      searchquery,
      matchingInmates,
      postedfile,
      currentstep,
      form: {
        title: null,
        comment: null,
        file: null,
        date: null,
        type_id: null,
        document: null,
      },
    };
  },
  props: {
    displayfilecreation: Boolean,
    sollicitation: Object,
    sollicitationcontext: Boolean,
  },
  created() {
    this.getfileTypes();
    this.moment = moment;
    if (this.sollicitationcontext === true) {
      const proposeddate = moment(this.sollicitation.date).format('YYYY-MM-DDTHH:mm');
      this.form.date = proposeddate;
    } else {
      const proposeddate = moment(new Date()).format('YYYY-MM-DDTHH:mm');
      this.form.date = proposeddate;
    }
  },
  methods: {
    getfileTypes() {
      this.$http.get('/api/filetypes')
        .then((response) => {
          this.filetypes = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getInmates() {
      const id = this.postedfile;
      this.$http.get(`/api/file-candidates/${id}/`)
        .then((response) => {
          this.inmates = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    selectFile(event) {
      [this.form.document] = event.target.files;
      window.console.log(this.form.document);
    },
    uploadFile() {
      window.console.log(this.form.document);
      const formData = new FormData();
      formData.append('title', this.form.title);
      formData.append('comment', this.form.comment);
      formData.append('date', this.form.date);
      formData.append('type_id', this.form.type_id);
      formData.append('document', this.form.document);
      this.$http.post('/api/file-creation/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        window.console.log(response.data);
        this.postedfile = response.data.id;
        this.$emit('filecreated');
        this.clearData();
        if (this.sollicitationcontext === false) {
          this.currentstep = 2;
        }
        this.getInmates();
      }).then(() => {
        if (this.sollicitationcontext === true) {
          this.linkSollicitation();
          this.linkSollicitationInmates();
        }
      }).catch((error) => {
        window.console.log(error);
      });
    },
    clearData() {
      this.form.title = null;
      this.form.comment = null;
      this.form.type_id = null;
    },
    linkInmate(inmateId) {
      this.$http.patch('api/inmate-file', {
        id: this.postedfile,
        inmate_id: inmateId,
      }).then((response) => {
        window.console.log(response.data);
        this.getInmates();
        this.$emit('filecreated');
      }).catch((error) => {
        window.console.log(error);
      });
    },
    linkSollicitationInmates() {
      for (let i = 0; i < this.sollicitation.inmates.length; i += 1) {
        const inmateId = this.sollicitation.inmates[i].id;
        this.$http.patch('api/inmate-file', {
          id: this.postedfile,
          inmate_id: inmateId,
        });
      }
    },
    linkSollicitation() {
      this.$http.patch('api/sollicitation-file', {
        id: this.postedfile,
        sollicitation_id: this.sollicitation.id,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('filecreated');
        this.closePanel();
      }).catch((error) => {
        window.console.log(error);
      });
    },
    closePanel() {
      this.currentstep = 1;
      this.$emit('shut');
    },
  },
};
</script>

<style scoped>
::placeholder {
  padding: 0.5rem;
}
</style>
