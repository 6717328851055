<!-- eslint-disable -->
<template>
  <div class="min-h-screen bg-white flex">
    <div
      class="
        flex-1 flex flex-col
        justify-center
        py-12
        px-4
        sm:px-6
        lg:flex-none lg:px-20
        xl:px-24
      "
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-20 w-auto" src="@/assets/img/favicon.svg" />
        </div>
        <!-- error message -->
        <div class="rounded-md bg-red-50 p-4 mt-5" v-if="errored == true">
          <div class="flex">
            <div class="flex-shrink-0">
              <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                Oups, nous n'avons pas pu vous identifier...
              </h3>
              <div class="mt-2 text-sm text-red-700">
                <ul role="list" class="list-disc pl-5 space-y-1">
                  <li>
                    Vous devez utiliser votre adresse email pour vous
                    identifier, vérifiez que celle que vous utilisez est bien la
                    bonne
                  </li>
                  <li>Votre mot de passe est peut-être erroné...</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- error message ends -->
        <div class="mt-8">
          <div class="mt-6">
            <form
              action="#"
              method="POST"
              class="space-y-6"
              v-on:keyup.enter="loginUser"
            >
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div class="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required=""
                    v-model="form.email"
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-indigo-500
                      focus:border-indigo-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>
              <div class="space-y-1">
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Mot de passe
                </label>
                <div class="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required=""
                    v-model="form.password"
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none focus:ring-red-500 focus:border-red-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>
              <!-- 
              <div class="flex items-center justify-between">
                <div class="flex items-center"></div>

                <div class="text-sm">
                  <a
                    href="#"
                    class="font-medium text-black-600 hover:text-indigo-500"
                  >
                    Mot de passe oublié ?
                  </a>
                </div>
              </div> -->

              <div>
                <button
                  type="submit"
                  @click.prevent="loginUser"
                  class="
                    w-full
                    flex
                    justify-center
                    py-2
                    px-4
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-white
                    bg-red-600
                    hover:bg-red-500
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  Commencez
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="@/assets/img/phone.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { XCircleIcon } from '@heroicons/vue/solid';

export default ({
  components: {
    XCircleIcon,
  },
  setup() {
    const currentuser = ref();
    const errored = ref(false);

    return {
      currentuser,
      errored,
      form: {
        email: null,
        password: null,
      },
    };
  },
  mounted() {
    if (localStorage.user) {
      this.currentuser = JSON.parse(localStorage.user);
    }
  },
  watch: {
    user(newUser) {
      localStorage.user = newUser;
    },
  },
  methods: {
    loginUser() {
      this.$http
        .post('api/login/', {
          email: this.form.email,
          password: this.form.password,
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        }).then((response) => {
          localStorage.setItem('user', JSON.stringify(response.data.user));
          this.$router
            .push({
              name: 'Dashboard',
            });
        })
        .catch(() => {
          this.form.password = null;
        });
    },
  },
});
</script>
