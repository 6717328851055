<template>
  <div class="min-h-full" v-if="inmate">
    <div class="bg-gray-800 pb-32">
      <AppNavbar />
      <AppNotification
        v-show="displaynotification"
        v-model:show="displaynotification"
        title="Le dossier a bien été créé !"
        content="Vous voici sur la page du dossier, vous pouvez effectuer toutes les actions nécessaires à partir de cette vue."
        @closenotif="hideNotif"
      />
      <AppNotification
        v-show="confirmnotepadupdate"
        v-model:show="confirmnotepadupdate"
        title="Le bloc notes a été mis à jour !"
        content="Le bloc notes de cette personne a bien été mis à jour."
        @closenotif="hideUpdateNotepadNotif"
      />
      <AppConfirmationChoice
        v-show="sollicitationcreation"
        v-model="sollicitationcreation"
        @shut="closeSollicitationCreation"
        @see="gotoSollicitations"
        title="La sollicitation a bien été créé"
        content="Vous pouvez en créer une autre ou continuer à l'enrichir."
      />
      <AppNotification
        v-show="sollicitationpeople"
        v-model:show="sollicitationpeople"
        :title="title"
        :content="content"
        @closenotif="hideSollicitationpeople"
      />
      <ChoiceModal
        title="Enregistrer un appel pour cette personne ?"
        description="Vous pouvez ici, enregistrer un appel qui ne donnera pas lieu à une sollicitation, simplement pour en garder une trace, plutôt à des fins statistiques."
        doText="Je confirme !"
        undoText="Non, j'annule"
        v-show="showable"
        v-model:showable="showable"
        v-model:inmateId="this.inmate.id"
        @close="closeModal"
        @action="PhoneCallCreation()"
      />
      <ContactBoundEdit
        v-model:contacteditionmode="contacteditionmode"
        v-model:contacttoedit="contacttoedit"
        v-model:contact="contacttoedit"
        v-model:inmateid="inmate.id"
        @shut="closeContactBoundEdition"
      />
      <GuideSendingCreation
        v-model:inmate="inmate"
        v-model:guidesending="guidesending"
        @guidecreated="getInmate"
        @shut="closeGuideModal"
      />
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex items-center">
            <h1 class="text-3xl font-bold text-white">
              {{ inmate.firstname }}
              {{ inmate.lastname }}
            </h1>
            <button
              @click.prevent="editinmate = true"
              type="button"
              class="
                bg-gray-800
                p-1
                rounded-full
                text-gray-400
                hover:text-white
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-offset-gray-800
                focus:ring-white
              "
            >
              <span class="sr-only">Voir les notifications</span>
              <PencilIcon class="h-6 w-6 mr-2" aria-hidden="true" />
            </button>
            <span
              v-if="inmate.pendingdeadletter == true"
              class="
                inline-flex
                items-center
                px-3
                py-0.5
                rounded-full
                text-sm
                font-medium
                bg-indigo-100
                text-indigo-800
                ml-auto
              "
            >
              NPAI signalé !
            </span>
          </div>
          <h3 class="text-2xl text-white">
            {{ inmate.current_location.title }}
          </h3>
        </div>
      </header>
    </div>
    <main class="-mt-32">
      <EventCreation
        v-model:displayeventcreation="displayeventcreation"
        @shut="closeEventCreation"
        @refresh="getInmate"
        v-model:inmate="this.inmate"
        v-model:currentnotepad="currentnotepad"
      />
      <FilePanel
        v-model:displayfilepanel="displayfilepanel"
        @shut="closeFilePanel"
        @filetrashed="getInmate"
        v-model:file="selectedfile"
      />
      <ContactCreation
        v-model:displaycontactcreation="displaycontactcreation"
        v-model:inmate="inmate"
        @contactadded="getInmate"
        @shut="hidecontactcreation"
      />
      <SubscriptionChoice
        v-show="subscriptionconfirmation"
        v-model:subscriptionconfirmation="subscriptionconfirmation"
        v-model:inmate="inmate"
        @shut="giveupSubscription"
        @confirm="subscriptionCreated"
      />
      <SignalShippingError
        v-show="signalshippingerror"
        v-model:signalshippingerror="signalshippingerror"
        v-model:subscription="inmate.subscription"
        @shut="hideSignalShippingError"
      />
      <FileInmateCreation
        v-show="displayfilecreation"
        v-model:displayfilecreation="displayfilecreation"
        v-model:inmate="inmate"
        @shut="closeFileCreation"
        @filecreated="confirmFileCreation"
      />
      <EditInmate
        v-show="editinmate"
        v-model:editinmate="editinmate"
        v-model:inmate="inmate"
        @shut="closeEdition"
        @inmateupdated="confirmEdition"
      />
      <ContactDetail
        v-show="seedetail"
        v-model:seedetail="seedetail"
        v-model:contact="chosencontact"
        v-model:inmate_id="inmate.id"
        v-model:relationupdater="relationupdater"
        @shut="hideDetail"
        @contactupdated="confirmUpdate"
      />
      <SendingCreation
        v-show="sendpublications"
        v-model:sendpublications="sendpublications"
        v-model:inmate="inmate"
        @sendingcreated="getInmate"
        @shut="closeSendingPanel"
      />
      <SollicitationContent
        v-model:displaycontent="displaycontent"
        v-model:sollicitation="chosensollicitation"
        @shut="hideDetailedContent"
      />
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
          <!-- tabs -->
          <div class="mb-12">
            <div class="sm:hidden">
              <label for="tabs" class="sr-only">Choississez une rubrique</label>
              <select
                @change="gotoTab(currenttab)"
                v-model="currenttab"
                id="tabs"
                name="tabs"
                class="
                  block
                  w-full
                  focus:ring-indigo-500 focus:border-indigo-500
                  border-gray-300
                  rounded-md
                "
              >
                <option
                  v-for="tab in tabs"
                  :key="tab.name"
                  :selected="tab.href"
                  :value="tab.href"
                >
                  {{ tab.name }}
                </option>
              </select>
            </div>
            <div class="hidden sm:block">
              <div class="border-b border-gray-200">
                <nav class="-mb-px flex" aria-label="Tabs">
                  <a
                    v-for="tab in tabs"
                    :key="tab.name"
                    @click="gotoTab(tab.href)"
                    :class="[
                      currenttab === tab.href
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm cursor-pointer',
                    ]"
                    :aria-current="tab.current ? 'page' : undefined"
                  >
                    {{ tab.name }}
                    <span
                      v-if="
                        tab.name == 'Sollicitations' &&
                        sollicitationscount != '0'
                      "
                      class="
                        inline-flex
                        items-center
                        px-2.5
                        py-0.5
                        rounded-full
                        text-xs
                        font-medium
                        bg-red-500
                        text-white
                        ml-2
                      "
                    >
                      {{ sollicitationscount }}
                    </span>
                  </a>
                </nav>
              </div>
            </div>
          </div>
          <!-- tabs end -->
          <div v-if="currenttab === 'infos'">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Informations personnelles
                </h3>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <p>
                    Vous trouverez, ci-dessous, les dernières informations en
                    date sur cette personne. Pour créer une sollicitation et
                    prendre en charge sa demande et les détails de sa situation,
                    <span style="font-weight: bold"
                      >vous pouvez créer une sollicitation.</span
                    >
                  </p>
                </div>
              </div>
              <div
                class="
                  mt-5
                  sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
                "
              >
                <a
                  :href="
                    /personnes-detenues/ +
                    'creer-une-sollicitation' +
                    '/' +
                    inmate.id
                  "
                >
                  <button
                    type="button"
                    class="
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-transparent
                      shadow-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-red-500
                      sm:text-sm
                    "
                  >
                    Créer une sollicitation
                  </button>
                </a>
              </div>
            </div>
            <div class="mt-5 border-t border-gray-200">
              <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt class="text-sm font-medium text-gray-500">Genre</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ inmate.gender.label }}
                  </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt class="text-sm font-medium text-gray-500">
                    Année de naissance
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span v-if="inmate.birthdate">{{ inmate.birthdate }}</span>
                    <span v-else>Inconnue</span>
                  </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt class="text-sm font-medium text-gray-500">
                    Numéro d'écrou actuel
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ inmate.current_number }}
                  </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt class="text-sm font-medium text-gray-500">
                    Numéro SAGI actuel
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ inmate.current_sagi }}
                  </dd>
                </div>
                <div
                  class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
                  v-if="inmate.current_disp"
                >
                  <dt class="text-sm font-medium text-gray-500">
                    DISP actuelle
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ inmate.current_disp.title }}
                  </dd>
                </div>
                <!-- inmate infos begin -->
                <div>
                  <h3 class="text-lg leading-6 font-medium text-gray-900 mt-12">
                    Informations
                  </h3>
                  <p
                    class="mt-1 max-w-2xl text-sm text-gray-500"
                    v-if="inmate.files.length > 0"
                  >
                    Vous trouverez ici quelques informations notées sur cette
                    personne.
                  </p>
                  <p
                    v-html="inmate.infos"
                    class="mt-4"
                    v-if="editinfos == false"
                  ></p>
                  <TipTap
                    v-model="currentinfos"
                    class="mt-6 notepad-textarea"
                    v-else
                  />
                  <div class="mt-6">
                    <a
                      v-if="editinfos == false"
                      @click="editInfos"
                      class="
                        cursor-pointer
                        w-full
                        flex
                        justify-center
                        items-center
                        px-4
                        py-2
                        border border-gray-300
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-gray-700
                        bg-white
                        hover:bg-gray-50
                      "
                    >
                      Mettre à jour ces informations
                    </a>
                    <a
                      v-if="editinfos == true"
                      @click.prevent="updateInfos"
                      class="
                        bg-red-600
                        cursor-pointer
                        w-full
                        flex
                        justify-center
                        items-center
                        px-4
                        py-2
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-white
                        hover:bg-red-700
                      "
                    >
                      Mettre à jour !
                    </a>
                  </div>
                </div>
                <!-- inmate infos ends -->
                <!-- inmate files begin -->
                <div>
                  <h3 class="text-lg leading-6 font-medium text-gray-900 mt-12">
                    Fichiers
                  </h3>
                  <p
                    class="mt-1 max-w-2xl text-sm text-gray-500"
                    v-if="inmate.files.length > 0"
                  >
                    Vous trouverez ici l'ensemble des fichiers liés à cette
                    personne.
                  </p>
                  <div
                    class="flex flex-col mt-6"
                    v-if="inmate.files.length > 0"
                  >
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div
                        class="
                          py-2
                          align-middle
                          inline-block
                          min-w-full
                          sm:px-6
                          lg:px-8
                        "
                      >
                        <div
                          class="
                            shadow
                            overflow-hidden
                            border-b border-gray-200
                            sm:rounded-lg
                          "
                        >
                          <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  class="
                                    px-6
                                    py-3
                                    text-left text-xs
                                    font-medium
                                    text-gray-500
                                    uppercase
                                    tracking-wider
                                  "
                                >
                                  Titre
                                </th>
                                <th
                                  scope="col"
                                  class="
                                    px-6
                                    py-3
                                    text-left text-xs
                                    font-medium
                                    text-gray-500
                                    uppercase
                                    tracking-wider
                                  "
                                >
                                  Type
                                </th>
                                <th
                                  scope="col"
                                  class="
                                    px-6
                                    py-3
                                    text-left text-xs
                                    font-medium
                                    text-gray-500
                                    uppercase
                                    tracking-wider
                                  "
                                >
                                  Sollicitations liées
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                  <span class="sr-only">Détails</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                              <tr v-for="file in inmate.files" :key="file.id">
                                <td class="px-6 py-4 whitespace-nowrap">
                                  <div class="text-sm text-gray-900">
                                    {{ file.title }}
                                  </div>
                                  <div class="text-sm text-gray-500">
                                    {{
                                      moment(file.created_at).format(
                                        "dddd DD MMMM YYYY à HH:mm"
                                      )
                                    }}
                                  </div>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                  <span
                                    class="
                                      px-2
                                      inline-flex
                                      text-xs
                                      leading-5
                                      font-semibold
                                      rounded-full
                                      bg-blue-100
                                      text-blue-800
                                    "
                                  >
                                    {{ file.type_id }}
                                  </span>
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                  <div v-if="file.sollicitations.length !== 0">
                                    <span
                                      v-for="sollicitation in file.sollicitations"
                                      :key="sollicitation.id"
                                      >{{ sollicitation.title }}
                                      <br />
                                    </span>
                                  </div>
                                  <span v-else
                                    >Ce fichier n'est lié à aucune
                                    sollicitation</span
                                  >
                                </td>
                                <td
                                  class="
                                    px-6
                                    py-4
                                    whitespace-nowrap
                                    text-right text-sm
                                    font-medium
                                  "
                                >
                                  <a
                                    @click.prevent="seeFile(file)"
                                    class="
                                      text-indigo-600
                                      hover:text-indigo-900
                                      cursor-pointer
                                    "
                                    >Voir le détail</a
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 max-w-xl text-sm text-gray-500" v-else>
                    <p>Aucun fichier n'est attaché à cette personne.</p>
                  </div>
                  <div class="mt-6">
                    <a
                      @click="displayfilecreation = true"
                      class="
                        cursor-pointer
                        w-full
                        flex
                        justify-center
                        items-center
                        px-4
                        py-2
                        border border-gray-300
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-gray-700
                        bg-white
                        hover:bg-gray-50
                      "
                    >
                      Attacher un nouveau fichier
                    </a>
                  </div>
                </div>
                <!-- inmate files end -->
              </dl>
            </div>
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900 mt-12">
                Historique et coordonnées
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Vous trouverez ici l'historique de la personne détenue, en
                particulier la mise à jour des informations de contact et/ou de
                localisation.
              </p>
              <div class="flow-root mt-6">
                <ul role="list" class="-my-5 divide-y divide-gray-200">
                  <li
                    v-for="event in inmate.events"
                    :key="event.id"
                    class="py-5"
                  >
                    <div
                      class="
                        relative
                        focus-within:ring-2 focus-within:ring-indigo-500
                      "
                    >
                      <h3 class="text-sm font-semibold text-gray-800">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        Changement
                        <span v-if="event.sagi_number"> de numéro SAGI</span>
                        <span v-if="event.inmate_number">
                          de numéro d'écrou</span
                        >
                        <span v-if="event.prison">
                          d'établissement pour
                          {{ event.prison.title }}</span
                        >
                        <span v-if="event.freeing == true">
                          -
                          <span
                            class="
                              inline-flex
                              items-center
                              rounded-md
                              bg-green-100
                              px-2.5
                              py-0.5
                              text-sm
                              font-medium
                              text-green-800
                            "
                            >Sortie de prison</span
                          ></span
                        >
                      </h3>
                      <p class="mt-1 text-sm text-gray-600 line-clamp-2">
                        {{ moment(event.date).format("dddd DD MMMM YYYY") }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mt-6">
                <a
                  @click="recordInmateEvent"
                  class="
                    cursor-pointer
                    w-full
                    flex
                    justify-center
                    items-center
                    px-4
                    py-2
                    border border-gray-300
                    shadow-sm
                    text-sm
                    font-medium
                    rounded-md
                    text-gray-700
                    bg-white
                    hover:bg-gray-50
                  "
                >
                  Enregistrer un nouvel événement
                </a>
              </div>
              <!-- phonecalls -->
              <div class="sm:flex sm:items-start sm:justify-between mt-12">
                <div>
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Appels
                  </h3>
                  <div class="mt-2 max-w-xl text-sm text-gray-500">
                    <p>
                      Ici sont listés tous les appels simples passés par cette
                      personne. Il s'agit des appels qui n'ont pas donné lieu à
                      une sollicitation.
                    </p>
                  </div>
                </div>
                <div
                  class="
                    mt-5
                    sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
                  "
                >
                  <button
                    @click.prevent="recordPhoneCall"
                    type="button"
                    class="
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-transparent
                      shadow-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-red-500
                      sm:text-sm
                    "
                  >
                    Notifier un appel
                  </button>
                </div>
              </div>
              <div class="flex flex-col mt-6" v-if="inmate.phonecalls != 0">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div
                    class="
                      py-2
                      align-middle
                      inline-block
                      min-w-full
                      sm:px-6
                      lg:px-8
                    "
                  >
                    <div
                      class="
                        shadow
                        overflow-hidden
                        border-b border-gray-200
                        sm:rounded-lg
                      "
                    >
                      <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Date
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          <tr
                            v-for="phonecall in inmate.phonecalls"
                            :key="phonecall.id"
                          >
                            <td
                              class="
                                px-6
                                py-4
                                whitespace-nowrap
                                text-sm
                                font-medium
                                text-gray-900
                              "
                            >
                              {{
                                moment(phonecall.created_at).format(
                                  "dddd DD MMMM YYYY à HH:mm"
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="currenttab === 'notes'">
            <div class="rounded-md bg-red-50 p-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <XCircleIcon
                    class="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">
                    IMPORTANT : NE PAS CONSERVER D’INFOS DANS LE BLOC-NOTES !
                  </h3>
                  <div class="mt-2 text-sm text-red-700">
                    <p class="text-sm text-red-700">
                      <span class="font-bold"
                        >Pour les informations générales, il faut utiliser le
                        bloc « informations », dans l’onglet « informations et
                        historique »</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="rounded-md bg-blue-50 p-4 mt-6">
              <div class="flex">
                <div class="flex-shrink-0">
                  <InformationCircleIcon
                    class="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                  <p class="text-sm text-blue-700">
                    Ce bloc-notes a été pensé pour que vous puissiez recueillir
                    des notes lors d’un appel avant de les transformer en
                    sollicitation, tout en déclenchant une notification à chaque
                    fois que le bloc-notes est rempli. Les notes ne doivent donc
                    pas être conservées de façon pérenne. Toutes les notes
                    saisies ici seront visibles par l'ensemble des membres de
                    l'équipe et ne seront donc pas "privées". Dans le cadre du
                    RGPD, veillez à ne pas inclure de données personnelles non
                    utilisées.
                  </p>
                </div>
              </div>
            </div>
            <TipTap v-model="currentnotepad" class="mt-6 notepad-textarea" />
            <!-- <div class="pt-5"> -->
            <div class="flex justify-end">
              <button
                @click="updateNotepad"
                type="submit"
                class="
                  ml-3
                  inline-flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-red-600
                  hover:bg-red-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-red-500
                  mt-6
                "
              >
                Enregistrer le bloc-notes et les modifications
              </button>
            </div>
          </div>
          <div v-if="currenttab === 'sollicitations'">
            <div class="flex flex-col" v-if="inmate.sollicitations.length > 0">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="
                    py-2
                    align-middle
                    inline-block
                    min-w-full
                    sm:px-6
                    lg:px-8
                  "
                >
                  <div
                    class="
                      shadow
                      overflow-hidden
                      border-b border-gray-200
                      sm:rounded-lg
                    "
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Numéro
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Titre
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Thème / Sous-thème
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Établissement
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Statut
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            À suivre ?
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Descriptif
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Détails</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr
                          v-for="sollicitation in inmate.sollicitations"
                          :key="sollicitation.id"
                        >
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm
                              font-medium
                              text-gray-900
                            "
                          >
                            {{
                              moment(sollicitation.date).format("DD/MM/YYYY")
                            }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm
                              font-medium
                              text-gray-900
                            "
                          >
                            {{ sollicitation.id }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            {{ sollicitation.title }}
                          </td>
                          <td class="px-6 py-4 text-sm text-gray-500">
                            <span style="font-weight: bold">{{
                              sollicitation.theme.title
                            }}</span>
                            <br />
                            <span v-if="sollicitation.subtheme">
                              {{ sollicitation.subtheme.title }}</span
                            >
                          </td>
                          <td class="px-6 py-4 text-sm text-gray-500">
                            {{ sollicitation.prison.title }}
                          </td>
                          <td
                            v-if="sollicitation.status"
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm
                              font-medium
                              text-gray-900
                            "
                          >
                            <span
                              v-if="sollicitation.status == 'Active'"
                              class="
                                inline-flex
                                items-center
                                px-2.5
                                py-0.5
                                rounded-full
                                text-xs
                                font-medium
                                bg-green-100
                                text-green-800
                              "
                            >
                              {{ sollicitation.status }}
                            </span>
                            <span
                              v-else
                              class="
                                inline-flex
                                items-center
                                px-2.5
                                py-0.5
                                rounded-full
                                text-xs
                                font-medium
                                bg-red-100
                                text-red-800
                              "
                            >
                              {{ sollicitation.status }}
                            </span>
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            <span
                              v-if="sollicitation.tofollow == true"
                              class="
                                px-2
                                inline-flex
                                text-xs
                                leading-5
                                font-semibold
                                rounded-full
                                bg-red-100
                                text-red-800
                              "
                            >
                              À suivre !
                            </span>
                            <span
                              v-else
                              class="
                                px-2
                                inline-flex
                                text-xs
                                leading-5
                                font-semibold
                                rounded-full
                                bg-green-100
                                text-green-800
                              "
                            >
                              Non
                            </span>
                          </td>
                          <td
                            class="px-6 py-4 text-sm font-medium text-gray-500"
                          >
                            <button
                              @click.prevent="displayDetail(sollicitation)"
                              type="button"
                              class="p-1 rounded-full hover:text-white"
                            >
                              <AnnotationIcon
                                class="
                                  flex-shrink-0
                                  mr-1.5
                                  h-5
                                  w-5
                                  text-gray-400
                                "
                                aria-hidden="true"
                              />
                            </button>
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-right text-sm
                              font-medium
                            "
                          >
                            <a
                              :href="/sollicitations/ + sollicitation.id"
                              target="_blank"
                              class="text-indigo-600 hover:text-indigo-900"
                              >Détails</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 max-w-xl text-sm text-gray-500" v-else>
              <p>Cette personne n'est liée à aucune sollicitation</p>
            </div>
          </div>
          <div v-if="currenttab === 'creer-une-sollicitation'">
            <SollicitationCreation
              v-model:inmate="this.inmate"
              @sollicitationcreated="displaySollicitationCreation"
              @personadded="personAdded"
              @personremoved="personRemoved"
              :key="componentKey"
            />
          </div>
          <div v-if="currenttab === 'contacts'">
            <!-- create contact -->
            <div class="bg-white shadow sm:rounded-lg mb-6">
              <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Créer un contact
                </h3>
                <div class="mt-2 sm:flex sm:items-start sm:justify-between">
                  <div class="max-w-xl text-sm text-gray-500">
                    <p>
                      Vous pouvez créer un contact, qui sera lié à
                      {{ inmate.firstname }}.
                    </p>
                  </div>
                  <div
                    class="
                      mt-5
                      sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
                    "
                  >
                    <button
                      @click.prevent="displayContactCreation"
                      type="button"
                      class="
                        inline-flex
                        items-center
                        px-4
                        py-2
                        border border-transparent
                        shadow-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                        sm:text-sm
                      "
                    >
                      Créer un nouveau contact
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- create contact ends -->
            <div
              class="bg-white shadow overflow-hidden sm:rounded-md"
              v-if="inmate.contacts.length > 0"
            >
              <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Contact(s) de {{ inmate.firstname }}
                </h3>
                <div class="rounded-md bg-blue-50 p-4 mt-2">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <InformationCircleIcon
                        class="h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                      <p class="text-sm text-blue-700">
                        Vous pouvez éditer le lien entre
                        {{ inmate.firstname }} et un contact en cliquant sur le
                        lien dans la colonne
                        <span class="font-bold">RELATION</span>.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col mt-6">
                  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                      class="
                        py-2
                        align-middle
                        inline-block
                        min-w-full
                        sm:px-6
                        lg:px-8
                      "
                    >
                      <div
                        class="
                          shadow
                          overflow-hidden
                          border-b border-gray-200
                          sm:rounded-lg
                        "
                      >
                        <table class="min-w-full divide-y divide-gray-200">
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="
                                  px-6
                                  py-3
                                  text-left text-xs
                                  font-medium
                                  text-gray-500
                                  uppercase
                                  tracking-wider
                                "
                              >
                                Nom complet
                              </th>
                              <th
                                scope="col"
                                class="
                                  px-6
                                  py-3
                                  text-left text-xs
                                  font-medium
                                  text-gray-500
                                  uppercase
                                  tracking-wider
                                "
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                class="
                                  px-6
                                  py-3
                                  text-left text-xs
                                  font-medium
                                  text-gray-500
                                  uppercase
                                  tracking-wider
                                "
                              >
                                Téléphone
                              </th>
                              <th
                                scope="col"
                                class="
                                  px-6
                                  py-3
                                  text-left text-xs
                                  font-medium
                                  text-gray-500
                                  uppercase
                                  tracking-wider
                                "
                              >
                                Relation
                              </th>
                              <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">Éditer</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody class="bg-white divide-y divide-gray-200">
                            <tr
                              v-for="contact in inmate.contacts"
                              :key="contact.id"
                            >
                              <td class="px-6 py-4 whitespace-nowrap">
                                <div class="flex items-center">
                                  <div class="ml-4">
                                    <div
                                      class="text-sm font-medium text-gray-900"
                                    >
                                      {{ contact.firstname }}
                                      {{ contact.lastname }}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td class="px-6 py-4 whitespace-nowrap">
                                <div class="text-sm text-gray-900">
                                  {{ contact.email }}
                                </div>
                              </td>
                              <td class="px-6 py-4 whitespace-nowrap">
                                <div class="text-sm text-gray-900">
                                  {{ contact.phone }}
                                </div>
                              </td>
                              <td class="px-6 py-4 whitespace-nowrap">
                                <button
                                  @click.prevent="
                                    displayContactBoundEdition(contact)
                                  "
                                  type="button"
                                >
                                  <span
                                    v-if="contact.bound"
                                    class="
                                      px-2
                                      inline-flex
                                      text-xs
                                      leading-5
                                      font-semibold
                                      rounded-full
                                      bg-indigo-100
                                      text-indigo-800
                                    "
                                  >
                                    {{ contact.bound.label }}
                                  </span>
                                </button>
                              </td>
                              <td
                                class="
                                  px-6
                                  py-4
                                  whitespace-nowrap
                                  text-right text-sm
                                  font-medium
                                "
                              >
                                <a
                                  @click.prevent="showDetail(contact)"
                                  class="
                                    text-indigo-600
                                    hover:text-indigo-900
                                    cursor-pointer
                                  "
                                  >Éditer</a
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 max-w-xl text-sm text-gray-500" v-else>
              <p>Cette personne n'est liée à aucun contact</p>
            </div>
          </div>
          <div v-if="currenttab === 'publications'">
            <!-- inmate subscription  -->
            <div v-if="inmate.subscription">
              <div
                class="bg-gray-50 sm:rounded-lg mb-6"
                v-if="inmate.subscription.active == 1"
              >
                <div class="px-4 py-5 sm:p-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Dedans Dehors
                  </h3>
                  <div class="mt-2 max-w-xl text-sm text-gray-500">
                    <p>
                      Abonné.e depuis le
                      <span style="font-weight: bold">{{
                        moment(inmate.subscription.starting_date).format(
                          " DD MMMM YYYY"
                        )
                      }}</span>
                      <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">
                        &middot;
                      </span>
                      A commencé avec le numéro
                      <span style="font-weight: bold">{{
                        inmate.subscription.issue_number
                      }}</span>
                    </p>
                  </div>
                  <div class="mt-5">
                    <button
                      v-if="!inmate.subscription.deadletter"
                      @click.prevent="signalshippingerror = true"
                      type="button"
                      class="
                        inline-flex
                        items-center
                        px-4
                        py-2
                        border border-gray-300
                        shadow-sm
                        font-medium
                        rounded-md
                        text-gray-700
                        bg-white
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                        sm:text-sm
                      "
                    >
                      Signaler un problème de réception
                    </button>
                    <div
                      class="bg-yellow-50 border-l-4 border-yellow-400 p-4"
                      v-else
                    >
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <ExclamationIcon
                            class="h-5 w-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div class="ml-3">
                          <p class="text-sm text-yellow-700">
                            Un retour en erreur a été signalé pour cet
                            abonnnement et n'a pas encore été résolu.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- inmate subscription ends -->
            <!-- inmate pending subscription -->
            <div v-if="inmate.subscription">
              <div
                class="rounded-md bg-blue-50 p-4"
                v-if="inmate.subscription.active == 0"
              >
                <div class="flex">
                  <div class="flex-shrink-0">
                    <InformationCircleIcon
                      class="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-blue-700">
                      Une demande d'abonnement est en cours auprès de Julie,
                      mais n'a pas encore été traitée.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- inmate pending subscription ends -->
            <!-- inmate subscription form -->
            <div class="bg-gray-50 sm:rounded-lg" v-if="!inmate.subscription">
              <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Dedans Dehors
                </h3>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <p>
                    Vous pouvez abonner cette personne à Dedans Dehors. Il vous
                    suffit d'indiquer le numéro auquel la souscription va
                    commencer.
                  </p>
                </div>
                <div class="mt-5">
                  <button
                    @click.prevent="askforSubscription"
                    type="button"
                    class="
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-gray-300
                      shadow-sm
                      font-medium
                      rounded-md
                      text-gray-700
                      bg-white
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                      sm:text-sm
                    "
                  >
                    Lancer un abonnement
                  </button>
                </div>
              </div>
            </div>
            <!-- inmate subscription form ends -->
            <div class="bg-gray-50 sm:rounded-lg mb-6 mt-6">
              <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Envoyer un guide du prisonnier
                </h3>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <p>
                    Vous pouvez demander, ici, l'envoi d'un guide du prisonnier.
                  </p>
                </div>
                <div class="mt-5">
                  <button
                    @click.prevent="guidesending = true"
                    type="button"
                    class="
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-gray-300
                      shadow-sm
                      font-medium
                      rounded-md
                      text-gray-700
                      bg-white
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                      sm:text-sm
                    "
                  >
                    Demander l'envoi d'un guide du prisonnier
                  </button>
                </div>
              </div>
            </div>
            <!-- inmate sending -->
            <div class="bg-gray-50 sm:rounded-lg mb-6 mt-6">
              <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Envoyer des publications
                </h3>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <p>
                    Vous pouvez envoyer des publications à cette personne, soit
                    des numéros de Dedans Dehors, soit Passé par La Case Prison
                    (ou les deux).
                  </p>
                </div>
                <div class="mt-5">
                  <button
                    @click.prevent="sendpublications = true"
                    type="button"
                    class="
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-gray-300
                      shadow-sm
                      font-medium
                      rounded-md
                      text-gray-700
                      bg-white
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                      sm:text-sm
                    "
                  >
                    Effectuer un envoi
                  </button>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 sm:rounded-lg mb-6 mt-6">
              <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Envois déjà effectués ou en cours
                </h3>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <p>Voici la liste des envois effectués ou en cours.</p>
                </div>
                <div class="mt-5">
                  <h3>Guides du prisonnier</h3>
                  <ul role="list" class="divide-y divide-gray-200">
                    <li
                      v-for="guide in inmate.guides"
                      :key="guide.id"
                      class="relative bg-gray-50 py-5 px-4"
                    >
                      <div class="flex justify-between space-x-3">
                        <div class="min-w-0 flex-1">
                          <span class="absolute inset-0" aria-hidden="true" />
                          <p class="text-sm font-medium text-gray-900 truncate">
                            {{
                              moment(guide.created_at).format(
                                "dddd DD MMMM YYYY à HH:mm"
                              )
                            }}
                            /
                            <span
                              class="text-sm text-gray-500 truncate"
                              v-if="guide.prison"
                              >{{ guide.prison.title }}</span
                            >
                          </p>
                          <!-- <p class="text-sm text-gray-500 truncate">
                            {{ guide.created_at }}
                          </p> -->
                        </div>
                        <time
                          class="
                            flex-shrink-0
                            whitespace-nowrap
                            text-sm text-gray-500
                          "
                        >
                          <span
                            class="
                              inline-flex
                              items-center
                              px-2.5
                              py-0.5
                              rounded-full
                              text-xs
                              font-medium
                              bg-indigo-100
                              text-indigo-800
                            "
                          >
                            {{ guide.status }}
                          </span>
                        </time>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="mt-5">
                  <h3>Dedans Dehors et PPLCP</h3>
                  <ul role="list" class="divide-y divide-gray-200">
                    <li
                      v-for="sending in inmate.sendings"
                      :key="sending.id"
                      class="relative bg-gray-50 py-5 px-4"
                    >
                      <div class="flex justify-between space-x-3">
                        <div class="min-w-0 flex-1">
                          <span class="absolute inset-0" aria-hidden="true" />
                          <p class="text-sm font-medium text-gray-900 truncate">
                            {{
                              moment(sending.created_at).format(
                                "dddd DD MMMM YYYY à HH:mm"
                              )
                            }}
                            /
                            <span
                              class="text-sm text-gray-500 truncate"
                              v-if="sending.prison"
                              >{{ sending.prison.title }}</span
                            >
                          </p>
                          <p class="text-sm text-gray-500 truncate">
                            {{ sending.type }}
                          </p>
                        </div>
                        <time
                          class="
                            flex-shrink-0
                            whitespace-nowrap
                            text-sm text-gray-500
                          "
                        >
                          <span
                            v-if="sending.is_done == false"
                            class="
                              inline-flex
                              items-center
                              px-2.5
                              py-0.5
                              rounded-full
                              text-xs
                              font-medium
                              bg-red-100
                              text-red-800
                            "
                          >
                            Non envoyé
                          </span>
                          <span
                            v-if="sending.is_done == true"
                            class="
                              inline-flex
                              items-center
                              px-2.5
                              py-0.5
                              rounded-full
                              text-xs
                              font-medium
                              bg-green-100
                              text-green-800
                            "
                          >
                            Envoyé
                          </span></time
                        >
                      </div>
                      <div class="mt-1">
                        <p class="line-clamp-2 text-sm text-gray-600">
                          Dedans Dehors numéros :
                          <span v-for="item in sending.issues" :key="item.id"
                            >{{ item.issue_number }} /
                          </span>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- inmate sending ends -->
          </div>
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import {
  InformationCircleIcon, ExclamationIcon, PencilIcon, AnnotationIcon, XCircleIcon,
} from '@heroicons/vue/solid';
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import AppNavbar from '@/components/AppNavbar.vue';
import AppNotification from '@/components/Modals/AppNotification.vue';
import AppConfirmationChoice from '@/components/Modals/AppConfirmationChoice.vue';
import SubscriptionChoice from '@/components/Modals/SubscriptionChoice.vue';
import EventCreation from '@/components/Forms/EventCreation.vue';
import TipTap from '@/components/TipTap.vue';
import SollicitationCreation from '@/components/Forms/SollicitationCreation.vue';
import FilePanel from '@/components/Modals/FilePanel.vue';
import FileInmateCreation from '@/components/Forms/FileInmateCreation.vue';
import SignalShippingError from '@/components/Forms/SignalShippingError.vue';
import ContactCreation from '@/components/Forms/ContactCreation.vue';
import EditInmate from '@/components/Modals/EditInmate.vue';
import ChoiceModal from '@/components/Modals/ChoiceModal.vue';
import ContactBoundEdit from '@/components/Modals/ContactBoundEdit.vue';
import ContactDetail from '@/components/Modals/ContactDetail.vue';
import SendingCreation from '@/components/Forms/SendingCreation.vue';
import GuideSendingCreation from '@/components/Modals/GuideSendingCreation.vue';
import SollicitationContent from '@/components/Modals/SollicitationContent.vue';

export default {
  components: {
    AppNavbar,
    FilePanel,
    FileInmateCreation,
    ExclamationIcon,
    AppNotification,
    AppConfirmationChoice,
    EventCreation,
    InformationCircleIcon,
    TipTap,
    SollicitationCreation,
    SubscriptionChoice,
    SignalShippingError,
    ContactCreation,
    PencilIcon,
    EditInmate,
    ChoiceModal,
    ContactBoundEdit,
    ContactDetail,
    SendingCreation,
    GuideSendingCreation,
    SollicitationContent,
    AnnotationIcon,
    XCircleIcon,
  },
  setup() {
    const inmate = ref();
    const displaynotification = ref(false);
    const displayeventcreation = ref(false);
    const confirmnotepadupdate = ref(false);
    const sollicitationcreation = ref(false);
    const displayfilepanel = ref(false);
    const currenttab = ref('');
    const currentnotepad = ref('');
    const sollicitationscount = ref('');
    const sollicitationpeople = ref(false);
    const title = ref('');
    const content = ref('');
    const selectedfile = ref();
    const subscriptionconfirmation = ref(false);
    const signalshippingerror = ref(false);
    const displaycontactcreation = ref(false);
    const sendpublications = ref(false);
    const displayfilecreation = ref(false);
    const editinfos = ref(false);
    const currentinfos = ref();
    const editinmate = ref(false);
    const showable = ref(false);
    const contacteditionmode = ref(false);
    const seedetail = ref(false);
    const relationupdater = ref(true);
    const componentKey = ref(0);
    const sollicitationcontext = ref(false);
    const guidesending = ref(false);
    const displaycontent = ref(false);

    const tabs = [
      { name: 'Informations et historique', href: 'infos', current: false },
      { name: 'Bloc-notes', href: 'notes', current: false },
      { name: 'Créer une sollicitation', href: 'creer-une-sollicitation', current: false },
      { name: 'Sollicitations', href: 'sollicitations', current: false },
      { name: 'Contacts', href: 'contacts', current: false },
      { name: 'Publications', href: 'publications', current: false },
    ];

    return {
      inmate,
      displaynotification,
      displayfilepanel,
      displayfilecreation,
      confirmnotepadupdate,
      displayeventcreation,
      sollicitationcreation,
      tabs,
      currenttab,
      currentnotepad,
      InformationCircleIcon,
      sollicitationscount,
      sollicitationpeople,
      title,
      content,
      selectedfile,
      subscriptionconfirmation,
      signalshippingerror,
      displaycontactcreation,
      sendpublications,
      editinfos,
      currentinfos,
      editinmate,
      showable,
      contacteditionmode,
      seedetail,
      relationupdater,
      componentKey,
      sollicitationcontext,
      guidesending,
      displaycontent,
    };
  },
  created() {
    this.getInmate();
    this.getSollicitationsCount();
    this.currenttab = this.$route.params.tab;
    this.displaynotification = this.$route.params.displaynotification;
    this.moment = moment;
  },
  methods: {
    gotoTab(tabname) {
      this.$router.push({ name: 'Inmate', params: { tab: tabname, id: this.$route.params.id } });
      this.currenttab = tabname;
    },
    gotoSollicitations() {
      this.$router.push({
        name: 'Inmate',
        params: {
          tab: 'Sollicitations', id: this.$route.params.id,
        },
      });
      this.currenttab = 'sollicitations';
    },
    seeFile(selectedFile) {
      this.displayfilepanel = true;
      this.selectedfile = selectedFile;
    },
    closeFilePanel() {
      this.displayfilepanel = false;
    },
    getInmate() {
      const { id } = this.$route.params;
      this.$http.get(`/api/inmates/${id}/`)
        .then((response) => {
          this.inmate = response.data;
          this.currentnotepad = response.data.notepad;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getSollicitationsCount() {
      const { id } = this.$route.params;
      this.$http.get(`/api/inmate-sollicitations/${id}/`)
        .then((response) => {
          this.sollicitationscount = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    recordInmateEvent() {
      this.displayeventcreation = true;
    },
    showEventCreation() {
      this.displayeventcreation = true;
    },
    closeEventCreation() {
      this.displayeventcreation = false;
    },
    displaySollicitationCreation() {
      this.getInmate();
      this.getSollicitationsCount();
      this.sollicitationcreation = true;
      this.forceRerender();
    },
    closeSollicitationCreation() {
      this.sollicitationcreation = false;
    },
    updateNotepad() {
      this.$http.patch('api/notepad-update', {
        id: this.inmate.id,
        notepad: this.currentnotepad,
      }).then((response) => {
        this.success = response.data;
        this.confirmnotepadupdate = true;
      })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    personAdded() {
      this.sollicitationpeople = true;
      this.title = 'Cette personne a bien été ajoutée à la sollicitation !';
      this.content = 'Cette personne est désormais bien liée à cette sollicitation, soit au titre de co-détenu.e ou de contact.';
    },
    personRemoved() {
      this.sollicitationpeople = true;
      this.title = 'Cette personne a bien été ôtée de la sollicitation !';
      this.content = 'Cette personne n\'est désormais plus liée à cette sollicitation, que ce soit soit au titre de co-détenu.e ou de contact.';
    },
    hideSollicitationpeople() {
      this.sollicitationpeople = false;
    },
    hideUpdateNotepadNotif() {
      this.confirmnotepadupdate = false;
    },
    hideNotif() {
      this.displaynotification = false;
    },
    askforSubscription() {
      this.subscriptionconfirmation = true;
    },
    giveupSubscription() {
      this.subscriptionconfirmation = false;
    },
    signalShippingError() {
      this.signalshippingerror = true;
    },
    hideSignalShippingError() {
      this.signalshippingerror = false;
      this.getInmate();
    },
    displayContactCreation() {
      this.displaycontactcreation = true;
    },
    hidecontactcreation() {
      this.displaycontactcreation = false;
    },
    closeFileCreation() {
      this.displayfilecreation = false;
    },
    confirmFileCreation() {
      this.closeFileCreation();
      this.getInmate();
    },
    editInfos() {
      this.editinfos = true;
      this.currentinfos = this.inmate.infos;
    },
    updateInfos() {
      this.$http.patch('api/inmate-infos-update', {
        id: this.inmate.id,
        infos: this.currentinfos,
      }).then((response) => {
        window.console.log(response.data);
        this.editinfos = false;
        this.getInmate();
      }).catch((error) => {
        window.console.log(error);
      });
    },
    closeEdition() {
      this.editinmate = false;
    },
    confirmEdition() {
      this.closeEdition();
      this.getInmate();
    },
    recordPhoneCall() {
      this.showable = true;
    },
    PhoneCallCreation() {
      this.$http.post('/api/inmate-phonecall/', {
        inmate_id: this.inmate.id,
      }).then((response) => {
        window.console.log(response.data);
        this.showable = false;
        this.getInmate();
      }).catch((error) => {
        window.console.log(error);
      });
    },
    displayContactBoundEdition(contactSelected) {
      this.contacteditionmode = true;
      this.contacttoedit = contactSelected;
    },
    closeContactBoundEdition() {
      this.contacteditionmode = false;
      this.getInmate();
    },
    showDetail(thisGuy) {
      this.seedetail = true;
      this.chosencontact = thisGuy;
    },
    hideDetail() {
      this.seedetail = false;
    },
    confirmUpdate() {
      this.seedetail = false;
      this.getInmate();
    },
    forceRerender() {
      this.componentKey += 1;
    },
    closeSendingPanel() {
      this.sendpublications = false;
    },
    closeGuideModal() {
      this.guidesending = false;
    },
    displayDetail(detailedSollicitation) {
      this.chosensollicitation = detailedSollicitation;
      this.displaycontent = true;
    },
    hideDetailedContent() {
      this.chosensollicitation = null;
      this.displaycontent = false;
    },
    subscriptionCreated() {
      this.subscriptionconfirmation = false;
      this.getInmate();
    },
    closeModal() {
      this.showable = false;
    },
  },
};
</script>

<style scoped>
.notepad-textarea {
  border: 1px solid #000 !important;
  padding: 1rem;
}
</style>
