<template>
  <TransitionRoot as="template" :show="guidesending">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="open = false"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
            "
          >
            <div>
              <div
                class="
                  mx-auto
                  flex
                  items-center
                  justify-center
                  h-12
                  w-12
                  rounded-full
                  bg-green-100
                "
              >
                <BookOpenIcon
                  class="h-6 w-6 text-green-600"
                  aria-hidden="true"
                />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  Envoyer un guide
                </DialogTitle>
                <div class="mt-2">
                  <p
                    class="text-sm text-gray-500"
                    v-if="inmate.current_location !== 'Inconnue'"
                  >
                    Vous pouvez envoyer un guide du prisonnier à cette personne.
                    <span
                      >Notez que ce guide sera envoyé à l'établissement actuel,
                      soit : <br />
                      <span class="font-bold">{{
                        inmate.current_location.title
                      }}</span></span
                    >
                  </p>
                  <p v-else class="text-sm text-gray-500">
                    La localisation de cette personne est inconnue, commencez
                    par créer un événement dans lequel son établissement est
                    rensignée avant de demander l'envoi d'un guide.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="
                mt-5
                sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense
              "
            >
              <button
                v-if="inmate.current_location == 'Inconnue'"
                type="button"
                class="
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-transparent
                  shadow-sm
                  px-4
                  py-2
                  bg-indigo-600
                  text-base
                  font-medium
                  text-white
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                  sm:col-start-2 sm:text-sm
                "
                @click="$emit('shut')"
              >
                D'accord
              </button>
              <button
                v-else
                type="button"
                class="
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-transparent
                  shadow-sm
                  px-4
                  py-2
                  bg-indigo-600
                  text-base
                  font-medium
                  text-white
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                  sm:col-start-2 sm:text-sm
                "
                @click="sendGuide"
              >
                Effectuer la demande
              </button>
              <button
                type="button"
                class="
                  mt-3
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-gray-300
                  shadow-sm
                  px-4
                  py-2
                  bg-white
                  text-base
                  font-medium
                  text-gray-700
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                  sm:mt-0 sm:col-start-1 sm:text-sm
                "
                @click="$emit('shut')"
                ref="cancelButtonRef"
              >
                J'annule
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { BookOpenIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    BookOpenIcon,
  },
  setup() {
    const open = ref(true);

    return {
      open,
    };
  },
  props: {
    guidesending: Boolean,
    inmate: Object,
  },
  methods: {
    sendGuide() {
      this.$http.post('/api/guide-creation/', {
        inmate_id: this.inmate.id,
        prison_id: this.inmate.current_location.id,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('guidecreated');
        this.$emit('shut');
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
