<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="displayfileattachment">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div
          class="
            pointer-events-none
            fixed
            inset-y-0
            right-0
            flex
            max-w-full
            pl-10
          "
        >
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="pointer-events-auto w-screen max-w-md">
              <div
                class="
                  flex
                  h-full
                  flex-col
                  overflow-y-scroll
                  bg-white
                  shadow-xl
                "
              >
                <div class="bg-gray-800 py-6 px-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Lier un fichier
                    </DialogTitle>
                    <div class="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        class="
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Vous pouvez lier un fichier à cette sollicitation.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <div>
                    <div class="flow-root mt-6">
                      <ul role="list" class="-my-5 divide-y divide-gray-200">
                        <li v-for="file in files" :key="file.id" class="py-4">
                          <div class="flex items-center space-x-4">
                            <div class="flex-1 min-w-0">
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-900
                                  truncate
                                "
                              >
                                {{ file.title }}
                              </p>
                              <p
                                class="text-sm text-gray-500 truncate"
                                v-for="owner in file.owners"
                                :key="owner.id"
                              >
                                {{ owner.firstname }} {{ owner.lastname }}
                              </p>
                            </div>
                            <div>
                              <a
                                @click.prevent="linkFile(file.id)"
                                class="
                                  inline-flex
                                  items-center
                                  shadow-sm
                                  px-2.5
                                  py-0.5
                                  border border-gray-300
                                  text-sm
                                  leading-5
                                  font-medium
                                  rounded-full
                                  text-gray-700
                                  bg-white
                                  hover:bg-gray-50
                                  cursor-pointer
                                "
                              >
                                Lier
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const open = ref(true);
    const selectedFile = ref();

    return {
      open,
      selectedFile,
    };
  },
  props: {
    sollicitation: Object,
    displayfileattachment: Boolean,
  },
  created() {
    this.getCandidateFiles();
  },
  methods: {
    getCandidateFiles() {
      const { id } = this.$route.params;
      this.$http.get(`/api/sollicitation-candidate-files/${id}/`)
        .then((response) => {
          this.files = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    linkFile(fileId) {
      this.$http.patch('api/sollicitation-file', {
        id: fileId,
        sollicitation_id: this.sollicitation.id,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('fileLinked');
        this.getCandidateFiles();
        this.$emit('shut');
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
