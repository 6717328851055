<template>
  <div class="min-h-full">
    <div class="bg-gray-800 pb-32">
      <AppNavbar />
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">Médiathèque</h1>
          <h3 class="text-2xl text-white">
            Gestion des fichiers relatifs aux personnes détenues.
          </h3>
        </div>
      </header>
    </div>
    <main class="-mt-32">
      <FileCreation
        v-model:displayfilecreation="displayfilecreation"
        @shut="closeFileCreation"
        @filecreated="getResults"
      />
      <FilePanel
        v-model:displayfilepanel="displayfilepanel"
        v-model:sollicitationcontext="sollicitationcontext"
        @shut="closeFilePanel"
        @filetrashed="getResults"
        v-model:file="selectedfile"
      />
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mb-6">
          <div class="bg-gray-50 sm:rounded-lg">
            <div class="px-4 py-5 sm:p-6">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Exporter la liste des fichiers
              </h3>
              <div class="mt-2 max-w-xl text-sm text-gray-500">
                <p>Vous pouvez télécharger la liste des fichiers.</p>
              </div>
              <div class="mt-5">
                <button
                  type="button"
                  @click="downloadFiles"
                  class="
                    inline-flex
                    items-center
                    rounded-md
                    border border-gray-300
                    bg-white
                    px-4
                    py-2
                    font-medium
                    text-gray-700
                    shadow-sm
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-indigo-500
                    focus:ring-offset-2
                    sm:text-sm
                  "
                >
                  Télécharger
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
          <button
            @click.prevent="addFile"
            type="button"
            class="
              relative
              block
              w-full
              border-2 border-gray-300 border-dashed
              rounded-lg
              p-12
              text-center
              hover:border-gray-400
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            <svg
              class="mx-auto h-12 w-12 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
              />
            </svg>
            <span class="mt-2 block text-sm font-medium text-gray-900">
              Ajouter un fichier
            </span>
          </button>
          <!-- mediatheque begins  -->
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900 mt-8">
              Fichiers
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Vous retrouvez, ici, tous les fichiers liés à des personnes
              détenues. Vous pouvez taper quelques mots du titre ou le nom d'une
              personne pour retrouver le fichier.
            </p>
            <div class="mt-2 relative flex items-center">
              <input
                v-if="!usersearch"
                @keyup.enter="searchFiles()"
                type="text"
                v-model="search"
                name="search"
                id="search"
                placeholder="Recherchez..."
                class="
                  shadow-sm
                  focus:ring-red-500 focus:border-red-500
                  block
                  w-full
                  pr-12
                  sm:text-sm
                  border-gray-500
                  rounded-md
                  h-8
                "
              />
              <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5"></div>
            </div>
            <div class="mb-4" v-if="usersearch">
              Vous avez recherché :
              <span
                class="
                  inline-flex
                  rounded-full
                  items-center
                  py-0.5
                  pl-2.5
                  pr-1
                  text-sm
                  font-medium
                  bg-indigo-100
                  text-indigo-700
                "
              >
                {{ usersearch }}
                <button
                  @click.prevent="resetSearch"
                  type="button"
                  class="
                    flex-shrink-0
                    ml-0.5
                    h-4
                    w-4
                    rounded-full
                    inline-flex
                    items-center
                    justify-center
                    text-indigo-400
                    hover:bg-indigo-200 hover:text-indigo-500
                    focus:outline-none focus:bg-indigo-500 focus:text-white
                  "
                >
                  <span class="sr-only">Réinitialisez</span>
                  <svg
                    class="h-2 w-2"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 8 8"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-width="1.5"
                      d="M1 1l6 6m0-6L1 7"
                    />
                  </svg>
                </button>
              </span>
            </div>
            <div class="flex flex-col mt-6">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="
                    py-2
                    align-middle
                    inline-block
                    min-w-full
                    sm:px-6
                    lg:px-8
                  "
                >
                  <div
                    class="
                      shadow
                      overflow-hidden
                      border-b border-gray-200
                      sm:rounded-lg
                    "
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Titre
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Personnes concernées
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Sollicitations liées
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Détails</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="file in files.data" :key="file.id">
                          <td class="px-6 py-4">
                            <div class="text-sm text-gray-900">
                              {{ file.title }}
                            </div>
                            <div class="text-sm text-gray-500">
                              {{
                                moment(file.created_at).format(
                                  "dddd DD MMM YYYY"
                                )
                              }}
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <span
                              class="
                                px-2
                                inline-flex
                                text-xs
                                leading-5
                                font-semibold
                                rounded-full
                                bg-blue-100
                                text-blue-800
                              "
                            >
                              {{ file.type_id }}
                            </span>
                          </td>
                          <td class="px-6 py-4 text-sm text-gray-500">
                            <div v-if="file.inmates.length > 0">
                              <span
                                v-for="inmate in file.inmates"
                                :key="inmate.id"
                                >{{ inmate.firstname }} {{ inmate.lastname }}
                                <br />
                              </span>
                            </div>
                            <span v-else
                              >Ce fichier n'est lié à aucune personne</span
                            >
                          </td>
                          <td class="px-6 py-4 text-sm text-gray-500">
                            <div v-if="file.sollicitations.length !== 0">
                              <span
                                v-for="sollicitation in file.sollicitations"
                                :key="sollicitation.id"
                                >{{ sollicitation.title }}
                                <br />
                              </span>
                            </div>
                            <span v-else
                              >Ce fichier n'est lié à aucune sollicitation</span
                            >
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-right text-sm
                              font-medium
                            "
                          >
                            <a
                              @click.prevent="seeFile(file)"
                              class="
                                text-indigo-600
                                hover:text-indigo-900
                                cursor-pointer
                              "
                              >Voir le détail</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- mediatheque ends -->
          <!-- pagination  -->
          <div v-if="files.data">
            <div v-if="files.data.length > 0">
              <nav
                v-if="!usersearch"
                class="
                  bg-white
                  px-4
                  py-3
                  flex
                  items-center
                  justify-between
                  border-t border-gray-200
                  sm:px-6
                "
                aria-label="Pagination"
              >
                <div class="hidden sm:block">
                  <p class="text-sm text-gray-700">
                    Page
                    {{ " " }}
                    <span class="font-medium">{{ currentpage }}</span>
                    {{ " " }}
                    sur
                    {{ " " }}
                    <span class="font-medium">{{ files.last_page }}</span>
                    {{ " " }}
                    de
                    {{ " " }}
                    <span class="font-medium">{{ files.total }}</span>
                    {{ " " }}
                    résultats
                  </p>
                </div>
                <div class="flex-1 flex justify-between sm:justify-end">
                  <button
                    @click.prevent="goPreviouspage"
                    :disabled="currentpage < 2"
                    :class="currentpage < 2 ? 'cursor-not-allowed' : ''"
                    class="
                      relative
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-gray-300
                      text-sm
                      font-medium
                      rounded-md
                      text-gray-700
                      bg-white
                      hover:bg-gray-50
                      cursor-pointer
                    "
                  >
                    Précédentes
                  </button>
                  <button
                    @click.prevent="goNextpage"
                    :disabled="currentpage == files.last_page"
                    :class="
                      currentpage == files.last_page ? 'cursor-not-allowed' : ''
                    "
                    class="
                      ml-3
                      relative
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-gray-300
                      text-sm
                      font-medium
                      rounded-md
                      text-gray-700
                      bg-white
                      hover:bg-gray-50
                      cursor-pointer
                    "
                  >
                    Suivantes
                  </button>
                </div>
              </nav>
            </div>
          </div>
          <!-- pagination ends -->
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import AppNavbar from '@/components/AppNavbar.vue';
import FileCreation from '@/components/Forms/FileCreation.vue';
import FilePanel from '@/components/Modals/FilePanel.vue';

export default {
  components: {
    AppNavbar,
    FileCreation,
    FilePanel,
  },
  setup() {
    const files = ref([]);
    const displayfilepanel = ref(false);
    const displayfilecreation = ref(false);
    const search = ref('');
    const usersearch = ref();
    const selectedfile = ref();
    const currentpage = ref();
    const sollicitationcontext = ref(false);

    return {
      files,
      search,
      usersearch,
      displayfilepanel,
      displayfilecreation,
      selectedfile,
      currentpage,
      sollicitationcontext,
    };
  },
  created() {
    this.moment = moment;
    this.currentpage = 1;
    this.getResults();
  },
  methods: {
    getResults() {
      this.$http.get(`api/files?page=${this.currentpage}`)
        .then((response) => {
          this.files = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    goNextpage() {
      this.currentpage += 1;
      this.getResults();
    },
    goPreviouspage() {
      this.currentpage -= 1;
      this.getResults();
    },
    resetSearch() {
      this.usersearch = null;
      this.search = null;
      this.getResults();
    },
    searchFiles() {
      this.usersearch = this.search;
      this.$http.get(`api/files-search/${this.search}`)
        .then((response) => {
          this.files.data = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    addFile() {
      this.displayfilecreation = true;
    },
    seeFile(selectedFile) {
      this.displayfilepanel = true;
      this.selectedfile = selectedFile;
    },
    closeFilePanel() {
      this.displayfilepanel = false;
      this.getResults();
    },
    closeFileCreation() {
      this.displayfilecreation = false;
    },
    downloadFiles() {
      this.$http.get('api/files_export', {
        responseType: 'blob',
      }).then((response) => {
        window.console.log(response.data.type);
        const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
        const filename = `${currentDate}_oip_files`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
  },
};
</script>
