<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="min-h-full">
    <div class="bg-gray-800 pb-32">
      <AppNavbar />
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">Créer une sollicitation</h1>
          <h3 class="text-2xl text-white">
            Créez une sollicitation en quelques minutes, en notant tous les
            détails. Vous pourrez lier, plus tard, les personnes concernées et
            les contacts à l'origine de la sollicitation.
          </h3>
        </div>
      </header>
    </div>
    <main class="-mt-32">
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
          <!-- error begins -->
          <div
            class="rounded-md bg-red-50 p-4 mr-6 mb-4"
            v-if="errored == true"
          >
            <div class="flex">
              <div class="flex-shrink-0">
                <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  Oups, votre sollicitation n'a pas pu être créée...
                </h3>
                <div class="mt-2 text-sm text-red-700">
                  <ul role="list" class="list-disc pl-5 space-y-1">
                    <li>
                      Vérifiez que les champs sont tous bien remplis, y compris
                      la date, qui est obligatoire
                    </li>
                    <li>
                      Si ça ne résoud pas votre problème, rapprochez-vous de
                      l'administrateur
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- error ends -->
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700"
              >Date de la sollicitation</label
            >
            <div class="rounded-md bg-yellow-50 p-4 mt-4 mb-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <ExclamationIcon
                    class="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-yellow-800">
                    Il s'agit de la date de notification de la sollicitation.
                    Est-ce bien la date à laquelle nous avons reçu le
                    signalement ?
                  </h3>
                </div>
              </div>
            </div>
            <div class="mt-1">
              <input
                type="datetime-local"
                name="date"
                v-model="sollicitation.date"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
              />
            </div>
            <div>
              <label
                for="title"
                class="block text-sm font-medium text-gray-700 mt-6"
                >Titre de la sollicitation</label
              >
              <div class="mt-1">
                <input
                  @input="forminprogress = true"
                  type="text"
                  name="title"
                  v-model="sollicitation.title"
                  placeholder="ex : Refus permanents de soins, Suppression irrégulière du droit de visite, etc. "
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                />
              </div>
            </div>
            <!-- choose prison -->
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-gray-700 mt-6"
                >Établissement concerné</label
              >
              <div class="mt-1">
                <input
                  type="text"
                  name="prison"
                  v-model="search"
                  placeholder="Tapez quelques lettres de l'établissement recherché"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                />
              </div>
              <ul
                role="list"
                class="-my-5 divide-y divide-gray-200 mt-6"
                v-if="search && !chosenprison"
              >
                <li
                  v-for="prison in matchingPrisons"
                  :key="prison.id"
                  class="py-4"
                >
                  <div class="flex items-center space-x-4">
                    <div class="flex-1 min-w-0">
                      <p class="text-sm font-medium text-gray-900 truncate">
                        {{ prison.title }}
                      </p>
                    </div>
                    <div>
                      <a
                        @click.prevent="choosePrison(prison)"
                        class="
                          inline-flex
                          items-center
                          shadow-sm
                          px-2.5
                          py-0.5
                          text-sm
                          leading-5
                          font-medium
                          rounded-full
                          text-white
                          bg-green-500
                          hover:bg-green-700
                          cursor-pointer
                        "
                      >
                        Choisir
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
              <div v-if="chosenprison">
                <span
                  class="
                    inline-flex
                    rounded-full
                    items-center
                    py-0.5
                    pl-2.5
                    pr-1
                    text-sm
                    font-medium
                    bg-indigo-100
                    text-indigo-700
                    mt-6
                  "
                >
                  {{ chosenprison.title }}
                  <button
                    @click.prevent="chosenprison = null"
                    type="button"
                    class="
                      flex-shrink-0
                      ml-0.5
                      h-4
                      w-4
                      rounded-full
                      inline-flex
                      items-center
                      justify-center
                      text-indigo-400
                      hover:bg-indigo-200 hover:text-indigo-500
                      focus:outline-none focus:bg-indigo-500 focus:text-white
                    "
                  >
                    <!-- <span class="sr-only">Remove large option</span> -->
                    <svg
                      class="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-width="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              </div>
            </div>
            <!-- choose prison ends -->
            <div class="rounded-md bg-blue-50 p-4 mt-6">
              <div class="flex">
                <div class="flex-shrink-0">
                  <InformationCircleIcon
                    class="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                  <p class="text-sm text-blue-700">
                    Si vous choississez directement le sous-thème, le thème ne
                    s'affichera pas ici mais
                    <span class="font-bold">sera bien pris en compte</span> dans
                    le backend de l'application.
                  </p>
                </div>
              </div>
            </div>
            <!-- choose theme -->
            <div class="mt-6">
              <Combobox as="div" v-model="chosentheme">
                <ComboboxLabel class="block text-sm font-medium text-gray-700"
                  >Thème</ComboboxLabel
                >
                <div class="relative mt-1">
                  <ComboboxInput
                    class="
                      w-full
                      rounded-md
                      border border-gray-300
                      bg-white
                      py-2
                      pl-3
                      pr-10
                      shadow-sm
                      focus:border-indigo-500
                      focus:outline-none
                      focus:ring-1
                      focus:ring-indigo-500
                      sm:text-sm
                    "
                    @change="searchtheme = $event.target.value"
                    :display-value="(theme) => theme.title"
                  />
                  <ComboboxButton
                    class="
                      absolute
                      inset-y-0
                      right-0
                      flex
                      items-center
                      rounded-r-md
                      px-2
                      focus:outline-none
                    "
                  >
                    <SelectorIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </ComboboxButton>

                  <ComboboxOptions
                    v-if="matchingThemes.length > 0"
                    class="
                      absolute
                      z-10
                      mt-1
                      max-h-60
                      w-full
                      overflow-auto
                      rounded-md
                      bg-white
                      py-1
                      text-base
                      shadow-lg
                      ring-1 ring-black ring-opacity-5
                      focus:outline-none
                      sm:text-sm
                    "
                  >
                    <ComboboxOption
                      v-for="theme in matchingThemes"
                      :key="theme.id"
                      :value="theme"
                      as="template"
                      v-slot="{ active, selected }"
                    >
                      <li
                        :class="[
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        ]"
                      >
                        <span
                          :class="[
                            'block truncate',
                            selected && 'font-semibold',
                          ]"
                        >
                          {{ theme.title }}
                        </span>

                        <span
                          v-if="selected"
                          :class="[
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                  <ComboboxOptions
                    v-else
                    class="
                      absolute
                      z-10
                      mt-1
                      max-h-60
                      w-full
                      overflow-auto
                      rounded-md
                      bg-white
                      py-1
                      text-base
                      shadow-lg
                      ring-1 ring-black ring-opacity-5
                      focus:outline-none
                      sm:text-sm
                    "
                  >
                    <ComboboxOption
                      v-for="theme in themes"
                      :key="theme.id"
                      :value="theme"
                      as="template"
                      v-slot="{ active, selected }"
                    >
                      <li
                        :class="[
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        ]"
                      >
                        <span
                          :class="[
                            'block truncate',
                            selected && 'font-semibold',
                          ]"
                        >
                          {{ theme.title }}
                        </span>

                        <span
                          v-if="selected"
                          :class="[
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </div>
              </Combobox>
            </div>
            <!-- choose theme ends -->
            <!-- choose subtheme -->
            <div v-if="chosentheme">
              <div v-if="chosentheme.subthemes.length > 0">
                <label
                  for="Subtheme"
                  class="block text-sm font-medium text-gray-700 mt-6 mb-1"
                  >Sous-thème</label
                >
                <select
                  id="subtheme"
                  name="subtheme"
                  autocomplete="subtheme"
                  v-model="chosensubtheme"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                >
                  <option
                    v-for="subtheme in chosentheme.subthemes"
                    :key="subtheme.id"
                    :value="subtheme"
                  >
                    {{ subtheme.title }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mt-6" v-if="!chosentheme">
              <Combobox as="div" v-model="chosensubtheme">
                <ComboboxLabel class="block text-sm font-medium text-gray-700"
                  >Sous-thème</ComboboxLabel
                >
                <div class="relative mt-1">
                  <ComboboxInput
                    class="
                      w-full
                      rounded-md
                      border border-gray-300
                      bg-white
                      py-2
                      pl-3
                      pr-10
                      shadow-sm
                      focus:border-indigo-500
                      focus:outline-none
                      focus:ring-1
                      focus:ring-indigo-500
                      sm:text-sm
                    "
                    @change="searchsubtheme = $event.target.value"
                    :display-value="(subtheme) => subtheme.title"
                  />
                  <ComboboxButton
                    class="
                      absolute
                      inset-y-0
                      right-0
                      flex
                      items-center
                      rounded-r-md
                      px-2
                      focus:outline-none
                    "
                  >
                    <SelectorIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </ComboboxButton>

                  <ComboboxOptions
                    v-if="matchingSubthemes.length > 0"
                    class="
                      absolute
                      z-10
                      mt-1
                      max-h-60
                      w-full
                      overflow-auto
                      rounded-md
                      bg-white
                      py-1
                      text-base
                      shadow-lg
                      ring-1 ring-black ring-opacity-5
                      focus:outline-none
                      sm:text-sm
                    "
                  >
                    <ComboboxOption
                      v-for="subtheme in matchingSubthemes"
                      :key="subtheme.id"
                      :value="subtheme"
                      as="template"
                      v-slot="{ active, selected }"
                    >
                      <li
                        :class="[
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        ]"
                      >
                        <span
                          :class="[
                            'block truncate',
                            selected && 'font-semibold',
                          ]"
                        >
                          {{ subtheme.title }}
                        </span>

                        <span
                          v-if="selected"
                          :class="[
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                  <ComboboxOptions
                    v-else-if="chosentheme"
                    class="
                      absolute
                      z-10
                      mt-1
                      max-h-60
                      w-full
                      overflow-auto
                      rounded-md
                      bg-white
                      py-1
                      text-base
                      shadow-lg
                      ring-1 ring-black ring-opacity-5
                      focus:outline-none
                      sm:text-sm
                    "
                  >
                    <ComboboxOption
                      v-for="subtheme in chosentheme.subthemes"
                      :key="subtheme.id"
                      :value="subtheme"
                      as="template"
                      v-slot="{ active, selected }"
                    >
                      <li
                        :class="[
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        ]"
                      >
                        <span
                          :class="[
                            'block truncate',
                            selected && 'font-semibold',
                          ]"
                        >
                          {{ subtheme.title }}
                        </span>

                        <span
                          v-if="selected"
                          :class="[
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                  <ComboboxOptions
                    v-else
                    class="
                      absolute
                      z-10
                      mt-1
                      max-h-60
                      w-full
                      overflow-auto
                      rounded-md
                      bg-white
                      py-1
                      text-base
                      shadow-lg
                      ring-1 ring-black ring-opacity-5
                      focus:outline-none
                      sm:text-sm
                    "
                  >
                    <ComboboxOption
                      v-for="subtheme in subthemes"
                      :key="subtheme.id"
                      :value="subtheme"
                      as="template"
                      v-slot="{ active, selected }"
                    >
                      <li
                        :class="[
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        ]"
                      >
                        <span
                          :class="[
                            'block truncate',
                            selected && 'font-semibold',
                          ]"
                        >
                          {{ subtheme.title }}
                        </span>

                        <span
                          v-if="selected"
                          :class="[
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </div>
              </Combobox>
            </div>
            <!-- choose subtheme ends -->
            <!-- choose channel -->
            <div>
              <label
                for="Channel"
                class="block text-sm font-medium text-gray-700 mt-6 mb-1"
                >Canal</label
              >
              <select
                id="channel"
                name="channel"
                autocomplete="channel"
                v-model="sollicitation.channel_id"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
              >
                <option
                  v-for="channel in channels"
                  :key="channel.id"
                  :value="channel.id"
                >
                  {{ channel.title }}
                </option>
              </select>
            </div>
            <!-- choose channel ends -->
            <!-- description begins -->
            <div class="mt-6">
              <label
                for="description"
                class="block text-sm font-medium text-gray-700"
              >
                Description détaillée de cette sollicitation
              </label>
              <TipTap
                @input="forminprogress = true"
                v-model="sollicitation.content"
                class="mt-6 notepad-textarea"
              />
            </div>
            <!-- description ends -->
            <!-- important tag -->
            <div class="mt-6">
              <legend class="text-base font-medium text-gray-900 mt-6 mb-4">
                Priorités de cette sollicitation
              </legend>
              <SwitchGroup as="div" class="flex items-center">
                <Switch
                  v-model="emergency"
                  :class="[
                    emergency ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      emergency ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]"
                  />
                </Switch>
                <SwitchLabel as="span" class="ml-3">
                  <div v-if="emergency == true">
                    <span class="text-sm font-medium text-gray-900"
                      >Urgente
                    </span>
                    <span class="text-sm text-gray-500"
                      >(Cette sollicitation est à traiter en priorité)</span
                    >
                  </div>
                  <div v-if="emergency == false">
                    <span class="text-sm font-medium text-gray-900"
                      >Normale
                    </span>
                    <span class="text-sm text-gray-500"
                      >(Cette sollicitation n'est pas urgente)</span
                    >
                  </div>
                </SwitchLabel>
              </SwitchGroup>
            </div>
            <!-- important tag ends -->
            <!-- active or not -->
            <div class="mt-6">
              <legend class="text-base font-medium text-gray-900 mt-6 mb-4">
                Cette sollicitation est-elle active ou close ?
              </legend>
              <SwitchGroup as="div" class="flex items-center">
                <Switch
                  v-model="pending"
                  :class="[
                    pending ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      pending ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]"
                  />
                </Switch>
                <SwitchLabel as="span" class="ml-3">
                  <div v-if="pending == true">
                    <span class="text-sm font-medium text-gray-900"
                      >Active
                    </span>
                    <span class="text-sm text-gray-500"
                      >(Cette sollicitation est active)</span
                    >
                  </div>
                  <div v-if="pending == false">
                    <span class="text-sm font-medium text-gray-900"
                      >Close
                    </span>
                    <span class="text-sm text-gray-500"
                      >(Cette sollicitation est close)</span
                    >
                  </div>
                </SwitchLabel>
              </SwitchGroup>
            </div>
            <!-- active or not ends -->
            <div class="mt-6">
              <a
                @click.prevent="createSollicitation"
                class="
                  w-full
                  flex
                  justify-center
                  items-center
                  px-4
                  py-2
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-red-600
                  hover:bg-red-500
                  cursor-pointer
                "
              >
                Créer cette sollicitation
              </a>
            </div>
          </div>
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import {
  CheckIcon, SelectorIcon, InformationCircleIcon, ExclamationIcon,
} from '@heroicons/vue/solid';
import {
  Switch, SwitchGroup, SwitchLabel, Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue';
import AppNavbar from '@/components/AppNavbar.vue';
import TipTap from '@/components/TipTap.vue';

export default {
  components: {
    AppNavbar,
    TipTap,
    Switch,
    SwitchGroup,
    SwitchLabel,
    CheckIcon,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    SelectorIcon,
    ExclamationIcon,
    InformationCircleIcon,
  },
  setup() {
    const prisons = ref();
    const channels = ref();
    const search = ref();
    const chosenprison = ref();
    const searchquery = computed(() => search.value.toLowerCase());
    const matchingPrisons = computed(() => prisons.value.filter((prison) => prison.title.toLowerCase().includes(searchquery.value)));
    const themes = ref();
    const subthemes = ref();
    const chosentheme = ref();
    const chosensubtheme = ref();
    const searchtheme = ref('');
    const matchingThemes = computed(() => (searchtheme.value === ''
      ? themes
      : themes.value.filter((theme) => theme.title.toLowerCase().includes(searchtheme.value.toLowerCase()))));
    const searchsubtheme = ref('');
    const matchingSubthemes = computed(() => (searchsubtheme.value === ''
      ? subthemes
      : subthemes.value.filter((subtheme) => subtheme.title.toLowerCase().includes(searchsubtheme.value.toLowerCase()))));
    const emergency = ref(false);
    const pending = ref(false);
    const errored = ref(false);
    const forminprogress = ref(false);

    return {
      errored,
      prisons,
      channels,
      search,
      matchingPrisons,
      chosenprison,
      themes,
      subthemes,
      chosentheme,
      chosensubtheme,
      searchtheme,
      matchingThemes,
      searchsubtheme,
      matchingSubthemes,
      emergency,
      pending,
      sollicitation: {
        date: null,
        title: '',
        theme_id: Number,
        subtheme_id: Number,
        content: '',
        prison_id: Number,
        channel_id: Number,
        is_urgent: Boolean,
      },
      forminprogress,
    };
  },
  created() {
    this.moment = moment;
    const proposeddate = moment(new Date()).format('YYYY-MM-DDTHH:mm');
    this.sollicitation.date = proposeddate;
  },
  mounted() {
    this.$http.get('/api/prisons')
      .then((response) => {
        this.prisons = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    this.$http.get('/api/sollicitations-channels')
      .then((response) => {
        this.channels = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    this.$http.get('/api/sollicitations-themes')
      .then((response) => {
        this.themes = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    this.$http.get('/api/sollicitations-subthemes')
      .then((response) => {
        this.subthemes = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
  beforeRouteLeave(to, next) {
    window.console.log('alors?');
    if (this.forminprogress === true) {
      // eslint-disable-next-line
      const answer = window.confirm('Voulez-vous vraiment quitter cette page ? Le formulaire n\'a pas encore été soumis...');
      if (answer) {
        window.location = to.path; // this is the trick
        next();
      } else {
        next(false);
      }
    }
  },
  methods: {
    choosePrison(chosenPrison) {
      this.chosenprison = chosenPrison;
      this.sollicitation.prison_id = chosenPrison.id;
    },
    choosePotentialSubtheme(chosenSubtheme) {
      this.chosensubtheme = chosenSubtheme;
    },
    chooseSubtheme() {
      window.console.log('chooseSubtheme');
      if (this.chosentheme == null) {
        this.chosentheme = this.chosensubtheme.theme;
      }
    },
    createSollicitation() {
      this.forminprogress = false;
      let themeId;
      let subthemeId;
      if (this.chosensubtheme) {
        themeId = this.chosensubtheme.theme_id;
        subthemeId = this.chosensubtheme.id;
      } else {
        themeId = this.chosentheme.id;
      }
      this.$http.post('/api/sollicitation-creation/', {
        title: this.sollicitation.title,
        date: this.sollicitation.date,
        content: this.sollicitation.content,
        is_active: this.pending,
        is_urgent: this.emergency,
        theme_id: themeId,
        subtheme_id: subthemeId,
        prison_id: this.sollicitation.prison_id,
        channel_id: this.sollicitation.channel_id,
      }).then((response) => {
        this.$router.push({ name: 'Sollicitation', params: { id: response.data.id } });
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
  },
};
</script>

<style scoped>
.notepad-textarea {
  border: 1px solid rgba(209, 213, 219, 1);
  border-color: rgba(209, 213, 219, 1) !important;
  padding: 1rem;
  border-radius: 5px;
}
</style>
