import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/index.css';
import client from './api/client';
import './registerServiceWorker';

const app = createApp(App);
app.config.globalProperties.$http = client;
app.use(router);
app.mount('#app');
