<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="displayinsidercreation">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Créer un insider
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white" v-if="prisonmode == true">
                      Vous pouvez créer un insider ici, qui sera attaché à cet
                      établissement.
                    </p>
                    <p class="text-sm text-white" v-else>
                      Vous pouvez créer un insider ici, et le lier à un
                      établissement.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <!-- error -->
                  <div
                    class="rounded-md bg-red-50 p-4 mb-6"
                    v-if="errored == true"
                  >
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <XCircleIcon
                          class="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">
                          Mince, votre contact n'a pas été créé... Vérifiez que
                          tous les champs sont bien remplis.
                          <span v-if="prisonmode == false"
                            >Avez-vous bien choisi un établissement ?</span
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <!-- error ends -->
                  <div class="mb-2">
                    <label
                      for="firstname"
                      class="block text-sm font-medium text-gray-700"
                      >Prénom</label
                    >
                    <div class="mt-1">
                      <input
                        type="firstname"
                        name="firstname"
                        id="firstname"
                        v-model="insider.firstname"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                        placeholder="John"
                      />
                    </div>
                  </div>
                  <div class="mb-2">
                    <label
                      for="lastname"
                      class="block text-sm font-medium text-gray-700"
                      >Nom</label
                    >
                    <div class="mt-1">
                      <input
                        type="lastname"
                        name="lastname"
                        id="lastname"
                        v-model="insider.lastname"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                        placeholder="Doe"
                      />
                    </div>
                  </div>
                  <div class="mb-2">
                    <label
                      for="email"
                      class="block text-sm font-medium text-gray-700"
                      >Email</label
                    >
                    <div class="mt-1">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        v-model="insider.email"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                        placeholder="vous@exemple.com"
                      />
                    </div>
                  </div>
                  <div class="mb-2">
                    <label
                      for="email"
                      class="block text-sm font-medium text-gray-700"
                      >Titre / Poste</label
                    >
                    <div class="mt-1">
                      <input
                        type="position"
                        name="position"
                        id="position"
                        v-model="insider.position"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                        placeholder="Directeur"
                      />
                    </div>
                  </div>
                  <div class="mb-2">
                    <label
                      for="phone"
                      class="block text-sm font-medium text-gray-700"
                      >Téléphone</label
                    >
                    <div class="mt-1">
                      <input
                        type="phone"
                        name="phone"
                        id="phone"
                        v-model="insider.phone"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                        placeholder="01 78 78 78 78"
                      />
                    </div>
                  </div>
                  <div class="mb-2">
                    <label
                      for="phone"
                      class="block text-sm font-medium text-gray-700"
                      >Fax</label
                    >
                    <div class="mt-1">
                      <input
                        type="fax"
                        name="fax"
                        id="fax"
                        v-model="insider.fax"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                        placeholder="01 78 78 78 78"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="location"
                      class="block text-sm font-medium text-gray-700 mt-2"
                      >Pôle</label
                    >
                    <select
                      id="pole"
                      name="pole"
                      v-model="insider.pole_id"
                      class="
                        mt-1
                        block
                        w-full
                        pl-3
                        pr-10
                        py-2
                        text-base
                        border-gray-300
                        focus:outline-none
                        focus:ring-indigo-500
                        focus:border-indigo-500
                        sm:text-sm
                        rounded-md
                      "
                    >
                      <option
                        v-for="pole in poles"
                        :key="pole.id"
                        :value="pole.id"
                      >
                        {{ pole.label }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <label
                      for="comment"
                      class="block text-sm font-medium text-gray-700"
                      >Commentaire</label
                    >
                    <div class="mt-1">
                      <textarea
                        rows="4"
                        name="comment"
                        id="comment"
                        v-model="insider.comment"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <SwitchGroup as="div" class="flex items-center mt-6">
                    <Switch
                      v-model="critical"
                      :class="[
                        critical ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                      ]"
                    >
                      <span
                        aria-hidden="true"
                        :class="[
                          critical ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                        ]"
                      />
                    </Switch>
                    <SwitchLabel as="span" class="ml-3">
                      <span
                        class="text-sm font-medium text-gray-900"
                        v-if="critical == false"
                        >Cet insider n'est pas critique
                      </span>
                      <span
                        class="text-sm font-medium text-gray-900"
                        v-if="critical == true"
                        >Cet insider est critique
                      </span>
                    </SwitchLabel>
                  </SwitchGroup>
                  {{ perimeter }}
                  <SwitchGroup as="div" class="flex items-center mt-6">
                    <Switch
                      v-model="perimeter"
                      :class="[
                        perimeter ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                      ]"
                    >
                      <span
                        aria-hidden="true"
                        :class="[
                          perimeter ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                        ]"
                      />
                    </Switch>
                    <SwitchLabel as="span" class="ml-3">
                      <span
                        class="text-sm font-medium text-gray-900"
                        v-if="perimeter == false"
                        >Cet insider n'a pas de périmètre étendu
                      </span>
                      <span
                        class="text-sm font-medium text-gray-900"
                        v-if="perimeter == true"
                        >Cet insider a un périmètre plus large que
                        l'établissement auquel il est rattaché
                      </span>
                    </SwitchLabel>
                  </SwitchGroup>
                  <div v-if="prisonmode == false">
                    <!-- choose prison -->
                    <div>
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700 mt-6"
                        >Établissement lié</label
                      >
                      <div class="mt-1">
                        <input
                          v-if="!chosenprison"
                          type="text"
                          name="prison"
                          v-model="search"
                          placeholder="Tapez quelques lettres de l'établissement recherché"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                      <ul
                        role="list"
                        class="-my-5 divide-y divide-gray-200 mt-6"
                        v-if="search && !chosenprison"
                      >
                        <li
                          v-for="prison in matchingPrisons"
                          :key="prison.id"
                          class="py-4"
                        >
                          <div class="flex items-center space-x-4">
                            <div class="flex-1 min-w-0">
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-900
                                  truncate
                                "
                              >
                                {{ prison.title }}
                              </p>
                            </div>
                            <div>
                              <a
                                @click.prevent="choosePrison(prison)"
                                class="
                                  inline-flex
                                  items-center
                                  shadow-sm
                                  px-2.5
                                  py-0.5
                                  text-sm
                                  leading-5
                                  font-medium
                                  rounded-full
                                  text-white
                                  bg-green-500
                                  hover:bg-green-700
                                  cursor-pointer
                                "
                              >
                                Choisir
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div v-if="chosenprison">
                        <span
                          class="
                            inline-flex
                            rounded-full
                            items-center
                            py-0.5
                            pl-2.5
                            pr-1
                            text-sm
                            font-medium
                            bg-indigo-100
                            text-indigo-700
                            mt-6
                          "
                        >
                          {{ chosenprison.title }}
                          <button
                            @click.prevent="chosenprison = null"
                            type="button"
                            class="
                              flex-shrink-0
                              ml-0.5
                              h-4
                              w-4
                              rounded-full
                              inline-flex
                              items-center
                              justify-center
                              text-indigo-400
                              hover:bg-indigo-200 hover:text-indigo-500
                              focus:outline-none
                              focus:bg-indigo-500
                              focus:text-white
                            "
                          >
                            <!-- <span class="sr-only">Remove large option</span> -->
                            <svg
                              class="h-2 w-2"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 8 8"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-width="1.5"
                                d="M1 1l6 6m0-6L1 7"
                              />
                            </svg>
                          </button>
                        </span>
                      </div>
                    </div>
                    <!-- choose prison ends -->
                  </div>
                  <!-- /End replace -->
                </div>
                <!-- Action buttons -->
                <div
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('shut')"
                    >
                      Annuler
                    </button>
                    <button
                      v-if="prisonmode == true"
                      @click.prevent="createInsider"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Créer cet insider
                    </button>
                    <button
                      v-if="prisonmode == false"
                      @click.prevent="createInsiderFromScratch"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Créer cet insider
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, computed } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, Switch, SwitchGroup, SwitchLabel,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { XCircleIcon } from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    XCircleIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  setup() {
    const open = ref(true);
    const errored = ref(false);
    const poles = ref();
    const critical = ref(false);
    const perimeter = ref(false);
    const prisons = ref();
    const search = ref();
    const searchquery = computed(() => search.value.toLowerCase());
    const matchingPrisons = computed(() => prisons.value.filter((prison) => prison.title.toLowerCase().includes(searchquery.value)));
    const chosenprison = ref();

    return {
      open,
      errored,
      poles,
      insider: {
        firstname: null,
        lastname: null,
        position: null,
        email: null,
        phone: null,
        fax: null,
        pole_id: null,
        is_critical: null,
        comment: null,
      },
      critical,
      perimeter,
      prisons,
      matchingPrisons,
      chosenprison,
      search,
    };
  },
  created() {
    this.getPoles();
    this.$http.get('/api/prisons')
      .then((response) => {
        this.prisons = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
  methods: {
    getPoles() {
      this.$http.get('/api/insiders-poles/')
        .then((response) => {
          this.poles = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    createInsider() {
      this.$http.post('/api/insider-creation/', {
        firstname: this.insider.firstname,
        lastname: this.insider.lastname,
        position: this.insider.position,
        email: this.insider.email,
        phone: this.insider.phone,
        fax: this.insider.fax,
        prison_id: this.prison.id,
        pole_id: this.insider.pole_id,
        is_critical: this.critical,
        comment: this.insider.comment,
        wide_perimeter: this.perimeter,
      }).then(() => {
        this.$emit('shut');
        this.$emit('insideradded');
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
    createInsiderFromScratch() {
      if (this.chosenprison == null) {
        this.errored = true;
      } else {
        this.$http.post('/api/insider-creation/', {
          firstname: this.insider.firstname,
          lastname: this.insider.lastname,
          position: this.insider.position,
          email: this.insider.email,
          phone: this.insider.phone,
          fax: this.insider.fax,
          prison_id: this.chosenprison.id,
          pole_id: this.insider.pole_id,
          is_critical: this.critical,
          wide_perimeter: this.perimeter,
        }).then(() => {
          this.$emit('shut');
          this.$emit('insideradded');
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
      }
    },
    choosePrison(chosenPrison) {
      this.chosenprison = chosenPrison;
      this.form.prison_id = chosenPrison.id;
    },
  },
  props: {
    prison: Object,
    displayinsidercreation: Boolean,
    prisonmode: Boolean,
  },
};
</script>
