<template>
  <TransitionRoot as="template" :show="subscriptionconfirmation">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Demander un abonnement
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          bg-gray-700
                          rounded-md
                          text-gray-200
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-gray-300">
                      Vous êtes sur le point de demander un abonnement à Dedans
                      Dehors pour cette personne. L'abonnement démarrera dès
                      qu'il aura été pris en compte par Julie.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <!-- errored -->
                  <div
                    class="rounded-md bg-red-50 p-4 mb-6"
                    v-if="errored == true"
                  >
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <XCircleIcon
                          class="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">
                          Mince, la demande n'a pas pu être effectuée...
                        </h3>
                        <div class="mt-2 text-sm text-red-700">
                          <ul role="list" class="list-disc pl-5 space-y-1">
                            <li>
                              vérifiez que numéro de cellule est de bâtiment
                              sont renseignés
                            </li>
                            <li>
                              Si le problème ne vient pas de ces informations,
                              contactez l'administrateur
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- errored ends -->
                  <div class="mt-2">
                    <label
                      for="cell"
                      class="block text-sm font-medium text-gray-700"
                      >Numéro de cellule</label
                    >
                    <div class="mt-1">
                      <input
                        type="cell"
                        name="cell"
                        id="cell"
                        v-model="cell_number"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div class="mt-2">
                    <label
                      for="bat"
                      class="block text-sm font-medium text-gray-700"
                      >Numéro de bâtiment</label
                    >
                    <div class="mt-1">
                      <input
                        type="batiment"
                        name="batiment"
                        id="batiment"
                        v-model="bat_number"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <!-- /End replace -->
                </div>
                <!-- Action buttons -->
                <div
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('shut')"
                    >
                      J'annule
                    </button>
                    <button
                      @click.prevent="createSubscription"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Envoyer la demande
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const open = ref(true);
    const errored = ref(false);

    return {
      open,
      cell_number: null,
      bat_number: null,
      errored,
    };
  },
  props: {
    subscriptionconfirmation: Boolean,
    inmate: Object,
  },
  methods: {
    createSubscription() {
      if (this.cell_number == null || this.bat_number == null) {
        this.errored = true;
      } else {
        this.$http.post('/api/subscription-demand/', {
          starting_date: null,
          active: 0,
          issue_number: null,
          inmate_id: this.inmate.id,
          cell_number: this.cell_number,
          bat_number: this.bat_number,
        }).then((response) => {
          window.console.log(response.data);
          this.$emit('shut');
          this.$emit('confirm');
        }).catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
      }
    },
  },
};
</script>
