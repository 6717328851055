<template>
  <TransitionRoot as="template" :show="displaycontacts">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Gestion des personnes concernées
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          bg-gray-800
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="closePanel"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Vous pouvez gérer, ici, les personnes concernées par cette
                      sollicitation.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <!-- display attached inmates -->
                  <div v-if="sollicitation.inmates.length > 0" class="mb-12">
                    <h4
                      class="font-bold text-xl text-gray-900 sm:text-2xl mb-2"
                    >
                      Personnes concernées
                    </h4>
                    <label
                      for="email"
                      class="block text-sm font-medium text-gray-700"
                      >Vous trouverez, ci-dessous, la liste des personnes
                      concernées</label
                    >
                    <div>
                      <div class="flow-root mt-6">
                        <ul role="list" class="-my-5 divide-y divide-gray-200">
                          <li
                            v-for="inmate in sollicitation.inmates"
                            :key="inmate.id"
                            class="py-4"
                          >
                            <div class="flex items-center space-x-4">
                              <div class="flex-1 min-w-0">
                                <p
                                  class="
                                    text-sm
                                    font-medium
                                    text-gray-900
                                    truncate
                                  "
                                >
                                  {{ inmate.firstname }} {{ inmate.lastname }}
                                </p>
                              </div>
                              <div>
                                <button
                                  @click.prevent="removeVictim(inmate.id)"
                                  class="
                                    inline-flex
                                    items-center
                                    shadow-sm
                                    px-2.5
                                    py-0.5
                                    border border-gray-300
                                    text-sm
                                    leading-5
                                    font-medium
                                    rounded-full
                                    text-gray-700
                                    bg-white
                                    hover:bg-gray-50
                                  "
                                >
                                  Retirer
                                </button>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- attached inmates ends -->
                  <!-- add inmates  -->
                  <div class="mt-6 mb-12">
                    <h4
                      class="font-bold text-xl text-gray-900 sm:text-2xl mb-2"
                    >
                      Ajoutez d'autres personnes
                    </h4>
                    <label
                      for="email"
                      class="block text-sm font-medium text-gray-700"
                      >Vous pouvez ajouter d'autres personnes détenues en
                      recherchant dans le champ ci-dessous</label
                    >
                    <div class="mt-2">
                      <input
                        @keyup.enter="searchInmates()"
                        type="text"
                        v-model="search"
                        name="search"
                        id="search"
                        placeholder="Recherchez..."
                        class="
                          shadow-sm
                          focus:ring-red-500 focus:border-red-500
                          block
                          w-full
                          pr-12
                          sm:text-sm
                          border-gray-500
                          rounded-md
                          h-8
                        "
                      />
                    </div>
                  </div>
                  <ul
                    role="list"
                    class="-my-5 divide-y divide-gray-200 mt-6 mb-6"
                    v-if="search"
                  >
                    <li v-for="person in persons" :key="person.id" class="py-4">
                      <div class="flex items-center space-x-4">
                        <div class="flex-1 min-w-0">
                          <p class="text-sm font-medium text-gray-900 truncate">
                            {{ person.firstname }} {{ person.lastname }}
                          </p>
                        </div>
                        <div>
                          <a
                            @click.prevent="addVictim(person)"
                            class="
                              inline-flex
                              items-center
                              shadow-sm
                              px-2.5
                              py-0.5
                              text-sm
                              leading-5
                              font-medium
                              rounded-full
                              text-white
                              bg-green-500
                              hover:bg-green-700
                              cursor-pointer
                            "
                          >
                            Ajouter
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <!-- add inmates ends -->
                  <!-- create inmate -->
                  <div v-if="currentstep == 1">
                    <h4
                      class="font-bold text-xl text-gray-900 sm:text-2xl mb-2"
                    >
                      Créer et ajouter un nouveau dossier
                    </h4>
                    <label class="block text-sm font-medium text-gray-700 mb-2"
                      >Vous pouvez créer et ajouter une nouvelle personne en
                      remplissant les champs ci-dessous</label
                    >
                    <!-- errored -->
                    <div
                      class="rounded-md bg-red-50 p-4 mb-2"
                      v-if="errored == true"
                    >
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <XCircleIcon
                            class="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-red-800">
                            Le dossier n'a pas été créé
                          </h3>
                          <div class="mt-2 text-sm text-red-700">
                            <ul role="list" class="list-disc pl-5 space-y-1">
                              <li>
                                vérifiez que tous les champs sont bien remplis
                              </li>
                              <li>
                                Les champs nom et prénom sont obligatoires
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- errored ends -->
                    <div>
                      <label
                        for="firstname"
                        class="block text-sm font-medium text-gray-700"
                        >Prénom</label
                      >
                      <div class="mt-1">
                        <input
                          type="firstname"
                          name="firstname"
                          id="firstname"
                          v-model="inmate.firstname"
                          placeholder="Jacques"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for="lastname"
                        class="block text-sm font-medium text-gray-700 mt-2"
                        >Nom de famille</label
                      >
                      <div class="mt-1">
                        <input
                          type="lastname"
                          name="lastname"
                          id="lastname"
                          v-model="inmate.lastname"
                          placeholder="Dupont"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for="birthdate"
                        class="block text-sm font-medium text-gray-700 mt-2"
                        >Année de naissance</label
                      >
                      <div class="mt-1">
                        <input
                          type="birthdate"
                          name="birthdate"
                          id="birthdate"
                          v-model="inmate.birthdate"
                          placeholder="1982"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for="location"
                        class="block text-sm font-medium text-gray-700 mt-2"
                        >Genre</label
                      >
                      <select
                        id="gender"
                        name="gender"
                        v-model="inmate.gender_id"
                        class="
                          mt-1
                          block
                          w-full
                          pl-3
                          pr-10
                          py-2
                          text-base
                          border-gray-300
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                          rounded-md
                        "
                      >
                        <option
                          v-for="gender in genders"
                          :key="gender.id"
                          :value="gender.id"
                        >
                          {{ gender.label }}
                        </option>
                      </select>
                    </div>
                    <div class="space-x-3 flex justify-end mt-4">
                      <button
                        @click.prevent="createandaddInmate"
                        type="submit"
                        class="
                          inline-flex
                          justify-center
                          py-2
                          px-4
                          border border-transparent
                          shadow-sm
                          text-sm
                          font-medium
                          rounded-md
                          text-white
                          bg-red-600
                          hover:bg-red-700
                          focus:outline-none
                          focus:ring-2
                          focus:ring-offset-2
                          focus:ring-red-500
                        "
                      >
                        Créer et ajouter ce dossier
                      </button>
                    </div>
                  </div>
                  <div v-if="currentstep == 2">
                    <h4
                      class="font-bold text-xl text-gray-900 sm:text-2xl mb-2"
                    >
                      .. et ajoutez un événement
                    </h4>
                    <label class="block text-sm font-medium text-gray-700 mb-2"
                      >Vous pouvez indiquer une localisation, un numéro SAGI
                      et/ou un établissement en utilisant le formulaire
                      ci-dessous.</label
                    >
                    <!-- errored -->
                    <div
                      class="rounded-md bg-red-50 p-4 mb-2"
                      v-if="errored == true"
                    >
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <XCircleIcon
                            class="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-red-800">
                            L'événement n'a pas pu être créé. La date est
                            obligatoire.
                          </h3>
                        </div>
                      </div>
                    </div>
                    <!-- errored ends -->
                    <div class="mt-4">
                      <label
                        for="project-name"
                        class="block text-sm font-medium text-gray-900"
                      >
                        Numéro SAGI
                      </label>
                      <div class="mt-1">
                        <input
                          type="text"
                          name="sagi_number"
                          id="sagi_number"
                          v-model="event.sagi_number"
                          class="
                            block
                            w-full
                            shadow-sm
                            sm:text-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-4">
                      <label
                        for="project-name"
                        class="block text-sm font-medium text-gray-900"
                      >
                        Numéro écrou
                      </label>
                      <div class="mt-1">
                        <input
                          type="text"
                          name="sagi_number"
                          id="sagi_number"
                          v-model="event.inmate_number"
                          class="
                            block
                            w-full
                            shadow-sm
                            sm:text-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-4">
                      <label
                        for="project-name"
                        class="block text-sm font-medium text-gray-900"
                      >
                        Date
                      </label>
                      <div class="mt-1">
                        <input
                          type="datetime-local"
                          name="date"
                          id="date"
                          v-model="event.date"
                          class="
                            block
                            w-full
                            shadow-sm
                            sm:text-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <!-- choose prison -->
                    <div class="mt-4">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700 mt-6"
                        >Établissement concerné</label
                      >
                      <div class="mt-1">
                        <input
                          v-if="!chosenprison"
                          type="text"
                          name="prison"
                          v-model="searchprison"
                          placeholder="Tapez quelques lettres de l'établissement recherché"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                      <ul
                        role="list"
                        class="-my-5 divide-y divide-gray-200 mt-6"
                        v-if="searchprison && !chosenprison"
                      >
                        <li
                          v-for="prison in matchingPrisons"
                          :key="prison.id"
                          class="py-4"
                        >
                          <div class="flex items-center space-x-4">
                            <div class="flex-1 min-w-0">
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-900
                                  truncate
                                "
                              >
                                {{ prison.title }}
                              </p>
                            </div>
                            <div>
                              <a
                                @click.prevent="choosePrison(prison)"
                                class="
                                  inline-flex
                                  items-center
                                  shadow-sm
                                  px-2.5
                                  py-0.5
                                  text-sm
                                  leading-5
                                  font-medium
                                  rounded-full
                                  text-white
                                  bg-green-500
                                  hover:bg-green-700
                                  cursor-pointer
                                "
                              >
                                Choisir
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div v-if="chosenprison">
                        <span
                          class="
                            inline-flex
                            rounded-full
                            items-center
                            py-0.5
                            pl-2.5
                            pr-1
                            text-sm
                            font-medium
                            bg-indigo-100
                            text-indigo-700
                            mt-6
                          "
                        >
                          {{ chosenprison.title }}
                          <button
                            @click.prevent="chosenprison = null"
                            type="button"
                            class="
                              flex-shrink-0
                              ml-0.5
                              h-4
                              w-4
                              rounded-full
                              inline-flex
                              items-center
                              justify-center
                              text-indigo-400
                              hover:bg-indigo-200 hover:text-indigo-500
                              focus:outline-none
                              focus:bg-indigo-500
                              focus:text-white
                            "
                          >
                            <!-- <span class="sr-only">Remove large option</span> -->
                            <svg
                              class="h-2 w-2"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 8 8"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-width="1.5"
                                d="M1 1l6 6m0-6L1 7"
                              />
                            </svg>
                          </button>
                        </span>
                      </div>
                    </div>
                    <!-- choose prison ends -->
                    <div class="space-x-3 flex justify-end mt-4">
                      <button
                        @click="closePanel"
                        type="button"
                        class="
                          bg-white
                          py-2
                          px-4
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          text-sm
                          font-medium
                          text-gray-700
                          hover:bg-gray-50
                          focus:outline-none
                          focus:ring-2
                          focus:ring-offset-2
                          focus:ring-indigo-500
                        "
                      >
                        Non, pas besoin
                      </button>
                      <button
                        @click.prevent="createEvent"
                        type="submit"
                        class="
                          inline-flex
                          justify-center
                          py-2
                          px-4
                          border border-transparent
                          shadow-sm
                          text-sm
                          font-medium
                          rounded-md
                          text-white
                          bg-red-600
                          hover:bg-red-700
                          focus:outline-none
                          focus:ring-2
                          focus:ring-offset-2
                          focus:ring-red-500
                        "
                      >
                        Créer et ajouter cet événement
                      </button>
                    </div>
                  </div>
                  <!-- create inmate ends -->
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, computed } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const persons = ref();
    const search = ref();
    const genders = ref();
    const errored = ref(false);
    const currentstep = ref();
    const chosenprison = ref();
    const prisons = ref();
    const searchprison = ref();
    const searchprisonquery = computed(() => searchprison.value.toLowerCase());
    const matchingPrisons = computed(() => prisons.value.filter((prison) => prison.title.toLowerCase().includes(searchprisonquery.value)));
    const newlycreatedinmate = ref();

    return {
      search,
      persons,
      prisons,
      genders,
      matchingPrisons,
      searchprison,
      searchprisonquery,
      chosenprison,
      newlycreatedinmate,
      inmate: {
        firstname: null,
        lastname: null,
        birthdate: null,
        gender_id: null,
      },
      errored,
      currentstep,
      event: {
        prison_id: null,
        inmate_number: null,
        sagi_number: null,
        date: null,
      },
    };
  },
  props: {
    displaycontacts: Boolean,
    sollicitation: Object,
  },
  created() {
    this.currentstep = 1;
    this.$http.get('/api/genders')
      .then((response) => {
        this.genders = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    this.$http.get('/api/prisons')
      .then((response) => {
        this.prisons = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
  methods: {
    removeVictim(inmateId) {
      this.$http.patch('api/sollicitation-inmates-remove', {
        id: this.sollicitation.id,
        inmate_id: inmateId,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('updateinmateslist');
      }).catch((error) => {
        window.console.log(error);
      });
    },
    addVictim(Inmate) {
      this.$http.patch('api/sollicitation-inmates', {
        id: this.sollicitation.id,
        inmate_id: Inmate.id,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('updateinmateslist');
        this.$emit('shut');
        this.search = null;
      }).catch((error) => {
        window.console.log(error);
      });
    },
    searchInmates() {
      this.usersearch = this.search;
      this.$http.get(`api/inmates-by-name/${this.search}`)
        .then((response) => {
          this.persons = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    choosePrison(chosenPrison) {
      this.chosenprison = chosenPrison;
      this.event.prison_id = chosenPrison.id;
    },
    createandaddInmate() {
      this.$http.post('/api/inmate-creation/', {
        firstname: this.inmate.firstname,
        lastname: this.inmate.lastname,
        gender_id: this.inmate.gender_id,
        birthdate: this.inmate.birthdate,
      }).then((response) => {
        this.newlycreatedinmate = response.data;
        this.$http.patch('api/sollicitation-inmates', {
          id: this.sollicitation.id,
          inmate_id: response.data.id,
        }).then(() => {
          this.$emit('updateinmateslist');
          this.inmate.lastname = null;
          this.inmate.firstname = null;
          this.inmate.birthdate = null;
          this.inmate.gender_id = null;
          this.currentstep = 2;
        });
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
    closePanel() {
      this.$emit('shut');
    },
    createEvent() {
      this.$http.post('api/event-creation', {
        inmate_id: this.newlycreatedinmate.id,
        prison_id: this.event.prison_id,
        inmate_number: this.event.inmate_number,
        sagi_number: this.event.sagi_number,
        date: this.event.date,
      }).then(() => {
        this.$emit('updateinmateslist');
        this.$emit('shut');
      }).catch((error) => {
        window.console.log(error);
        this.eventerrored = true;
      });
    },
  },
};
</script>
