<template>
  <TransitionRoot as="template" :show="displaynotepads">
    <Dialog
      as="div"
      class="fixed inset-0 overflow-hidden"
      @click="$emit('shut')"
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Bloc-notes remplis
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Voici la liste des dossiers, de vos DISPS, qui ont des
                      bloc-notes remplis.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Bloc-notes remplis
                  </h3>
                  <ul role="list" class="relative z-0 divide-y divide-gray-200">
                    <li v-for="notepad in notepads" :key="notepad.id">
                      <div
                        class="
                          relative
                          px-6
                          py-5
                          flex
                          items-center
                          space-x-3
                          hover:bg-gray-50
                        "
                      >
                        <div class="flex-1 min-w-0">
                          <a
                            :href="
                              /personnes-detenues/ + 'notes' + '/' + notepad.id
                            "
                            class="focus:outline-none"
                          >
                            <!-- Extend touch target to entire panel -->
                            <span class="absolute inset-0" aria-hidden="true" />
                            <p class="text-sm font-medium text-gray-900">
                              {{ notepad.firstname }} {{ notepad.lastname }}
                            </p>
                            <p
                              class="text-sm text-gray-500 truncate"
                              v-if="notepad.current_location"
                            >
                              {{ notepad.current_location.title }}
                            </p>
                            <p class="text-sm text-gray-500 truncate" v-else>
                              Localisation inconnue
                            </p>
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const open = ref(true);

    return {
      open,
    };
  },
  props: {
    displaynotepads: Boolean,
    notepads: Object,
  },
};
</script>
