<template>
  <div class="min-h-full">
    <div class="bg-gray-800 pb-32">
      <AppNavbar />
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">Insiders</h1>
          <h3 class="text-2xl text-white">
            Aidants et indicateurs, de l'intérieur...
          </h3>
        </div>
      </header>
    </div>
    <main class="-mt-32">
      <InsiderDetail
        v-show="seedetail"
        v-model:seedetail="seedetail"
        v-model:insider="choseninsider"
        @shut="hideDetail"
        @insiderupdated="confirmUpdate"
      />
      <InsiderCreation
        v-model:displayinsidercreation="displayinsidercreation"
        v-model:prisonmode="prisonmode"
        @insideradded="getResults"
        @shut="hideInsiderCreation"
      />
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mb-12">
          <!-- Action panels -->
          <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 mb-12">
            <div
              class="
                px-4
                py-5
                bg-white
                shadow
                rounded-lg
                overflow-hidden
                sm:p-6
              "
            >
              <dd class="mt-1 text-3xl font-semibold text-gray-900">
                <div class="px-4 py-5 sm:p-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Créer un insider
                  </h3>
                  <div class="mt-2 sm:flex sm:items-start sm:justify-between">
                    <div class="max-w-xl text-sm text-gray-500">
                      <p>
                        Créez un insider et attribuer à ce contact un
                        établissement.
                      </p>
                    </div>
                    <div
                      class="
                        mt-5
                        sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
                      "
                    >
                      <button
                        @click.prevent="displayinsidercreation = true"
                        type="button"
                        class="
                          inline-flex
                          items-center
                          px-4
                          py-2
                          border border-transparent
                          shadow-sm
                          font-medium
                          rounded-md
                          text-white
                          bg-red-600
                          hover:bg-red-700
                          focus:outline-none
                          focus:ring-2
                          focus:ring-offset-2
                          focus:ring-red-500
                          sm:text-sm
                        "
                      >
                        Créer un insider
                      </button>
                    </div>
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="
                px-4
                py-5
                bg-white
                shadow
                rounded-lg
                overflow-hidden
                sm:p-6
              "
            >
              <dd class="mt-1 text-3xl font-semibold text-gray-900">
                <div class="px-4 py-5 sm:p-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Exporter les insiders
                  </h3>
                  <div class="mt-2 sm:flex sm:items-start sm:justify-between">
                    <div class="max-w-xl text-sm text-gray-500">
                      <p>Exporter les insiders au format Excel.</p>
                    </div>
                    <div
                      class="
                        mt-5
                        sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
                      "
                    >
                      <button
                        @click.prevent="downloadInsiders"
                        type="button"
                        class="
                          inline-flex
                          items-center
                          px-4
                          py-2
                          border border-transparent
                          shadow-sm
                          font-medium
                          rounded-md
                          text-white
                          bg-green-600
                          hover:bg-green-700
                          focus:outline-none
                          focus:ring-2
                          focus:ring-offset-2
                          focus:ring-green-500
                          sm:text-sm
                        "
                      >
                        Exporter les insiders
                      </button>
                    </div>
                  </div>
                </div>
              </dd>
            </div>
          </dl>
          <!-- Action panels ends -->
          <!-- Search sollicitation -->
          <div class="mb-4" v-if="!usersearch">
            <label for="search" class="block text-sm font-medium text-gray-700"
              >Recherchez avec <span class="font-bold">1 mot-clé</span></label
            >
            <div class="mt-1 relative flex items-center mb-2">
              <input
                @keyup.enter="searchInsiders()"
                @keydown.space.prevent
                type="text"
                name="search"
                v-model="search"
                id="search"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  pr-12
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                placeholder="Vous pouvez rechercher un mot clé le nom, prénom, la prison, le titre ou le pôle d'un.e insider"
              />
              <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                <kbd
                  class="
                    inline-flex
                    items-center
                    border border-gray-200
                    rounded
                    px-2
                    text-sm
                    font-sans font-medium
                    text-gray-400
                  "
                >
                  Entrer
                </kbd>
              </div>
            </div>
            <!-- Checkbox filter -->
            <fieldset class="space-y-5 mt-4 mb-12">
              <div class="relative flex items-start">
                <div class="flex h-5 items-center">
                  <input
                    id="comments"
                    v-model="wideperimeter"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    class="
                      h-4
                      w-4
                      rounded
                      border-gray-300
                      text-indigo-600
                      focus:ring-indigo-500
                    "
                  />
                </div>
                <div class="ml-3 text-sm">
                  <span id="comments-description" class="text-gray-500"
                    >Afficher uniquement les insiders avec un périmètre
                    étendu</span
                  >
                </div>
              </div>
            </fieldset>
            <!-- Checkbox filter ends -->
          </div>
          <!-- Search ends -->
          <!-- Search query -->
          <div class="mb-4" v-if="usersearch">
            Vous avez recherché :
            <span
              class="
                inline-flex
                rounded-full
                items-center
                py-0.5
                pl-2.5
                pr-1
                text-sm
                font-medium
                bg-indigo-100
                text-indigo-700
              "
            >
              {{ usersearch }}
              <button
                @click.prevent="resetSearch"
                type="button"
                class="
                  flex-shrink-0
                  ml-0.5
                  h-4
                  w-4
                  rounded-full
                  inline-flex
                  items-center
                  justify-center
                  text-indigo-400
                  hover:bg-indigo-200 hover:text-indigo-500
                  focus:outline-none focus:bg-indigo-500 focus:text-white
                "
              >
                <span class="sr-only">Réinitialisez</span>
                <svg
                  class="h-2 w-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-width="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </span>
          </div>
          <!-- Search query ends -->
          <div v-if="insiders">
            <div v-if="insiders.data">
              <div class="flex flex-col" v-if="insiders.data.length > 0">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div
                    class="
                      py-2
                      align-middle
                      inline-block
                      min-w-full
                      sm:px-6
                      lg:px-8
                    "
                  >
                    <div
                      class="
                        shadow
                        overflow-hidden
                        border-b border-gray-200
                        sm:rounded-lg
                      "
                    >
                      <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Nom complet
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Poste ou titre
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Date de création
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Pôle
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Prison
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Périmètre plus large ?
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Sensible
                            </th>
                            <th scope="col" class="relative px-6 py-3">
                              <span class="sr-only">Voir le détail</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(insider, insiderIdx) in insiders.data"
                            :key="insider.id"
                            :class="
                              insiderIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                            "
                          >
                            <td
                              class="
                                px-6
                                py-4
                                text-sm
                                font-medium
                                text-gray-500
                              "
                            >
                              {{ insider.firstname }}
                              {{ insider.lastname }}
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                text-sm
                                font-medium
                                text-gray-900
                              "
                            >
                              {{ insider.position }}
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                text-sm
                                font-medium
                                text-gray-900
                              "
                            >
                              {{
                                moment(insider.created_at).format(
                                  "ddd DD MMM YYYY"
                                )
                              }}
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                text-sm
                                font-medium
                                text-gray-900
                              "
                            >
                              <div v-if="insider.pole">
                                {{ insider.pole.label }}
                              </div>
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                text-sm
                                font-medium
                                text-gray-900
                              "
                            >
                              <span v-if="insider.prison">{{
                                insider.prison.title
                              }}</span>
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                text-sm
                                font-medium
                                text-gray-500
                              "
                            >
                              <LocationMarkerIcon
                                v-if="insider.wide_perimeter == 1"
                                class="h-5 w-5 text-green-400"
                                aria-hidden="true"
                              />
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                text-sm
                                font-medium
                                text-gray-500
                              "
                            >
                              <EyeOffIcon
                                v-if="insider.is_critical == 1"
                                class="h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                              <EyeIcon
                                v-if="insider.is_critical == 0"
                                class="h-5 w-5 text-green-400"
                                aria-hidden="true"
                              />
                            </td>
                            <td
                              class="px-6 py-4 text-right text-sm font-medium"
                            >
                              <a
                                @click.prevent="seeDetail(insider)"
                                class="
                                  text-indigo-600
                                  hover:text-indigo-900
                                  cursor-pointer
                                "
                                >Détails</a
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- pagination  -->
            <nav
              v-if="!usersearch"
              class="
                bg-white
                px-4
                py-3
                flex
                items-center
                justify-between
                border-t border-gray-200
                sm:px-6
              "
              aria-label="Pagination"
            >
              <div class="hidden sm:block">
                <p class="text-sm text-gray-700">
                  Page
                  {{ " " }}
                  <span class="font-medium">{{ currentpage }}</span>
                  {{ " " }}
                  sur
                  {{ " " }}
                  <span class="font-medium">{{ insiders.last_page }}</span>
                  {{ " " }}
                  de
                  {{ " " }}
                  <span class="font-medium">{{ insiders.total }}</span>
                  {{ " " }}
                  résultats
                </p>
              </div>
              <div class="flex-1 flex justify-between sm:justify-end">
                <button
                  @click.prevent="goPreviouspage"
                  :disabled="currentpage < 2"
                  :class="currentpage < 2 ? 'cursor-not-allowed' : ''"
                  class="
                    relative
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-gray-300
                    text-sm
                    font-medium
                    rounded-md
                    text-gray-700
                    bg-white
                    hover:bg-gray-50
                    cursor-pointer
                  "
                >
                  Précédentes
                </button>
                <button
                  @click.prevent="goNextpage"
                  :disabled="currentpage == insiders.last_page"
                  :class="
                    currentpage == insiders.last_page
                      ? 'cursor-not-allowed'
                      : ''
                  "
                  class="
                    ml-3
                    relative
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-gray-300
                    text-sm
                    font-medium
                    rounded-md
                    text-gray-700
                    bg-white
                    hover:bg-gray-50
                    cursor-pointer
                  "
                >
                  Suivantes
                </button>
              </div>
            </nav>
            <!-- pagination ends -->
          </div>
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import AppNavbar from '@/components/AppNavbar.vue';
import InsiderDetail from '@/components/Modals/InsiderDetail.vue';
import { EyeOffIcon, EyeIcon, LocationMarkerIcon } from '@heroicons/vue/outline';
import InsiderCreation from '@/components/Forms/InsiderCreation.vue';

export default {
  components: {
    AppNavbar,
    InsiderDetail,
    EyeOffIcon,
    EyeIcon,
    InsiderCreation,
    LocationMarkerIcon,
  },
  setup() {
    const insiders = ref();
    const usersearch = ref();
    const currentpage = ref();
    const search = ref();
    const choseninsider = ref();
    const seedetail = ref(false);
    const displayinsidercreation = ref(false);
    const wideperimeter = ref(false);

    return {
      insiders,
      usersearch,
      currentpage,
      search,
      choseninsider,
      seedetail,
      displayinsidercreation,
      wideperimeter,
    };
  },
  created() {
    this.moment = moment;
    this.currentpage = 1;
    this.getResults();
    this.prisonmode = false;
  },
  methods: {
    getResults() {
      this.$http.get(`api/insiders?page=${this.currentpage}`)
        .then((response) => {
          this.insiders = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    goNextpage() {
      this.currentpage += 1;
      this.getResults();
    },
    goPreviouspage() {
      this.currentpage -= 1;
      this.getResults();
    },
    searchInsiders() {
      this.usersearch = this.search;
      this.$http.get(`api/insiders-search/${this.search}/${this.wideperimeter}`)
        .then((response) => {
          this.insiders.data = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    resetSearch() {
      this.usersearch = null;
      this.search = null;
      this.getResults();
    },
    seeDetail(thisGuy) {
      this.seedetail = true;
      this.choseninsider = thisGuy;
    },
    hideDetail() {
      this.seedetail = false;
    },
    confirmUpdate() {
      this.seedetail = false;
      if (this.search == null) {
        this.getResults();
      } else {
        this.searchInsiders();
      }
    },
    hideInsiderCreation() {
      this.displayinsidercreation = false;
    },
    downloadInsiders() {
      const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
      this.$http.get('api/insiders_excel', {
        responseType: 'blob',
      }).then((response) => {
        window.console.log(response.data.type);
        const filename = `${currentDate}_oip_insiders`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
  },
};
</script>
