<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="displayhandlesubscription">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
            "
          >
            <div>
              <div
                class="
                  mx-auto
                  flex
                  items-center
                  justify-center
                  h-12
                  w-12
                  rounded-full
                  bg-green-100
                "
              >
                <InboxInIcon
                  class="h-6 w-6 text-green-600"
                  aria-hidden="true"
                />
              </div>
              <div
                class="mt-3 text-center sm:mt-5"
                v-if="subscription.status == 'Actif'"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  Désactiver l'abonnement
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Vous êtes sur le point de désactiver l'abonnement.
                  </p>
                </div>
              </div>
              <div
                class="mt-3 text-center sm:mt-5"
                v-if="subscription.status == 'Non actif'"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  Activer l'abonnement
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Vous êtes sur le point d'activer l'abonnement. Choississez
                    la date de début et le premier numéro de l'abonnement.
                  </p>
                </div>
                <div class="mt-4">
                  <label
                    for="date"
                    class="block text-sm font-medium text-gray-700"
                    >Date</label
                  >
                  <div class="mt-1">
                    <input
                      type="datetime-local"
                      name="date"
                      id="date"
                      v-model="activation.starting_date"
                      class="
                        shadow-sm
                        focus:ring-indigo-500 focus:border-indigo-500
                        block
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                    />
                  </div>
                </div>
                <div class="mt-2">
                  <label
                    for="email"
                    class="block text-sm font-medium text-gray-700"
                    >Premier numéro de l'abonnement</label
                  >
                  <div class="mt-1">
                    <input
                      type="issue"
                      name="issue"
                      id="issue"
                      v-model="activation.issue_number"
                      class="
                        shadow-sm
                        focus:ring-indigo-500 focus:border-indigo-500
                        block
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                    />
                  </div>
                </div>
              </div>
              <div
                class="mt-3 text-center sm:mt-5"
                v-if="subscription.status == 'NPAI signalé'"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  Le problème a t-il été réglé ?
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Le problème signalé le
                    {{
                      moment(subscription.deadletter.date).format("D MMMM YYYY")
                    }}
                    ,avec le commentaire :
                    <span class="italic"
                      >"{{ subscription.deadletter.comment }}"</span
                    >
                    ne semble pas encore avoir été réglé. Si c'est le cas, vous
                    pouvez le signaler.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="
                mt-5
                sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense
              "
            >
              <button
                v-if="subscription.status == 'Actif'"
                @click.prevent="deactivateSubscription"
                type="button"
                class="
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-transparent
                  shadow-sm
                  px-4
                  py-2
                  bg-indigo-600
                  text-base
                  font-medium
                  text-white
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                  sm:col-start-2 sm:text-sm
                "
              >
                Désactiver
              </button>
              <button
                v-if="subscription.status == 'Non actif'"
                @click.prevent="activateSubscription"
                type="button"
                class="
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-transparent
                  shadow-sm
                  px-4
                  py-2
                  bg-indigo-600
                  text-base
                  font-medium
                  text-white
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                  sm:col-start-2 sm:text-sm
                "
              >
                Activer
              </button>
              <button
                @click.prevent="solveNPAI"
                v-if="subscription.status == 'NPAI signalé'"
                type="button"
                class="
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-transparent
                  shadow-sm
                  px-4
                  py-2
                  bg-indigo-600
                  text-base
                  font-medium
                  text-white
                  hover:bg-indigo-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                  sm:col-start-2 sm:text-sm
                "
              >
                Oui, c'est réglé
              </button>
              <button
                type="button"
                class="
                  mt-3
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-gray-300
                  shadow-sm
                  px-4
                  py-2
                  bg-white
                  text-base
                  font-medium
                  text-gray-700
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                  sm:mt-0 sm:col-start-1 sm:text-sm
                "
                @click="$emit('shut')"
                ref="cancelButtonRef"
              >
                Annuler
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { InboxInIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    InboxInIcon,
  },
  setup() {
    const open = ref(true);

    return {
      open,
      activation: {
        starting_date: null,
        issue_number: null,
      },
    };
  },
  props: {
    subscription: Object,
    displayhandlesubscription: Boolean,
  },
  created() {
    this.moment = moment;
  },
  methods: {
    activateSubscription() {
      this.$http.patch('api/subscription-update', {
        id: this.subscription.id,
        active: 1,
        starting_date: this.activation.starting_date,
        issue_number: this.activation.issue_number,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('shut');
        this.activation.starting_date = null;
        this.activation.issue_number = null;
      }).catch((error) => {
        window.console.log(error);
      });
    },
    deactivateSubscription() {
      this.$http.patch('api/subscription-update', {
        id: this.subscription.id,
        active: 0,
        starting_date: this.subscription.starting_date,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('shut');
      }).catch((error) => {
        window.console.log(error);
      });
    },
    solveNPAI() {
      this.$http.patch('api/solve-deadletter', {
        id: this.subscription.deadletter.id,
        resolved: 1,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('shut');
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
