<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="signalshippingerror">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Signaler un problème de réception
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          bg-gray-900
                          rounded-md
                          text-white
                          hover:text-white hover:bg-gray-500
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Vous pouvez signaler, ici, un problème de réception pour
                      cet abonnement.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div class="absolute inset-0 py-6 px-4 sm:px-6">
                    <!-- error -->
                    <div
                      class="rounded-md bg-red-50 p-4 mb-6"
                      v-if="errored === true"
                    >
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <XCircleIcon
                            class="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-red-800">
                            Mince, votre signalement n'a pas pu être
                            enregistré...
                          </h3>
                          <div class="mt-2 text-sm text-red-700">
                            <ul role="list" class="list-disc pl-5 space-y-1">
                              <li>Vérifiez que la date est bien rentrée</li>
                              <li>
                                Ajoutez un commentaire pour donner quelques
                                précisions
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- error ends -->
                    <div>
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Date du constat</label
                      >
                      <div class="mt-1">
                        <input
                          type="datetime-local"
                          name="date"
                          v-model="date"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-12">
                      <label
                        for="comment"
                        class="block text-sm font-medium text-gray-700"
                        >Ajouter un commentaire</label
                      >
                      <div class="mt-4">
                        <textarea
                          rows="4"
                          name="comment"
                          id="comment"
                          v-model="comment"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-600
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <!-- /End replace -->
                </div>
                <div
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('shut')"
                    >
                      Annuler
                    </button>
                    <button
                      @click.prevent="signalShippingError"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Signaler !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { XCircleIcon } from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    XCircleIcon,
  },
  setup() {
    const open = ref(true);
    const date = ref();
    const comment = ref();
    const errored = ref(false);

    return {
      open,
      date,
      comment,
      errored,
    };
  },
  props: {
    subscription: Object,
    signalshippingerror: Boolean,
  },
  methods: {
    signalShippingError() {
      this.$http.post('/api/deadletter/', {
        date: this.date,
        subscription_id: this.subscription.id,
        resolved: 0,
        comment: this.comment,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('shut');
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
  },
};
</script>
