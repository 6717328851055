<template>
  <div class="min-h-full">
    <div class="bg-gray-800 pb-32">
      <AppNavbar />
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white" v-if="prison">
            <span>{{ prison.title }}</span>
          </h1>
          <h3 class="text-2xl text-white">
            <span v-if="prison">{{ prison.disp.title }}</span>
          </h3>
        </div>
      </header>
    </div>
    <main class="-mt-32">
      <InsiderCreation
        v-model:displayinsidercreation="displayinsidercreation"
        v-model:prison="prison"
        v-model:prisonmode="prisonmode"
        @insideradded="getPrison"
        @shut="hideInsiderCreation"
      />
      <InsiderDetail
        v-show="seedetail"
        v-model:seedetail="seedetail"
        v-model:insider="choseninsider"
        @shut="hideDetail"
        @insiderupdated="confirmUpdate"
      />
      <SollicitationContent
        v-model:displaycontent="displaycontent"
        v-model:sollicitation="chosensollicitation"
        @shut="hideDetailedContent"
      />
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
          <!-- tabs -->
          <div class="mb-12">
            <div class="sm:hidden">
              <label for="tabs" class="sr-only">Choississez une rubrique</label>
              <select
                @change="gotoTab(currenttab)"
                v-model="currenttab"
                id="tabs"
                name="tabs"
                class="
                  block
                  w-full
                  focus:ring-indigo-500 focus:border-indigo-500
                  border-gray-300
                  rounded-md
                "
              >
                <option
                  v-for="tab in tabs"
                  :key="tab.name"
                  :selected="tab.href"
                  :value="tab.href"
                >
                  {{ tab.name }}
                </option>
              </select>
            </div>
            <div class="hidden sm:block">
              <div class="border-b border-gray-200" v-if="prison">
                <nav class="-mb-px flex" aria-label="Tabs">
                  <a
                    v-for="tab in tabs"
                    :key="tab.name"
                    @click="gotoTab(tab.href)"
                    :class="[
                      currenttab === tab.href
                        ? 'border-blue-500 text-blue-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm cursor-pointer',
                    ]"
                    :aria-current="tab.current ? 'page' : undefined"
                  >
                    {{ tab.name }}
                    <span
                      v-if="
                        tab.name == 'Sollicitations' &&
                        prison.sollicitationscount != '0'
                      "
                      class="
                        inline-flex
                        items-center
                        px-2.5
                        py-0.5
                        rounded-full
                        text-xs
                        font-medium
                        bg-red-500
                        text-white
                        ml-2
                      "
                    >
                      {{ prison.sollicitationscount }}
                    </span>
                  </a>
                </nav>
              </div>
            </div>
          </div>
          <!-- tabs end -->
          <div v-if="currenttab === 'infos' && prison">
            <div class="sm:flex sm:items-start sm:justify-between">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Informations générales
                </h3>
                <div class="mt-2 max-w-xl text-sm text-gray-500">
                  <p>
                    Vous trouverez, ci-dessous, les dernières informations en
                    date sur cet étalissemnent.
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 border-t border-gray-200">
              <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt class="text-sm font-medium text-gray-500">
                    Adresse complète
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ prison.address }} {{ prison.postcode }} {{ prison.city }}
                  </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt class="text-sm font-medium text-gray-500">Téléphone</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    0{{ prison.phone }}
                  </dd>
                </div>
                <div
                  class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
                  v-if="prison.updated_at != null"
                >
                  <dt class="text-sm font-medium text-gray-500">
                    Mise à jour le
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ prison.updated_at }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <!-- inmates  -->
          <div v-if="currenttab === 'detenus'">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Dossiers liés
            </h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p v-if="prison.inmates.length > 0">
                Vous trouverez, ci-dessous, la liste des personnes détenues
                liées à cet établissement.
              </p>
              <p v-else>
                Aucun dossier n'est, pour le moment, lié à cet établissement.
              </p>
            </div>
            <ul
              role="list"
              class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-6"
            >
              <li
                v-for="inmate in prison.inmates"
                :key="inmate.id"
                class="
                  col-span-1
                  bg-white
                  rounded-lg
                  shadow
                  divide-y divide-gray-200
                "
              >
                <div
                  class="w-full flex items-center justify-between p-6 space-x-6"
                >
                  <div class="flex-1 truncate">
                    <div class="flex items-center space-x-3">
                      <h3 class="text-gray-900 text-sm font-medium truncate">
                        <a
                          :href="
                            /personnes-detenues/ + 'infos' + '/' + inmate.id
                          "
                        >
                          {{ inmate.firstname }} {{ inmate.lastname }}
                        </a>
                      </h3>
                      <span
                        v-if="inmate.hasfollowups == true"
                        class="
                          flex-shrink-0
                          inline-block
                          px-2
                          py-0.5
                          text-yellow-800 text-xs
                          font-medium
                          bg-yellow-100
                          rounded-full
                        "
                        >Sollicitation(s) à suivre</span
                      >
                    </div>
                    <p class="mt-1 text-gray-500 text-sm truncate">
                      Depuis le
                      {{ moment(inmate.created_at).format("D MMMM YYYY") }}
                    </p>
                    <p
                      class="mt-1 text-gray-500 text-sm truncate"
                      v-if="inmate.current_location"
                    >
                      {{ inmate.current_location.title }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <!-- inmates end -->
          <!-- sollicitations -->
          <div v-if="currenttab == 'sollicitations'">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Sollicitations pour cet établissement
            </h3>
            <p
              class="mt-1 max-w-2xl text-sm text-gray-500 mb-8"
              v-if="prison.sollicitationscount != '0'"
            >
              Vous trouverez ici la liste des sollicitations en cours sur cet
              établissement.
            </p>
            <p class="mt-1 max-w-2xl text-sm text-gray-500 mb-8" v-else>
              Aucune sollicitation n'est liée à cet établissement.
            </p>
            <div class="flex flex-col" v-if="prison.sollicitationscount != '0'">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="
                    py-2
                    align-middle
                    inline-block
                    min-w-full
                    sm:px-6
                    lg:px-8
                  "
                >
                  <div
                    class="
                      shadow
                      overflow-hidden
                      border-b border-gray-200
                      sm:rounded-lg
                    "
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Titre
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Personnes concernées
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Thème / Sous-thème
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            État
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Descriptif
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Aller voir</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            sollicitation, sollicitationIdx
                          ) in prison.sollicitations"
                          :key="sollicitation.id"
                          :class="
                            sollicitationIdx % 2 === 0
                              ? 'bg-white'
                              : 'bg-gray-50'
                          "
                        >
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm
                              font-medium
                              text-gray-900
                            "
                          >
                            {{ sollicitation.title }} <br />
                            <span class="text-gray-400">{{
                              moment(sollicitation.created_at).format(
                                "D MMMM YYYY"
                              )
                            }}</span>
                          </td>
                          <td class="px-6 py-4 text-sm text-gray-500">
                            <ul>
                              <li
                                v-for="inmate in sollicitation.inmates"
                                :key="inmate.id"
                              >
                                {{ inmate.firstname }} {{ inmate.lastname }}
                              </li>
                            </ul>
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            <span style="font-weight: bold">{{
                              sollicitation.theme_id
                            }}</span>
                            <br />
                            {{ sollicitation.subtheme_id }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            <span
                              v-if="sollicitation.is_active == 1"
                              class="
                                px-2
                                inline-flex
                                text-xs
                                leading-5
                                font-semibold
                                rounded-full
                                bg-yellow-100
                                text-yellow-800
                              "
                            >
                              En cours
                            </span>
                            <span
                              v-else-if="sollicitation.is_active == 0"
                              class="
                                px-2
                                inline-flex
                                text-xs
                                leading-5
                                font-semibold
                                rounded-full
                                bg-green-100
                                text-green-800
                              "
                            >
                              Terminée
                            </span>
                          </td>
                          <td
                            class="px-6 py-4 text-sm font-medium text-gray-500"
                          >
                            <button
                              @click.prevent="displayDetail(sollicitation)"
                              type="button"
                              class="p-1 rounded-full hover:text-white"
                            >
                              <AnnotationIcon
                                class="
                                  flex-shrink-0
                                  mr-1.5
                                  h-5
                                  w-5
                                  text-gray-400
                                "
                                aria-hidden="true"
                              />
                            </button>
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-right text-sm
                              font-medium
                            "
                          >
                            <a
                              :href="/sollicitations/ + sollicitation.id"
                              class="text-indigo-600 hover:text-indigo-900"
                              >En détails</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- sollicitations end -->
          <div v-if="currenttab === 'insiders'">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Insiders liés
            </h3>
            <div class="mt-2 sm:flex sm:items-start sm:justify-between">
              <div class="max-w-xl text-sm text-gray-500">
                <p>
                  Il s'agit de la liste des insiders pour cet établissement.
                </p>
              </div>
              <div
                class="
                  mt-5
                  sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
                "
              >
                <button
                  @click.prevent="displayInsiderCreation"
                  type="button"
                  class="
                    cursor-pointer
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-transparent
                    shadow-sm
                    font-medium
                    rounded-md
                    text-white
                    bg-red-600
                    hover:bg-red-700 hover:text-white
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-red-500
                    sm:text-sm
                  "
                >
                  Créer un insider
                </button>
              </div>
            </div>
            <div v-if="prison">
              <div class="flex flex-col mt-6" v-if="prison.insiders.length > 0">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div
                    class="
                      py-2
                      align-middle
                      inline-block
                      min-w-full
                      sm:px-6
                      lg:px-8
                    "
                  >
                    <div
                      class="
                        shadow
                        overflow-hidden
                        border-b border-gray-200
                        sm:rounded-lg
                      "
                    >
                      <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Prénom
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Nom
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Titre
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Pôle
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Sensible
                            </th>
                            <th scope="col" class="relative px-6 py-3">
                              <span class="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(insider, insiderIdx) in prison.insiders"
                            :key="insider.id"
                            :class="
                              insiderIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                            "
                          >
                            <td
                              class="
                                px-6
                                py-4
                                whitespace-nowrap
                                text-sm
                                font-medium
                                text-gray-900
                              "
                            >
                              {{ insider.firstname }}
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                whitespace-nowrap
                                text-sm text-gray-500
                              "
                            >
                              {{ insider.lastname }}
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                whitespace-nowrap
                                text-sm text-gray-500
                              "
                            >
                              {{ insider.position }}
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                whitespace-nowrap
                                text-sm text-gray-500
                              "
                            >
                              <span v-if="insider.pole">{{
                                insider.pole.label
                              }}</span>
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                text-sm
                                font-medium
                                text-gray-500
                              "
                            >
                              <EyeOffIcon
                                v-if="insider.is_critical == 1"
                                class="h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                              <EyeIcon
                                v-if="insider.is_critical == 0"
                                class="h-5 w-5 text-green-400"
                                aria-hidden="true"
                              />
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                whitespace-nowrap
                                text-right text-sm
                                font-medium
                              "
                            >
                              <a
                                @click.prevent="seeDetail(insider)"
                                class="
                                  text-indigo-600
                                  hover:text-indigo-900
                                  cursor-pointer
                                "
                                >Détails</a
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <p class="mt-2 max-w-xl text-sm text-gray-500" v-else>
                Aucun contact n'est, pour le moment, lié à cet établissement.
              </p>
            </div>
          </div>
          <div v-if="currenttab === 'avocats'">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Contacts pertinents
            </h3>
            <div class="mt-2 sm:flex sm:items-start sm:justify-between">
              <div class="max-w-xl text-sm text-gray-500">
                <p>
                  Il s'agit de la liste des contacts pertinents ayant le rôle
                  avocat pour cet établissement.
                </p>
              </div>
              <div
                class="
                  mt-5
                  sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
                "
              ></div>
            </div>
            <div v-if="prison">
              <div class="flex flex-col mt-6" v-if="prison.lawyers.length > 0">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div
                    class="
                      py-2
                      align-middle
                      inline-block
                      min-w-full
                      sm:px-6
                      lg:px-8
                    "
                  >
                    <div
                      class="
                        shadow
                        overflow-hidden
                        border-b border-gray-200
                        sm:rounded-lg
                      "
                    >
                      <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Prénom
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Nom
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Téléphone
                            </th>
                            <th
                              scope="col"
                              class="
                                px-6
                                py-3
                                text-left text-xs
                                font-medium
                                text-gray-500
                                uppercase
                                tracking-wider
                              "
                            >
                              Email
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(lawyer, lawyerIdx) in prison.lawyers"
                            :key="lawyer.id"
                            :class="
                              lawyerIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                            "
                          >
                            <td
                              class="
                                px-6
                                py-4
                                whitespace-nowrap
                                text-sm
                                font-medium
                                text-gray-900
                              "
                            >
                              {{ lawyer.firstname }}
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                whitespace-nowrap
                                text-sm text-gray-500
                              "
                            >
                              {{ lawyer.lastname }}
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                whitespace-nowrap
                                text-sm text-gray-500
                              "
                            >
                              {{ lawyer.phone }}
                            </td>
                            <td
                              class="
                                px-6
                                py-4
                                whitespace-nowrap
                                text-sm text-gray-500
                              "
                            >
                              {{ lawyer.email }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <p class="mt-2 max-w-xl text-sm text-gray-500" v-else>
                Aucun contact n'est, pour le moment, lié à cet établissement.
              </p>
            </div>
          </div>
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import AppNavbar from '@/components/AppNavbar.vue';
import InsiderCreation from '@/components/Forms/InsiderCreation.vue';
import InsiderDetail from '@/components/Modals/InsiderDetail.vue';
import { EyeOffIcon, EyeIcon } from '@heroicons/vue/outline';
import { AnnotationIcon } from '@heroicons/vue/solid';
import SollicitationContent from '@/components/Modals/SollicitationContent.vue';

const tabs = [
  { name: 'Informations et historique', href: 'infos', current: false },
  { name: 'Personnes détenues', href: 'detenus', current: false },
  { name: 'Sollicitations', href: 'sollicitations', current: false },
  { name: 'Insiders', href: 'insiders', current: false },
  { name: 'Avocats', href: 'avocats', current: false },
];
const currenttab = ref('');

export default {
  components: {
    AppNavbar,
    InsiderCreation,
    InsiderDetail,
    EyeOffIcon,
    EyeIcon,
    AnnotationIcon,
    SollicitationContent,
  },
  setup() {
    const prison = ref();
    const displayinsidercreation = ref(false);
    const seedetail = ref(false);
    const chosensollicitation = ref();
    const displaycontent = ref(false);

    return {
      tabs,
      currenttab,
      prison,
      displayinsidercreation,
      seedetail,
      chosensollicitation,
      displaycontent,
    };
  },
  created() {
    this.moment = moment;
    this.currenttab = this.$route.params.tab;
    this.getPrison();
    this.prisonmode = true;
  },
  methods: {
    gotoTab(tabname) {
      this.$router.push({ name: 'Prison', params: { tab: tabname, id: this.$route.params.id } });
      this.currenttab = tabname;
    },
    getPrison() {
      const { id } = this.$route.params;
      this.$http.get(`/api/prisons/${id}/`)
        .then((response) => {
          this.prison = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    displayInsiderCreation() {
      this.displayinsidercreation = true;
    },
    hideInsiderCreation() {
      this.displayinsidercreation = false;
    },
    seeDetail(thisGuy) {
      this.seedetail = true;
      this.choseninsider = thisGuy;
    },
    hideDetail() {
      this.seedetail = false;
    },
    confirmUpdate() {
      this.seedetail = false;
      this.getPrison();
    },
    displayDetail(detailedSollicitation) {
      this.chosensollicitation = detailedSollicitation;
      this.displaycontent = true;
    },
    hideDetailedContent() {
      this.chosensollicitation = null;
      this.displaycontent = false;
    },
  },
};
</script>
