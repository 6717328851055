<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="seedetail">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      {{ insider.firstname }} {{ insider.lastname }}
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Voici toutes les informations de ce point d'entrée ou
                      indicateur.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <!-- Visualize -->
                  <div v-if="editionmode == false">
                    <div class="absolute inset-0 py-6 px-4 sm:px-6">
                      <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                        <!-- Alert if is_critical -->
                        <div
                          class="
                            bg-yellow-50
                            border-l-4 border-yellow-400
                            p-4
                            mb-4
                          "
                          v-if="insider.is_critical == 1"
                        >
                          <div class="flex">
                            <div class="flex-shrink-0">
                              <ExclamationIcon
                                class="h-5 w-5 text-yellow-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div class="ml-3">
                              <p class="text-sm text-yellow-700">
                                Attention, ce contact est
                                <span class="font-bold">critique</span>. Avant
                                de l'utiliser, vérifiez, avec le reste de
                                l'équipe, qu'il est opportun de le contacter.
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- Alert ends -->
                        <dl class="space-y-8 px-4 sm:px-6 sm:space-y-6">
                          <div v-if="insider.position">
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Titre ou poste
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                            >
                              {{ insider.position }}
                            </dd>
                          </div>
                          <div v-if="insider.pole">
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Pôle
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                            >
                              {{ insider.pole.label }}
                            </dd>
                          </div>
                          <div v-if="insider.prison">
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Prison
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                            >
                              {{ insider.prison.title }}
                            </dd>
                          </div>
                          <div v-if="insider.phone">
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Téléphone
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                            >
                              {{ insider.phone }}
                            </dd>
                          </div>
                          <div v-if="insider.fax">
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Fax
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                            >
                              {{ insider.fax }}
                            </dd>
                          </div>
                          <div v-if="insider.email">
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Email
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                            >
                              <a :href="'mailto:' + insider.email">{{
                                insider.email
                              }}</a>
                            </dd>
                          </div>
                          <div v-if="insider.wide_perimeter">
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Périmètre plus large ?
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                            >
                              <span v-if="insider.wide_perimeter == true"
                                >Oui</span
                              >
                              <span v-else>Non</span>
                            </dd>
                          </div>
                          <div v-if="insider.comment">
                            <dt
                              class="
                                text-sm
                                font-medium
                                text-gray-500
                                sm:w-40 sm:flex-shrink-0
                              "
                            >
                              Commentaire
                            </dt>
                            <dd
                              class="mt-1 text-sm text-gray-900 sm:col-span-2"
                            >
                              {{ insider.comment }}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <!-- Visualize ends -->
                  <!-- Edit -->
                  <div v-if="editionmode == true">
                    <h4
                      class="font-bold text-xl text-gray-900 sm:text-2xl mb-2"
                    >
                      Modifier cet insider
                    </h4>
                    <label class="block text-sm font-medium text-gray-700 mb-2"
                      >Vous pouvez éditer ce contact en modifiant les valeurs
                      des champs ci-dessous.</label
                    >
                    <div>
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Prénom</label
                      >
                      <div class="mt-1">
                        <input
                          type="firstname"
                          name="firstname"
                          id="firstname"
                          v-model="edition.firstname"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-1">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Nom</label
                      >
                      <div class="mt-1">
                        <input
                          type="lastname"
                          name="lastname"
                          id="lastname"
                          v-model="edition.lastname"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-1">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Poste ou titre</label
                      >
                      <div class="mt-1">
                        <input
                          type="position"
                          name="position"
                          id="position"
                          v-model="edition.position"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-1">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Email</label
                      >
                      <div class="mt-1">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          v-model="edition.email"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                          placeholder="you@example.com"
                        />
                      </div>
                    </div>
                    <div class="mt-1">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Téléphone</label
                      >
                      <div class="mt-1">
                        <input
                          type="phone"
                          name="phone"
                          id="phone"
                          v-model="edition.phone"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-1">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Fax</label
                      >
                      <div class="mt-1">
                        <input
                          type="fax"
                          name="fax"
                          id="fax"
                          v-model="edition.fax"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-1">
                      <label
                        for="pole"
                        class="block text-sm font-medium text-gray-700"
                        >Pôle</label
                      >
                      <select
                        id="pole"
                        name="pole"
                        v-model="edition.pole_id"
                        class="
                          mt-1
                          block
                          w-full
                          pl-3
                          pr-10
                          py-2
                          text-base
                          border-gray-300
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                          rounded-md
                        "
                      >
                        <option
                          v-for="pole in poles"
                          :key="pole.id"
                          :value="pole.id"
                        >
                          {{ pole.label }}
                        </option>
                      </select>
                    </div>
                    <div class="mt-1">
                      <label
                        for="pole"
                        class="block text-sm font-medium text-gray-700"
                        >Prison</label
                      >
                      <select
                        id="prison"
                        name="prison"
                        v-model="edition.prison_id"
                        class="
                          mt-1
                          block
                          w-full
                          pl-3
                          pr-10
                          py-2
                          text-base
                          border-gray-300
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                          rounded-md
                        "
                      >
                        <option
                          v-for="prison in prisons"
                          :key="prison.id"
                          :value="prison.id"
                        >
                          {{ prison.title }}
                        </option>
                      </select>
                    </div>
                    <div class="mt-1">
                      <label
                        for="comment"
                        class="block text-sm font-medium text-gray-700"
                        >Commentaire</label
                      >
                      <div class="mt-1">
                        <textarea
                          rows="4"
                          name="comment"
                          id="comment"
                          v-model="edition.comment"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <SwitchGroup as="div" class="flex items-center mt-6">
                      <Switch
                        v-model="critical"
                        :class="[
                          critical ? 'bg-indigo-600' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        ]"
                      >
                        <span
                          aria-hidden="true"
                          :class="[
                            critical ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                          ]"
                        />
                      </Switch>
                      <SwitchLabel as="span" class="ml-3">
                        <span
                          class="text-sm font-medium text-gray-900"
                          v-if="critical == false"
                          >Ce contact n'est pas critique
                        </span>
                        <span
                          class="text-sm font-medium text-gray-900"
                          v-if="critical == true"
                          >Ce contact est critique
                        </span>
                      </SwitchLabel>
                    </SwitchGroup>
                    <SwitchGroup as="div" class="flex items-center mt-6">
                      <Switch
                        v-model="perimeter"
                        :class="[
                          critical ? 'bg-indigo-600' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        ]"
                      >
                        <span
                          aria-hidden="true"
                          :class="[
                            perimeter ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                          ]"
                        />
                      </Switch>
                      <SwitchLabel as="span" class="ml-3">
                        <span
                          class="text-sm font-medium text-gray-900"
                          v-if="perimeter == false"
                          >Cet insider n'a pas de périmètre étendu
                        </span>
                        <span
                          class="text-sm font-medium text-gray-900"
                          v-if="perimeter == true"
                          >Cet insider a un périmètre plus large que
                          l'établissement auquel il est rattaché
                        </span>
                      </SwitchLabel>
                    </SwitchGroup>
                  </div>
                  <!-- Edit ends -->
                  <!-- /End replace -->
                </div>
                <!-- Action buttons -->
                <div
                  v-if="editionmode == false"
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('shut')"
                    >
                      Fermer
                    </button>
                    <button
                      @click.prevent="enterEditionMode"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Éditer cet insider
                    </button>
                  </div>
                </div>
                <div
                  v-if="editionmode == true"
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="closePanel"
                    >
                      Ne rien changer
                    </button>
                    <button
                      @click.prevent="updateInsider"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Enregister les changements
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, Switch, SwitchGroup, SwitchLabel,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { ExclamationIcon } from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    ExclamationIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  setup() {
    const open = ref(true);
    const editionmode = ref(false);
    const poles = ref();
    const prisons = ref();
    const critical = ref();
    const perimeter = ref();

    return {
      open,
      editionmode,
      poles,
      prisons,
      critical,
      perimeter,
      edition: {
        firstname: null,
        lastname: null,
        position: null,
        phone: null,
        fax: null,
        pole_id: null,
        prison_id: null,
        comment: null,
        email: null,
      },
    };
  },
  props: {
    insider: Object,
    seedetail: Boolean,
  },
  created() {
    this.getPoles();
    this.getPrisons();
  },
  methods: {
    enterEditionMode() {
      this.editionmode = true;
      this.setData();
    },
    setData() {
      this.edition.firstname = this.insider.firstname;
      this.edition.lastname = this.insider.lastname;
      this.edition.position = this.insider.position;
      this.edition.email = this.insider.email;
      this.edition.phone = this.insider.phone;
      this.edition.fax = this.insider.fax;
      this.edition.comment = this.insider.comment;
      this.edition.prison_id = this.insider.prison_id;
      this.edition.pole_id = this.insider.pole_id;
      this.critical = this.insider.is_critical;
      this.perimeter = this.insider.wide_perimeter;
    },
    closePanel() {
      this.editionmode = false;
      this.$emit('shut');
    },
    getPoles() {
      this.$http.get('/api/insiders-poles/')
        .then((response) => {
          this.poles = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getPrisons() {
      this.$http.get('/api/prisons/')
        .then((response) => {
          this.prisons = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    updateInsider() {
      this.$http.patch('api/insider-update', {
        id: this.insider.id,
        firstname: this.edition.firstname,
        lastname: this.edition.lastname,
        email: this.edition.email,
        phone: this.edition.phone,
        fax: this.edition.fax,
        position: this.edition.position,
        comment: this.edition.comment,
        pole_id: this.edition.pole_id,
        prison_id: this.edition.prison_id,
        is_critical: this.critical,
        wide_perimeter: this.perimeter,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('insiderupdated');
        this.editionmode = false;
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
