<template>
  <TransitionRoot as="template" :show="showinmates">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="p-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      Personne(s) concernée(s)
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          bg-white
                          rounded-md
                          text-gray-400
                          hover:text-gray-500
                          focus:ring-2 focus:ring-indigo-500
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <ul
                  role="list"
                  class="flex-1 divide-y divide-gray-200 overflow-y-auto"
                >
                  <li v-for="inmate in inmates" :key="inmate.id">
                    <div class="relative group py-6 px-5 flex items-center">
                      <a
                        :href="/personnes-detenues/ + 'infos' + '/' + inmate.id"
                        class="-m-1 flex-1 block p-1"
                      >
                        <div
                          class="absolute inset-0 group-hover:bg-gray-50"
                          aria-hidden="true"
                        />
                        <div class="flex-1 flex items-center min-w-0 relative">
                          <div class="ml-4 truncate">
                            <p
                              class="text-sm font-medium text-gray-900 truncate"
                            >
                              {{ inmate.firstname }} {{ inmate.lastname }}
                            </p>
                            <p class="text-sm text-gray-500 truncate"></p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const open = ref(true);

    return {
      open,
    };
  },
  props: {
    inmates: Object,
    showinmates: Boolean,
  },
};
</script>
