<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="displaynotifications">
    <Dialog
      as="div"
      class="fixed inset-0 overflow-hidden"
      @click="$emit('shut')"
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Qu'avez-vous manqué ?
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Voici quelques événements que vous auriez pu manquer.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div v-if="unseenfollowups" class="mb-6">
                    <h3
                      class="text-lg leading-6 font-medium text-gray-900"
                      v-if="unseenfollowups.length !== 0"
                    >
                      Suivis
                    </h3>
                    <div class="flow-root mt-6">
                      <ul role="list" class="-my-5 divide-y divide-gray-200">
                        <li
                          v-for="followup in unseenfollowups"
                          :key="followup.id"
                          class="py-4"
                        >
                          <div class="flex items-center space-x-4">
                            <div class="flex-1 min-w-0">
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-900
                                  truncate
                                "
                              >
                                {{ followup.user.name }} vous attribué la
                                sollicitation <br />
                                <span v-if="followup.sollicitation"
                                  >"{{ followup.sollicitation.title }}"</span
                                >
                              </p>
                              <p class="text-sm text-gray-500 truncate">
                                {{
                                  moment(followup.created_at).format(
                                    "dddd DD MMMM YYYY"
                                  )
                                }}
                              </p>
                              <p class="text-sm text-gray-500 truncate">
                                {{ followup.comment }}
                              </p>
                            </div>
                            <div>
                              <a
                                @click.prevent="markasSeen(followup)"
                                class="
                                  inline-flex
                                  items-center
                                  shadow-sm
                                  px-2.5
                                  py-0.5
                                  border border-gray-300
                                  text-sm
                                  leading-5
                                  font-medium
                                  rounded-full
                                  text-gray-700
                                  bg-white
                                  hover:bg-gray-50
                                  cursor-pointer
                                "
                              >
                                Voir
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- Actions  -->
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Actions du jour
                  </h3>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500 mb-6">
                    Voici vos actions du jour.
                  </p>
                  <div>
                    <ul
                      role="list"
                      class="
                        mt-3
                        grid grid-cols-1
                        gap-5
                        sm:gap-6 sm:grid-cols-2
                        lg:grid-cols-1
                      "
                    >
                      <!-- refacto li  -->
                      <li
                        v-for="action in actions"
                        :key="action.id"
                        class="relative col-span-1 flex shadow-sm rounded-md"
                      >
                        <div
                          :class="[
                            action.color,
                            'flex-shrink-0 flex items-center justify-center w-20 text-center text-white text-sm font-medium rounded-l-md',
                          ]"
                        >
                          {{ action.type }}
                        </div>
                        <div
                          class="
                            flex-1 flex
                            items-center
                            justify-between
                            border-t border-r border-b border-gray-200
                            bg-white
                            rounded-r-md
                            truncate
                          "
                        >
                          <div class="flex-1 px-4 py-2 text-sm truncate">
                            <a
                              :href="/sollicitations/ + action.sollicitation.id"
                              class="
                                text-gray-900
                                font-medium
                                hover:text-gray-600
                              "
                              >{{ action.sollicitation.title }}</a
                            >
                            <p class="text-gray-500 truncate text-ellipsis">
                              {{ action.prison.title }}
                            </p>
                            <p class="text-gray-500">
                              {{ moment(action.date).format("DD MMM Y") }}
                              <span
                                v-if="action.ontime == false"
                                class="
                                  ml-2
                                  inline-flex
                                  items-center
                                  px-2.5
                                  py-0.5
                                  rounded-full
                                  text-xs
                                  font-medium
                                  bg-red-100
                                  text-red-800
                                "
                              >
                                En retard
                              </span>
                            </p>
                          </div>
                        </div>
                      </li>
                      <!-- refacto eli -->
                    </ul>
                  </div>
                  <!-- Actions ends -->
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const open = ref(true);
    const actions = ref();

    return {
      open,
      actions,
    };
  },
  props: {
    displaynotifications: Boolean,
    unseenfollowups: Array,
    currentuserid: Number,
  },
  created() {
    this.moment = moment;
  },
  mounted() {
    if (this.currentuser !== null) {
      this.getMyTodayTodo();
    }
  },
  methods: {
    markasSeen(selectedNotif) {
      this.$http.patch('api/followup-seen', {
        id: selectedNotif.id,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('shut');
        this.$router.push({ name: 'Sollicitation', params: { id: selectedNotif.sollicitation.id } });
      }).catch((error) => {
        window.console.log(error);
      });
    },
    getMyTodayTodo() {
      this.$http.get(`/api/today-todo/${this.currentuserid}`)
        .then((response) => {
          this.actions = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
  },
};
</script>
