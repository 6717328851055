<template>
  <TransitionRoot as="template" :show="sendingconfirmation">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-900 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Confirmer l'envoi ?
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          bg-gray-900
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Si l'envoi a été fait, vous pouvez l'enregistrer afin que
                      cette ligne disparaisse du tableau de bord et de votre
                      todolist...
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div class="absolute inset-0 py-6 px-4 sm:px-6">
                    <!-- error -->
                    <div
                      class="rounded-md bg-red-50 p-4 mb-6"
                      v-if="errored === true"
                    >
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <XCircleIcon
                            class="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-red-800">
                            Mince, votre envoi n'a pas pu être enregistré...
                            Avez-vous bien rentré la date ?
                          </h3>
                          <div class="mt-2 text-sm text-red-700"></div>
                        </div>
                      </div>
                    </div>
                    <!-- error ends -->
                    <div>
                      <label
                        for="date"
                        class="block text-sm font-medium text-gray-700"
                        >Date de l'envoi</label
                      >
                      <div class="mt-1">
                        <input
                          type="datetime-local"
                          name="date"
                          id="date"
                          v-model="form.date"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <!-- sending content -->
                    <div class="mt-6">
                      <label
                        for="date"
                        class="block text-sm font-medium text-gray-700"
                        >Contenu de l'envoi</label
                      >
                      <ul role="list" class="divide-y divide-gray-200">
                        <li
                          v-for="issue in sending.issues"
                          :key="issue.id"
                          class="
                            relative
                            bg-white
                            py-5
                            px-4
                            hover:bg-gray-50
                            focus-within:ring-2
                            focus-within:ring-inset
                            focus-within:ring-indigo-600
                          "
                        >
                          <div class="flex justify-between space-x-3">
                            <div class="min-w-0 flex-1">
                              <span
                                class="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-900
                                  truncate
                                "
                              >
                                Dedans Dehors n° {{ issue.issue_number }}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li
                          v-if="sending.is_pplcp == true"
                          class="
                            relative
                            bg-white
                            py-5
                            px-4
                            hover:bg-gray-50
                            focus-within:ring-2
                            focus-within:ring-inset
                            focus-within:ring-indigo-600
                          "
                        >
                          <div class="flex justify-between space-x-3">
                            <div class="min-w-0 flex-1">
                              <span
                                class="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-900
                                  truncate
                                "
                              >
                                Passé Par la Case Prison
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <!-- sending content ends -->
                    <div class="mt-6">
                      <label
                        for="date"
                        class="block text-sm font-medium text-gray-700"
                        >Coordonnées dans la prison</label
                      >
                      <ul role="list" class="divide-y divide-gray-200">
                        <li
                          class="
                            relative
                            bg-white
                            py-5
                            px-4
                            hover:bg-gray-50
                            focus-within:ring-2
                            focus-within:ring-inset
                            focus-within:ring-indigo-600
                          "
                        >
                          <div class="flex justify-between space-x-3">
                            <div class="min-w-0 flex-1">
                              <span
                                class="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-900
                                  truncate
                                "
                              >
                                Numéro de cellule : {{ sending.cell_number }}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li
                          class="
                            relative
                            bg-white
                            py-5
                            px-4
                            hover:bg-gray-50
                            focus-within:ring-2
                            focus-within:ring-inset
                            focus-within:ring-indigo-600
                          "
                        >
                          <div class="flex justify-between space-x-3">
                            <div class="min-w-0 flex-1">
                              <span
                                class="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-900
                                  truncate
                                "
                              >
                                Numéro de bâtiment : {{ sending.bat_number }}
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- /End replace -->
                </div>
                <!-- Action buttons -->
                <div
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('shut')"
                    >
                      Annuler
                    </button>
                    <button
                      @click.prevent="confirmSending"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      C'est envoyé !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { XCircleIcon } from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    XCircleIcon,
  },
  setup() {
    const open = ref(true);
    const errored = ref(false);

    return {
      open,
      errored,
      form: {
        date: null,
      },
    };
  },
  props: {
    sendingconfirmation: Boolean,
    sending: Object,
  },
  created() {
    this.moment = moment;
    const proposeddate = moment(new Date()).format('YYYY-MM-DDTHH:mm');
    this.form.date = proposeddate;
  },
  methods: {
    confirmSending() {
      this.$http.patch('api/confirm-sending', {
        id: this.sending.id,
        is_done: 1,
        date: this.form.date,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('sent');
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
  },
};
</script>
