<template>
  <TransitionRoot as="template" :show="displayfilepanel">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="px-4 py-6 sm:px-6">
                  <div class="flex items-start justify-between">
                    <h2
                      id="slide-over-heading"
                      class="text-lg font-medium text-gray-900"
                    >
                      Fichier / Document
                    </h2>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          bg-white
                          rounded-md
                          text-gray-400
                          hover:text-gray-500
                          focus:ring-2 focus:ring-indigo-500
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Main -->
                <div>
                  <div class="pb-1 sm:pb-6">
                    <div>
                      <div
                        class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6"
                      >
                        <div class="sm:flex-1">
                          <div>
                            <div class="flex items-center">
                              <h3
                                class="
                                  font-bold
                                  text-xl text-gray-900
                                  sm:text-2xl
                                "
                              >
                                {{ file.title }}
                              </h3>
                            </div>
                            <p class="text-sm text-gray-500">
                              Créé le
                              {{
                                moment(file.created_at).format(
                                  "dddd DD MMMM YYYY"
                                )
                              }}
                            </p>
                          </div>
                          <div
                            class="
                              mt-5
                              flex flex-wrap
                              space-y-3
                              sm:space-y-0 sm:space-x-3
                            "
                          >
                            <button
                              @click="deleteFile"
                              type="button"
                              class="
                                flex-1
                                w-full
                                inline-flex
                                items-center
                                justify-center
                                px-4
                                py-2
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                text-sm
                                font-medium
                                text-gray-700
                                bg-white
                                hover:bg-gray-50
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-indigo-500
                              "
                            >
                              Supprimer
                            </button>

                            <button
                              @click="downloadFileDocument"
                              type="button"
                              class="
                                flex-shrink-0
                                w-full
                                inline-flex
                                items-center
                                justify-center
                                px-4
                                py-2
                                border border-transparent
                                rounded-md
                                shadow-sm
                                text-sm
                                font-medium
                                text-white
                                bg-blue-600
                                hover:bg-blue-700
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-blue-500
                                sm:flex-1
                              "
                            >
                              Télécharger
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                    <dl class="space-y-8 px-4 sm:px-6 sm:space-y-6">
                      <div>
                        <dt
                          class="
                            text-sm
                            font-medium
                            text-gray-500
                            sm:w-40 sm:flex-shrink-0
                          "
                        >
                          Type du fichier
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                          <span
                            class="
                              px-2
                              inline-flex
                              text-xs
                              leading-5
                              font-semibold
                              rounded-full
                              bg-blue-100
                              text-blue-800
                            "
                          >
                            {{ file.type_id }}
                          </span>
                        </dd>
                      </div>
                      <div v-if="file.comment !== 'null'">
                        <dt
                          class="
                            text-sm
                            font-medium
                            text-gray-500
                            sm:w-40 sm:flex-shrink-0
                          "
                        >
                          Commentaire
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                          <p>
                            {{ file.comment }}
                          </p>
                        </dd>
                      </div>
                      <!-- display attached inmates -->
                      <div>
                        <h4
                          class="
                            font-bold
                            text-xl text-gray-900
                            sm:text-2xl
                            mb-2
                          "
                        >
                          Contacts identifiés
                        </h4>
                        <label
                          for="email"
                          class="block text-sm font-medium text-gray-700"
                          >Vous trouverez, ci-dessous, la liste des personnes
                          identifiées</label
                        >
                        <div>
                          <div class="flow-root mt-6">
                            <ul
                              role="list"
                              class="-my-5 divide-y divide-gray-200"
                              v-if="file"
                            >
                              <li
                                v-for="inmate in file.inmates"
                                :key="inmate.id"
                                class="py-4"
                              >
                                <div class="flex items-center space-x-4">
                                  <div class="flex-1 min-w-0">
                                    <p
                                      class="
                                        text-sm
                                        font-medium
                                        text-gray-900
                                        truncate
                                      "
                                    >
                                      {{ inmate.firstname }}
                                      {{ inmate.lastname }}
                                    </p>
                                  </div>
                                  <div>
                                    <button
                                      @click="removeInmateFile(inmate.id)"
                                      class="
                                        inline-flex
                                        items-center
                                        shadow-sm
                                        px-2.5
                                        py-0.5
                                        border border-gray-300
                                        text-sm
                                        leading-5
                                        font-medium
                                        rounded-full
                                        text-gray-700
                                        bg-white
                                        hover:bg-gray-50
                                      "
                                    >
                                      Retirer
                                    </button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div class="rounded-md bg-blue-50 p-4" v-else>
                              <div class="flex">
                                <div class="flex-shrink-0">
                                  <InformationCircleIcon
                                    class="h-5 w-5 text-blue-400"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div
                                  class="ml-3 flex-1 md:flex md:justify-between"
                                >
                                  <p class="text-sm text-blue-700">
                                    Aucun contact n'a été identifié comme étant
                                    à l'origine de cette sollicitation.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- attached inmates ends -->
                      <!-- attach inmate -->
                      <h4
                        class="
                          font-bold
                          text-xl text-gray-900
                          sm:text-2xl
                          mb-2
                          mt-6
                        "
                      >
                        Ajouter d'autres personnes
                      </h4>
                      <div
                        class="
                          border border-gray-300
                          rounded-md
                          px-3
                          py-2
                          shadow-sm
                          focus-within:ring-1
                          focus-within:ring-indigo-600
                          focus-within:border-indigo-600
                        "
                      >
                        <label
                          for="name"
                          class="block text-xs font-medium text-gray-900"
                          >Rechercher par nom</label
                        >
                        <input
                          @input="getInmates"
                          v-model="search"
                          type="text"
                          name="search"
                          id="search"
                          class="
                            block
                            w-full
                            border-0
                            p-0
                            text-gray-900
                            placeholder-gray-500
                            focus:ring-0
                            sm:text-sm
                          "
                          placeholder="Jane Doe"
                        />
                      </div>
                      <ul
                        role="list"
                        class="-my-5 divide-y divide-gray-200 mt-6"
                        v-if="search"
                      >
                        <li
                          v-for="inmate in matchingInmates"
                          :key="inmate.id"
                          class="py-4"
                        >
                          <div class="flex items-center space-x-4">
                            <div class="flex-1 min-w-0">
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-900
                                  truncate
                                "
                              >
                                {{ inmate.firstname }} {{ inmate.lastname }}
                              </p>
                            </div>
                            <div>
                              <a
                                @click.prevent="linkInmate(inmate.id)"
                                class="
                                  inline-flex
                                  items-center
                                  shadow-sm
                                  px-2.5
                                  py-0.5
                                  text-sm
                                  leading-5
                                  font-medium
                                  rounded-full
                                  text-white
                                  bg-green-500
                                  hover:bg-green-700
                                  cursor-pointer
                                "
                              >
                                Ajouter
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <p class="text-sm text-gray-500" v-else>
                        Tapez quelques lettres du nom ou prénom pour pouvoir
                        afficher puis lier ce fichier à une personne détenue.
                      </p>
                      <!-- attach inmate ends -->
                      <h4
                        v-if="file.sollicitations.length !== 0"
                        class="
                          font-bold
                          text-xl text-gray-900
                          sm:text-2xl
                          mb-2
                          mt-6
                        "
                      >
                        Sollicitations liées
                      </h4>
                      <ul
                        role="list"
                        class="-my-5 divide-y divide-gray-200"
                        v-if="file.sollicitations.length > 0"
                      >
                        <li
                          v-for="sollicitation in file.sollicitations"
                          :key="sollicitation.id"
                          class="py-4"
                        >
                          <div class="flex items-center space-x-4">
                            <div class="flex-1 min-w-0">
                              <p
                                class="
                                  text-sm
                                  font-medium
                                  text-gray-900
                                  truncate
                                "
                              >
                                {{ sollicitation.title }}
                              </p>
                            </div>
                            <div>
                              <a :href="/sollicitations/ + sollicitation.id">
                                <button
                                  class="
                                    inline-flex
                                    items-center
                                    shadow-sm
                                    px-2.5
                                    py-0.5
                                    border border-gray-300
                                    text-sm
                                    leading-5
                                    font-medium
                                    rounded-full
                                    text-gray-700
                                    bg-white
                                    hover:bg-gray-50
                                  "
                                >
                                  Voir
                                </button>
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, computed } from 'vue';
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import moment from 'moment';
import 'moment/locale/fr';

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const inmates = ref();
    const search = ref('');
    const searchquery = computed(() => search.value.toLowerCase());
    const matchingInmates = computed(() => inmates.value.filter((inmate) => inmate.firstname.toLowerCase().includes(searchquery.value) || inmate.lastname.toLowerCase().includes(searchquery.value)));

    return {
      search,
      searchquery,
      matchingInmates,
      inmates,
    };
  },
  props: {
    file: Object,
    displayfilepanel: Boolean,
  },
  created() {
    this.moment = moment;
  },
  methods: {
    deleteFile() {
      this.$http.patch('api/file-delete', {
        id: this.file.id,
      }).then((response) => {
        this.success = response.data;
        this.$emit('filetrashed');
        this.$emit('shut');
      })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getInmates() {
      this.$http.get(`/api/file-candidates/${this.file.id}/`)
        .then((response) => {
          this.inmates = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    removeInmateFile(inmateId) {
      this.$http.patch('api/inmate-file-remove', {
        id: this.file.id,
        inmate_id: inmateId,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('shut');
      }).catch((error) => {
        window.console.log(error);
      });
    },
    linkInmate(inmateId) {
      this.$http.patch('api/inmate-file', {
        id: this.file.id,
        inmate_id: inmateId,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('shut');
        this.getInmates();
      }).catch((error) => {
        window.console.log(error);
      });
    },
    downloadFileDocument() {
      this.$http.get(`api/file-output/${this.file.id}`, {
        responseType: 'blob',
      }).then((response) => {
        window.console.log(response.data.type);
        const filename = this.file.title;
        const filenameCleaned = filename.replace(' ', '_');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const extensions = {
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
          'application/pdf': 'pdf',
          'message/rfc822': 'eml',
          'image/gif': 'gif',
          'text/calendar': 'ics',
          'image/jpeg': 'jpg',
          'application/vnd.oasis.opendocument.text': 'odt',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
          'application/rtf': 'rtf',
          'application/vnd.ms-powerpoint': 'ppt',
          'application/msword': 'doc',
          'image/png': 'png',
        };
        const contentType = response.data.type;
        const extension = extensions[contentType];
        link.href = url;
        link.setAttribute('download', `${filenameCleaned}.${extension}`);
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
  },
};
</script>
