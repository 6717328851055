<template>
  <AppNavbar />
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';

export default ({
  setup() {
  },
  components: {
    AppNavbar,
  },
});
</script>
