<!-- eslint-disable max-len -->
<template>
  <div class="min-h-full">
    <div class="bg-gray-800 pb-32">
      <AppNavbar />
    </div>

    <main class="-mt-20">
      <InmateCreation v-model:display="display" @shut="closeInmateCreation" />
      <ChoiceModal
        title="Que voulez-vous faire ?"
        description="Vous pouvez soit aller voir la sollicitation en question soit effacer ce suivi si vous l'avez effectué."
        doText="Voir la sollicitation"
        undoText="Effacer ce suivi"
        v-show="showable"
        v-model:showable="showable"
        v-model:followup="chosenfollowup"
        @close="closeFollowupAction"
      />
      <ShowInmates
        v-model:showinmates="showinmates"
        v-model:inmates="selectedactioninmates"
        @shut="hideInmates"
      />
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <!-- demo code -->
        <div class="min-h-full">
          <main class="-mt-24 pb-8">
            <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              <h1 class="sr-only">Profile</h1>
              <!-- Main 3 column grid -->
              <div
                class="
                  grid grid-cols-1
                  gap-4
                  items-start
                  lg:grid-cols-3 lg:gap-8
                "
              >
                <!-- Left column -->
                <div class="grid grid-cols-1 gap-4 lg:col-span-2">
                  <!-- Welcome panel -->
                  <section aria-labelledby="profile-overview-title">
                    <div class="rounded-lg bg-white overflow-hidden shadow">
                      <h2 class="sr-only" id="profile-overview-title">
                        Profile Overview
                      </h2>
                      <div class="bg-white p-6">
                        <div class="sm:flex sm:items-center sm:justify-between">
                          <div class="sm:flex sm:space-x-5">
                            <!-- <div class="flex-shrink-0">
                              <img
                                class="mx-auto h-20 w-20 rounded-full"
                                :src="user.imageUrl"
                                alt=""
                              />
                            </div> -->
                            <div
                              class="
                                mt-4
                                text-center
                                sm:mt-0 sm:pt-1 sm:text-left
                              "
                            >
                              <p class="text-sm font-medium text-gray-600">
                                Bienvenue,
                              </p>
                              <p
                                class="
                                  text-xl
                                  font-bold
                                  text-gray-900
                                  sm:text-2xl
                                "
                              >
                                {{ currentuser.name }} {{ currentuser.surname }}
                              </p>
                              <p class="text-sm font-medium text-gray-600">
                                {{ myposition }}
                              </p>
                            </div>
                          </div>
                          <div class="mt-5 flex justify-center sm:mt-0">
                            <a
                              href="/creer-une-sollicitation/"
                              class="
                                flex
                                justify-center
                                items-center
                                px-4
                                py-2
                                shadow-sm
                                text-sm
                                font-medium
                                rounded-md
                                text-white
                                bg-red-500
                                hover:bg-red-600
                              "
                            >
                              Créer une sollicitation
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          border-t border-gray-200
                          bg-gray-50
                          grid grid-cols-1
                          divide-y divide-gray-200
                          sm:grid-cols-3 sm:divide-y-0 sm:divide-x
                        "
                      >
                        <div class="px-6 py-5 text-sm font-medium text-center">
                          <span class="text-gray-600" v-if="myactions"
                            >{{ myactions.length }} actions à mener</span
                          >
                        </div>
                        <div class="px-6 py-5 text-sm font-medium text-center">
                          <span class="text-gray-900">{{
                            sollicitationscount
                          }}</span>
                          <span class="text-gray-600">
                            sollicitations en cours</span
                          >
                        </div>
                        <div class="px-6 py-5 text-sm font-medium text-center">
                          <span class="text-gray-900">{{ dispscount }}</span>
                          <span class="text-gray-600"> DISP</span>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- criticals -->
                  <div
                    class="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-6"
                    v-if="criticalsollicitations"
                  >
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Sollicitations urgentes
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500 mb-6">
                      Vous trouverez ici les sollicitations urgentes.
                    </p>
                    <div
                      class="bg-white shadow overflow-hidden sm:rounded-md"
                      v-if="criticalsollicitations.length > 0"
                    >
                      <div class="flex flex-col">
                        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div
                            class="
                              py-2
                              align-middle
                              inline-block
                              min-w-full
                              sm:px-6
                              lg:px-8
                            "
                          >
                            <div
                              class="
                                shadow
                                overflow-hidden
                                border-b border-gray-200
                                sm:rounded-lg
                              "
                            >
                              <table
                                class="min-w-full divide-y divide-gray-200"
                              >
                                <thead class="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      class="
                                        px-6
                                        py-3
                                        text-left text-xs
                                        font-medium
                                        text-gray-500
                                        uppercase
                                        tracking-wider
                                      "
                                    >
                                      Titre & Établissement
                                    </th>
                                    <th
                                      scope="col"
                                      class="
                                        px-6
                                        py-3
                                        text-left text-xs
                                        font-medium
                                        text-gray-500
                                        uppercase
                                        tracking-wider
                                      "
                                    >
                                      Thème & sous-thème
                                    </th>
                                    <th
                                      scope="col"
                                      class="
                                        px-6
                                        py-3
                                        text-left text-xs
                                        font-medium
                                        text-gray-500
                                        uppercase
                                        tracking-wider
                                      "
                                    >
                                      Détails
                                    </th>
                                    <th scope="col" class="relative px-6 py-3">
                                      <span class="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(
                                      sollicitation, sollicitationIdx
                                    ) in criticalsollicitations"
                                    :key="sollicitation.id"
                                    :class="
                                      sollicitationIdx % 2 === 0
                                        ? 'bg-white'
                                        : 'bg-gray-50'
                                    "
                                  >
                                    <td
                                      class="
                                        px-6
                                        py-4
                                        text-sm
                                        font-medium
                                        text-gray-900
                                      "
                                    >
                                      {{ sollicitation.title }} <br />
                                      {{ sollicitation.prison_id }}
                                    </td>
                                    <td class="px-6 py-4 text-sm text-gray-500">
                                      {{ sollicitation.theme.title }} <br />
                                      <span v-if="sollicitation.subtheme">{{
                                        sollicitation.subtheme.title
                                      }}</span>
                                    </td>
                                    <td
                                      class="
                                        px-6
                                        py-4
                                        text-right text-sm
                                        font-medium
                                      "
                                    >
                                      <a
                                        :href="
                                          /sollicitations/ + sollicitation.id
                                        "
                                        class="
                                          text-indigo-600
                                          hover:text-indigo-900
                                        "
                                        >Voir</a
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- criticals ends -->
                  <!-- on going -->
                  <div
                    class="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-6"
                    v-if="ongoingsollicitations"
                  >
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Sollicitations en cours
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500 mb-6">
                      Vous trouverez ici les sollicitations en cours.
                    </p>
                    <div
                      class="bg-white shadow overflow-hidden sm:rounded-md"
                      v-if="ongoingsollicitations.length > 0"
                    >
                      <div class="flex flex-col">
                        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div
                            class="
                              py-2
                              align-middle
                              inline-block
                              min-w-full
                              sm:px-6
                              lg:px-8
                            "
                          >
                            <div
                              class="
                                shadow
                                overflow-hidden
                                border-b border-gray-200
                                sm:rounded-lg
                              "
                            >
                              <table
                                class="min-w-full divide-y divide-gray-200"
                              >
                                <thead class="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      class="
                                        px-6
                                        py-3
                                        text-left text-xs
                                        font-medium
                                        text-gray-500
                                        uppercase
                                        tracking-wider
                                      "
                                    >
                                      Titre & Établissement
                                    </th>
                                    <th
                                      scope="col"
                                      class="
                                        px-6
                                        py-3
                                        text-left text-xs
                                        font-medium
                                        text-gray-500
                                        uppercase
                                        tracking-wider
                                      "
                                    >
                                      Thème & sous-thème
                                    </th>
                                    <th
                                      scope="col"
                                      class="
                                        px-6
                                        py-3
                                        text-left text-xs
                                        font-medium
                                        text-gray-500
                                        uppercase
                                        tracking-wider
                                      "
                                    >
                                      Détails
                                    </th>
                                    <th scope="col" class="relative px-6 py-3">
                                      <span class="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(
                                      sollicitation, sollicitationIdx
                                    ) in ongoingsollicitations"
                                    :key="sollicitation.id"
                                    :class="
                                      sollicitationIdx % 2 === 0
                                        ? 'bg-white'
                                        : 'bg-gray-50'
                                    "
                                  >
                                    <td
                                      class="
                                        px-6
                                        py-4
                                        text-sm
                                        font-medium
                                        text-gray-900
                                      "
                                    >
                                      {{ sollicitation.title }} <br />
                                      {{ sollicitation.prison_id }}
                                    </td>
                                    <td class="px-6 py-4 text-sm text-gray-500">
                                      {{ sollicitation.theme.title }} <br />
                                      <span v-if="sollicitation.subtheme">{{
                                        sollicitation.subtheme.title
                                      }}</span>
                                    </td>
                                    <td
                                      class="
                                        px-6
                                        py-4
                                        text-right text-sm
                                        font-medium
                                      "
                                    >
                                      <a
                                        :href="
                                          /sollicitations/ + sollicitation.id
                                        "
                                        class="
                                          text-indigo-600
                                          hover:text-indigo-900
                                        "
                                        >Voir</a
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ongoing ends -->
                  <!-- actions -->
                  <div
                    class="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-6"
                    v-if="myactions"
                  >
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Actions à mener
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500 mb-6">
                      Vous trouverez ici l'ensemble des actions à mener sur les
                      sollicitations des DISPS auxquelles vous êtes lié.e.
                    </p>
                    <div>
                      <ul
                        role="list"
                        class="
                          mt-3
                          grid grid-cols-1
                          gap-5
                          sm:gap-6 sm:grid-cols-2
                          lg:grid-cols-2
                        "
                      >
                        <!-- refacto li  -->
                        <li
                          v-for="action in myactions"
                          :key="action.id"
                          class="relative col-span-1 flex shadow-sm rounded-md"
                        >
                          <div
                            :class="[
                              action.color,
                              'flex-shrink-0 flex items-center justify-center w-20 text-center text-white text-sm font-medium rounded-l-md',
                            ]"
                          >
                            {{ action.type }}
                          </div>
                          <div
                            class="
                              flex-1 flex
                              items-center
                              justify-between
                              border-t border-r border-b border-gray-200
                              bg-white
                              rounded-r-md
                              truncate
                            "
                          >
                            <div class="flex-1 px-4 py-2 text-sm truncate">
                              <a
                                :href="
                                  /sollicitations/ + action.sollicitation.id
                                "
                                class="
                                  text-gray-900
                                  font-medium
                                  hover:text-gray-600
                                "
                                v-if="action.sollicitation"
                                >{{ action.sollicitation.title }}</a
                              >
                              <p
                                class="text-gray-500 truncate text-ellipsis"
                                v-if="action.prison"
                              >
                                {{ action.prison.title }}
                              </p>
                              <p class="text-gray-500">
                                {{ moment(action.date).format("DD MMM Y") }}
                                <span
                                  v-if="action.ontime == false"
                                  class="
                                    ml-2
                                    inline-flex
                                    items-center
                                    px-2.5
                                    py-0.5
                                    rounded-full
                                    text-xs
                                    font-medium
                                    bg-red-100
                                    text-red-800
                                  "
                                >
                                  En retard
                                </span>
                              </p>
                            </div>
                            <Menu as="div" class="flex-shrink-0 pr-2">
                              <MenuButton
                                class="
                                  w-8
                                  h-8
                                  bg-white
                                  inline-flex
                                  items-center
                                  justify-center
                                  text-gray-400
                                  rounded-full
                                  hover:text-gray-500
                                  focus:outline-none
                                  focus:ring-2
                                  focus:ring-offset-2
                                  focus:ring-purple-500
                                "
                              >
                                <span class="sr-only">Open options</span>
                                <DotsVerticalIcon
                                  class="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </MenuButton>
                              <transition
                                enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                              >
                                <MenuItems
                                  class="
                                    z-10
                                    mx-3
                                    origin-top-right
                                    absolute
                                    right-10
                                    top-3
                                    w-48
                                    mt-1
                                    rounded-md
                                    shadow-lg
                                    bg-white
                                    ring-1 ring-black ring-opacity-5
                                    divide-y divide-gray-200
                                    focus:outline-none
                                  "
                                >
                                  <div class="py-1">
                                    <MenuItem v-slot="{ active }">
                                      <a
                                        :href="
                                          /sollicitations/ +
                                          action.sollicitation.id
                                        "
                                        :class="[
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm',
                                        ]"
                                        >Voir la sollicitation</a
                                      >
                                    </MenuItem>
                                  </div>
                                  <div class="py-1">
                                    <MenuItem v-slot="{ active }">
                                      <a
                                        @click.prevent="
                                          showInmates(action.inmates)
                                        "
                                        :class="[
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm',
                                        ]"
                                      >
                                        Voir les personnes <br />
                                        concernées
                                      </a>
                                    </MenuItem>
                                  </div>
                                </MenuItems>
                              </transition>
                            </Menu>
                          </div>
                        </li>
                        <!-- refacto eli -->
                      </ul>
                    </div>
                  </div>
                  <!-- actions ends  -->
                  <!-- sollicitations -->
                  <div
                    class="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-6"
                  >
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Sollicitations à suivre
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500 mb-6">
                      Vous trouverez ici l'ensemble de vos suivis, soit que vous
                      avez généré pour vous soit qui vous ont été attribués par
                      d'autres membres de l'équipe.
                    </p>
                    <div class="bg-white shadow overflow-hidden sm:rounded-md">
                      <ul role="list" class="divide-y divide-gray-200">
                        <li v-for="followup in followups" :key="followup.id">
                          <a
                            @click.prevent="chooseFollowupAction(followup)"
                            class="block hover:bg-gray-50 cursor-pointer"
                          >
                            <div class="px-4 py-4 sm:px-6">
                              <div class="flex items-center justify-between">
                                <p
                                  class="
                                    text-sm
                                    font-medium
                                    text-indigo-600
                                    truncate
                                  "
                                >
                                  {{ followup.sollicitation.title }} (n°
                                  {{ followup.sollicitation.id }})
                                </p>
                                <div class="ml-2 flex-shrink-0 flex">
                                  <p
                                    v-if="followup.status == 'A faire'"
                                    class="
                                      px-2
                                      inline-flex
                                      text-xs
                                      leading-5
                                      font-semibold
                                      rounded-full
                                      bg-green-100
                                      text-green-800
                                    "
                                  >
                                    {{ followup.status }}
                                  </p>
                                  <p
                                    v-if="followup.status == 'En retard'"
                                    class="
                                      px-2
                                      inline-flex
                                      text-xs
                                      leading-5
                                      font-semibold
                                      rounded-full
                                      bg-yellow-100
                                      text-yellow-800
                                    "
                                  >
                                    {{ followup.status }}
                                  </p>
                                </div>
                              </div>
                              <div class="mt-2 sm:flex sm:justify-between">
                                <div>
                                  <div>
                                    <p
                                      v-if="followup.comment"
                                      class="
                                        mt-3
                                        flex
                                        items-center
                                        text-sm text-gray-500
                                      "
                                    >
                                      <LocationMarkerIcon
                                        class="
                                          flex-shrink-0
                                          mr-1.5
                                          h-5
                                          w-5
                                          text-gray-400
                                        "
                                        aria-hidden="true"
                                      />
                                      {{ followup.prison }}
                                    </p>
                                  </div>
                                  <div>
                                    <p
                                      v-if="followup.comment"
                                      class="
                                        mt-3
                                        flex
                                        items-center
                                        text-sm text-gray-500
                                      "
                                    >
                                      <AnnotationIcon
                                        class="
                                          flex-shrink-0
                                          mr-1.5
                                          h-5
                                          w-5
                                          text-gray-400
                                        "
                                        aria-hidden="true"
                                      />
                                      "{{ followup.comment }}"
                                    </p>
                                  </div>
                                </div>
                                <div
                                  class="
                                    mt-2
                                    flex
                                    items-center
                                    text-sm text-gray-500
                                    sm:mt-0
                                  "
                                >
                                  <CalendarIcon
                                    class="
                                      flex-shrink-0
                                      mr-1.5
                                      h-5
                                      w-5
                                      text-gray-400
                                    "
                                    aria-hidden="true"
                                  />
                                  <p>
                                    <time>
                                      {{
                                        moment(followup.date).format(
                                          "DD MMM YYYY"
                                        )
                                      }}</time
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- sollicitations ends -->
                </div>

                <!-- Right column -->
                <div class="grid grid-cols-1 gap-4">
                  <!-- Announcements -->
                  <section aria-labelledby="announcements-title">
                    <div class="rounded-lg bg-white overflow-hidden shadow">
                      <div class="p-6">
                        <h2
                          class="text-base font-medium text-gray-900"
                          id="recent-hires-title"
                        >
                          Rubriques
                        </h2>
                        <div
                          v-for="(action, actionIdx) in actions"
                          :key="action.title"
                          :class="[
                            actionIdx === 0
                              ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                              : '',
                            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                            actionIdx === actions.length - 2
                              ? 'sm:rounded-bl-lg'
                              : '',
                            actionIdx === actions.length - 1
                              ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                              : '',
                            'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                          ]"
                        >
                          <div>
                            <span
                              :class="[
                                action.iconBackground,
                                action.iconForeground,
                                'rounded-lg inline-flex p-3 ring-4 ring-white',
                              ]"
                            >
                              <component
                                :is="action.icon"
                                class="h-6 w-6"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <div class="mt-2">
                            <h3 class="text-lg font-medium">
                              <a
                                @click="showInmateCreation"
                                class="focus:outline-none"
                                style="cursor: pointer"
                                v-if="action.title === 'Créez un dossier'"
                              >
                                <!-- Extend touch target to entire panel -->
                                <span
                                  class="absolute inset-0"
                                  aria-hidden="true"
                                />
                                {{ action.title }}
                              </a>
                              <a
                                :href="action.href"
                                class="focus:outline-none"
                                v-else
                              >
                                <!-- Extend touch target to entire panel -->
                                <span
                                  class="absolute inset-0"
                                  aria-hidden="true"
                                />
                                {{ action.title }}
                              </a>
                            </h3>
                            <p class="mt-2 text-sm text-gray-500">
                              {{ action.description }}
                            </p>
                          </div>
                          <span
                            class="
                              pointer-events-none
                              absolute
                              top-6
                              right-6
                              text-gray-300
                              group-hover:text-gray-400
                            "
                            aria-hidden="true"
                          >
                            <svg
                              class="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <!-- Last inmates -->
                  <section aria-labelledby="recent-hires-title">
                    <div class="rounded-lg bg-white overflow-hidden shadow">
                      <div class="p-6">
                        <h2
                          class="text-base font-medium text-gray-900"
                          id="recent-hires-title"
                        >
                          Derniers dossiers
                        </h2>
                        <div class="flow-root mt-6">
                          <ul
                            role="list"
                            class="-my-5 divide-y divide-gray-200"
                          >
                            <li
                              v-for="inmate in lastinmates"
                              :key="inmate.id"
                              class="py-4"
                            >
                              <div class="flex items-center space-x-4">
                                <div class="flex-1 min-w-0">
                                  <p
                                    class="
                                      text-sm
                                      font-medium
                                      text-gray-900
                                      truncate
                                    "
                                  >
                                    {{ inmate.firstname }} {{ inmate.lastname }}
                                  </p>
                                  <p class="text-sm text-gray-500 truncate">
                                    {{
                                      moment(inmate.created_at).format(
                                        "DD MMM Y"
                                      )
                                    }}
                                  </p>
                                </div>
                                <div>
                                  <a
                                    :href="
                                      /personnes-detenues/ +
                                      'infos' +
                                      '/' +
                                      inmate.id
                                    "
                                    class="
                                      inline-flex
                                      items-center
                                      shadow-sm
                                      px-2.5
                                      py-0.5
                                      border border-gray-300
                                      text-sm
                                      leading-5
                                      font-medium
                                      rounded-full
                                      text-gray-700
                                      bg-white
                                      hover:bg-gray-50
                                    "
                                  >
                                    en détails
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="mt-6">
                          <a
                            href="/personnes-detenues/"
                            class="
                              w-full
                              flex
                              justify-center
                              items-center
                              px-4
                              py-2
                              border border-gray-300
                              shadow-sm
                              text-sm
                              font-medium
                              rounded-md
                              text-gray-700
                              bg-white
                              hover:bg-gray-50
                            "
                          >
                            Voir toute la liste
                          </a>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </main>
          <footer>
            <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
              <div
                class="
                  border-t border-gray-200
                  py-8
                  text-sm text-gray-500 text-center
                  sm:text-left
                "
              >
                <span class="block sm:inline"
                  >&copy; Observatoire International des Prisons</span
                >
              </div>
            </div>
          </footer>
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import {
  DocumentSearchIcon,
  UserAddIcon,
  // ChartPieIcon,
  // ClockIcon,

  BellIcon,
  MenuIcon,
  XIcon,
} from '@heroicons/vue/outline';
import {
  CalendarIcon, LocationMarkerIcon, UsersIcon, UserIcon, AnnotationIcon,
  DotsVerticalIcon,
  SearchIcon,
} from '@heroicons/vue/solid';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import InmateCreation from '@/components/Forms/InmateCreation.vue';
import ChoiceModal from '@/components/Modals/ChoiceModal.vue';
import AppNavbar from '@/components/AppNavbar.vue';
import ShowInmates from '@/components/Modals/ShowInmates.vue';

const display = ref(false);
const currentuser = ref();
const followups = ref();
const showable = ref(false);
const sollicitationscount = ref();
const showinmates = ref(false);
const myactions = ref();
const selectedactioninmates = ref();
const dispscount = ref();
const ongoingsollicitations = ref();
const criticalsollicitations = ref();
const actions = [
  {
    title: 'Créez un dossier',
    description: 'Créez un dossier pour une personne détenue, afin de receuillir toutes les informations nécessaires.',
    icon: UserAddIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Consultez les dossiers les personnes détenues',
    href: '/personnes-detenues/',
    description: 'Accédez au répertoire des personnes détenues, afin d\'accéder à leur fiche.',
    icon: UsersIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
  },
  {
    title: 'Fichiers & mediathèque',
    href: '/mediatheque/',
    description: 'Ajoutez et consultez les fichiers liés aux personnes détenues.',
    icon: DocumentSearchIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
];

export default {
  components: {
    AppNavbar,
    InmateCreation,
    ChoiceModal,
    CalendarIcon,
    LocationMarkerIcon,
    UsersIcon,
    UserIcon,
    AnnotationIcon,
    DotsVerticalIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverOverlay,
    PopoverPanel,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuIcon,
    SearchIcon,
    XIcon,
    ShowInmates,
  },
  setup() {
    return {
      dispscount,
      currentuser,
      followups,
      actions,
      display,
      showable,
      sollicitationscount,
      myactions,
      showinmates,
      selectedactioninmates,
      criticalsollicitations,
      ongoingsollicitations,
    };
  },
  created() {
    if (localStorage.user) {
      this.currentuser = JSON.parse(localStorage.user);
    }
    this.moment = moment;
  },
  watch: {
    user(newUser) {
      localStorage.user = newUser;
    },
  },
  mounted() {
    this.getFollowups();
    this.getTodolist();
    this.getLastinmates();
    this.getMyActions();
  },
  methods: {
    getFollowups() {
      this.$http.get(`/api/user-followups/${this.currentuser.id}`)
        .then((response) => {
          this.followups = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getTodolist() {
      this.$http.get(`/api/myprofile/${this.currentuser.id}`)
        .then((response) => {
          this.sollicitationscount = response.data.sollicitationscount;
          this.dispscount = response.data.dispscount;
          this.mydisps = response.data.disps;
          this.myposition = response.data.myposition;
          this.criticalsollicitations = response.data.criticalsollicitations;
          this.ongoingsollicitations = response.data.ongoingsollicitations;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getMyActions() {
      this.$http.get(`/api/actionstodo/${this.currentuser.id}`)
        .then((response) => {
          this.myactions = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    getLastinmates() {
      this.$http.get('/api/lastinmates/')
        .then((response) => {
          this.lastinmates = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    showInmateCreation() {
      this.display = true;
    },
    closeInmateCreation() {
      this.display = false;
    },
    chooseFollowupAction(chosenFollowup) {
      this.showable = true;
      this.chosenfollowup = chosenFollowup;
    },
    closeFollowupAction() {
      this.showable = false;
      this.getFollowups();
    },
    showInmates(InmateList) {
      this.showinmates = true;
      this.selectedactioninmates = InmateList;
    },
    hideInmates() {
      this.showinmates = false;
    },
  },
};
</script>
