<template>
  <TransitionRoot as="template" :show="displayactioncreation">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <form
                class="
                  h-full
                  divide-y divide-gray-200
                  flex flex-col
                  bg-white
                  shadow-xl
                "
              >
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-gray-800 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        Créer une action
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          class="
                            bg-gray-800
                            rounded-md
                            text-white
                            hover:text-white
                            focus:outline-none focus:ring-2 focus:ring-white
                          "
                          @click="$emit('shut')"
                        >
                          <span class="sr-only">Fermer</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-white">
                        Vous pouvez créer une action à partir de ce menu. Les
                        champs à renseigner vont dépendre du type d'action
                        choisi.
                      </p>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <fieldset v-if="chosenaction == null">
                          <legend class="text-gray-900">Action à mener</legend>
                          <div class="mt-6 space-y-5">
                            <div v-for="action in actions" :key="action.slug">
                              <div class="relative flex items-start">
                                <div class="absolute flex items-center h-5">
                                  <input
                                    @change="determineAction(action.slug)"
                                    id="chosenaction"
                                    name="action"
                                    aria-describedby="privacy-private-to-project-description"
                                    type="radio"
                                    class="
                                      focus:ring-indigo-500
                                      h-4
                                      w-4
                                      text-indigo-600
                                      border-gray-300
                                    "
                                  />
                                </div>
                                <div class="pl-7 text-sm">
                                  <label
                                    for="privacy-private"
                                    class="font-medium text-gray-900"
                                  >
                                    {{ action.title }}
                                  </label>
                                  <p
                                    id="privacy-private-description"
                                    class="text-gray-500"
                                  >
                                    {{ action.description }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div v-if="chosenaction === 'reponse'">
                          <legend class="text-gray-900 mb-6">
                            Réponse immédiate
                          </legend>
                          <div>
                            <label
                              for="question"
                              class="block text-sm font-medium text-gray-900"
                            >
                              Question posée
                            </label>
                            <div class="mt-1">
                              <textarea
                                v-model="answer.question"
                                id="description"
                                name="description"
                                rows="4"
                                class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500 focus:border-indigo-500
                                  border border-gray-300
                                  rounded-md
                                "
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="description"
                              class="
                                block
                                text-sm
                                font-medium
                                text-gray-900
                                mt-6
                              "
                            >
                              Réponse donnée
                            </label>
                            <div class="mt-1">
                              <textarea
                                v-model="answer.answer"
                                id="answer"
                                name="answer"
                                rows="4"
                                class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500 focus:border-indigo-500
                                  border border-gray-300
                                  rounded-md
                                "
                              />
                            </div>
                          </div>
                        </div>
                        <div v-if="chosenaction === 'mailanswer'">
                          <legend class="text-gray-900 mb-6">
                            Réponse par courrier
                          </legend>
                          <div>
                            <label
                              for="description"
                              class="
                                block
                                text-sm
                                font-medium
                                text-gray-900
                                mt-6
                              "
                            >
                              Commentaire
                            </label>
                            <div class="mt-1">
                              <textarea
                                v-model="mailanswer.comment"
                                id="answer"
                                name="answer"
                                rows="4"
                                class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500 focus:border-indigo-500
                                  border border-gray-300
                                  rounded-md
                                "
                              />
                            </div>
                          </div>
                          <!-- prisons -->
                          <label
                            for="description"
                            class="block text-sm font-medium text-gray-900 mt-6"
                          >
                            Établissement concerné
                          </label>
                          <div
                            v-if="!chosenprison"
                            class="
                              border border-gray-300
                              rounded-md
                              px-3
                              py-2
                              shadow-sm
                              focus-within:ring-1
                              focus-within:ring-indigo-600
                              focus-within:border-indigo-600
                              mt-2
                            "
                          >
                            <label
                              for="name"
                              class="block text-xs font-medium text-gray-900"
                              >Recherchez par nom d'établissement</label
                            >
                            <input
                              v-model="search"
                              type="text"
                              name="search"
                              id="search"
                              class="
                                block
                                w-full
                                border-0
                                p-0
                                text-gray-900
                                placeholder-gray-500
                                focus:ring-0
                                sm:text-sm
                              "
                              placeholder="ex : Marseille"
                            />
                          </div>
                          <ul
                            role="list"
                            class="-my-5 divide-y divide-gray-200 mt-6"
                            v-if="search && !chosenprison"
                          >
                            <li
                              v-for="prison in matchingPrisons"
                              :key="prison.id"
                              class="py-4"
                            >
                              <div class="flex items-center space-x-4">
                                <div class="flex-1 min-w-0">
                                  <p
                                    class="
                                      text-sm
                                      font-medium
                                      text-gray-900
                                      truncate
                                    "
                                  >
                                    {{ prison.title }}
                                  </p>
                                </div>
                                <div>
                                  <a
                                    @click.prevent="choosePrison(prison)"
                                    class="
                                      inline-flex
                                      items-center
                                      shadow-sm
                                      px-2.5
                                      py-0.5
                                      text-sm
                                      leading-5
                                      font-medium
                                      rounded-full
                                      text-white
                                      bg-green-500
                                      hover:bg-green-700
                                      cursor-pointer
                                    "
                                  >
                                    Choisir
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <p
                            class="text-sm text-gray-500 mt-4"
                            v-if="!search && !chosenprison"
                          >
                            Tapez quelques lettres du nom de l'établissement
                            pour choisir l'établissement destinataire du
                            courrier.
                          </p>
                          <div v-if="chosenprison">
                            <span
                              class="
                                inline-flex
                                rounded-full
                                items-center
                                py-0.5
                                pl-2.5
                                pr-1
                                text-sm
                                font-medium
                                bg-indigo-100
                                text-indigo-700
                                mt-2
                              "
                            >
                              {{ chosenprison.title }}
                              <button
                                @click.prevent="chosenprison = null"
                                type="button"
                                class="
                                  flex-shrink-0
                                  ml-0.5
                                  h-4
                                  w-4
                                  rounded-full
                                  inline-flex
                                  items-center
                                  justify-center
                                  text-indigo-400
                                  hover:bg-indigo-200 hover:text-indigo-500
                                  focus:outline-none
                                  focus:bg-indigo-500
                                  focus:text-white
                                "
                              >
                                <!-- <span class="sr-only">Remove large option</span> -->
                                <svg
                                  class="h-2 w-2"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 8 8"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-width="1.5"
                                    d="M1 1l6 6m0-6L1 7"
                                  />
                                </svg>
                              </button>
                            </span>
                          </div>
                        </div>
                        <!-- prisons end -->
                        <div v-if="chosenaction === 'rappel'">
                          <legend class="text-gray-900 mb-6">
                            Planifier un rappel
                          </legend>
                          <div>
                            <label
                              for="callback"
                              class="block text-sm font-medium text-gray-900"
                            >
                              Nom complet du contact à rappeler
                            </label>
                            <div class="mt-1">
                              <input
                                type="text"
                                v-model="callback.contact_fullname"
                                id="description"
                                name="contact"
                                rows="4"
                                class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500 focus:border-indigo-500
                                  border border-gray-300
                                  rounded-md
                                "
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="phone"
                              class="
                                block
                                text-sm
                                font-medium
                                text-gray-900
                                mt-6
                              "
                            >
                              Numéro de téléphone
                            </label>
                            <div class="mt-1">
                              <input
                                type="text"
                                v-model="callback.phone_number"
                                id="phone"
                                name="phone"
                                rows="4"
                                class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500 focus:border-indigo-500
                                  border border-gray-300
                                  rounded-md
                                "
                              />
                            </div>
                          </div>
                        </div>
                        <div v-if="chosenaction === 'tiers'">
                          <legend class="text-gray-900 mb-6">
                            Contacter un tiers
                          </legend>
                          <div>
                            <label
                              for="callback"
                              class="block text-sm font-medium text-gray-900"
                            >
                              Nom complet du contact à rappeler
                            </label>
                            <div class="mt-1">
                              <input
                                type="text"
                                v-model="thirdpart.contact"
                                id="contact"
                                name="contact"
                                rows="4"
                                class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500 focus:border-indigo-500
                                  border border-gray-300
                                  rounded-md
                                "
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="phone"
                              class="
                                block
                                text-sm
                                font-medium
                                text-gray-900
                                mt-6
                              "
                            >
                              Commentaire
                            </label>
                            <div class="mt-1">
                              <textarea
                                type="text"
                                v-model="thirdpart.comment"
                                id="phone"
                                name="phone"
                                rows="4"
                                class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500 focus:border-indigo-500
                                  border border-gray-300
                                  rounded-md
                                "
                              />
                            </div>
                          </div>
                        </div>
                        <div v-if="chosenaction === 'communique'">
                          <legend class="text-gray-900 mb-6">
                            Communiqué de presse
                          </legend>
                          <div>
                            <label
                              for="company-website"
                              class="block text-sm font-medium text-gray-700"
                            >
                              URL du communiqué
                            </label>
                            <div class="mt-1 flex rounded-md shadow-sm">
                              <span
                                class="
                                  inline-flex
                                  items-center
                                  px-3
                                  rounded-l-md
                                  border border-r-0 border-gray-300
                                  bg-gray-50
                                  text-gray-500
                                  sm:text-sm
                                "
                              >
                                https://
                              </span>
                              <input
                                type="text"
                                name="press-url"
                                id="press-url"
                                v-model="pressrelease.url"
                                class="
                                  flex-1
                                  min-w-0
                                  block
                                  w-full
                                  px-3
                                  py-2
                                  rounded-none rounded-r-md
                                  focus:ring-indigo-500 focus:border-indigo-500
                                  sm:text-sm
                                  border-gray-300
                                "
                                placeholder="www.oip.org"
                              />
                            </div>
                          </div>
                        </div>
                        <div v-if="chosenaction === 'email'">
                          <legend class="text-gray-900 mb-6">
                            Envoyer un email
                          </legend>
                          <div>
                            <label
                              for="company-website"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Email du contact
                            </label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                              <div
                                class="
                                  absolute
                                  inset-y-0
                                  left-0
                                  pl-3
                                  flex
                                  items-center
                                  pointer-events-none
                                "
                              >
                                <MailIcon
                                  class="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <input
                                type="email"
                                v-model="mail.recipient"
                                name="email"
                                id="email"
                                class="
                                  focus:ring-indigo-500 focus:border-indigo-500
                                  block
                                  w-full
                                  pl-10
                                  sm:text-sm
                                  border-gray-300
                                  rounded-md
                                "
                                placeholder="john@acme.com"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="comment"
                              class="
                                block
                                text-sm
                                font-medium
                                text-gray-900
                                mt-6
                              "
                            >
                              Commentaire
                            </label>
                            <div class="mt-1">
                              <textarea
                                v-model="mail.comment"
                                id="comment"
                                name="comment"
                                rows="4"
                                class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500 focus:border-indigo-500
                                  border border-gray-300
                                  rounded-md
                                "
                              />
                            </div>
                          </div>
                        </div>
                        <div v-if="chosenaction === 'saisine'">
                          <legend class="text-gray-900 mb-6">
                            Effectuer une saisine
                          </legend>
                          <div>
                            <label
                              for="callback"
                              class="block text-sm font-medium text-gray-900"
                            >
                              Institution saisie
                            </label>
                            <div class="mt-1">
                              <select
                                id="institution"
                                name="institution"
                                v-model="referral.institution_id"
                                class="
                                  mt-1
                                  block
                                  w-full
                                  pl-3
                                  pr-10
                                  py-2
                                  text-base
                                  border-gray-300
                                  focus:outline-none
                                  focus:ring-indigo-500
                                  focus:border-indigo-500
                                  sm:text-sm
                                  rounded-md
                                "
                              >
                                <option
                                  v-for="institution in institutions"
                                  :key="institution.id"
                                  :value="institution.id"
                                >
                                  {{ institution.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="mt-4">
                            <label
                              for="callback"
                              class="block text-sm font-medium text-gray-900"
                            >
                              Réponses attendues
                            </label>
                            <SwitchGroup
                              as="div"
                              class="flex items-center mt-4"
                            >
                              <Switch
                                v-model="institutionanswer"
                                :class="[
                                  institutionanswer
                                    ? 'bg-indigo-600'
                                    : 'bg-gray-200',
                                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                                ]"
                              >
                                <span
                                  aria-hidden="true"
                                  :class="[
                                    institutionanswer
                                      ? 'translate-x-5'
                                      : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                  ]"
                                />
                              </Switch>
                              <SwitchLabel as="span" class="ml-3">
                                <span
                                  class="text-sm font-medium text-gray-900"
                                  v-if="institutionanswer == false"
                                  >L'institution n'a pas répondu
                                </span>
                                <span
                                  class="text-sm font-medium text-gray-900"
                                  v-else
                                  >L'institution a répondu
                                </span>
                              </SwitchLabel>
                            </SwitchGroup>
                            <SwitchGroup
                              as="div"
                              class="flex items-center mt-4"
                            >
                              <Switch
                                v-model="inmateanswer"
                                :class="[
                                  inmateanswer
                                    ? 'bg-indigo-600'
                                    : 'bg-gray-200',
                                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                                ]"
                              >
                                <span
                                  aria-hidden="true"
                                  :class="[
                                    inmateanswer
                                      ? 'translate-x-5'
                                      : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                  ]"
                                />
                              </Switch>
                              <SwitchLabel as="span" class="ml-3">
                                <span
                                  class="text-sm font-medium text-gray-900"
                                  v-if="inmateanswer == false"
                                  >La personne détenue n'a pas répondu
                                </span>
                                <span
                                  class="text-sm font-medium text-gray-900"
                                  v-else
                                  >La personne détenue a répondu
                                </span>
                              </SwitchLabel>
                            </SwitchGroup>
                          </div>
                        </div>
                        <!-- action status switch -->
                        <SwitchGroup
                          as="div"
                          class="flex items-center justify-between"
                          v-if="chosenaction"
                        >
                          <span class="flex-grow flex flex-col">
                            <SwitchLabel
                              as="span"
                              class="text-sm font-medium text-gray-900"
                              passive
                              ><span v-if="enabled == true"
                                >L'action a déjà été effectuée</span
                              ><span v-else
                                >L'action est à programmer</span
                              ></SwitchLabel
                            >
                            <SwitchDescription
                              as="span"
                              class="text-sm text-gray-500"
                              ><span v-if="enabled == true"
                                >L'action a déjà été réalisée, nul besoin de
                                mettre un rappel.</span
                              ><span v-else
                                >L'action n'a pas encore été réalisée et
                                apparaîtra dans votre liste des actions à
                                mener.</span
                              ></SwitchDescription
                            >
                          </span>
                          <Switch
                            v-model="enabled"
                            :class="[
                              enabled ? 'bg-indigo-600' : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                            ]"
                          >
                            <span
                              aria-hidden="true"
                              :class="[
                                enabled ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                              ]"
                            />
                          </Switch>
                        </SwitchGroup>
                        <!-- action status switch ends -->
                        <!-- date completion -->
                        <div v-if="chosenaction">
                          <label
                            for="date"
                            class="block text-sm font-medium text-gray-900 mt-6"
                          >
                            <span v-if="enabled == true"
                              >Date à laquelle l'action a été effectuée</span
                            ><span v-else>Date de rappel</span>
                          </label>
                          <div class="mt-1">
                            <input
                              type="datetime-local"
                              v-model="chosendate"
                              id="date"
                              name="date"
                              rows="4"
                              class="
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                border border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                        </div>
                        <!-- date completion ends -->
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="flex-shrink-0 px-4 py-4 flex justify-end"
                  v-if="chosenaction == 'reponse'"
                >
                  <button
                    type="button"
                    class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                    @click="chosenaction = null"
                  >
                    Revenir au choix
                  </button>
                  <button
                    @click.prevent="createAnswer"
                    type="submit"
                    class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-red-500
                    "
                  >
                    Créer cette action
                  </button>
                </div>
                <div
                  class="flex-shrink-0 px-4 py-4 flex justify-end"
                  v-if="chosenaction == 'mailanswer'"
                >
                  <button
                    type="button"
                    class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                    @click="chosenaction = null"
                  >
                    Revenir au choix
                  </button>
                  <button
                    @click.prevent="createMailAnswer"
                    type="submit"
                    class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-red-500
                    "
                  >
                    Créer cette réponse
                  </button>
                </div>
                <div
                  class="flex-shrink-0 px-4 py-4 flex justify-end"
                  v-if="chosenaction == 'rappel'"
                >
                  <button
                    type="button"
                    class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                    @click="chosenaction = null"
                  >
                    Revenir au choix
                  </button>
                  <button
                    @click.prevent="createCallback"
                    type="submit"
                    class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-red-500
                    "
                  >
                    Planifier ce rappel
                  </button>
                </div>
                <div
                  class="flex-shrink-0 px-4 py-4 flex justify-end"
                  v-if="chosenaction == 'tiers'"
                >
                  <button
                    type="button"
                    class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                    @click="chosenaction = null"
                  >
                    Revenir au choix
                  </button>
                  <button
                    @click.prevent="createThirdpart"
                    type="submit"
                    class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-red-500
                    "
                  >
                    Planifier ce contact
                  </button>
                </div>
                <div
                  class="flex-shrink-0 px-4 py-4 flex justify-end"
                  v-if="chosenaction == 'communique'"
                >
                  <button
                    type="button"
                    class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                    @click="chosenaction = null"
                  >
                    Revenir au choix
                  </button>
                  <button
                    @click.prevent="createPressrelease"
                    type="submit"
                    class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-red-500
                    "
                  >
                    Enregistrer ce communiqué
                  </button>
                </div>
                <div
                  class="flex-shrink-0 px-4 py-4 flex justify-end"
                  v-if="chosenaction == 'email'"
                >
                  <button
                    type="button"
                    class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                    @click="chosenaction = null"
                  >
                    Revenir au choix
                  </button>
                  <button
                    @click.prevent="createMail"
                    type="submit"
                    class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-red-500
                    "
                  >
                    Planifier cet email
                  </button>
                </div>
                <div
                  class="flex-shrink-0 px-4 py-4 flex justify-end"
                  v-if="chosenaction == 'saisine'"
                >
                  <button
                    type="button"
                    class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                    @click="chosenaction = null"
                  >
                    Revenir au choix
                  </button>
                  <button
                    @click.prevent="createReferral"
                    type="submit"
                    class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-red-500
                    "
                  >
                    Créer cette saisine
                  </button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, computed } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
  Switch, SwitchDescription, SwitchGroup, SwitchLabel,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { MailIcon } from '@heroicons/vue/solid';

const actions = [
  {
    title: 'Réponse immédiate',
    description: 'Il s\'agit d\'une réponse simple, donnée à une personne qui appelle pour avoir un renseignement.',
    slug: 'reponse',
  },
  {
    title: 'Réponse par courrier',
    description: 'Il s\'agit d\'une réponse simple, envoyée par courrier, la plupart du temps à l\'établissement ',
    slug: 'mailanswer',
  },
  {
    title: 'Planifier un rappel',
    description: 'Il faut rappeler quelqu\'un ou rappeler la personne détenue. S’il s\'agit plutôt d\'entrer en contact pour la première fois avec quelqu\'un d\'autre que la personne détenue, préférez "personne tierce" comme type d\'action. ',
    slug: 'rappel',
  },
  {
    title: 'Contacter un tiers',
    description: 'Il faut contacter un tiers afin d\'obtenir ou de délivrer un renseignement qui fera avancer la situation.',
    slug: 'tiers',
  },
  {
    title: 'Communiqué de presse',
    description: 'Enquête à mener en vue de publier un communiqué.',
    slug: 'communique',
  },
  {
    title: 'Courriel',
    description: 'Envoyer un email à un contact, à un avocat, à un proche, à l\'Administration Pénitentiaire, etc.',
    slug: 'email',
  },
  {
    title: 'Saisine',
    description: 'Saisir une institution pour faire avancer la situation.',
    slug: 'saisine',
  },
];
const chosenaction = ref();
const institutions = ref();
const prisons = ref();
const search = ref();
const searchquery = computed(() => search.value.toLowerCase());
const matchingPrisons = computed(() => prisons.value.filter((prison) => prison.title.toLowerCase().includes(searchquery.value)));
const chosenprison = ref();
const inmateanswer = ref(false);
const institutionanswer = ref(false);

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    MailIcon,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
  },
  setup() {
    const open = ref(true);
    const enabled = ref(false);
    const chosendate = ref();

    return {
      chosenaction,
      open,
      actions,
      institutions,
      inmateanswer,
      institutionanswer,
      prisons,
      answer: {
        question: null,
        answer: null,
      },
      mailanswer: {
        comment: null,
        prison_id: null,
      },
      search,
      matchingPrisons,
      chosenprison,
      callback: {
        contact_fullname: null,
        phone_number: null,
      },
      thirdpart: {
        contact: null,
        comment: null,
      },
      pressrelease: {
        url: null,
      },
      mail: {
        recipient: null,
        comment: null,
      },
      referral: {
        institution_id: null,
      },
      enabled,
      chosendate,
    };
  },
  props: {
    displayactioncreation: Boolean,
    sollicitation: Object,
  },
  created() {
    this.$http.get('api/referrals-institutions')
      .then((response) => {
        this.institutions = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    this.$http.get('api/prisons')
      .then((response) => {
        this.prisons = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
  methods: {
    closePanel() {
      this.$emit('shut');
      this.chosenaction = null;
    },
    determineAction(actionSlug) {
      this.chosenaction = actionSlug;
    },
    createAnswer() {
      this.$http.post('/api/action-answer/', {
        question: this.answer.answer,
        answer: this.answer.answer,
        date: this.chosendate,
        sollicitation_id: this.sollicitation.id,
        is_closed: this.enabled,
      }).then((response) => {
        window.console.log(response.data);
        this.answer.question = null;
        this.answer.answer = null;
        this.chosendate = null;
        this.$emit('actioncreated');
        this.closePanel();
      }).catch((error) => {
        window.console.log(error);
      });
    },
    createMailAnswer() {
      this.$http.post('/api/action-mailanswer/', {
        prison_id: this.chosenprison.id,
        date: this.chosendate,
        sollicitation_id: this.sollicitation.id,
        is_closed: this.enabled,
      }).then((response) => {
        window.console.log(response.data);
        this.mailanswer.prison_id = null;
        this.mailanswer.comment = null;
        this.chosendate = null;
        this.$emit('actioncreated');
        this.closePanel();
      }).catch((error) => {
        window.console.log(error);
      });
    },
    choosePrison(chosenPrison) {
      this.chosenprison = chosenPrison;
    },
    createCallback() {
      this.$http.post('/api/action-callback/', {
        contact_fullname: this.callback.contact_fullname,
        phone_number: this.callback.phone_number,
        sollicitation_id: this.sollicitation.id,
        date: this.chosendate,
        is_closed: this.enabled,
      }).then((response) => {
        window.console.log(response.data);
        this.callback.contact_fullname = null;
        this.callback.phone_number = null;
        this.chosendate = null;
        this.$emit('actioncreated');
        this.closePanel();
      }).catch((error) => {
        window.console.log(error);
      });
    },
    createPressrelease() {
      this.$http.post('/api/action-pressrelease/', {
        url: this.pressrelease.url,
        date: this.chosendate,
        sollicitation_id: this.sollicitation.id,
        is_closed: this.enabled,
      }).then((response) => {
        window.console.log(response.data);
        this.pressrelease.url = null;
        this.chosendate = null;
        this.$emit('actioncreated');
        this.closePanel();
      }).catch((error) => {
        window.console.log(error);
      });
    },
    createMail() {
      this.$http.post('/api/action-mail/', {
        recipient: this.mail.recipient,
        comment: this.mail.comment,
        date: this.chosendate,
        sollicitation_id: this.sollicitation.id,
        is_closed: this.enabled,
      }).then((response) => {
        window.console.log(response.data);
        this.mail.recipient = null;
        this.mail.comment = null;
        this.chosendate = null;
        this.$emit('actioncreated');
        this.closePanel();
      }).catch((error) => {
        window.console.log(error);
      });
    },
    createThirdpart() {
      this.$http.post('/api/action-thirdpart/', {
        contact: this.thirdpart.contact,
        comment: this.thirdpart.comment,
        date: this.chosendate,
        sollicitation_id: this.sollicitation.id,
        is_closed: this.enabled,
      }).then((response) => {
        window.console.log(response.data);
        this.thirdpart.contact = null;
        this.thirdpart.comment = null;
        this.chosendate = null;
        this.$emit('actioncreated');
        this.closePanel();
      }).catch((error) => {
        window.console.log(error);
      });
    },
    createReferral() {
      this.$http.post('/api/action-referral/', {
        institution_id: this.referral.institution_id,
        inmate_answer: this.inmateanswer,
        institution_answer: this.institutionanswer,
        date: this.chosendate,
        sollicitation_id: this.sollicitation.id,
        is_closed: this.enabled,
      }).then((response) => {
        window.console.log(response.data);
        this.referral.institution_id = null;
        this.chosendate = null;
        this.$emit('actioncreated');
        this.closePanel();
        this.inmateanswer = false;
        this.institutionanswer = false;
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
