<template>
  <TransitionRoot as="template" :show="contacteditionmode">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle
                      class="text-lg font-medium text-white"
                      v-if="contact"
                    >
                      {{ contact.firstname }} {{ contact.lastname }}
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Vous pouvez éditer, ici, la nature du lien entre la
                      personne détenue et ce contact.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <h4 class="font-bold text-xl text-gray-900 sm:text-2xl mb-2">
                    Changer le lien
                  </h4>
                  <div class="mt-4">
                    <label
                      for="bound"
                      class="block text-sm font-medium text-gray-700"
                      >Lien</label
                    >
                    <select
                      @change="updateBound"
                      id="bound"
                      name="bound"
                      v-model="changedbound"
                      class="
                        mt-1
                        block
                        w-full
                        pl-3
                        pr-10
                        py-2
                        text-base
                        border-gray-300
                        focus:outline-none
                        focus:ring-indigo-500
                        focus:border-indigo-500
                        sm:text-sm
                        rounded-md
                      "
                    >
                      <option
                        v-for="bound in bounds"
                        :key="bound.id"
                        :value="bound.id"
                      >
                        {{ bound.label }}
                      </option>
                    </select>
                  </div>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const open = ref(true);
    const bounds = ref();

    return {
      open,
      bounds,
      changedbound: null,
    };
  },
  props: {
    contacteditionmode: Boolean,
    contact: Object,
    inmateid: Number,
  },
  created() {
    this.$http.get('/api/bounds')
      .then((response) => {
        this.bounds = response.data;
      })
      .catch((error) => {
        window.console.log(error);
      });
  },
  methods: {
    updateBound() {
      this.$http.patch('api/inmate-contact-bound', {
        id: this.inmateid,
        contact_id: this.contact.id,
        bound_id: this.changedbound,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('shut');
        this.changedbound = null;
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
