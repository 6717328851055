<template>
  <!-- eslint-disable max-len -->
  <div class="min-h-full">
    <div class="bg-gray-800 pb-32">
      <AppNavbar />
      <InmateCreation v-model:display="display" @shut="closeInmateCreation" />
      <ChoiceModal
        title="Enregistrer un appel pour cette personne ?"
        description="Vous pouvez ici, enregistrer un appel qui ne donnera pas lieu à une sollicitation, simplement pour en garder une trace, plutôt à des fins statistiques."
        doText="Je confirme !"
        undoText="Non, j'annule"
        v-show="showable"
        v-model:showable="showable"
        v-model:inmateId="inmateId"
        @close="closeModal"
        @action="PhoneCallCreation(inmateId)"
      />
      <AppNotification
        v-show="displaynotification"
        v-model:show="displaynotification"
        title="Un appel a bien été enregistré !"
        content="L'appel a bien été
      enregistré, vous le retrouverez sur la page de détail de la personne
      détenue."
        @closenotif="closeAppNotification"
      />
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex">
          <h1 class="text-3xl font-bold text-white">Personnes détenues</h1>
        </div>
      </header>
    </div>

    <main class="-mt-32">
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="bg-white rounded-lg shadow px-5 py-10 sm:px-6">
          <!-- Action panel -->
          <div class="bg-white shadow sm:rounded-lg">
            <div class="px-4 py-5 sm:p-6">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Recherchez ou créez un dossier
              </h3>
              <div class="mt-2 sm:flex sm:items-start sm:justify-between">
                <div class="max-w-xl text-sm text-gray-500">
                  <p>
                    Vous pouvez rechercher un dossier en recherchant quelques
                    lettres du prénom, du nom, d'un établissement dans lequel la
                    personne aurait été détenue, d'un numéro SAGI ou d'écrou.
                    Vous pouvez aussi rechercher avec le nom de famille d'un
                    contact.
                  </p>
                  <div class="mt-2 relative flex items-center">
                    <input
                      v-if="!usersearch"
                      @keyup.enter="searchInmates()"
                      type="text"
                      v-model="search"
                      name="search"
                      id="search"
                      placeholder="Recherchez..."
                      class="
                        shadow-sm
                        focus:ring-red-500 focus:border-red-500
                        block
                        w-full
                        pr-12
                        sm:text-sm
                        border-gray-500
                        rounded-md
                        h-8
                      "
                    />
                    <div
                      class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5"
                    ></div>
                  </div>
                  <div class="mb-4" v-if="usersearch">
                    Vous avez recherché :
                    <span
                      class="
                        inline-flex
                        rounded-full
                        items-center
                        py-0.5
                        pl-2.5
                        pr-1
                        text-sm
                        font-medium
                        bg-indigo-100
                        text-indigo-700
                      "
                    >
                      {{ usersearch }}
                      <button
                        @click.prevent="resetSearch"
                        type="button"
                        class="
                          flex-shrink-0
                          ml-0.5
                          h-4
                          w-4
                          rounded-full
                          inline-flex
                          items-center
                          justify-center
                          text-indigo-400
                          hover:bg-indigo-200 hover:text-indigo-500
                          focus:outline-none
                          focus:bg-indigo-500
                          focus:text-white
                        "
                      >
                        <span class="sr-only">Réinitialisez</span>
                        <svg
                          class="h-2 w-2"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 8 8"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-width="1.5"
                            d="M1 1l6 6m0-6L1 7"
                          />
                        </svg>
                      </button>
                    </span>
                  </div>
                </div>
                <div
                  class="
                    mt-5
                    sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
                  "
                >
                  <button
                    type="button"
                    @click.prevent="showInmateCreation"
                    class="
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-transparent
                      shadow-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                      sm:text-sm
                    "
                  >
                    Créer un dossier
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Action panel ends -->
          <ul
            role="list"
            class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-6"
          >
            <li
              v-for="inmate in inmates.data"
              :key="inmate.id"
              class="
                col-span-1
                bg-white
                rounded-lg
                shadow
                divide-y divide-gray-200
              "
            >
              <div
                class="w-full flex items-center justify-between p-6 space-x-6"
              >
                <div class="flex-1 truncate">
                  <div class="flex items-center space-x-3">
                    <h3 class="text-gray-900 text-sm font-medium truncate">
                      <a
                        :href="/personnes-detenues/ + 'infos' + '/' + inmate.id"
                      >
                        {{ inmate.firstname }} {{ inmate.lastname }}
                      </a>
                    </h3>
                    <span
                      v-if="inmate.hasfollowups == true"
                      class="
                        flex-shrink-0
                        inline-block
                        px-2
                        py-0.5
                        text-yellow-800 text-xs
                        font-medium
                        bg-yellow-100
                        rounded-full
                      "
                      >Sollicitation(s) à suivre</span
                    >
                  </div>
                  <p
                    class="mt-1 text-gray-500 text-sm truncate"
                    v-if="inmate.current_number"
                  >
                    Écrou n° {{ inmate.current_number.inmate_number }}
                  </p>
                  <p class="mt-1 text-gray-300 text-sm truncate" v-else>
                    N° d'écrou inconnu
                  </p>
                  <p
                    class="mt-1 text-gray-500 text-sm truncate"
                    v-if="inmate.current_location"
                  >
                    {{ inmate.current_location.title }}
                  </p>
                  <p class="mt-1 text-gray-300 text-sm truncate" v-else>
                    Établissement inconnu
                  </p>
                </div>
              </div>
              <div>
                <div class="-mt-px flex divide-x divide-gray-200">
                  <div class="-ml-px w-0 flex-1 flex">
                    <a
                      class="
                        cursor-pointer
                        relative
                        w-0
                        flex-1
                        inline-flex
                        items-center
                        justify-center
                        py-4
                        text-sm text-gray-700
                        font-medium
                        border border-transparent
                        rounded-br-lg
                        hover:text-gray-500
                      "
                    >
                      <PhoneIncomingIcon
                        class="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span
                        class="ml-3"
                        @click.prevent="recordPhoneCall(inmate.id)"
                        >Notifier un appel</span
                      >
                    </a>
                  </div>
                  <div class="w-0 flex-1 flex">
                    <a
                      :href="
                        /personnes-detenues/ +
                        'creer-une-sollicitation' +
                        '/' +
                        inmate.id
                      "
                      class="
                        cursor-pointer
                        relative
                        -mr-px
                        w-0
                        flex-1
                        inline-flex
                        items-center
                        justify-center
                        py-4
                        text-sm text-gray-700
                        font-medium
                        border border-transparent
                        rounded-bl-lg
                        hover:text-gray-500
                      "
                    >
                      <ViewGridAddIcon
                        class="w-5 h-5 text-blue-500"
                        aria-hidden="true"
                      />
                      <span class="ml-3 text-blue-500">Sollicitation</span>
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <!-- pagination  -->
          <div v-if="inmates.data" class="mt-6">
            <div>
              <nav
                class="
                  bg-white
                  px-4
                  py-3
                  flex
                  items-center
                  justify-between
                  border-t border-gray-200
                  sm:px-6
                "
                aria-label="Pagination"
              >
                <div class="hidden sm:block">
                  <p class="text-sm text-gray-700">
                    Page
                    {{ " " }}
                    <span class="font-medium">{{ currentpage }}</span>
                    {{ " " }}
                    sur
                    {{ " " }}
                    <span class="font-medium">{{ inmates.last_page }}</span>
                    {{ " " }}
                    de
                    {{ " " }}
                    <span class="font-medium">{{ inmates.total }}</span>
                    {{ " " }}
                    résultats
                  </p>
                </div>
                <div class="flex-1 flex justify-between sm:justify-end">
                  <button
                    @click.prevent="goPreviouspage"
                    :disabled="currentpage < 2"
                    :class="currentpage < 2 ? 'cursor-not-allowed' : ''"
                    class="
                      relative
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-gray-300
                      text-sm
                      font-medium
                      rounded-md
                      text-gray-700
                      bg-white
                      hover:bg-gray-50
                      cursor-pointer
                    "
                  >
                    Précédentes
                  </button>
                  <button
                    @click.prevent="goNextpage"
                    :disabled="currentpage == inmates.last_page"
                    :class="
                      currentpage == inmates.last_page
                        ? 'cursor-not-allowed'
                        : ''
                    "
                    class="
                      ml-3
                      relative
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-gray-300
                      text-sm
                      font-medium
                      rounded-md
                      text-gray-700
                      bg-white
                      hover:bg-gray-50
                      cursor-pointer
                    "
                  >
                    Suivantes
                  </button>
                </div>
              </nav>
            </div>
          </div>
          <!-- pagination ends -->
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import { ViewGridAddIcon, PhoneIncomingIcon } from '@heroicons/vue/solid';
import AppNavbar from '@/components/AppNavbar.vue';
import InmateCreation from '@/components/Forms/InmateCreation.vue';
import AppNotification from '@/components/Modals/AppNotification.vue';
import ChoiceModal from '@/components/Modals/ChoiceModal.vue';

export default {
  components: {
    AppNavbar,
    ViewGridAddIcon,
    PhoneIncomingIcon,
    ChoiceModal,
    InmateCreation,
    AppNotification,
  },
  setup() {
    const search = ref('');
    const inmates = ref([]);
    const usersearch = ref();
    const showable = ref(false);
    const display = ref(false);
    const displaynotification = ref(false);
    const dispsfilter = ref(false);
    const currentuser = ref();
    const currentpage = ref();

    return {
      inmates,
      search,
      usersearch,
      showable,
      display,
      displaynotification,
      dispsfilter,
      currentuser,
      currentpage,
    };
  },
  created() {
    this.getResults();
    if (localStorage.user) {
      this.currentuser = JSON.parse(localStorage.user);
    }
    this.currentpage = 1;
    this.usersearch = null;
  },
  watch: {
    user(newUser) {
      localStorage.user = newUser;
    },
  },
  methods: {
    getResults() {
      if (this.dispsfilter === false) {
        this.$http.get(`api/inmates-paginated?page=${this.currentpage}`)
          .then((response) => {
            this.inmates = response.data;
          })
          .catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      } else {
        this.$http.get(`/api/myinmates/${this.currentuser.id}`)
          .then((response) => {
            this.inmates = response.data;
          })
          .catch((error) => {
            window.console.log(error);
            this.errored = true;
          });
      }
    },
    searchInmates() {
      this.usersearch = this.search;
      this.$http.get(`api/inmates-search/${this.search}?page=${this.currentpage}`)
        .then((response) => {
          this.inmates = response.data;
          this.currentpage = 1;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    seePaginatedInmates() {
      this.usersearch = this.search;
      this.$http.get(`api/inmates-search/${this.search}?page=${this.currentpage}`)
        .then((response) => {
          this.inmates = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    resetSearch() {
      this.usersearch = null;
      this.search = null;
      this.currentpage = 1;
      this.getResults();
    },
    goNextpage() {
      this.currentpage += 1;
      window.console.log(this.usersearch);
      if (this.usersearch !== null) {
        this.seePaginatedInmates();
      } else {
        this.getResults();
      }
    },
    goPreviouspage() {
      this.currentpage -= 1;
      if (this.usersearch !== null) {
        this.seePaginatedInmates();
      } else {
        this.getResults();
      }
    },
    recordPhoneCall(inmateId) {
      this.showable = true;
      this.inmateId = inmateId;
    },
    PhoneCallCreation(inmateId) {
      this.$http.post('/api/inmate-phonecall/', {
        inmate_id: inmateId,
      }).then((response) => {
        window.console.log(response.data);
        this.showable = false;
        this.displaynotification = true;
      }).catch((error) => {
        window.console.log(error);
      });
    },
    closeModal() {
      this.showable = false;
    },
    showInmateCreation() {
      this.display = true;
    },
    closeInmateCreation() {
      this.display = false;
    },
    closeAppNotification() {
      this.displaynotification = false;
    },
  },
};
</script>
