<template>
  <TransitionRoot as="template" :show="displaycontacts">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Gestion des contacts à l'origine de cette sollicitation
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          bg-gray-800
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Vous pouvez gérer, ici, les personnes contacts à l'origine
                      de cette sollicitation.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <!-- display attached inmates -->
                  <div>
                    <h4
                      class="font-bold text-xl text-gray-900 sm:text-2xl mb-2"
                    >
                      Contacts identifiés
                    </h4>
                    <label
                      for="email"
                      class="block text-sm font-medium text-gray-700"
                      >Vous trouverez, ci-dessous, la liste des personnes
                      identifiées</label
                    >
                    <div>
                      <div class="flow-root mt-6">
                        <ul role="list" class="-my-5 divide-y divide-gray-200">
                          <li
                            v-for="contact in sollicitation.origin"
                            :key="contact.id"
                            class="py-4"
                          >
                            <div class="flex items-center space-x-4">
                              <div class="flex-1 min-w-0">
                                <p
                                  class="
                                    text-sm
                                    font-medium
                                    text-gray-900
                                    truncate
                                  "
                                >
                                  {{ contact.firstname }} {{ contact.lastname }}
                                  <br />
                                  <span class="text-gray-500">{{
                                    contact.type
                                  }}</span>
                                </p>
                              </div>
                              <div>
                                <button
                                  v-if="contact.type == 'Contact'"
                                  @click.prevent="removeOrigin(contact.id)"
                                  class="
                                    inline-flex
                                    items-center
                                    shadow-sm
                                    px-2.5
                                    py-0.5
                                    border border-gray-300
                                    text-sm
                                    leading-5
                                    font-medium
                                    rounded-full
                                    text-gray-700
                                    bg-white
                                    hover:bg-gray-50
                                  "
                                >
                                  Retirer
                                </button>
                                <button
                                  v-if="contact.type == 'Détenu.e'"
                                  @click.prevent="
                                    removeCoInmateOrigin(contact.id)
                                  "
                                  class="
                                    inline-flex
                                    items-center
                                    shadow-sm
                                    px-2.5
                                    py-0.5
                                    border border-gray-300
                                    text-sm
                                    leading-5
                                    font-medium
                                    rounded-full
                                    text-gray-700
                                    bg-white
                                    hover:bg-gray-50
                                  "
                                >
                                  Retirer
                                </button>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- attached inmates ends -->
                  <div class="mt-12">
                    <h4
                      class="font-bold text-xl text-gray-900 sm:text-2xl mb-2"
                    >
                      Ajoutez d'autres personnes
                    </h4>
                    <label
                      for="email"
                      class="block text-sm font-medium text-gray-700"
                      >Vous pouvez ajouter d'autres personnes détenues en
                      recherchant dans le champ ci-dessous</label
                    >
                    <div class="mt-2">
                      <input
                        @keyup.enter="searchOrigin()"
                        type="text"
                        name="search"
                        v-model="search"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                        placeholder="Tapez quelques lettres ici"
                      />
                    </div>
                  </div>
                  <ul
                    role="list"
                    class="-my-5 divide-y divide-gray-200 mt-6"
                    v-if="search"
                  >
                    <li
                      v-for="person in matchingPersons"
                      :key="person.id"
                      class="py-4"
                    >
                      <div class="flex items-center space-x-4">
                        <div class="flex-1 min-w-0">
                          <p class="text-sm font-medium text-gray-900 truncate">
                            {{ person.firstname }} {{ person.lastname }} <br />
                            <span class="text-gray-500">{{ person.type }}</span>
                          </p>
                        </div>
                        <a
                          v-if="person.type == 'Contact'"
                          @click.prevent="addOriginContact(person)"
                          class="
                            inline-flex
                            items-center
                            shadow-sm
                            px-2.5
                            py-0.5
                            text-sm
                            leading-5
                            font-medium
                            rounded-full
                            text-white
                            bg-green-500
                            hover:bg-green-700
                            cursor-pointer
                          "
                        >
                          Ajouter
                        </a>
                        <a
                          v-if="person.type == 'Personne détenue'"
                          @click.prevent="addOriginCoinmate(person.id)"
                          class="
                            inline-flex
                            items-center
                            shadow-sm
                            px-2.5
                            py-0.5
                            text-sm
                            leading-5
                            font-medium
                            rounded-full
                            text-white
                            bg-green-500
                            hover:bg-green-700
                            cursor-pointer
                          "
                        >
                          Ajouter
                        </a>
                      </div>
                    </li>
                  </ul>
                  <!-- create inmate -->
                  <h4
                    class="
                      font-bold
                      text-xl text-gray-900
                      sm:text-2xl
                      mb-2
                      mt-6
                    "
                  >
                    Créer et ajouter un nouveau contact
                  </h4>
                  <label class="block text-sm font-medium text-gray-700 mb-2"
                    >Vous pouvez créer et ajouter un nouveau contact en
                    remplissant les champs ci-dessous</label
                  >
                  <div>
                    <label
                      for="project-name"
                      class="block text-sm font-medium text-gray-900"
                    >
                      Genre
                    </label>
                    <div class="mt-1">
                      <select
                        id="gender"
                        name="gender"
                        autocomplete="gender"
                        v-model="contact.gender_id"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      >
                        <option
                          v-for="gender in genders"
                          :key="gender.id"
                          :value="gender.id"
                        >
                          {{ gender.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label
                      for="firstname"
                      class="block text-sm font-medium text-gray-700"
                      >Prénom</label
                    >
                    <div class="mt-1">
                      <input
                        type="firstname"
                        name="firstname"
                        id="firstname"
                        v-model="contact.firstname"
                        placeholder="Jacques"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="lastname"
                      class="block text-sm font-medium text-gray-700 mt-2"
                      >Nom de famille</label
                    >
                    <div class="mt-1">
                      <input
                        type="lastname"
                        name="lastname"
                        id="lastname"
                        v-model="contact.lastname"
                        placeholder="Dupont"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="phone"
                      class="block text-sm font-medium text-gray-700 mt-2"
                      >Numéro de téléphone</label
                    >
                    <div class="mt-1">
                      <input
                        type="phone"
                        name="phone"
                        id="phone"
                        v-model="contact.phone"
                        placeholder="01 78 78 78 78"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div class="mt-1">
                    <label
                      for="email"
                      class="block text-sm font-medium text-gray-700"
                      >Email</label
                    >
                    <div class="mt-1">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        v-model="contact.email"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                        placeholder="vous@exemple.com"
                      />
                    </div>
                  </div>
                  <div class="mt-2">
                    <label
                      for="address"
                      class="block text-sm font-medium text-gray-700"
                      >Ajouter une adresse complète</label
                    >
                    <div class="mt-1">
                      <textarea
                        rows="4"
                        name="address"
                        v-model="contact.address"
                        id="address"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div
                    class="mt-2"
                    v-if="
                      sollicitation.inmates.length < 2 &&
                      sollicitation.inmates.length != 0
                    "
                  >
                    <label
                      for="bound"
                      class="block text-sm font-medium text-gray-700"
                      >Lien</label
                    >
                    <div class="mt-1">
                      <select
                        id="bound"
                        name="bound"
                        v-model="contact.bound_id"
                        class="
                          mt-1
                          block
                          w-full
                          pl-3
                          pr-10
                          py-2
                          text-base
                          border-gray-300
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                          rounded-md
                        "
                      >
                        <option
                          v-for="bound in bounds"
                          :key="bound.id"
                          :value="bound.id"
                        >
                          {{ bound.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="space-x-3 flex justify-end mt-4">
                    <button
                      @click.prevent="createandaddContact"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Créer et ajouter ce contact
                    </button>
                  </div>
                  <!-- create inmate ends -->
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const persons = ref();
    const search = ref();
    const matchingPersons = ref([]);
    const selectedcontacttoadd = ref();
    const genders = ref();
    const bounds = ref([]);

    return {
      search,
      persons,
      matchingPersons,
      bounds,
      contact: {
        gender_id: null,
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        bound_id: null,
        address: null,
      },
      selectedcontacttoadd,
      genders,
    };
  },
  props: {
    displaycontacts: Boolean,
    sollicitation: Object,
  },
  created() {
    this.$http.get('/api/bounds')
      .then((response) => {
        this.bounds = response.data;
      })
      .catch((error) => {
        window.console.log(error);
      });
    this.$http.get('/api/genders')
      .then((response) => {
        this.genders = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
  methods: {
    removeOrigin(personId) {
      this.$http.patch('api/sollicitation-contacts-remove', {
        id: this.sollicitation.id,
        contact_id: personId,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('contactsupdated');
      }).catch((error) => {
        window.console.log(error);
      });
    },
    removeCoInmateOrigin(personId) {
      this.$http.patch('api/sollicitation-coinmates-remove', {
        id: this.sollicitation.id,
        inmate_id: personId,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('contactsupdated');
      }).catch((error) => {
        window.console.log(error);
      });
    },
    addOriginContact(personId) {
      this.$http.patch('api/sollicitation-contacts', {
        id: this.sollicitation.id,
        contact_id: personId,
      }).then(() => {
        if (this.sollicitation.inmates.length < 2) {
          this.$http.patch('api/inmate-contact-add', {
            id: this.sollicitation.inmates[0].id,
            contact_id: personId,
          });
        } else {
          for (let i = 0; i < this.sollicitation.inmates.length; i += 1) {
            const inmateId = this.sollicitation.inmates[i].id;
            this.$http.patch('api/inmate-contact-add', {
              id: inmateId,
              contact_id: personId,
            });
          }
        }
      }).then(() => {
        this.$emit('contactsupdated');
        this.$emit('shut');
        this.search = null;
      }).catch((error) => {
        window.console.log(error);
      });
    },
    addOriginCoinmate(personId) {
      this.$http.patch('api/sollicitation-coinmates', {
        id: this.sollicitation.id,
        inmate_id: personId,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('contactsupdated');
        this.$emit('shut');
        this.search = null;
      }).catch((error) => {
        window.console.log(error);
      });
    },
    // getContacts() {
    //   this.$http.get('/api/directory/')
    //     .then((response) => {
    //       this.persons = response.data;
    //     })
    //     .catch((error) => {
    //       window.console.log(error);
    //       this.errored = true;
    //     });
    // },
    searchOrigin() {
      this.usersearch = this.search;
      this.$http.get(`api/origin-search/${this.search}`)
        .then((response) => {
          this.matchingPersons = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    createandaddContact() {
      this.$http.post('/api/contact-creation/', {
        gender_id: this.contact.gender_id,
        firstname: this.contact.firstname,
        lastname: this.contact.lastname,
        phone: this.contact.phone,
        email: this.contact.email,
        address: this.contact.address,
      }).then((response) => {
        this.newlycreatedcontactid = response.data.id;
        this.$http.patch('api/sollicitation-contacts', {
          id: this.sollicitation.id,
          contact_id: response.data.id,
        }).then(() => {
          this.contact.lastname = null;
          this.contact.firstname = null;
          this.contact.phone = null;
          this.contact.email = null;
          this.contact.address = null;
        });
      }).then(() => {
        if (this.sollicitation.inmates.length < 2 && this.sollicitation.inmates.length > 0) {
          this.$http.patch('api/inmate-contact-add', {
            id: this.sollicitation.inmates[0].id,
            contact_id: this.newlycreatedcontactid,
            bound_id: this.contact.bound_id,
          }).then(() => {
            this.$emit('contactsupdated');
            this.$emit('shut');
            this.contact.bound_id = null;
          });
        } else if (this.sollicitation.inmates.length >= 2) {
          for (let i = 0; i < this.sollicitation.inmates.length; i += 1) {
            const inmateId = this.sollicitation.inmates[i].id;
            this.$http.patch('api/inmate-contact-add', {
              id: inmateId,
              contact_id: this.newlycreatedcontactid,
              bound_id: this.contact.bound_id,
            }).then(() => {
              this.$emit('contactsupdated');
              this.$emit('shut');
            });
          }
        } else {
          this.$emit('contactsupdated');
          this.$emit('shut');
        }
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
