<template>
  <div class="min-h-full" v-if="sollicitation">
    <div class="bg-gray-800 pb-32">
      <AppNavbar />
      <AppNotification
        v-show="displaynotification"
        v-model:show="displaynotification"
        :title="title"
        :content="content"
        @closenotif="closeAppNotification"
      />
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex items-center">
            <h1 class="text-3xl font-bold text-white">
              {{ sollicitation.title }}
            </h1>
            <button
              @click.prevent="edittitle = true"
              type="button"
              class="
                bg-gray-800
                p-1
                rounded-full
                text-gray-400
                hover:text-white
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-offset-gray-800
                focus:ring-white
              "
            >
              <PencilIcon class="h-6 w-6 mr-2" aria-hidden="true" />
            </button>
            <span
              v-if="enabled == 0"
              class="
                inline-flex
                items-center
                px-2.5
                py-0.5
                rounded-full
                text-xs
                font-medium
                bg-red-100
                text-red-800
                ml-4
              "
            >
              Close
            </span>
            <span
              v-else
              class="
                inline-flex
                items-center
                px-2.5
                py-0.5
                rounded-full
                text-xs
                font-medium
                bg-green-100
                text-green-800
                ml-4
              "
            >
              Active
            </span>
            <span
              v-if="sollicitation.is_urgent == true"
              class="
                inline-flex
                items-center
                px-2.5
                py-0.5
                rounded-full
                text-xs
                font-medium
                bg-red-100
                text-red-800
                ml-4
              "
            >
              Urgente
            </span>
            <SwitchGroup as="div" class="flex items-center ml-auto">
              <Switch
                @click="changeSollicitationUrgency"
                v-model="emergency"
                :class="[
                  emergency ? 'bg-red-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                ]"
              >
                <span
                  aria-hidden="true"
                  :class="[
                    emergency ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                  ]"
                />
              </Switch>
              <SwitchLabel as="span" class="ml-3">
                <div v-if="emergency == '0'">
                  <span class="text-sm font-medium text-white"
                    >Non urgente
                  </span>
                </div>
                <div v-if="emergency == '1'">
                  <span class="text-sm font-medium text-white">Urgente </span>
                </div>
              </SwitchLabel>
            </SwitchGroup>
          </div>
          <h3 class="text-2xl text-white">
            Sollicitation datée du
            {{ moment(sollicitation.date).format("dddd DD MMMM YYYY à HH:mm") }}
          </h3>
        </div>
      </header>
    </div>
    <main class="-mt-32">
      <SollicitationContacts
        v-model:displaycontacts="displaycontacts"
        v-model:sollicitation="sollicitation"
        v-show="displaycontacts"
        @shut="closeSollicitationContacts"
        @updateinmateslist="getSollicitation"
      />
      <ActionCreation
        v-show="displayactioncreation"
        v-model:displayactioncreation="displayactioncreation"
        v-model:sollicitation="sollicitation"
        @shut="closeActionPanel"
        @actioncreated="getSollicitation"
      />
      <FollowupCreation
        v-show="displayfollowupcreation"
        v-model:show="displayfollowupcreation"
        v-model:sollicitation="sollicitation"
        @shut="closeFollowupCreation"
        @followupcreated="confirmFollowupCreation"
      />
      <ActionDetail
        v-show="displayactiondetail"
        v-model:show="displayactiondetail"
        v-model:actionstatus="actionstatus"
        v-model:action="actionfocused"
        @shut="closeActionFocus"
        @statuschanged="statusChangedconfirm"
      />
      <ManageContacts
        v-show="managecontacts"
        v-model:sollicitation="sollicitation"
        v-model:show="managecontacts"
        @shut="closeManageContacts"
        @contactsupdated="confirmUpdatedContacts"
      />
      <EditSollicitationContent
        v-model:editcontent="editcontent"
        v-show="editcontent"
        v-model:sollicitation="sollicitation"
        @shut="closeContentEdition"
        @contentedited="confirmContentEdition"
      />
      <EditSollicitationTitle
        v-model:sollicitation="sollicitation"
        v-model:edittitle="edittitle"
        @shut="closeUpdateTitle"
        @titleupdated="confirmUpdatedTitle"
      />
      <FilePanel
        v-model:displayfilepanel="displayfilepanel"
        @shut="closeFilePanel"
        @filetrashed="getSollicitation"
        v-model:file="selectedfile"
      />
      <FileCreation
        v-model:displayfilecreation="displayfilecreation"
        v-model:sollicitationcontext="sollicitationcontext"
        v-model:sollicitation="sollicitation"
        @shut="closeFileCreation"
        @filecreated="getSollicitation"
      />
      <FileAttachment
        v-model:sollicitation="sollicitation"
        v-model:displayfileattachment="displayfileattachment"
        @shut="closeFileAttachment"
        @fileLinked="getSollicitation"
      />
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="bg-white rounded-lg shadow px-5 py-10 sm:px-6">
          <!-- description begins -->
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Informations importantes
          </h3>
          <div class="mt-2 sm:flex sm:items-start sm:justify-between mb-6">
            <div class="max-w-xl text-sm text-gray-500">
              <p v-if="sollicitation.actions != 0">
                Vous trouverez, ci-dessous, les informations essentielles de
                cette sollicitation.
              </p>
            </div>
            <div
              class="
                mt-5
                sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
              "
            >
              <button
                @click.prevent="displayFollowupCreation"
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  shadow-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-red-600
                  hover:bg-red-700 hover:text-white
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-red-500
                  sm:text-sm
                "
              >
                Créer un suivi
              </button>
            </div>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">Canal</dt>
                <dd
                  class="mt-1 text-sm text-gray-900"
                  v-if="sollicitation.channel"
                >
                  {{ sollicitation.channel.title }}
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Établissement concerné
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ sollicitation.prison.title }}
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">
                  Thème / Sous-thème
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ sollicitation.theme.title }}
                  <span v-if="sollicitation.subtheme !== null">
                    / {{ sollicitation.subtheme.title }}</span
                  >
                  <span v-else> / Pas de sous-thème</span>
                </dd>
              </div>
              <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">DISP</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ sollicitation.disp.title }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-medium text-gray-500">
                  Description complète
                </dt>
                <dd
                  class="mt-1 text-sm text-gray-900"
                  v-html="sollicitation.content"
                ></dd>
                <div class="mt-6">
                  <a
                    @click="editcontent = true"
                    class="
                      w-full
                      flex
                      justify-center
                      items-center
                      px-4
                      py-2
                      border border-gray-300
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-gray-700
                      bg-white
                      hover:bg-gray-50
                      cursor-pointer
                    "
                  >
                    Modifier cette sollicitation
                  </a>
                </div>
              </div>
            </dl>
          </div>
          <!-- description ends -->
        </div>
        <!-- /End replace -->
        <!-- inmates list begin -->
        <div class="bg-white rounded-lg shadow px-5 py-10 sm:px-6 mt-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Personnes concernées
          </h3>
          <div class="mt-2 sm:flex sm:items-start sm:justify-between">
            <div class="max-w-xl text-sm text-gray-500">
              <p>
                Vous pouvez mettre à jour ou modifier les personnes concernées
                et/ou les contacts de cette sollicitation.
              </p>
            </div>
            <div
              class="
                mt-5
                sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
              "
            >
              <button
                @click.prevent="editContacts()"
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  shadow-sm
                  font-medium
                  rounded-md
                  text-dark
                  bg-gray-200
                  hover:bg-blue-700 hover:text-white
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-blue-500
                  sm:text-sm
                "
              >
                Gérer les personnes concernées
              </button>
            </div>
          </div>
          <!-- already origin  -->
          <div class="rounded-md bg-yellow-50 p-4 mt-4" v-if="alreadyorigin">
            <div class="flex">
              <div class="flex-shrink-0">
                <ExclamationCircleIcon
                  class="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-yellow-800">
                  Cette personne est déjà signalée comme étant à l'origine de la
                  sollicitation.
                </p>
              </div>
              <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    @click.prevent="alreadyorigin = false"
                    type="button"
                    class="
                      inline-flex
                      bg-yellow-50
                      rounded-md
                      p-1.5
                      text-yellow-500
                      hover:bg-yellow-100
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-offset-yellow-50
                      focus:ring-yellow-600
                    "
                  >
                    <span class="sr-only">Fermer</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- already origin ends -->
          <!-- confirm origin  -->
          <div class="rounded-md bg-green-50 p-4 mt-4" v-if="confirmorigin">
            <div class="flex">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-green-800">
                  Cette personne est enregistrée comme étant à l'origine de la
                  sollicitation !
                </p>
              </div>
              <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    @click.prevent="confirmorigin = false"
                    type="button"
                    class="
                      inline-flex
                      bg-green-50
                      rounded-md
                      p-1.5
                      text-green-500
                      hover:bg-green-100
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-offset-green-50
                      focus:ring-green-600
                    "
                  >
                    <span class="sr-only">Fermer</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- confirm origin ends -->
          <ul
            role="list"
            class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-6"
          >
            <li
              v-for="inmate in sollicitation.inmates"
              :key="inmate.id"
              class="
                col-span-1
                bg-white
                rounded-lg
                shadow
                divide-y divide-gray-200
              "
            >
              <div
                class="w-full flex items-center justify-between p-6 space-x-6"
              >
                <div class="flex-1 truncate">
                  <div class="flex items-center space-x-3">
                    <h3 class="text-gray-900 text-sm font-medium truncate">
                      <a
                        :href="/personnes-detenues/ + 'infos' + '/' + inmate.id"
                        target="_blank"
                        >{{ inmate.firstname }} {{ inmate.lastname }}
                      </a>
                    </h3>
                  </div>
                  <p class="mt-1 text-gray-500 text-sm truncate">
                    <span v-if="inmate.current_number !== 'Inconnu'"
                      >Écrou n° {{ inmate.current_number }}</span
                    >
                    <span v-else>{{ inmate.current_number }}</span>
                  </p>
                  <p class="mt-1 text-gray-300 text-sm truncate">
                    {{ inmate.current_location }}
                  </p>
                </div>
              </div>
              <div>
                <div class="-mt-px flex divide-x divide-gray-200">
                  <div class="-ml-px w-0 flex-1 flex">
                    <a
                      class="
                        cursor-pointer
                        relative
                        w-0
                        flex-1
                        inline-flex
                        items-center
                        justify-center
                        py-4
                        text-sm text-gray-700
                        font-medium
                        border border-transparent
                        rounded-br-lg
                        hover:text-gray-500
                      "
                    >
                      <SpeakerphoneIcon
                        class="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span class="ml-3" @click.prevent="makeOrigin(inmate.id)"
                        >A l'origine du signalement</span
                      >
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- inmates list ends -->
        <!-- origin list -->
        <div class="bg-white rounded-lg shadow px-5 py-10 sm:px-6 mt-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Origine(s) de la sollicitation
          </h3>
          <div class="mt-2 sm:flex sm:items-start sm:justify-between">
            <div class="max-w-xl text-sm text-gray-500">
              <p class="mb-4">
                Vous pouvez mettre à jour ou modifier les personnes à l'origine
                de cette sollicitation. Ces personnes peuvent être la personne
                concernée par la sollicitation, l'un de ses contacts ou un.e
                codétenu.e.
              </p>
            </div>
            <div
              class="
                mt-5
                sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
              "
            >
              <button
                @click.prevent="editOrigin()"
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  shadow-sm
                  font-medium
                  rounded-md
                  text-dark
                  bg-gray-200
                  hover:bg-blue-700 hover:text-white
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-blue-500
                  sm:text-sm
                  mb-2
                "
              >
                Gérer ces personnes
              </button>
            </div>
          </div>

          <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
            <ul role="list" class="divide-y divide-gray-200">
              <li v-for="contact in sollicitation.origin" :key="contact.id">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="text-sm font-medium text-blue-600 truncate">
                      {{ contact.firstname }} {{ contact.lastname }}
                    </p>
                    <div
                      class="ml-2 flex-shrink-0 flex"
                      v-if="contact.type === 'Contact'"
                    >
                      <p
                        class="
                          px-2
                          inline-flex
                          text-xs
                          leading-5
                          font-semibold
                          rounded-full
                          bg-green-100
                          text-green-800
                        "
                      >
                        {{ contact.type }}
                      </p>
                    </div>
                    <div class="ml-2 flex-shrink-0 flex" v-else>
                      <p
                        class="
                          px-2
                          inline-flex
                          text-xs
                          leading-5
                          font-semibold
                          rounded-full
                          bg-blue-100
                          text-blue-800
                        "
                      >
                        {{ contact.type }}
                      </p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex" v-if="contact.type === 'Contact'">
                      <p class="flex items-center text-sm text-gray-500">
                        <MailIcon
                          class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {{ contact.email }}
                      </p>
                      <p
                        class="
                          mt-2
                          flex
                          items-center
                          text-sm text-gray-500
                          sm:mt-0 sm:ml-6
                        "
                      >
                        <PhoneIcon
                          class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {{ contact.phone }}
                      </p>
                    </div>
                    <div class="sm:flex" v-else>
                      <p class="flex items-center text-sm text-gray-500">
                        <QrcodeIcon
                          class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {{ contact.inmate_number }}
                      </p>
                      <p
                        class="
                          mt-2
                          flex
                          items-center
                          text-sm text-gray-500
                          sm:mt-0 sm:ml-6
                        "
                      >
                        <LocationMarkerIcon
                          v-if="contact.current_location !== null"
                          class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span v-if="contact.current_location">{{
                          contact.current_location.title
                        }}</span>
                      </p>
                    </div>
                    <div
                      class="
                        mt-2
                        flex
                        items-center
                        text-sm text-gray-500
                        sm:mt-0
                      "
                    >
                      <CalendarIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <p>
                        Créé le
                        <time>
                          {{
                            moment(contact.created_at).format("DD MMMM YYYY")
                          }}</time
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <!-- <div class="max-w-xl text-sm text-gray-500" v-else>
              Aucun contact n'a été identifié comme étant à l'origine de cette
              sollicitation.
            </div> -->
          </div>
        </div>
        <!-- origin list ends -->
        <!-- actions list begins -->
        <div class="bg-white rounded-lg shadow px-5 py-10 sm:px-6 mt-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Actions menées
          </h3>
          <div class="mt-2 sm:flex sm:items-start sm:justify-between">
            <div class="max-w-xl text-sm text-gray-500">
              <p v-if="sollicitation.actions != 0">
                Vous trouverez la liste des actions menées pour cette
                sollicitation.
              </p>
              <p v-else>
                Aucune action n'a encore été menée pour cette sollicitation.
              </p>
            </div>
            <div
              class="
                mt-5
                sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
              "
            >
              <button
                @click.prevent="displayActionPanel"
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  shadow-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-red-600
                  hover:bg-red-700 hover:text-white
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-red-500
                  sm:text-sm
                "
              >
                Créer une action
              </button>
            </div>
          </div>
          <div class="flex flex-col mt-6">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div
                class="
                  py-2
                  align-middle
                  inline-block
                  min-w-full
                  sm:px-6
                  lg:px-8
                "
              >
                <div
                  class="
                    shadow
                    overflow-hidden
                    border-b border-gray-200
                    sm:rounded-lg
                  "
                  v-if="sollicitation.actions != 0"
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Statut
                        </th>
                        <th scope="col" class="relative px-6 py-3">
                          <span class="sr-only">En détail</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr
                        v-for="action in sollicitation.actions"
                        :key="action.id"
                      >
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div class="text-sm text-gray-500">
                                {{
                                  moment(action.date).format(
                                    "dddd D MMM YYYY à HH:mm"
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="text-sm text-gray-900">
                            {{ action.type }} <br />
                            <span v-if="action.type == 'Saisine'">{{
                              action.institution.name
                            }}</span>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <span
                            v-if="action.is_closed == 0"
                            class="
                              px-2
                              inline-flex
                              text-xs
                              leading-5
                              font-semibold
                              rounded-full
                              bg-yellow-100
                              text-yellow-800
                            "
                          >
                            En cours
                          </span>
                          <span
                            v-else-if="action.is_closed == 1"
                            class="
                              px-2
                              inline-flex
                              text-xs
                              leading-5
                              font-semibold
                              rounded-full
                              bg-green-100
                              text-green-800
                            "
                          >
                            Terminée
                          </span>
                          <span
                            v-else-if="
                              action.inmate_answer == 0 ||
                              action.institution_answer == 0
                            "
                            class="
                              px-2
                              inline-flex
                              text-xs
                              leading-5
                              font-semibold
                              rounded-full
                              bg-yellow-100
                              text-yellow-800
                            "
                          >
                            En cours
                          </span>
                          <span
                            v-else-if="
                              action.inmate_answer == 1 &&
                              action.institution_answer == 1
                            "
                            class="
                              px-2
                              inline-flex
                              text-xs
                              leading-5
                              font-semibold
                              rounded-full
                              bg-green-100
                              text-green-800
                            "
                          >
                            Terminée
                          </span>
                        </td>
                        <td
                          class="
                            px-6
                            py-4
                            whitespace-nowrap
                            text-right text-sm
                            font-medium
                          "
                        >
                          <a
                            @click.prevent="displayActionFocus(action)"
                            class="
                              text-indigo-600
                              hover:text-indigo-900
                              cursor-pointer
                            "
                            >Voir le détail</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- actions list ends -->
        <!-- file list -->
        <div class="bg-white rounded-lg shadow px-5 py-10 sm:px-6 mt-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Fichiers liés
          </h3>
          <div class="mt-2 sm:flex sm:items-start sm:justify-between">
            <div class="max-w-xl text-sm text-gray-500">
              <p class="mb-4">
                Voici la liste des fichiers liés à cette sollicitation. Vous
                pouvez également en ajouter à partir de cette vue. Si vous
                ajoutez un fichier à partir de cette vue, il sera également lié
                aux personnes détenues concernées par cette sollicitation.
              </p>
            </div>
            <div
              class="
                mt-5
                sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
              "
            >
              <button
                @click.prevent="displayfilecreation = true"
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  shadow-sm
                  font-medium
                  rounded-md
                  text-dark
                  bg-gray-200
                  hover:bg-blue-700 hover:text-white
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-blue-500
                  sm:text-sm
                  mb-2
                "
              >
                Ajouter un fichier
              </button>
              <button
                @click.prevent="displayfileattachment = true"
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  shadow-sm
                  font-medium
                  rounded-md
                  text-dark
                  bg-gray-200
                  hover:bg-blue-700 hover:text-white
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-blue-500
                  sm:text-sm
                  mb-2
                  ml-4
                "
              >
                Lier un fichier
              </button>
            </div>
          </div>
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
              class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
              v-if="sollicitation.files"
            >
              <div
                class="
                  shadow
                  overflow-hidden
                  border-b border-gray-200
                  sm:rounded-lg
                "
                v-if="sollicitation.files.length > 0"
              >
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="
                          px-6
                          py-3
                          text-left text-xs
                          font-medium
                          text-gray-500
                          uppercase
                          tracking-wider
                        "
                      >
                        Titre
                      </th>
                      <th
                        scope="col"
                        class="
                          px-6
                          py-3
                          text-left text-xs
                          font-medium
                          text-gray-500
                          uppercase
                          tracking-wider
                        "
                      >
                        Type
                      </th>
                      <th scope="col" class="relative px-6 py-3">
                        <span class="sr-only">Détails</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="file in sollicitation.files" :key="file.id">
                      <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900">
                          {{ file.title }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{
                            moment(file.created_at).format("dddd DD MMMM YYYY")
                          }}
                        </div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        <span
                          class="
                            px-2
                            inline-flex
                            text-xs
                            leading-5
                            font-semibold
                            rounded-full
                            bg-blue-100
                            text-blue-800
                          "
                        >
                          {{ file.type_id }}
                        </span>
                      </td>
                      <td
                        class="
                          px-6
                          py-4
                          whitespace-nowrap
                          text-right text-sm
                          font-medium
                        "
                      >
                        <a
                          @click.prevent="seeFile(file)"
                          class="
                            text-indigo-600
                            hover:text-indigo-900
                            cursor-pointer
                          "
                          >Voir le détail</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- file list ends -->
        <!-- followup list begins -->
        <div class="bg-white rounded-lg shadow px-5 py-10 sm:px-6 mt-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Suivis en cours
          </h3>
          <div class="mt-2 sm:flex sm:items-start sm:justify-between">
            <div class="max-w-xl text-sm text-gray-500">
              <p>
                Voici la liste des suivis en cours pour cette sollicitation.
              </p>
            </div>
            <div
              class="
                mt-5
                sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center
              "
            >
              <button
                @click.prevent="displayFollowupCreation"
                type="button"
                class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  shadow-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-red-600
                  hover:bg-red-700 hover:text-white
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-red-500
                  sm:text-sm
                "
              >
                Créer un suivi
              </button>
            </div>
          </div>
          <div class="bg-white shadow overflow-hidden sm:rounded-md mt-6">
            <ul role="list" class="divide-y divide-gray-200">
              <li
                v-for="followup in sollicitation.followups"
                :key="followup.id"
              >
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="text-sm font-medium text-indigo-600 truncate">
                      {{ followup.sollicitation.title }}
                    </p>
                    <div class="ml-2 flex-shrink-0 flex">
                      <p
                        v-if="followup.status == 'A faire'"
                        class="
                          px-2
                          inline-flex
                          text-xs
                          leading-5
                          font-semibold
                          rounded-full
                          bg-green-100
                          text-green-800
                        "
                      >
                        {{ followup.status }}
                      </p>
                      <p
                        v-if="followup.status == 'En retard'"
                        class="
                          px-2
                          inline-flex
                          text-xs
                          leading-5
                          font-semibold
                          rounded-full
                          bg-yellow-100
                          text-yellow-800
                        "
                      >
                        {{ followup.status }}
                      </p>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div>
                      <div class="sm:flex">
                        <p class="flex items-center text-sm text-gray-500">
                          <UserIcon
                            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          Attribué par {{ followup.origin.name }}
                          {{ followup.origin.surname }} à
                          {{ followup.follower.name }}
                          {{ followup.follower.surname }}
                        </p>
                        <p
                          class="
                            mt-2
                            flex
                            items-center
                            text-sm text-gray-500
                            sm:mt-0 sm:ml-6
                          "
                        >
                          <LocationMarkerIcon
                            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {{ followup.prison }}
                        </p>
                      </div>
                      <div>
                        <p
                          v-if="followup.comment"
                          class="mt-3 flex items-center text-sm text-gray-500"
                        >
                          <AnnotationIcon
                            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          "{{ followup.comment }}"
                        </p>
                      </div>
                    </div>
                    <div
                      class="
                        mt-2
                        flex
                        items-center
                        text-sm text-gray-500
                        sm:mt-0
                      "
                    >
                      <CalendarIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <p>
                        <time>
                          {{
                            moment(followup.date).format("dddd DD MMM YYYY")
                          }}</time
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- followup list ends -->
        <!-- close sollicitation -->
        <div class="bg-white shadow sm:rounded-lg mt-6">
          <div class="px-4 py-5 sm:p-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              <span v-if="enabled == '1'">Clore cette sollicitation</span>
              <span v-else>Réouvrir cette sollicitation</span>
            </h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <div v-if="enabled == '1'">
                <p>
                  Si cette sollicitation n'est plus active, soit parce que le
                  problème rencontré par la personne détenue soit parce qu'elle
                  est figée, vous pouvez la clore. Elle restera dans la base, il
                  s'agit juste de changer son statut pour vous permettre de
                  rester concentré.es sur les sollicitation ouvertes.
                </p>
              </div>
              <div v-else>
                <p>
                  Cette sollicitation est close mais peut-être réouverte, afin
                  de pouvoir prolonger les actions qui doivent être encore
                  menées ou tout simplement parce qu'un élément nouveau est
                  intervenu dans cette situation.
                </p>
              </div>
            </div>
            <div class="mt-5">
              <SwitchGroup as="div" class="flex items-center">
                <Switch
                  @click="changeSollicitationStatus"
                  v-model="enabled"
                  :class="[
                    enabled ? 'bg-red-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      enabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]"
                  />
                </Switch>
                <SwitchLabel as="span" class="ml-3">
                  <div v-if="enabled == '0'">
                    <span class="text-sm font-medium text-gray-900"
                      >Réouvrir cette sollicitation
                    </span>
                    <span class="text-sm text-gray-500"
                      >(à utiliser si cette sollicitation doit être
                      réactivée)</span
                    >
                  </div>
                  <div v-if="enabled == '1'">
                    <span class="text-sm font-medium text-gray-900"
                      >Clore cette sollicitation
                    </span>
                    <span class="text-sm text-gray-500"
                      >(cette sollicitation ne sera pas effacée, elle sera
                      simplement désactivée pour vous permettre de vous
                      concentrer sur celles qui sont actives)</span
                    >
                  </div>
                </SwitchLabel>
              </SwitchGroup>
            </div>
          </div>
        </div>
        <!-- close sollicitation ends -->
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import {
  CalendarIcon, MailIcon, PhoneIcon, LocationMarkerIcon, QrcodeIcon, UserIcon, AnnotationIcon, SpeakerphoneIcon, ExclamationCircleIcon, XIcon, CheckCircleIcon, PencilIcon,
} from '@heroicons/vue/solid';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import moment from 'moment';
import 'moment/locale/fr';
import SollicitationContacts from '@/components/Forms/SollicitationContacts.vue';
import AppNavbar from '@/components/AppNavbar.vue';
import AppNotification from '@/components/Modals/AppNotification.vue';
import ActionCreation from '@/components/Forms/ActionCreation.vue';
import FollowupCreation from '@/components/Forms/FollowupCreation.vue';
import ActionDetail from '@/components/Modals/ActionDetail.vue';
import ManageContacts from '@/components/Forms/ManageContacts.vue';
import EditSollicitationContent from '@/components/Modals/EditSollicitationContent.vue';
import EditSollicitationTitle from '@/components/Modals/EditSollicitationTitle.vue';
import FilePanel from '@/components/Modals/FilePanel.vue';
import FileCreation from '@/components/Forms/FileCreation.vue';
import FileAttachment from '@/components/Forms/FileAttachment.vue';

export default {
  components: {
    AppNavbar,
    ActionDetail,
    AppNotification,
    CalendarIcon,
    LocationMarkerIcon,
    MailIcon,
    PhoneIcon,
    QrcodeIcon,
    UserIcon,
    AnnotationIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    SollicitationContacts,
    ActionCreation,
    FollowupCreation,
    ManageContacts,
    EditSollicitationContent,
    SpeakerphoneIcon,
    ExclamationCircleIcon,
    CheckCircleIcon,
    PencilIcon,
    XIcon,
    EditSollicitationTitle,
    FilePanel,
    FileCreation,
    FileAttachment,
  },
  setup() {
    const sollicitation = ref();
    const enabled = ref();
    const title = ref();
    const displaynotification = ref();
    const displaycontacts = ref(false);
    const displayactioncreation = ref(false);
    const displayfollowupcreation = ref(false);
    const displayactiondetail = ref(false);
    const todaysDate = new Date();
    const managecontacts = ref(false);
    const editcontent = ref(false);
    const alreadyorigin = ref(false);
    const confirmorigin = ref(false);
    const edittitle = ref(false);
    const emergency = ref();
    const displayfilepanel = ref(false);
    const displayfilecreation = ref(false);
    const selectedfile = ref();
    const sollicitationcontext = ref(true);
    const displayfileattachment = ref(false);

    return {
      sollicitation,
      todaysDate,
      displaynotification,
      displayactioncreation,
      displayfollowupcreation,
      displayactiondetail,
      displaycontacts,
      enabled,
      title,
      managecontacts,
      editcontent,
      alreadyorigin,
      confirmorigin,
      edittitle,
      emergency,
      displayfilepanel,
      displayfilecreation,
      selectedfile,
      sollicitationcontext,
      displayfileattachment,
    };
  },
  created() {
    this.getSollicitation();
    this.moment = moment;
  },
  methods: {
    getSollicitation() {
      const { id } = this.$route.params;
      this.$http.get(`/api/sollicitations/${id}/`)
        .then((response) => {
          this.sollicitation = response.data;
          if (response.data.is_active === 0) {
            this.enabled = false;
          } else {
            this.enabled = true;
          }
          if (response.data.is_urgent === 0) {
            this.emergency = false;
          } else {
            this.emergency = true;
          }
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    changeSollicitationStatus() {
      let sollicitationstatus = this.enabled;
      if (this.enabled === false) {
        sollicitationstatus = 1;
      } else {
        sollicitationstatus = 0;
      }
      const { id } = this.$route.params;
      this.$http.patch('api/sollicitation-status/', {
        id,
        is_active: sollicitationstatus,
      }).then((response) => {
        this.success = response.data;
        if (response.data.is_active === 1) {
          this.title = 'Sollicitation active !';
          this.content = 'La sollicitation est active, vous pouvez donc continuer à la suivre et à gérer des actions';
        } else {
          this.title = 'Sollicitation close !';
          this.content = 'La sollicitation est close, vous devrez donc la réouvrir pour pouvoir la suivre et surtout effectuer des actions';
        }
        this.displaynotification = true;
      })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    changeSollicitationUrgency() {
      let emergencystatus = this.emergency;
      if (this.emergency === false) {
        emergencystatus = 1;
      } else {
        emergencystatus = 0;
      }
      window.console.log(emergencystatus);
      const { id } = this.$route.params;
      this.$http.patch('api/sollicitation-emergency/', {
        id,
        is_urgent: emergencystatus,
      }).then((response) => {
        this.success = response.data;
        if (response.data === 1) {
          this.title = 'Sollicitation urgente !';
          this.content = 'La sollicitation est urgente et donc prioritaire';
        } else {
          this.title = 'Sollicitation non urgente !';
          this.content = 'La sollicitation n\'est pas ou plus urgente';
        }
        this.displaynotification = true;
      })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    // changeSollicitationEmergency() {
    //   let emergencystatus = this.emergency;
    //   if (this.emergency === false) {
    //     emergencystatus = 1;
    //   } else {
    //     emergencystatus = 0;
    //   }
    //   const { id } = this.$route.params;
    //   this.$http.patch('api/sollicitation-emergency/', {
    //     id,
    //     is_urgent: emergencystatus,
    //   }).then((response) => {
    //     window.console.log(response.data);
    //   })
    //     .catch((error) => {
    //       window.console.log(error);
    //       this.errored = true;
    //     });
    // },
    closeAppNotification() {
      this.displaynotification = false;
    },
    editContacts() {
      this.displaycontacts = true;
    },
    closeSollicitationContacts() {
      this.displaycontacts = false;
      this.getSollicitation();
    },
    displayActionPanel() {
      this.displayactioncreation = true;
    },
    closeActionPanel() {
      this.displayactioncreation = false;
    },
    displayFollowupCreation() {
      this.displayfollowupcreation = true;
    },
    closeFollowupCreation() {
      this.displayfollowupcreation = false;
    },
    confirmFollowupCreation() {
      this.displayfollowupcreation = false;
      this.title = 'Le suivi a bien été enregistré !';
      this.content = 'Il apparaîtra sur le tableau de bord du membre de l\'équipe concerné';
      this.displaynotification = true;
      this.getSollicitation();
    },
    displayActionFocus(chosenAction) {
      this.displayactiondetail = true;
      this.actionfocused = chosenAction;
      this.actionstatus = chosenAction.is_closed;
    },
    closeActionFocus() {
      this.displayactiondetail = false;
    },
    statusChangedconfirm() {
      this.getSollicitation();
      this.displayactiondetail = false;
    },
    editOrigin() {
      this.managecontacts = true;
    },
    closeManageContacts() {
      this.managecontacts = false;
    },
    confirmUpdatedContacts() {
      this.closeManageContacts();
      this.getSollicitation();
    },
    closeContentEdition() {
      this.editcontent = false;
    },
    confirmContentEdition() {
      this.editcontent = false;
      this.getSollicitation();
    },
    makeOrigin(personId) {
      this.$http.patch('api/sollicitation-coinmates', {
        id: this.sollicitation.id,
        inmate_id: personId,
      }).then((response) => {
        window.console.log(response.data);
        this.getSollicitation();
        this.confirmorigin = true;
      }).catch((error) => {
        window.console.log(error);
        this.alreadyorigin = true;
      });
    },
    closeUpdateTitle() {
      this.edittitle = false;
    },
    confirmUpdatedTitle() {
      this.closeUpdateTitle();
      this.getSollicitation();
    },
    seeFile(selectedFile) {
      this.displayfilepanel = true;
      this.selectedfile = selectedFile;
    },
    closeFilePanel() {
      this.displayfilepanel = false;
      this.getSollicitation();
    },
    closeFileCreation() {
      this.displayfilecreation = false;
    },
    closeFileAttachment() {
      this.displayfileattachment = false;
    },
  },
};
</script>
