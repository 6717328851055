<template>
  <TransitionRoot as="template" :show="displayeventcreation">
    <Dialog
      as="div"
      class="fixed inset-0 overflow-hidden"
      @close="open = false"
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <form
                class="
                  h-full
                  divide-y divide-gray-200
                  flex flex-col
                  bg-white
                  shadow-xl
                "
              >
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-gray-800 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        Créez un événément dans l'historique <br />
                        de la personne détenue
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button
                          @click="$emit('shut')"
                          type="button"
                          class="
                            bg-gray-700
                            rounded-md
                            text-indigo-200
                            hover:text-white
                            focus:outline-none focus:ring-2 focus:ring-white
                          "
                        >
                          <span class="sr-only">Fermer</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-white">
                        Vous pouvez indiquer des changements de numéro SAGI, de
                        numéro d'écrou et d'établissement de cette personne.
                      </p>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <!-- if errored -->
                    <div
                      class="rounded-md bg-red-50 p-4"
                      v-if="errored == true"
                    >
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <XCircleIcon
                            class="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-red-800">
                            Oups, cet événement n'a pas été enregistré... La
                            date est indispensable, est-elle bien renseignée ?
                          </h3>
                        </div>
                      </div>
                    </div>
                    <!-- errored ends -->
                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <div>
                          <label
                            for="project-name"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Date
                          </label>
                          <div class="mt-1">
                            <input
                              type="datetime-local"
                              name="firstname"
                              id="firstname"
                              v-model="form.date"
                              class="
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            for="project-name"
                            class="block text-sm font-medium text-gray-900"
                          >
                            N° SAGI
                          </label>
                          <div class="mt-1">
                            <input
                              type="text"
                              name="lastname"
                              id="lastname"
                              v-model="form.sagi_number"
                              class="
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            for="project-name"
                            class="block text-sm font-medium text-gray-900"
                          >
                            Numéro d'écrou
                          </label>
                          <div class="mt-1">
                            <input
                              type="text"
                              name="inmate_number"
                              id="inmate_number"
                              :placeholder="this.inmate.current_number"
                              v-model="form.inmate_number"
                              class="
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                        </div>
                        <!-- choose prison -->
                        <div>
                          <label
                            for="email"
                            class="block text-sm font-medium text-gray-700 mt-6"
                            >Établissement concerné</label
                          >
                          <div class="mt-1">
                            <input
                              v-if="!chosenprison"
                              type="text"
                              name="prison"
                              v-model="search"
                              placeholder="Tapez quelques lettres de l'établissement recherché"
                              class="
                                shadow-sm
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                sm:text-sm
                                border-gray-300
                                rounded-md
                              "
                            />
                          </div>
                          <ul
                            role="list"
                            class="-my-5 divide-y divide-gray-200 mt-6"
                            v-if="search && !chosenprison"
                          >
                            <li
                              v-for="prison in matchingPrisons"
                              :key="prison.id"
                              class="py-4"
                            >
                              <div class="flex items-center space-x-4">
                                <div class="flex-1 min-w-0">
                                  <p
                                    class="
                                      text-sm
                                      font-medium
                                      text-gray-900
                                      truncate
                                    "
                                  >
                                    {{ prison.title }}
                                  </p>
                                </div>
                                <div>
                                  <a
                                    @click.prevent="choosePrison(prison)"
                                    class="
                                      inline-flex
                                      items-center
                                      shadow-sm
                                      px-2.5
                                      py-0.5
                                      text-sm
                                      leading-5
                                      font-medium
                                      rounded-full
                                      text-white
                                      bg-green-500
                                      hover:bg-green-700
                                      cursor-pointer
                                    "
                                  >
                                    Choisir
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div v-if="chosenprison">
                            <span
                              class="
                                inline-flex
                                rounded-full
                                items-center
                                py-0.5
                                pl-2.5
                                pr-1
                                text-sm
                                font-medium
                                bg-indigo-100
                                text-indigo-700
                                mt-6
                              "
                            >
                              {{ chosenprison.title }}
                              <button
                                @click.prevent="chosenprison = null"
                                type="button"
                                class="
                                  flex-shrink-0
                                  ml-0.5
                                  h-4
                                  w-4
                                  rounded-full
                                  inline-flex
                                  items-center
                                  justify-center
                                  text-indigo-400
                                  hover:bg-indigo-200 hover:text-indigo-500
                                  focus:outline-none
                                  focus:bg-indigo-500
                                  focus:text-white
                                "
                              >
                                <!-- <span class="sr-only">Remove large option</span> -->
                                <svg
                                  class="h-2 w-2"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 8 8"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-width="1.5"
                                    d="M1 1l6 6m0-6L1 7"
                                  />
                                </svg>
                              </button>
                            </span>
                          </div>
                        </div>
                        <!-- choose prison ends -->
                        <div class="relative flex items-start">
                          <div class="flex h-5 items-center">
                            <input
                              id="freeing"
                              v-model="form.freeing"
                              aria-describedby="freeing-description"
                              name="freeing"
                              type="checkbox"
                              class="
                                h-4
                                w-4
                                rounded
                                border-gray-300
                                text-indigo-600
                                focus:ring-indigo-500
                              "
                            />
                          </div>
                          <div class="ml-3 text-sm">
                            <label
                              for="freeing"
                              class="font-medium text-gray-700"
                              >Sortie ?</label
                            >
                            <p
                              id="candidates-description"
                              class="text-gray-500"
                            >
                              Cochez cette case si cette personne a été libérée.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button
                    @click="$emit('shut')"
                    type="button"
                    class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                  >
                    J'annule
                  </button>
                  <button
                    @click.prevent="createEvent"
                    type="submit"
                    class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-red-600
                      hover:bg-red-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-indigo-500
                    "
                  >
                    Créer
                  </button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, computed } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XCircleIcon } from '@heroicons/vue/solid';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    XCircleIcon,
  },
  setup() {
    const prisons = ref();
    const errored = ref();
    const search = ref();
    const searchquery = computed(() => search.value.toLowerCase());
    const matchingPrisons = computed(() => prisons.value.filter((prison) => prison.title.toLowerCase().includes(searchquery.value)));
    const chosenprison = ref();

    return {
      prisons,
      matchingPrisons,
      chosenprison,
      errored,
      search,
      form: {
        prison_id: null,
        inmate_number: null,
        sagi_number: null,
        date: null,
        freeing: false,
      },
    };
  },
  props: {
    displayeventcreation: Boolean,
    inmate: Object,
  },
  created() {
    this.moment = moment;
    const proposeddate = moment(new Date()).format('YYYY-MM-DDTHH:mm');
    this.form.date = proposeddate;
    this.$http.get('/api/prisons')
      .then((response) => {
        this.prisons = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
  methods: {
    createEvent() {
      this.$http.post('/api/event-creation/', {
        inmate_id: this.inmate.id,
        prison_id: this.form.prison_id,
        inmate_number: this.form.inmate_number,
        sagi_number: this.form.sagi_number,
        date: this.form.date,
        freeing: this.form.freeing,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('refresh');
        this.$emit('shut');
        this.form.prison_id = null;
        this.form.sagi_number = null;
        this.form.inmate_number = null;
        this.form.date = null;
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
    choosePrison(chosenPrison) {
      this.chosenprison = chosenPrison;
      this.form.prison_id = chosenPrison.id;
    },
  },
};
</script>
