<template>
  <Transition>
    <router-view />
  </Transition>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  mounted() {
    this.$http.get('/')
      .then((data) => console.log(data))
      .catch((error) => console.log(error));
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
