<template>
  <div class="min-h-full">
    <div class="bg-gray-800 pb-32">
      <AppNavbar />
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">Contacts</h1>
          <h3 class="text-2xl text-white">
            Vous trouverez, ici, l'ensemble des contacts, pour l'ensemble des
            dossiers.
          </h3>
        </div>
      </header>
    </div>
    <main class="-mt-32">
      <ContactDetail
        v-show="seedetail"
        v-model:seedetail="seedetail"
        v-model:contact="chosencontact"
        @shut="hideDetail"
        @contactupdated="confirmUpdate"
      />
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
          <!-- Search contacts -->
          <div class="mb-4" v-if="!usersearch">
            <label for="search" class="block text-sm font-medium text-gray-700"
              >Recherchez avec <span class="font-bold">1 mot-clé</span></label
            >
            <div class="mt-1 relative flex items-center">
              <input
                @keyup.enter="searchContacts()"
                @keydown.space.prevent
                type="text"
                name="search"
                v-model="search"
                id="search"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  pr-12
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                placeholder="Vous pouvez rechercher un mot clé le nom, prénom, la prison, l'email ou le numéro de téléphone d'un contact"
              />
              <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                <kbd
                  class="
                    inline-flex
                    items-center
                    border border-gray-200
                    rounded
                    px-2
                    text-sm
                    font-sans font-medium
                    text-gray-400
                  "
                >
                  Entrer
                </kbd>
              </div>
            </div>
          </div>
          <!-- Search ends -->
          <!-- Search query -->
          <div class="mb-4" v-if="usersearch">
            Vous avez recherché :
            <span
              class="
                inline-flex
                rounded-full
                items-center
                py-0.5
                pl-2.5
                pr-1
                text-sm
                font-medium
                bg-indigo-100
                text-indigo-700
              "
            >
              {{ usersearch }}
              <button
                @click.prevent="resetSearch"
                type="button"
                class="
                  flex-shrink-0
                  ml-0.5
                  h-4
                  w-4
                  rounded-full
                  inline-flex
                  items-center
                  justify-center
                  text-indigo-400
                  hover:bg-indigo-200 hover:text-indigo-500
                  focus:outline-none focus:bg-indigo-500 focus:text-white
                "
              >
                <span class="sr-only">Réinitialisez</span>
                <svg
                  class="h-2 w-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-width="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </span>
          </div>
          <!-- Search query ends -->
          <div v-if="contacts">
            <div class="flex flex-col" v-if="contacts.data.length > 0">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="
                    py-2
                    align-middle
                    inline-block
                    min-w-full
                    sm:px-6
                    lg:px-8
                  "
                >
                  <div
                    class="
                      shadow
                      overflow-hidden
                      border-b border-gray-200
                      sm:rounded-lg
                    "
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Prénom et nom
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Genre
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Date de création
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Téléphone
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Personne détenue
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">En détail</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="contact in contacts.data" :key="contact.id">
                          <td
                            class="px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ contact.firstname }} {{ contact.lastname }}
                          </td>
                          <td class="px-6 py-4 text-sm text-gray-500">
                            <span v-if="contact.gender">{{
                              contact.gender.label
                            }}</span>
                          </td>
                          <td class="px-6 py-4 text-sm text-gray-500">
                            {{
                              moment(contact.created_at).format(
                                "ddd DD MMM YYYY"
                              )
                            }}
                          </td>
                          <td class="px-6 py-4 text-sm text-gray-500">
                            {{ contact.email }}
                          </td>
                          <td class="px-6 py-4 text-sm text-gray-500">
                            {{ contact.phone }}
                          </td>
                          <td class="px-6 py-4 text-sm text-gray-500">
                            <span
                              v-for="inmate in contact.inmates"
                              :key="inmate.id"
                            >
                              <a
                                :href="
                                  /personnes-detenues/ +
                                  'infos' +
                                  '/' +
                                  inmate.id
                                "
                                >{{ inmate.firstname }} {{ inmate.lastname }}</a
                              >
                              <br
                            /></span>
                          </td>
                          <td class="px-6 py-4 text-right text-sm font-medium">
                            <a
                              @click.prevent="showDetail(contact)"
                              class="
                                text-indigo-600
                                hover:text-indigo-900
                                cursor-pointer
                              "
                              >Détails</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- pagination  -->
            <div v-if="contacts.data.length > 0">
              <nav
                v-if="!usersearch"
                class="
                  bg-white
                  px-4
                  py-3
                  flex
                  items-center
                  justify-between
                  border-t border-gray-200
                  sm:px-6
                "
                aria-label="Pagination"
              >
                <div class="hidden sm:block">
                  <p class="text-sm text-gray-700">
                    Page
                    {{ " " }}
                    <span class="font-medium">{{ currentpage }}</span>
                    {{ " " }}
                    sur
                    {{ " " }}
                    <span class="font-medium">{{ contacts.last_page }}</span>
                    {{ " " }}
                    de
                    {{ " " }}
                    <span class="font-medium">{{ contacts.total }}</span>
                    {{ " " }}
                    résultats
                  </p>
                </div>
                <div class="flex-1 flex justify-between sm:justify-end">
                  <button
                    @click.prevent="goPreviouspage"
                    :disabled="currentpage < 2"
                    :class="currentpage < 2 ? 'cursor-not-allowed' : ''"
                    class="
                      relative
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-gray-300
                      text-sm
                      font-medium
                      rounded-md
                      text-gray-700
                      bg-white
                      hover:bg-gray-50
                      cursor-pointer
                    "
                  >
                    Précédents
                  </button>
                  <button
                    @click.prevent="goNextpage"
                    :disabled="currentpage == contacts.last_page"
                    :class="
                      currentpage == contacts.last_page
                        ? 'cursor-not-allowed'
                        : ''
                    "
                    class="
                      ml-3
                      relative
                      inline-flex
                      items-center
                      px-4
                      py-2
                      border border-gray-300
                      text-sm
                      font-medium
                      rounded-md
                      text-gray-700
                      bg-white
                      hover:bg-gray-50
                      cursor-pointer
                    "
                  >
                    Suivants
                  </button>
                </div>
              </nav>
            </div>
            <!-- pagination ends -->
          </div>
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import AppNavbar from '@/components/AppNavbar.vue';
import ContactDetail from '@/components/Modals/ContactDetail.vue';

export default {
  components: {
    AppNavbar,
    ContactDetail,
  },
  setup() {
    const contacts = ref();
    const usersearch = ref();
    const currentpage = ref();
    const search = ref();
    const seedetail = ref(false);

    return {
      contacts,
      usersearch,
      currentpage,
      search,
      seedetail,
    };
  },
  created() {
    this.moment = moment;
    this.currentpage = 1;
    this.getResults();
  },
  methods: {
    getResults() {
      this.$http.get(`api/contacts?page=${this.currentpage}`)
        .then((response) => {
          this.contacts = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    goNextpage() {
      this.currentpage += 1;
      this.getResults();
    },
    goPreviouspage() {
      this.currentpage -= 1;
      this.getResults();
    },
    searchContacts() {
      this.usersearch = this.search;
      this.$http.get(`api/contacts-search/${this.search}`)
        .then((response) => {
          this.contacts.data = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    resetSearch() {
      this.usersearch = null;
      this.search = null;
      this.getResults();
    },
    showDetail(thisGuy) {
      this.seedetail = true;
      this.chosencontact = thisGuy;
    },
    hideDetail() {
      this.seedetail = false;
    },
    confirmUpdate() {
      this.seedetail = false;
      this.getResults();
    },
  },
};
</script>
