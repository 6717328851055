<template>
  <TransitionRoot as="template" :show="displaycontactcreation">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Créer un contact
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Vous pouvez créer un contact pour cette personne.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <!-- info  -->
                  <div class="rounded-md bg-blue-50 p-4 mb-6">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <InformationCircleIcon
                          class="h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p class="text-sm text-blue-700">
                          Un contact peut être lié à plusieurs personnes
                          détenues. Après sa création, le contact sera
                          automatiquement lié à
                          <span class="font-bold"
                            >{{ inmate.firstname }} {{ inmate.lastname }}</span
                          >.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- info ends -->
                  <!-- errored -->
                  <div
                    class="rounded-md bg-red-50 p-4 mb-6"
                    v-if="errored == true"
                  >
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <XCircleIcon
                          class="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">
                          Oups, le contact n'a pas pu être ajouté... Vérifiez
                          que tous les champs sont remplis. Les champs Nom et
                          Prénom sont obligatoires.
                        </h3>
                      </div>
                    </div>
                  </div>

                  <!-- errored ends -->
                  <!-- form  -->
                  <h4 class="font-bold text-xl text-gray-900 sm:text-2xl mb-2">
                    Créer et ajouter un nouveau contact
                  </h4>
                  <label class="block text-sm font-medium text-gray-700 mb-2"
                    >Vous pouvez créer et ajouter un nouvelle personne en
                    remplissant les champs ci-dessous</label
                  >
                  <div>
                    <label
                      for="project-name"
                      class="block text-sm font-medium text-gray-900"
                    >
                      Genre
                    </label>
                    <div class="mt-1">
                      <select
                        id="gender"
                        name="gender"
                        autocomplete="gender"
                        v-model="contact.gender_id"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      >
                        <option
                          v-for="gender in genders"
                          :key="gender.id"
                          :value="gender.id"
                        >
                          {{ gender.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-2">
                    <label
                      for="firstname"
                      class="block text-sm font-medium text-gray-700"
                      >Prénom</label
                    >
                    <div class="mt-1">
                      <input
                        type="firstname"
                        name="firstname"
                        id="firstname"
                        v-model="contact.firstname"
                        placeholder="Jacques"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div class="mt-2">
                    <label
                      for="lastname"
                      class="block text-sm font-medium text-gray-700"
                      >Nom</label
                    >
                    <div class="mt-1">
                      <input
                        type="lastname"
                        name="lastname"
                        id="lastname"
                        v-model="contact.lastname"
                        placeholder="Dupont"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div class="mt-2">
                    <label
                      for="lastname"
                      class="block text-sm font-medium text-gray-700"
                      >Email</label
                    >
                    <div class="mt-1">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        v-model="contact.email"
                        placeholder="prenom@acme.org"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div class="mt-2">
                    <label
                      for="phone"
                      class="block text-sm font-medium text-gray-700"
                      >Téléphone</label
                    >
                    <div class="mt-1">
                      <input
                        type="phone"
                        name="phone"
                        id="phone"
                        v-model="contact.phone"
                        placeholder="01 50 50 50 50"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div class="mt-2">
                    <label
                      for="address"
                      class="block text-sm font-medium text-gray-700"
                      >Ajouter une adresse complète</label
                    >
                    <div class="mt-1">
                      <textarea
                        rows="4"
                        name="address"
                        v-model="contact.address"
                        id="address"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div class="mt-4">
                    <label
                      for="bound"
                      class="block text-sm font-medium text-gray-700"
                      >Lien</label
                    >
                    <div class="mt-1">
                      <select
                        id="bound"
                        name="bound"
                        v-model="contact.bound_id"
                        class="
                          mt-1
                          block
                          w-full
                          pl-3
                          pr-10
                          py-2
                          text-base
                          border-gray-300
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                          rounded-md
                        "
                      >
                        <option
                          v-for="bound in bounds"
                          :key="bound.id"
                          :value="bound.id"
                        >
                          {{ bound.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- form ends -->
                  <!-- /End replace -->
                </div>
                <!-- Action buttons -->
                <div
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('shut')"
                    >
                      J'annule
                    </button>
                    <button
                      @click.prevent="createContact"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Créer le contact
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { XCircleIcon } from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    XCircleIcon,
  },
  setup() {
    const open = ref(true);
    const errored = ref(false);
    const bounds = ref();
    const genders = ref();

    return {
      open,
      errored,
      bounds,
      genders,
      contact: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        address: null,
        bound_id: null,
        gender_id: null,
      },
    };
  },
  props: {
    inmate: Object,
    displaycontactcreation: Boolean,
  },
  created() {
    this.$http.get('/api/bounds')
      .then((response) => {
        this.bounds = response.data;
      })
      .catch((error) => {
        window.console.log(error);
      });
    this.$http.get('/api/genders')
      .then((response) => {
        this.genders = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
  methods: {
    createContact() {
      this.$http.post('/api/contact-creation/', {
        gender_id: this.contact.gender_id,
        firstname: this.contact.firstname,
        lastname: this.contact.lastname,
        email: this.contact.email,
        phone: this.contact.phone,
        address: this.contact.address,
      }).then((response) => {
        this.$http.patch('api/inmate-contact-add', {
          id: this.inmate.id,
          contact_id: response.data.id,
          bound_id: this.contact.bound_id,
        }).then(() => {
          this.$emit('contactadded');
          this.$emit('shut');
        });
      }).then(() => {
        this.contact.firstname = null;
        this.contact.lastname = null;
        this.contact.email = null;
        this.contact.phone = null;
        this.contact.bound_id = null;
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
  },
};
</script>
