<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="min-h-full">
    <div class="bg-gray-800 pb-32">
      <AppNavbar />
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">Établissements</h1>
          <h3 class="text-2xl text-white">
            Vous trouverez ici la liste de l'ensemble des établissements
          </h3>
        </div>
      </header>
    </div>

    <main class="-mt-32">
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="bg-white rounded-lg shadow px-5 py-10 sm:px-6">
          <!-- Search Prisons -->
          <div class="mb-4" v-if="!usersearch">
            <label for="search" class="block text-sm font-medium text-gray-700"
              >Recherchez avec <span class="font-bold">1 mot-clé</span></label
            >
            <div class="mt-1 relative flex items-center">
              <input
                @keyup.enter="searchPrisons()"
                @keydown.space.prevent
                type="text"
                name="search"
                v-model="search"
                id="search"
                class="
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  pr-12
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                placeholder="Vous pouvez rechercher un mot clé du nom, du DISP, ou de l'adresse d'un établissement"
              />
              <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                <kbd
                  class="
                    inline-flex
                    items-center
                    border border-gray-200
                    rounded
                    px-2
                    text-sm
                    font-sans font-medium
                    text-gray-400
                  "
                >
                  Entrer
                </kbd>
              </div>
            </div>
          </div>
          <!-- Search ends -->
          <!-- Search query -->
          <div class="mb-4" v-if="usersearch">
            Vous avez recherché :
            <span
              class="
                inline-flex
                rounded-full
                items-center
                py-0.5
                pl-2.5
                pr-1
                text-sm
                font-medium
                bg-indigo-100
                text-indigo-700
              "
            >
              {{ usersearch }}
              <button
                @click.prevent="resetSearch"
                type="button"
                class="
                  flex-shrink-0
                  ml-0.5
                  h-4
                  w-4
                  rounded-full
                  inline-flex
                  items-center
                  justify-center
                  text-indigo-400
                  hover:bg-indigo-200 hover:text-indigo-500
                  focus:outline-none focus:bg-indigo-500 focus:text-white
                "
              >
                <span class="sr-only">Réinitialisez</span>
                <svg
                  class="h-2 w-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-width="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </span>
          </div>
          <!-- Search query ends -->
          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div
                class="
                  py-2
                  align-middle
                  inline-block
                  min-w-full
                  sm:px-6
                  lg:px-8
                "
              >
                <div
                  class="
                    shadow
                    overflow-hidden
                    border-b border-gray-200
                    sm:rounded-lg
                  "
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Établissement
                        </th>
                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Dossiers
                        </th>
                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Sollicitations
                        </th>
                        <th scope="col" class="relative px-6 py-3">
                          <span class="sr-only">Détails</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(prison, prisonIdx) in prisons"
                        :key="prisonIdx"
                        :class="prisonIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      >
                        <td
                          class="
                            px-6
                            py-4
                            whitespace-nowrap
                            text-sm
                            font-medium
                            text-gray-900
                          "
                        >
                          {{ prison.title }} <br />
                          <span class="text-gray-500">{{
                            prison.disp_id
                          }}</span>
                        </td>
                        <td
                          class="
                            px-6
                            py-4
                            whitespace-nowrap
                            text-sm text-gray-500
                          "
                        >
                          <span v-if="prison.inmatescount > 0">{{
                            prison.inmatescount
                          }}</span>
                          <span v-else class="text-gray-200"> - </span>
                        </td>
                        <td
                          class="
                            px-6
                            py-4
                            whitespace-nowrap
                            text-sm text-gray-500
                          "
                        >
                          <span v-if="prison.sollicitationscount > 0">{{
                            prison.sollicitationscount
                          }}</span>
                          <span v-else class="text-gray-200"> - </span>
                        </td>
                        <td
                          class="
                            px-6
                            py-4
                            whitespace-nowrap
                            text-right text-sm
                            font-medium
                          "
                        >
                          <a
                            :href="/etablissements/ + 'infos' + '/' + prison.id"
                            class="text-indigo-600 hover:text-indigo-900"
                            >En détails</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- pagination  -->
            <div v-if="prisons && !usersearch">
              <div v-if="prisons.length > 0">
                <nav
                  class="
                    bg-white
                    px-4
                    py-3
                    flex
                    items-center
                    justify-between
                    border-t border-gray-200
                    sm:px-6
                  "
                  aria-label="Pagination"
                >
                  <div class="hidden sm:block">
                    <p class="text-sm text-gray-700">
                      Page
                      {{ " " }}
                      <span class="font-medium">{{ currentpage }}</span>
                      {{ " " }}
                      sur
                      {{ " " }}
                      <span class="font-medium">{{ prisons.last_page }}</span>
                      {{ " " }}
                      de
                      {{ " " }}
                      <span class="font-medium">{{ prisons.total }}</span>
                      {{ " " }}
                      résultats
                    </p>
                  </div>
                  <div class="flex-1 flex justify-between sm:justify-end">
                    <button
                      @click.prevent="goPreviouspage"
                      :disabled="currentpage < 2"
                      :class="currentpage < 2 ? 'cursor-not-allowed' : ''"
                      class="
                        relative
                        inline-flex
                        items-center
                        px-4
                        py-2
                        border border-gray-300
                        text-sm
                        font-medium
                        rounded-md
                        text-gray-700
                        bg-white
                        hover:bg-gray-50
                        cursor-pointer
                      "
                    >
                      Précédentes
                    </button>
                    <button
                      @click.prevent="goNextpage"
                      :disabled="currentpage == prisons.last_page"
                      :class="
                        currentpage == prisons.last_page
                          ? 'cursor-not-allowed'
                          : ''
                      "
                      class="
                        ml-3
                        relative
                        inline-flex
                        items-center
                        px-4
                        py-2
                        border border-gray-300
                        text-sm
                        font-medium
                        rounded-md
                        text-gray-700
                        bg-white
                        hover:bg-gray-50
                        cursor-pointer
                      "
                    >
                      Suivantes
                    </button>
                  </div>
                </nav>
              </div>
            </div>
            <!-- pagination ends -->
          </div>
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import AppNavbar from '@/components/AppNavbar.vue';

export default {
  components: {
    AppNavbar,
  },
  setup() {
    const prisons = ref([]);
    const search = ref('');
    const usersearch = ref();
    const currentpage = ref(1);

    return {
      prisons,
      search,
      usersearch,
      currentpage,
    };
  },
  created() {
    this.moment = moment;
    this.getPrisons();
  },
  methods: {
    getPrisons() {
      this.$http.get(`api/prisons/paginated?page=${this.currentpage}`)
        .then((response) => {
          this.prisons = response.data.data;
          this.prisons.last_page = response.data.last_page;
          this.prisons.total = response.data.total;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    searchPrisons() {
      this.usersearch = this.search;
      this.$http.get(`api/prisons-search/${this.search}`)
        .then((response) => {
          this.prisons = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    resetSearch() {
      this.usersearch = null;
      this.search = null;
      // this.selecteddisp = null;
      this.getPrisons();
    },
    goNextpage() {
      this.currentpage += 1;
      this.getPrisons();
    },
    goPreviouspage() {
      this.currentpage -= 1;
      this.getPrisons();
    },
  },
};
</script>
