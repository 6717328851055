<template>
  <TransitionRoot as="template" :show="displaycontent">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-2xl">
              <div
                class="
                  h-full
                  flex flex-col
                  py-6
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle
                      class="text-lg font-medium text-gray-900"
                      v-if="sollicitation"
                    >
                      Detail du contenu de la sollicitation : "{{
                        sollicitation.title
                      }}"
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          bg-white
                          rounded-md
                          text-gray-400
                          hover:text-gray-500
                          focus:outline-none
                          focus:ring-2
                          focus:ring-offset-2
                          focus:ring-indigo-500
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="mt-6 relative flex-1 px-4 sm:px-6"
                  v-if="sollicitation"
                >
                  <!-- Replace with your content -->
                  <p v-html="sollicitation.content"></p>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const open = ref(true);

    return {
      open,
    };
  },
  props: {
    displaycontent: Boolean,
    sollicitation: Object,
  },
};
</script>
