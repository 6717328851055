<template>
  <!-- eslint-disable max-len -->
  <div class="flex-1 flex items-stretch overflow-hidden">
    <main class="flex-1 overflow-y-auto">
      <!-- Primary column -->
      <section
        aria-labelledby="primary-heading"
        class="min-w-0 flex-1 h-full flex flex-col lg:order-last"
      >
        <!-- general infos form -->
        <!-- error begins -->
        <div class="rounded-md bg-red-50 p-4 mr-6 mb-4" v-if="errored">
          <div class="flex">
            <div class="flex-shrink-0">
              <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                Oups, votre sollicitation n'a pas pu être créée...
              </h3>
              <div class="mt-2 text-sm text-red-700">
                <ul role="list" class="list-disc pl-5 space-y-1">
                  <li>
                    Vérifiez que les champs sont tous bien remplis, y compris la
                    date, qui est obligatoire
                  </li>
                  <li>
                    Si ça ne résoud pas votre problème, rapprochez-vous de
                    l'administrateur
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- error ends -->
        <form
          class="space-y-8 divide-y divide-gray-200 mr-6 mb-12"
          v-if="currentstep == 1"
        >
          <div class="space-y-8 divide-y divide-gray-200">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Créer une sollicitation
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Vous allez créer une sollicitation pour cette personne. Chaque
                sollicitation représente une problématique rencontrée par cette
                personne. Si elle en rencontre plusieurs, vous ne pouvez donc
                pas les grouper dans une seule sollicitation.
              </p>
            </div>
            <div
              class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pt-8"
            >
              <div class="sm:col-span-8">
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Date de la sollicitation
                </label>
                <div class="rounded-md bg-yellow-50 p-4 mt-4 mb-4">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <ExclamationIcon
                        class="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ml-3">
                      <h3 class="text-sm font-medium text-yellow-800">
                        Il s'agit de la date de notification de la
                        sollicitation. Est-ce bien la date à laquelle nous avons
                        reçu le signalement ?
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="mt-1">
                  <input
                    id="date"
                    name="date"
                    type="datetime-local"
                    v-model="form.date"
                    class="
                      shadow-sm
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                  />
                </div>
              </div>
              <div class="sm:col-span-8">
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Titre de la sollicitation (afin de l'identifier plus
                  facilement et/ou de la résumer en quelques mots)
                </label>
                <div class="mt-1">
                  <input
                    id="title"
                    name="title"
                    type="text"
                    v-model="form.title"
                    class="
                      shadow-sm
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                  />
                </div>
              </div>

              <div class="sm:col-span-8">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700"
                >
                  Établissement
                </label>
                <div class="mt-1" v-if="!chosenprison">
                  <label
                    for="name"
                    class="block text-xs font-medium text-gray-900 mb-2"
                    >Recherchez par nom d'établissement</label
                  >
                  <input
                    v-model="search"
                    type="text"
                    name="search"
                    id="search"
                    class="
                      shadow-sm
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                    placeholder="ex : Marseille"
                  />
                </div>
                <ul
                  role="list"
                  class="-my-5 divide-y divide-gray-200 mt-6"
                  v-if="search && !chosenprison"
                >
                  <li
                    v-for="prison in matchingPrisons"
                    :key="prison.id"
                    class="py-4"
                  >
                    <div class="flex items-center space-x-4">
                      <div class="flex-1 min-w-0">
                        <p class="text-sm font-medium text-gray-900 truncate">
                          {{ prison.title }}
                        </p>
                      </div>
                      <div>
                        <a
                          @click.prevent="choosePrison(prison)"
                          class="
                            inline-flex
                            items-center
                            shadow-sm
                            px-2.5
                            py-0.5
                            text-sm
                            leading-5
                            font-medium
                            rounded-full
                            text-white
                            bg-green-500
                            hover:bg-green-700
                            cursor-pointer
                          "
                        >
                          Choisir
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
                <p
                  class="text-sm text-gray-500 mt-4"
                  v-if="!search && !chosenprison"
                >
                  Tapez quelques lettres du nom de l'établissement pour choisir
                  l'établissement destinataire du courrier.
                </p>
                <div v-if="chosenprison">
                  <span
                    class="
                      inline-flex
                      rounded-full
                      items-center
                      py-0.5
                      pl-2.5
                      pr-1
                      text-sm
                      font-medium
                      bg-indigo-100
                      text-indigo-700
                      mt-2
                    "
                  >
                    {{ chosenprison.title }}
                    <button
                      @click.prevent="chosenprison = null"
                      type="button"
                      class="
                        flex-shrink-0
                        ml-0.5
                        h-4
                        w-4
                        rounded-full
                        inline-flex
                        items-center
                        justify-center
                        text-indigo-400
                        hover:bg-indigo-200 hover:text-indigo-500
                        focus:outline-none focus:bg-indigo-500 focus:text-white
                      "
                    >
                      <!-- <span class="sr-only">Remove large option</span> -->
                      <svg
                        class="h-2 w-2"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 8 8"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-width="1.5"
                          d="M1 1l6 6m0-6L1 7"
                        />
                      </svg>
                    </button>
                  </span>
                </div>
              </div>
              <div class="sm:col-span-8">
                <div class="rounded-md bg-blue-50 p-4 mt-6">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <InformationCircleIcon
                        class="h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                      <p class="text-sm text-blue-700">
                        Si vous choississez directement le sous-thème, le thème
                        ne s'affichera pas ici mais
                        <span class="font-bold">sera bien pris en compte</span>
                        dans le backend de l'application.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sm:col-span-8">
                <Combobox as="div" v-model="selectedTheme">
                  <ComboboxLabel class="block text-sm font-medium text-gray-700"
                    >Thème</ComboboxLabel
                  >
                  <div class="relative mt-1">
                    <ComboboxInput
                      class="
                        w-full
                        rounded-md
                        border border-gray-300
                        bg-white
                        py-2
                        pl-3
                        pr-10
                        shadow-sm
                        focus:border-indigo-500
                        focus:outline-none
                        focus:ring-1
                        focus:ring-indigo-500
                        sm:text-sm
                      "
                      @change="query = $event.target.value"
                      :display-value="(theme) => theme.title"
                    />
                    <ComboboxButton
                      class="
                        absolute
                        inset-y-0
                        right-0
                        flex
                        items-center
                        rounded-r-md
                        px-2
                        focus:outline-none
                      "
                    >
                      <SelectorIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </ComboboxButton>

                    <ComboboxOptions
                      v-if="filteredThemes.length > 0"
                      class="
                        absolute
                        z-10
                        mt-1
                        max-h-60
                        w-full
                        overflow-auto
                        rounded-md
                        bg-white
                        py-1
                        text-base
                        shadow-lg
                        ring-1 ring-black ring-opacity-5
                        focus:outline-none
                        sm:text-sm
                      "
                    >
                      <ComboboxOption
                        v-for="theme in filteredThemes"
                        :key="theme.id"
                        :value="theme"
                        as="template"
                        v-slot="{ active, selected }"
                      >
                        <li
                          :class="[
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900',
                          ]"
                        >
                          <span
                            :class="[
                              'block truncate',
                              selected && 'font-semibold',
                            ]"
                          >
                            {{ theme.title }}
                          </span>

                          <span
                            v-if="selected"
                            :class="[
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ComboboxOption>
                    </ComboboxOptions>
                    <ComboboxOptions
                      v-else
                      class="
                        absolute
                        z-10
                        mt-1
                        max-h-60
                        w-full
                        overflow-auto
                        rounded-md
                        bg-white
                        py-1
                        text-base
                        shadow-lg
                        ring-1 ring-black ring-opacity-5
                        focus:outline-none
                        sm:text-sm
                      "
                    >
                      <ComboboxOption
                        v-for="theme in themes"
                        :key="theme.id"
                        :value="theme"
                        as="template"
                        v-slot="{ active, selected }"
                      >
                        <li
                          :class="[
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                            active
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900',
                          ]"
                        >
                          <span
                            :class="[
                              'block truncate',
                              selected && 'font-semibold',
                            ]"
                          >
                            {{ theme.title }}
                          </span>

                          <span
                            v-if="selected"
                            :class="[
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ComboboxOption>
                    </ComboboxOptions>
                  </div>
                </Combobox>
              </div>

              <div class="sm:col-span-8">
                <div v-if="selectedTheme">
                  <div v-if="selectedTheme.subthemes.length > 0">
                    <label
                      for="Subtheme"
                      class="block text-sm font-medium text-gray-700 mt-6 mb-1"
                      >Sous-thème</label
                    >
                    <select
                      id="subtheme"
                      name="subtheme"
                      autocomplete="subtheme"
                      v-model="selectedSubtheme"
                      class="
                        shadow-sm
                        focus:ring-indigo-500 focus:border-indigo-500
                        block
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                    >
                      <option
                        v-for="subtheme in selectedTheme.subthemes"
                        :key="subtheme.id"
                        :value="subtheme"
                      >
                        {{ subtheme.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mt-6" v-if="!selectedTheme">
                  <Combobox as="div" v-model="selectedSubtheme">
                    <ComboboxLabel
                      class="block text-sm font-medium text-gray-700"
                      >Sous-thème</ComboboxLabel
                    >
                    <div class="relative mt-1">
                      <ComboboxInput
                        class="
                          w-full
                          rounded-md
                          border border-gray-300
                          bg-white
                          py-2
                          pl-3
                          pr-10
                          shadow-sm
                          focus:border-indigo-500
                          focus:outline-none
                          focus:ring-1
                          focus:ring-indigo-500
                          sm:text-sm
                        "
                        @change="querySubtheme = $event.target.value"
                        :display-value="(subtheme) => subtheme.title"
                      />
                      <ComboboxButton
                        class="
                          absolute
                          inset-y-0
                          right-0
                          flex
                          items-center
                          rounded-r-md
                          px-2
                          focus:outline-none
                        "
                      >
                        <SelectorIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </ComboboxButton>

                      <ComboboxOptions
                        v-if="filteredSubthemes.length > 0"
                        class="
                          absolute
                          z-10
                          mt-1
                          max-h-60
                          w-full
                          overflow-auto
                          rounded-md
                          bg-white
                          py-1
                          text-base
                          shadow-lg
                          ring-1 ring-black ring-opacity-5
                          focus:outline-none
                          sm:text-sm
                        "
                      >
                        <ComboboxOption
                          v-for="subtheme in filteredSubthemes"
                          :key="subtheme.id"
                          :value="subtheme"
                          as="template"
                          v-slot="{ active, selected }"
                        >
                          <li
                            :class="[
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                              active
                                ? 'bg-indigo-600 text-white'
                                : 'text-gray-900',
                            ]"
                          >
                            <span
                              :class="[
                                'block truncate',
                                selected && 'font-semibold',
                              ]"
                            >
                              {{ subtheme.title }}
                            </span>

                            <span
                              v-if="selected"
                              :class="[
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-indigo-600',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ComboboxOption>
                      </ComboboxOptions>
                      <ComboboxOptions
                        v-else-if="selectedTheme"
                        class="
                          absolute
                          z-10
                          mt-1
                          max-h-60
                          w-full
                          overflow-auto
                          rounded-md
                          bg-white
                          py-1
                          text-base
                          shadow-lg
                          ring-1 ring-black ring-opacity-5
                          focus:outline-none
                          sm:text-sm
                        "
                      >
                        <ComboboxOption
                          v-for="subtheme in selectedTheme.subthemes"
                          :key="subtheme.id"
                          :value="subtheme"
                          as="template"
                          v-slot="{ active, selected }"
                        >
                          <li
                            :class="[
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                              active
                                ? 'bg-indigo-600 text-white'
                                : 'text-gray-900',
                            ]"
                          >
                            <span
                              :class="[
                                'block truncate',
                                selected && 'font-semibold',
                              ]"
                            >
                              {{ subtheme.title }}
                            </span>

                            <span
                              v-if="selected"
                              :class="[
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-indigo-600',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ComboboxOption>
                      </ComboboxOptions>
                      <ComboboxOptions
                        v-else
                        class="
                          absolute
                          z-10
                          mt-1
                          max-h-60
                          w-full
                          overflow-auto
                          rounded-md
                          bg-white
                          py-1
                          text-base
                          shadow-lg
                          ring-1 ring-black ring-opacity-5
                          focus:outline-none
                          sm:text-sm
                        "
                      >
                        <ComboboxOption
                          v-for="subtheme in subthemes"
                          :key="subtheme.id"
                          :value="subtheme"
                          as="template"
                          v-slot="{ active, selected }"
                        >
                          <li
                            :class="[
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                              active
                                ? 'bg-indigo-600 text-white'
                                : 'text-gray-900',
                            ]"
                          >
                            <span
                              :class="[
                                'block truncate',
                                selected && 'font-semibold',
                              ]"
                            >
                              {{ subtheme.title }}
                            </span>

                            <span
                              v-if="selected"
                              :class="[
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-indigo-600',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ComboboxOption>
                      </ComboboxOptions>
                    </div>
                  </Combobox>
                </div>
              </div>

              <div class="sm:col-span-8">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700"
                >
                  Canal
                </label>
                <div class="mt-1">
                  <select
                    id="channel"
                    name="channel"
                    autocomplete="channel"
                    v-model="form.channel_id"
                    class="
                      shadow-sm
                      focus:ring-indigo-500 focus:border-indigo-500
                      block
                      w-full
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                  >
                    <option
                      v-for="channel in channels"
                      :key="channel.id"
                      :value="channel.id"
                    >
                      {{ channel.title }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="sm:col-span-8">
                <label
                  for="description"
                  class="block text-sm font-medium text-gray-700"
                >
                  Description détaillée de cette sollicitation
                </label>
                <TipTap v-model="fullcontent" class="mt-6 notepad-textarea" />
              </div>
            </div>
            <div class="mt-6">
              <fieldset class="mt-6">
                <legend class="text-base font-medium text-gray-900 mt-6">
                  Priorités de cette sollicitation
                </legend>
                <div class="mt-4 space-y-4">
                  <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="urgent"
                        name="urgent"
                        type="checkbox"
                        v-model="form.is_urgent"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                        "
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="candidates" class="font-medium text-gray-700"
                        >Urgente ?</label
                      >
                      <p class="text-gray-500">
                        Cette sollicitation est-elle urgente et/ou prioritaire ?
                      </p>
                    </div>
                  </div>
                  <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="active"
                        name="active"
                        type="checkbox"
                        v-model="form.is_active"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                        "
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="active" class="font-medium text-gray-700"
                        >Active ?</label
                      >
                      <p class="text-gray-500">
                        Cette notification sera active si cette case est cochée.
                        En d'autres termes, elle sera publiées et les autres
                        membres de l'équipe y auront accès.
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="pt-5">
            <div class="flex justify-end">
              <button
                type="button"
                class="
                  bg-white
                  py-2
                  px-4
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-gray-700
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                "
              >
                Annuler
              </button>
              <button
                @click.prevent="createSollicitation"
                type="submit"
                class="
                  ml-3
                  inline-flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-red-600
                  hover:bg-red-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-red-500
                "
              >
                Créer cette sollicitation
              </button>
            </div>
          </div>
        </form>
        <!-- general infos form ends  -->
      </section>
    </main>
    <!-- Secondary column (hidden on smaller screens) -->
    <aside
      class="
        hidden
        w-96
        bg-white
        border-l border-gray-200
        overflow-y-auto
        lg:block
        pl-4
      "
    >
      <h3 class="text-lg leading-6 font-medium text-gray-900">Bloc-notes</h3>
      <div v-html="notepad" v-if="notepad"></div>
      <div class="mt-2 max-w-xl text-sm text-gray-500" v-else>
        <p>Le bloc-notes attaché à cette personne est vide.</p>
      </div>
    </aside>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import {
  CheckIcon, SelectorIcon, XCircleIcon, InformationCircleIcon, ExclamationIcon,
} from '@heroicons/vue/solid';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue';
import moment from 'moment';
import 'moment/locale/fr';
import TipTap from '@/components/TipTap.vue';

export default ({
  components: {
    TipTap,
    XCircleIcon,
    CheckIcon,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    SelectorIcon,
    InformationCircleIcon,
    ExclamationIcon,
  },
  setup() {
    const prisons = ref();
    const notepad = ref();
    const themes = ref();
    const subthemes = ref([]);
    const subthemechoice = ref(false);
    const channels = ref([]);
    const fullcontent = ref();
    const inmates = ref();
    const contacts = ref();
    const currentstep = ref(1);
    const newsollicitation = ref();
    const errored = ref();
    const search = ref();
    const searchquery = computed(() => search.value.toLowerCase());
    const matchingPrisons = computed(() => prisons.value.filter((prison) => prison.title.toLowerCase().includes(searchquery.value)));
    const chosenprison = ref();
    const query = ref('');
    const selectedTheme = ref();
    const filteredThemes = computed(() => (query.value === ''
      ? themes
      : themes.value.filter((theme) => theme.title.toLowerCase().includes(query.value.toLowerCase()))));
    const querySubtheme = ref('');
    const selectedSubtheme = ref();
    const filteredSubthemes = computed(() => (querySubtheme.value === ''
      ? subthemes
      : subthemes.value.filter((subtheme) => subtheme.title.toLowerCase().includes(querySubtheme.value.toLowerCase()))));

    return {
      prisons,
      notepad,
      themes,
      subthemes,
      subthemechoice,
      channels,
      fullcontent,
      inmates,
      contacts,
      currentstep,
      newsollicitation,
      search,
      matchingPrisons,
      chosenprison,
      form: {
        title: null,
        date: null,
        content: null,
        is_active: false,
        is_urgent: false,
        theme_id: Number,
        subtheme_id: Number,
        channel_id: null,
      },
      errored,
      query,
      selectedTheme,
      filteredThemes,
      querySubtheme,
      selectedSubtheme,
      filteredSubthemes,
    };
  },
  props: {
    inmate: Object,
  },
  created() {
    this.setData();
    this.moment = moment;
  },
  mounted() {
    this.form.prison_id = this.inmate.current_location.id;
    this.$http.get('/api/prisons')
      .then((response) => {
        this.prisons = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    this.$http.get('/api/sollicitations-themes')
      .then((response) => {
        this.themes = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    this.$http.get('/api/sollicitations-subthemes')
      .then((response) => {
        this.subthemes = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    this.$http.get('/api/sollicitations-channels')
      .then((response) => {
        this.channels = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    this.$http.get(`/api/notepad/${this.inmate.id}/`)
      .then((response) => {
        this.notepad = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
  methods: {
    setData() {
      if (this.inmate.sollicitations.length !== 0) {
        const proposeddate = moment(this.inmate.sollicitations[0].date).format('YYYY-MM-DDThh:mm');
        this.form.date = proposeddate;
        this.chosenprison = this.inmate.sollicitations[0].prison;
        this.form.channel_id = this.inmate.sollicitations[0].channel_id;
      }
    },
    chooseSubtheme() {
      this.subthemechoice = true;
      this.$http.get(`/api/sollicitations-subthemes/${this.form.theme_id}`)
        .then((response) => {
          this.subthemes = response.data;
          window.console.log(response.data);
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    choosePrison(chosenPrison) {
      this.chosenprison = chosenPrison;
    },
    createSollicitation() {
      let themeId;
      let subthemeId;
      if (this.selectedSubtheme) {
        themeId = this.selectedSubtheme.theme_id;
        subthemeId = this.selectedSubtheme.id;
      } else {
        themeId = this.selectedTheme.id;
      }
      this.$http.post('/api/sollicitation-creation/', {
        title: this.form.title,
        date: this.form.date,
        content: this.fullcontent,
        is_active: this.form.is_active,
        is_urgent: this.form.is_urgent,
        theme_id: themeId,
        subtheme_id: subthemeId,
        prison_id: this.chosenprison.id,
        channel_id: this.form.channel_id,
      }).then((response) => {
        this.newsollicitation = response.data.id;
        this.$http.patch('api/sollicitation-inmates', {
          id: response.data.id,
          inmate_id: this.inmate.id,
        }).then(() => {
          this.errored = false;
          this.currentstep = 2;
          this.$emit('sollicitationcreated');
        });
      }).catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
    },
  },
});
</script>

<style scoped>
.notepad-textarea {
  border: 1px solid rgba(209, 213, 219, 1);
  border-color: rgba(209, 213, 219, 1) !important;
  padding: 1rem;
  border-radius: 5px;
}
</style>
