import { createWebHistory, createRouter } from 'vue-router';
import Cookies from 'js-cookie';
import client from '@/api/client';
import Insiders from '@/views/Insiders.vue';
import Inmates from '@/views/Inmates.vue';
import Dashboard from '@/views/Dashboard.vue';
import Login from '@/views/Login.vue';
import Inmate from '@/views/Inmate.vue';
import Contacts from '@/views/Contacts.vue';
import Account from '@/views/Account.vue';
import SollicitationCreate from '@/views/SollicitationCreate.vue';
import Sollicitations from '@/views/Sollicitations.vue';
import Sollicitation from '@/views/Sollicitation.vue';
import Prisons from '@/views/Prisons.vue';
import Prison from '@/views/Prison.vue';
import Mediatheque from '@/views/Mediatheque.vue';
import Statistics from '@/views/Statistics.vue';
import Publications from '@/views/Publications.vue';
import PageNotFound from '@/views/PageNotFound.vue';

const routes = [
  {
    path: '/authentification',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Authentification - OIP',
      requiresAuth: false,
    },
  },
  {
    path: '/mon-compte',
    name: 'Account',
    component: Account,
    meta: {
      title: 'Mon compte - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Tableau de bord - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/personnes-detenues/',
    name: 'Inmates',
    component: Inmates,
    meta: {
      title: 'Dossiers - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/personnes-detenues/:tab/:id',
    name: 'Inmate',
    component: Inmate,
    meta: {
      title: 'Dossier - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/contacts/',
    name: 'Contacts',
    component: Contacts,
    meta: {
      title: 'Contacts - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/insiders/',
    name: 'Insiders',
    component: Insiders,
    meta: {
      title: 'Insiders - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/creer-une-sollicitation/',
    name: 'SollicitationCreate',
    component: SollicitationCreate,
    meta: {
      title: 'Créer une sollicitation - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/sollicitations',
    name: 'Sollicitations',
    component: Sollicitations,
    meta: {
      title: 'Sollicitations - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/sollicitations/:id',
    name: 'Sollicitation',
    component: Sollicitation,
    meta: {
      title: 'Sollicitation - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/etablissements',
    name: 'Prisons',
    component: Prisons,
    meta: {
      title: 'Établissements - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/etablissements/:tab/:id',
    name: 'Prison',
    component: Prison,
    meta: {
      title: 'Établissement - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/mediatheque/',
    name: 'Mediatheque',
    component: Mediatheque,
    meta: {
      title: 'Médiathèque - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/publications/:tab/',
    name: 'Publications',
    component: Publications,
    meta: {
      title: 'Publications - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/statistiques/',
    name: 'Statistics',
    component: Statistics,
    meta: {
      title: 'Stats - OIP',
      requiresAuth: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      title: 'Oups... - OIP',
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // the requested route requires authentication
    if (!Cookies.get('token')) {
      // no user is logged in, we redirect to the login page
      next({ name: 'Login' });
    } else if (!localStorage.user) {
      // a user is already logged in, we can route him to the requested page
      const response = await client.get('/api/login/', {
        Authorization: `JWT ${Cookies.get('token')}`,
      });
      localStorage.setItem('user', JSON.stringify(response.data.user));
      next();
    }
    next();
  } else {
    next();
  }
});

export default router;
