<template>
  <TransitionRoot as="template" :show="sendpublications">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Créer un envoi
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white" v-if="inmate">
                      Vous pouvez, à partir d'ici, envoyer une ou plusieurs
                      publications, accompagnées ou non de Passé Par La Case
                      Prison, à {{ inmate.firstname }} {{ inmate.lastname }}
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div
                    class="rounded-md bg-yellow-50 p-4 mb-6"
                    v-if="inmate.current_location === 'Inconnue'"
                  >
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <ExclamationIcon
                          class="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-yellow-800">
                          La localisation n'est pas connue
                        </h3>
                        <div class="mt-2 text-sm text-yellow-700">
                          <p>
                            La localisation de cette personne n'est pas connue,
                            vous devez d'abord créer un événement avec sa
                            localisation actuelle avant de pouvoir demander un
                            envoi de publications.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Define sending content -->
                  <div>
                    <div class="mt-2">
                      <label
                        for="cell"
                        class="block text-sm font-medium text-gray-700"
                        >Numéro de cellule</label
                      >
                      <div class="mt-1">
                        <input
                          type="cell"
                          name="cell"
                          id="cell"
                          v-model="cell_number"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-2">
                      <label
                        for="bat"
                        class="block text-sm font-medium text-gray-700"
                        >Numéro de bâtiment</label
                      >
                      <div class="mt-1">
                        <input
                          type="batiment"
                          name="batiment"
                          id="batiment"
                          v-model="bat_number"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-2">
                      <label
                        for="email"
                        class="block text-sm font-medium text-gray-700"
                        >Ajouter un numéro de Dedans Dehors</label
                      >
                      <div class="mt-1 relative flex items-center">
                        <input
                          @keyup.enter="addIssue"
                          v-model="newissuenumber"
                          type="issue"
                          name="issue"
                          id="issue"
                          class="
                            shadow-sm
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            pr-12
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                          placeholder="32"
                        />
                        <div
                          class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5"
                        >
                          <kbd
                            class="
                              inline-flex
                              items-center
                              border border-gray-200
                              rounded
                              px-2
                              text-sm
                              font-sans font-medium
                              text-gray-400
                            "
                          >
                            Enter
                          </kbd>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Define sending content ends -->
                  <!-- Add is_pplcp -->
                  <SwitchGroup
                    as="div"
                    class="flex items-center justify-between mt-6"
                  >
                    <span class="flex-grow flex flex-col">
                      <SwitchLabel
                        as="span"
                        class="text-sm font-medium text-gray-900"
                        passive
                        >Ajouter un Passé Par La Case Prison</SwitchLabel
                      >
                      <SwitchDescription as="span" class="text-sm text-gray-500"
                        >Il sera ajouté à l'envoi</SwitchDescription
                      >
                    </span>
                    <Switch
                      v-model="addpplc"
                      :class="[
                        addpplc ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                      ]"
                    >
                      <span
                        aria-hidden="true"
                        :class="[
                          addpplc ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                        ]"
                      />
                    </Switch>
                  </SwitchGroup>
                  <!-- Add is_pplcp ends -->
                  <!-- Sending content -->
                  <div class="mt-6">
                    <label
                      for="date"
                      class="block text-sm font-medium text-gray-700"
                      >Contenu de l'envoi</label
                    >
                    <ul role="list" class="divide-y divide-gray-200">
                      <li
                        v-for="issue in sending.issues"
                        :key="issue.id"
                        class="
                          relative
                          bg-white
                          py-5
                          px-4
                          hover:bg-gray-50
                          focus-within:ring-2
                          focus-within:ring-inset
                          focus-within:ring-indigo-600
                        "
                      >
                        <div class="flex justify-between space-x-3">
                          <div class="min-w-0 flex-1">
                            <span class="absolute inset-0" aria-hidden="true" />
                            <p
                              class="text-sm font-medium text-gray-900 truncate"
                            >
                              Dedans Dehors n° {{ issue.issue_number }}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li
                        v-if="addpplc == true"
                        class="
                          relative
                          bg-white
                          py-5
                          px-4
                          hover:bg-gray-50
                          focus-within:ring-2
                          focus-within:ring-inset
                          focus-within:ring-indigo-600
                        "
                      >
                        <div class="flex justify-between space-x-3">
                          <div class="min-w-0 flex-1">
                            <span class="absolute inset-0" aria-hidden="true" />
                            <p
                              class="text-sm font-medium text-gray-900 truncate"
                            >
                              Passé Par la Case Prison
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!-- Sending content ends -->
                  <!-- /End replace -->
                </div>
                <!-- Action buttons -->
                <div
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('shut')"
                    >
                      J'annule
                    </button>
                    <button
                      @click.prevent="createSending"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Créer l'envoi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
  Switch, SwitchDescription, SwitchGroup, SwitchLabel,
} from '@headlessui/vue';
import { ExclamationIcon } from '@heroicons/vue/solid';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    ExclamationIcon,
  },
  setup() {
    const open = ref(true);
    const newissuenumber = ref();
    const addpplc = ref(false);

    return {
      open,
      newissuenumber,
      sending: {
        issues: [],
      },
      addpplc,
      cell_number: null,
      bat_number: null,
    };
  },
  props: {
    inmate: Object,
    sendpublications: Boolean,
  },
  methods: {
    addIssue() {
      this.sending.issues.push({ issue_number: Number(this.newissuenumber) });
      this.newissuenumber = null;
    },
    createSending() {
      this.$http.post('/api/sending-creation/', {
        inmate_id: this.inmate.id,
        issues: this.sending.issues,
        is_pplcp: this.addpplc,
        is_done: false,
        prison_id: this.inmate.current_location.id,
        cell_number: this.cell_number,
        bat_number: this.bat_number,
      }).then((response) => {
        window.console.log(response.data);
        this.showable = false;
        this.addpplc = null;
        this.sending.issues = null;
        this.cell_number = null;
        this.bat_number = null;
        this.$emit('sendingcreated');
        this.$emit('shut');
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
