<template>
  <TransitionRoot as="template" :show="editcontent">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Éditer le contenu
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Vous pouvez, à partir d'ici, modifier le contenu de cette
                      sollicitation.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <label
                    for="description"
                    class="block text-sm font-medium text-gray-900 mt-6"
                  >
                    Date
                  </label>
                  <div class="mt-1">
                    <input
                      id="date"
                      name="date"
                      type="datetime-local"
                      v-model="editeddate"
                      class="
                        shadow-sm
                        focus:ring-indigo-500 focus:border-indigo-500
                        block
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                    />
                  </div>
                  <label
                    for="description"
                    class="block text-sm font-medium text-gray-900 mt-6"
                  >
                    Changer le canal
                  </label>
                  <div>
                    <select
                      id="channel"
                      name="channel"
                      v-model="editedchannel"
                      class="
                        mt-1
                        block
                        w-full
                        pl-3
                        pr-10
                        py-2
                        text-base
                        border-gray-300
                        focus:outline-none
                        focus:ring-indigo-500
                        focus:border-indigo-500
                        sm:text-sm
                        rounded-md
                      "
                    >
                      <option
                        v-for="channel in channels"
                        :key="channel.id"
                        :value="channel.id"
                      >
                        {{ channel.title }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <label
                      for="description"
                      class="block text-sm font-medium text-gray-900 mt-6"
                    >
                      Établissement concerné
                    </label>
                    <div
                      v-if="!chosenprison"
                      class="
                        border border-gray-300
                        rounded-md
                        px-3
                        py-2
                        shadow-sm
                        focus-within:ring-1
                        focus-within:ring-indigo-600
                        focus-within:border-indigo-600
                        mt-2
                      "
                    >
                      <label
                        for="name"
                        class="block text-xs font-medium text-gray-900"
                        >Recherchez par nom d'établissement</label
                      >
                      <input
                        @input="getPrisons"
                        v-model="search"
                        type="text"
                        name="search"
                        id="search"
                        class="
                          block
                          w-full
                          border-0
                          p-0
                          text-gray-900
                          placeholder-gray-500
                          focus:ring-0
                          sm:text-sm
                        "
                        placeholder="ex : Marseille"
                      />
                    </div>
                    <ul
                      role="list"
                      class="-my-5 divide-y divide-gray-200 mt-6"
                      v-if="search && !chosenprison"
                    >
                      <li
                        v-for="prison in matchingPrisons"
                        :key="prison.id"
                        class="py-4"
                      >
                        <div class="flex items-center space-x-4">
                          <div class="flex-1 min-w-0">
                            <p
                              class="text-sm font-medium text-gray-900 truncate"
                            >
                              {{ prison.title }}
                            </p>
                          </div>
                          <div>
                            <a
                              @click.prevent="choosePrison(prison)"
                              class="
                                inline-flex
                                items-center
                                shadow-sm
                                px-2.5
                                py-0.5
                                text-sm
                                leading-5
                                font-medium
                                rounded-full
                                text-white
                                bg-green-500
                                hover:bg-green-700
                                cursor-pointer
                              "
                            >
                              Choisir
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <p
                      class="text-sm text-gray-500 mt-4"
                      v-if="!search && !chosenprison"
                    >
                      Tapez quelques lettres du nom de l'établissement pour
                      choisir l'établissement destinataire du courrier.
                    </p>
                    <div v-if="chosenprison">
                      <span
                        class="
                          inline-flex
                          rounded-full
                          items-center
                          py-0.5
                          pl-2.5
                          pr-1
                          text-sm
                          font-medium
                          bg-indigo-100
                          text-indigo-700
                          mt-2
                        "
                      >
                        {{ chosenprison.title }}
                        <button
                          @click.prevent="chosenprison = null"
                          type="button"
                          class="
                            flex-shrink-0
                            ml-0.5
                            h-4
                            w-4
                            rounded-full
                            inline-flex
                            items-center
                            justify-center
                            text-indigo-400
                            hover:bg-indigo-200 hover:text-indigo-500
                            focus:outline-none
                            focus:bg-indigo-500
                            focus:text-white
                          "
                        >
                          <!-- <span class="sr-only">Remove large option</span> -->
                          <svg
                            class="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-width="1.5"
                              d="M1 1l6 6m0-6L1 7"
                            />
                          </svg>
                        </button>
                      </span>
                    </div>
                    <label
                      for="comment"
                      class="block text-sm font-medium text-gray-700 mt-6"
                      >Modifier le contenu</label
                    >
                    <TipTap
                      v-model="editedcontent"
                      class="mt-6 notepad-textarea"
                    />
                  </div>
                </div>
                <!-- Action buttons -->
                <div
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('shut')"
                    >
                      Annuler
                    </button>
                    <button
                      @click.prevent="saveContentEdition"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Enregistrer les modifications
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { computed, ref } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import TipTap from '@/components/TipTap.vue';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    TipTap,
  },
  setup() {
    const open = ref(true);
    const editedcontent = ref();
    const editeddate = ref();
    const editedchannel = ref();
    const chosenprison = ref();
    const prisons = ref();
    const search = ref();
    const searchquery = computed(() => search.value.toLowerCase());
    const matchingPrisons = computed(() => prisons.value.filter((prison) => prison.title.toLowerCase().includes(searchquery.value)));
    const channels = ref();

    return {
      open,
      editedcontent,
      chosenprison,
      search,
      prisons,
      matchingPrisons,
      editedchannel,
      channels,
      editeddate,
    };
  },
  props: {
    sollicitation: Object,
    editcontent: Boolean,
  },
  created() {
    this.setData();
    this.moment = moment;
    this.$http.get('/api/sollicitations-channels')
      .then((response) => {
        this.channels = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
  methods: {
    setData() {
      this.editedcontent = this.sollicitation.content;
      this.chosenprison = this.sollicitation.prison;
      this.editedchannel = this.sollicitation.channel_id;
      const proposeddate = moment(this.sollicitation.date).format('YYYY-MM-DDThh:mm');
      this.editeddate = proposeddate;
    },
    getPrisons() {
      this.$http.get('api/prisons')
        .then((response) => {
          this.prisons = response.data;
        })
        .catch((error) => {
          window.console.log(error);
          this.errored = true;
        });
    },
    choosePrison(chosenPrison) {
      this.chosenprison = chosenPrison;
    },
    saveContentEdition() {
      window.console.log(this.editedchannel);
      this.$http.patch('api/sollicitation-content', {
        date: this.editeddate,
        id: this.sollicitation.id,
        content: this.editedcontent,
        prison_id: this.chosenprison.id,
        channel_id: this.editedchannel,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('contentedited');
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
