<!-- eslint-disable max-len -->
<template>
  <div class="min-h-full">
    <div class="bg-gray-800 pb-32">
      <AppNavbar />
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">Statistiques</h1>
        </div>
      </header>
    </div>

    <main class="-mt-32">
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
          <Counters />
        </div>
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            6 thématiques les plus fréquentes
          </h3>
          <BarChart :chartData="thematics" class="mt-12" />
        </div>
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Sollicitations par DISP
          </h3>
          <BarChart :chartData="disps" class="mt-12" />
        </div>
        <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Sollicitations par canal
          </h3>
          <DoughnutChart :chartData="channels" class="mt-12" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import AppNavbar from '@/components/AppNavbar.vue';
import Counters from '@/components/Stats/Counters.vue';
import { BarChart, DoughnutChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default {
  components: {
    AppNavbar,
    Counters,
    BarChart,
    DoughnutChart,
  },
  setup() {
    const thematics = ref();
    const disps = ref();
    const channels = ref();
    return {
      thematics,
      disps,
      channels,
    };
  },
  created() {
    this.$http.get('api/thematics/')
      .then((response) => {
        this.thematics = response.data;
        this.thematics.labels = response.data.labels;
        this.thematics.datasets = [];
        this.thematics.datasets.push({
          label: '6 principaux thèmes des sollicitations',
          indexAxis: 'y',
          data: response.data.counts,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(201, 203, 207, 0.2)',
          ],
          borderWidth: 1,
        });
      })
      .catch((error) => {
        window.console.log(error);
      });
    this.$http.get('api/disps_statistics/')
      .then((response) => {
        this.disps = response.data;
        this.disps.labels = response.data.labels;
        this.disps.datasets = [];
        this.disps.datasets.push({
          label: 'Sollicitations par DISP',
          indexAxis: 'y',
          data: response.data.counts,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(178, 102, 255, 0.2)',
            'rgba(278, 256, 207, 0.2)',
            'rgba(102, 162, 235, 0.2)',
            'rgba(153, 102, 105, 0.2)',
            'rgba(201, 203, 207, 0.2)',
          ],
          borderWidth: 1,
        });
      })
      .catch((error) => {
        window.console.log(error);
      });
    this.$http.get('api/channel_statistics/')
      .then((response) => {
        this.channels = response.data;
        this.channels.labels = response.data.labels;
        this.channels.datasets = [];
        this.channels.datasets.push({
          label: 'Sollicitations par canal',
          data: response.data.counts,
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(34, 205, 135, 0.2)',
            'rgba(75, 192, 192, 0.2)',
          ],
          borderWidth: 1,
        });
      })
      .catch((error) => {
        window.console.log(error);
      });
  },
};
</script>
