<template>
  <TransitionRoot as="template" :show="editinmate">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div
                class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="py-6 px-4 bg-gray-800 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      Modifier ou corriger
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="
                          rounded-md
                          text-white
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                        @click="$emit('shut')"
                      >
                        <span class="sr-only">Fermer</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-white">
                      Vous pouvez modifier, ici, le nom et prénom de cette
                      personne, ou même son genre.
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div>
                    <label
                      for="firstname"
                      class="block text-sm font-medium text-gray-700"
                      >Prénom</label
                    >
                    <div class="mt-1">
                      <input
                        type="text"
                        name="firstname"
                        id="firstname"
                        v-model="edition.firstname"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div class="mt-2">
                    <label
                      for="firstname"
                      class="block text-sm font-medium text-gray-700"
                      >Nom</label
                    >
                    <div class="mt-1">
                      <input
                        type="text"
                        name="lastname"
                        id="lastname"
                        v-model="edition.lastname"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div class="mt-2">
                    <label
                      for="firstname"
                      class="block text-sm font-medium text-gray-700"
                      >Année de naissance</label
                    >
                    <div class="mt-1">
                      <input
                        type="text"
                        name="birthdate"
                        id="birthdate"
                        maxlength="4"
                        v-model="edition.birthdate"
                        class="
                          shadow-sm
                          focus:ring-indigo-500 focus:border-indigo-500
                          block
                          w-full
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                      />
                    </div>
                  </div>
                  <div class="mt-2">
                    <label
                      for="genre"
                      class="block text-sm font-medium text-gray-700"
                      >Genre</label
                    >
                    <div class="mt-1">
                      <select
                        id="genre"
                        name="genre"
                        v-model="edition.gender_id"
                        class="
                          mt-1
                          block
                          w-full
                          pl-3
                          pr-10
                          py-2
                          text-base
                          border-gray-300
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                          rounded-md
                        "
                      >
                        <option
                          v-for="gender in genders"
                          :key="gender.id"
                          :value="gender.id"
                        >
                          {{ gender.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- /End replace -->
                </div>
                <!-- Action buttons -->
                <div
                  class="
                    flex-shrink-0
                    px-4
                    border-t border-gray-200
                    py-5
                    sm:px-6
                  "
                >
                  <div class="space-x-3 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('shut')"
                    >
                      Annuler
                    </button>
                    <button
                      @click.prevent="updateInmate"
                      type="submit"
                      class="
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                    >
                      Modifier cette personne
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const open = ref(true);
    const genders = ref();

    return {
      genders,
      open,
      edition: {
        firstname: null,
        lastname: null,
        birthdate: null,
        gender_id: null,
      },
    };
  },
  created() {
    this.edition.firstname = this.inmate.firstname;
    this.edition.lastname = this.inmate.lastname;
    this.edition.birthdate = this.inmate.birthdate;
    this.edition.gender_id = this.inmate.gender_id;
    this.$http.get('/api/genders')
      .then((response) => {
        this.genders = response.data;
      })
      .catch((error) => {
        window.console.log(error);
        this.errored = true;
      });
  },
  props: {
    inmate: Object,
    editinmate: Boolean,
  },
  methods: {
    updateInmate() {
      this.$http.patch('api/inmate-basic-infos-update', {
        id: this.inmate.id,
        firstname: this.edition.firstname,
        lastname: this.edition.lastname,
        birthdate: this.edition.birthdate,
        gender_id: this.edition.gender_id,
      }).then((response) => {
        window.console.log(response.data);
        this.$emit('inmateupdated');
      }).catch((error) => {
        window.console.log(error);
      });
    },
  },
};
</script>
